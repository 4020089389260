import { useModals } from "@mantine/modals";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function useUnSavedChanges({ onConfirm }) {
  const [unSavedChanges, setUnsavedChanges] = useState(false);
  const modals = useModals();
  const { t } = useTranslation();
  const openConfirmationModal = () => {
    if (unSavedChanges) {
      modals.openContextModal("confirmation", {
        //   title: t('Delete Media File'),
        centered: true,
        radius: "lg",
        innerProps: {
          title: t("Unsaved changes"),
          modalBody: t(
            "You have unsaved changes. Would you like to continue without saving?"
          ),
          onCancel: () => console.log("Cancel"),
          onConfirm: onConfirm,
        },
      });
    } else {
      onConfirm();
    }
  };

  return { unSavedChanges, setUnsavedChanges, openConfirmationModal };
}

export default useUnSavedChanges;
