import { Box, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { generateRandomId } from "../../utils/utils";
import MTKInput from "../Input";
import GalleryMediaUploader from "./components/GalleryMediaUploader";
import YoutubePlayer from "./components/YoutubePlayer";

const generateItem = () => {
  const newItem = {
    id: generateRandomId(),
    url: null,
    type: null,
    isNew: true,
  };
  return newItem;
};

function GalleryUploader({
  ratio = 16 / 9,
  recommendedAspectRatio,
  files,
  setFiles,
  readyOnly = false,
}) {
  const { t } = useTranslation();

  //   const [files, setFiles] = useState([

  //   ]);
  const [selectedFile, setSelectedFile] = useState(files[0]?.id);
  const [selectedFileData, setSelectedFileData] = useState(files[0]);

  useEffect(() => {
    if (selectedFile) {
      setSelectedFileData(files.find((d) => d.id === selectedFile));
    }
  }, [selectedFile]);
  const onDelete = (id) => {
    setFiles((f) => {
      const files = f.filter((d) => d.id !== id);

      if (files.length === 0) {
        const newItem = generateItem();
        setSelectedFile(newItem.id);
        return [newItem];
      }
      if (files.length > 0) {
        setSelectedFile(files[0]?.id);
      }
      return files;
    });
  };

  const changeFiles = ({ url, type, file, isNew, isYoutube = false }) => {
    if (selectedFile) {
      const newItem = generateItem();
      setFiles((f) =>
        f.map((d) =>
          d.id === selectedFile
            ? { ...newItem, url, type, file, isNew, isYoutube }
            : d
        )
      );
      setSelectedFile(newItem.id);
    } else {
      setFiles((f) => [
        ...f,
        { ...generateItem(), url, type, file, isNew, isYoutube },
      ]);
    }
  };
  const createNewItem = () => {
    if (readyOnly) return;
    const item = generateItem();
    setFiles((f) => {
      return [...f, item];
    });
    setTimeout(() => {
      setSelectedFile(item.id);
    }, 100);
  };
  useEffect(() => {
    const selectedFileIn = files?.find((d) => d.id === selectedFile);

    if (
      (selectedFile && !selectedFileIn) ||
      (files.length > 0 && !selectedFile)
    ) {
      setSelectedFile(files[0]?.id);
    } else if (files.length === 0) {
      createNewItem();
    }
  }, [files, selectedFile]);
  return (
    <Box
      mb="40px"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {selectedFileData?.isYoutube ? (
        <YoutubePlayer url={selectedFileData?.url} />
      ) : (
        <GalleryMediaUploader
          ratio={ratio}
          onChange={changeFiles}
          value={selectedFile ? files.find((d) => d.id === selectedFile) : null}
          objectDescription={t("a video or image")}
          recommendedAspectRatio={recommendedAspectRatio}
          readyOnly={readyOnly || selectedFileData?.isYoutube}
        />
      )}
      {!readyOnly && (
        <MTKInput
          label={`${t("or Youtube URL")}`}
          labelColor="blue"
          disabled={
            selectedFileData &&
            selectedFileData?.url &&
            !selectedFileData?.isYoutube
          }
          value={
            selectedFileData && selectedFileData.isYoutube
              ? selectedFileData?.url
              : ""
          }
          onChange={(e) =>
            changeFiles({
              url: e.target.value,
              // type: "youtube",
              type: "video",
              isYoutube: true,
            })
          }
          color="green"
        />
      )}
      <Box
        sx={{
          flex: "1 1 0%",
          display: "flex",
          overflowX: "auto",
          alignItems: "flex-start",
          padding: "10px 0",
        }}
      >
        {files.map((file, i) => (
          <ItemBox
            key={file.id}
            index={i}
            total={files.length}
            selected={selectedFile === file.id}
            onSelect={() => setSelectedFile(file.id)}
            onDelete={() => onDelete(file.id)}
            readyOnly={readyOnly}
            {...file}
          />
        ))}
        {!readyOnly && <ItemBox plus createNewItem={createNewItem} />}
      </Box>
    </Box>
  );
}

const ItemBox = ({
  type,
  selected = false,
  index,
  total,
  plus = false,
  createNewItem,
  onSelect,
  onDelete,
  readyOnly = false,
}) => {
  return (
    <Box
      sx={{
        border: "1px solid rgb(1, 158, 211)",
        borderRadius: "8px",
        marginRight: "10px",
        width: "100px",
        minWidth: "100px",
        height: "70px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "rgb(1, 158, 211)",
        fontSize: " 14px",
        opacity: selected || plus ? 1 : 0.4,
        position: "relative",
      }}
      onClick={plus ? createNewItem : onSelect}
    >
      {plus ? (
        <Box>+</Box>
      ) : selected ? (
        <Text>
          {1 + index}/{total}
        </Text>
      ) : (
        type || "image"
      )}

      {selected && !readyOnly && (
        <Box
          onClick={onDelete}
          sx={{
            border: "1px solid rgb(1, 158, 211)",
            borderRadius: "25px",
            position: "absolute",
            bottom: -1,
            right: -1,
            width: "20px",
            textAlign: "center",
            cursor: "pointer",
          }}
        >
          X
        </Box>
      )}
    </Box>
  );
};
export default GalleryUploader;
