import { Box, createStyles, Tabs, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getClassromByID } from "../../../api/Classrooms";
import {
  deleteCourse,
  getCourseByID,
  updateCourse,
} from "../../../api/Courses";
import {
  getGamesOfCourse,
  getOtherGamesOfCourse,
  updateGame,
} from "../../../api/Games";
import { getOrganizationByID } from "../../../api/Organisations";
import { getSchoolYearByID } from "../../../api/SchoolYear";
import { downloadFileFromStorage } from "../../../api/Storage";
import BackButtonCircle from "../../../components/BackButtonCircle";
import MTKBreadcrumbs from "../../../components/Breadcrumbs";
import AppLayout from "../../../components/layout/AppLayout";
import LoadingScreen from "../../../components/layout/LoadingScreen";
import SubmitBottomBar from "../../../components/SubmitBottomBar";
import MTKTabs from "../../../components/Tabs";
import GlobalContext from "../../../context/GlobalContext";
import useIsSuperAdmin from "../../../hooks/useIsSuperAdmin";
import { withLanguage } from "../../../lib/i18";
import {
  getJewishSchoolYear,
  getQueryParams,
  isDirectorOfOrganisation,
  isTeacher as isTeacherF,
} from "../../../utils/utils";
import useCourseForm from "../NewCourse/useCourseForm";
import AboutTab from "./components/AboutTab";
import GamesTab from "./components/GamesTab";
import ReportsTab from "./components/ReportsTab";
const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    position: "sticky",
    top: 0,
    padding: "50px 0",
    zIndex: 3,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "20px",
    [`@media screen and (max-width: 900px)`]: {
      padding: "30px 0",
    },
  },
  headerText: {
    marginBottom: "30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));
function CourseDetails({ type: pageTab }) {
  const { courseId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState(null);
  const [classroom, setClassroom] = useState([]);
  const [organisation, setOrganisation] = useState([]);
  const [schoolYear, setSchoolYear] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(true);
  const { form, onFormSubmit, loading, setDefaultData } = useCourseForm({
    update: true,
  });
  const { profile } = useContext(GlobalContext);
  const { classes } = useStyles();
  const location = useLocation();
  const queryParams = getQueryParams(location);

  const { back } = queryParams;
  useEffect(() => {
    if (courseId && refreshData) {
      setPageLoading(true);
      getCourseByID(courseId).then(async (data) => {
        const games = await getGamesOfCourse(courseId);
        const otherGames = await getOtherGamesOfCourse(courseId);
        setData({ ...data, games, otherGames });
        setRefreshData(false);
      });
    }
  }, [courseId, refreshData]);

  useEffect(() => {
    const getData = async () => {
      const classroom = await getClassromByID(data.classroomId);
      setClassroom(classroom);

      const [organisation, schoolYear] = await Promise.all([
        getOrganizationByID(classroom.organisationId),
        getSchoolYearByID(classroom.schoolYearId),
      ]);

      setOrganisation(organisation);
      setSchoolYear(schoolYear);
    };

    if (data?.classroomId) {
      getData();
    }
  }, [data]);

  useEffect(() => {
    const getData = async () => {
      let audioFile = null;
      let coverImage = null;

      if (data?.audioFile && data?.audioFile?.id) {
        const file = await downloadFileFromStorage({
          path: `Audio/${data.audioFile.id}/audio`,
          name: data.audioFile?.name || "audioFile",
          type: data.audioFile?.type || "audio/mpeg",
        });
        audioFile = file;
      }

      if (data?.coverImage && data?.coverImage?.id) {
        const file = await downloadFileFromStorage({
          path: `Images/${data.coverImage.id}/image`,
          name: data.coverImage?.name || "coverImage",
          type: data.coverImage?.type || "coverImage",
        });
        coverImage = file;
      }
      setDefaultData(
        {
          ...data,
          coverImage,
          audioFile,
        },
        { ...data, id: courseId }
      );
      setPageLoading(false);
    };
    if (data && pageLoading) {
      getData();
    }
  }, [data, pageLoading]);

  const [activeTab, setActiveTab] = useState(
    ["about", "games", "reports"].indexOf(pageTab)
  );
  const [activeType, setActiveType] = useState(pageTab);

  const onChange = (active, tabKey) => {
    setActiveTab(active);
    setActiveType(tabKey);
    history.push(withLanguage(`/courses/${courseId}/${tabKey}`));
  };

  useEffect(() => {
    // console.log(type, activeType);
    if (pageTab !== activeType) {
      setActiveType(pageTab);
    }
  }, [pageTab, activeType]);

  const addGameToReport = async (id) => {
    updateGame(id, { addedToReport: true }).then(() => {
      setData((d) => ({
        ...d,
        games: d?.games?.map((game) =>
          game?.id === id ? { ...game, addedToReport: true } : game
        ),
      }));
    });
  };
  const removeGameFromReport = (id) => {
    updateGame(id, { addedToReport: false }).then(() => {
      setData((d) => ({
        ...d,
        games: d?.games?.map((game) =>
          game?.id === id ? { ...game, addedToReport: false } : game
        ),
      }));
    });
  };
  const onDeleteCourse = async () => {
    // setLoading
    await deleteCourse(courseId, data?.classroomId);
    history.push(withLanguage("/courses"));
  };
  // if (!data) return <LoadingScreen />;

  const { name } = form.values;

  const saveGamesOrder = async (games) => {
    const gamesIds = games.map((c) => c.id);

    const data = {
      gamesOrder: gamesIds,
    };

    try {
      await updateCourse(courseId, data);
      setData((d) => ({ ...d, gamesOrder: gamesIds }));
    } catch (error) {
      console.log(error);
    }
  };

  const { games = [], gamesOrder = [], otherGames } = data || {};
  const getPageTitle = () => {
    return name || t("Course");
  };

  const isAdmin = useIsSuperAdmin();
  const isDirector = isDirectorOfOrganisation(profile, organisation);
  const isTeacher = isTeacherF(profile);
  let navigateBackToLink;

  if (back) {
    navigateBackToLink = back;
  } else {
    if (isAdmin || isDirector || isTeacher) {
      navigateBackToLink = withLanguage(`/classrooms/${classroom?.id}`);
    } else {
      navigateBackToLink = withLanguage(`/courses/all`);
    }
  }
  return (
    <AppLayout loading={!data || pageLoading} title={getPageTitle()}>
      <Box className={classes.header}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            overflowX: "hidden",
          }}
          mb="40px"
        >
          <Box
            mb="md"
            sx={{
              minWidth: "350px",
              maxWidth: "100%",
              overflowX: "hidden",
            }}
          >
            <MTKBreadcrumbs
              data={[
                {
                  title: organisation?.name,
                  url: `/organisations/${organisation?.id}`,
                },
                {
                  title: classroom?.name,
                  url: `/classrooms/${classroom?.id}`,
                },
              ]}
            />
            <Box
              mb="md"
              sx={{
                display: "flex",
              }}
            >
              <BackButtonCircle
                mr="md"
                onClick={() => history.push(navigateBackToLink)}
              />
              <Title order={3}>{name}</Title>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                background: "rgb(254, 215, 0)",
                color: "rgb(255, 255, 255)",
                fontSize: "12px",
                padding: "5px",
                display: "block",
                borderRadius: "8px",
              }}
            >
              {t("School Year")}
              {getJewishSchoolYear(schoolYear?.key)}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            overflowX: "auto",
          }}
        >
          <MTKTabs active={activeTab} onTabChange={onChange}>
            <Tabs.Tab label={t("Settings")} tabKey={"about"}></Tabs.Tab>
            <Tabs.Tab label={t("Games")} tabKey={"games"}></Tabs.Tab>
            <Tabs.Tab label={t("Reports")} tabKey={"reports"}></Tabs.Tab>
          </MTKTabs>
        </Box>
      </Box>

      {activeType === "about" && (
        <AboutTab
          onDeleteCourse={onDeleteCourse}
          form={form}
          organisation={organisation}
          schoolYear={schoolYear}
          classroom={classroom}
        />
      )}
      {activeType === "games" && (
        <GamesTab
          games={games}
          gamesOrder={gamesOrder}
          addGameToReport={addGameToReport}
          removeGameFromReport={removeGameFromReport}
          courseId={courseId}
          organisationId={organisation?.id}
          classroomId={classroom?.id}
          saveGamesOrder={saveGamesOrder}
          //   courses={courses}
          //   teachers={teachers}
          //   onRemoveTeacher={onRemoveTeacher}
          //   addTeacherCallback={addTeacherCallback}
        />
      )}
      {activeType === "reports" && (
        <ReportsTab
          games={games}
          otherGames={otherGames}
          classroom={classroom}
          organisation={organisation}
          courseId={courseId}
          refreshData={() => setRefreshData(true)}
          //   courses={courses}
          //   teachers={teachers}
          //   coursesOrder={coursesOrder}
        />
      )}

      {activeType === "about" && (
        <SubmitBottomBar
          okText={t("Save")}
          onCreate={form.onSubmit(onFormSubmit)}
          // onCreate={() => {
          //   console.log(form.errors);
          // }}
          onCancel={() => history.push(withLanguage("/courses/"))}
          loading={loading}
        />
      )}
    </AppLayout>
  );
}

export default CourseDetails;
