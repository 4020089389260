import { SimpleGrid, Box } from "@mantine/core";
import React from "react";

function FormSection({
  id = "",
  title = "",
  description = "",
  layout = "column",
  margin = "0px 0px 0px 0px",
  color = "blue",
  transparentBg = false,
  contentPadding = "30px 20px",
  children,
  ...props
}) {
  let bgColor = "#eff7ff";
  let fontColor = "#019ed3";

  if (color === "green") {
    bgColor = "#EDF7E9";
    fontColor = "#93D578";
  }

  if (transparentBg) {
    bgColor = "transparent";
  }

  // if (layout === 'column') {
  //   return (
  //     <ContainerColumn id={id} margin={margin}>
  //       <Title color={fontColor}>{title}</Title>
  //       <ContentColumn color={bgColor}>{children}</ContentColumn>
  //     </ContainerColumn>
  //   );
  // } else if (layout === 'row') {
  return (
    <Row id={id} margin={margin} {...props}>
      <Header>
        <Title color={fontColor}>{title}</Title>
        <Description>{description}</Description>
      </Header>
      <ContentRow color={bgColor} padding={contentPadding}>
        {children}
      </ContentRow>
    </Row>
  );
  // }
}

export default FormSection;

const Row = ({ children, id, margin, ...props }) => {
  return (
    <Box
      sx={{
        margin: `${margin}`,
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
        /* padding-right: 40px, */
        "& ul, ol": {
          marginLeft: "30px",
          marginBottom: "30px",
        },
        "@media screen and (max-width: 1200px)": {
          /* background: red, */
          flexDirection: "column",
        },
      }}
      id={id}
      margin={margin}
      {...props}
    >
      {" "}
      {children}
    </Box>
  );
};

const Header = ({ children }) => {
  return (
    <Box
      sx={{
        paddingLeft: "37px",
        width: "50%",
        paddingRight: "40px",
        "@media screen and (max-width: 1200px)": {
          width: "100%",
          paddingLeft: "0",
          marginBottom: "30px",
        },
      }}
    >
      {children}
    </Box>
  );
};

const Title = ({ children, color }) => {
  return (
    <Box
      component="h3"
      sx={{
        fontSize: "24px",
        /* color: #019ed3, */
        color: color,
        paddingLeft: "10px",
        marginTop: 0,
        marginBottom: "20px",
      }}
    >
      {children}
    </Box>
  );
};

const Description = ({ children }) => {
  return (
    <Box
      sx={{
        fontSize: "16px",
        color: "#bbbbbb",
        paddingLeft: "10px",
      }}
    >
      {children}
    </Box>
  );
};

const ContentRow = ({ children, color, padding }) => {
  return (
    <Box
      sx={{
        /* background: #eff7ff; */
        background: color,
        borderRadius: "22px",
        /* padding: 30px 20px; */
        padding: padding,
        width: "50%",
        ["@media screen and (max-width: 1200px)"]: {
          width: "100%",
        },
      }}
    >
      {children}
    </Box>
  );
};
