import {
  getDocs,
  getDoc,
  collection,
  query,
  where,
  setDoc,
  deleteDoc,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../lib/firebase";

//   export const getClassroomsOfUser = async (id, allowList = []) => {
//     const classroomList = await getClassrooms();
//     return classroomList?.filter(
//       (classroom) =>
//         classroom?.createdByUserId === id ||
//         (classroom?.teachers && classroom?.teachers[id]) ||
//         allowList?.includes(classroom.id)
//     );
//   };

export const getStudentsAnswerOfGame = async (gameId) => {
  const studentsAnswerRef = collection(db, "StudentAnswers");
  const q = query(studentsAnswerRef, where("gameId", "==", gameId));
  const querySnapshot = await getDocs(q);

  const studentsAnswers = await Promise.all(
    querySnapshot.docs.map(async (ans) => {
      const answerData = {
        id: ans.id,
        ...ans.data(),
      };
      const ansRef = collection(db, "StudentAnswers", ans.id, "Answers");
      const ansRes = await getDocs(ansRef);

      const answers = ansRes.docs.map((d) => ({
        id: d.id,
        ...d.data(),
      }));

      return { ...answerData, answers };
    })
  );
  return studentsAnswers;
};
