import { Select } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

function MTKSelect({
  labelColor = "blue",
  data = [],
  label = "",
  onChange,
  onChangeTopic = (a) => {},
  onChangeSubTopic = (a) => {},
  value,
  test = false,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <Select
      label={label}
      placeholder={t("Select an option...")}
      size={"md"}
      my="md"
      data={data}
      value={value}
      onChange={(v) => {
        onChange(v);
        onChangeTopic("---");
        onChangeSubTopic("---");
      }}
      styles={(theme) => ({
        label: {
          color: labelColor === "blue" ? theme.colors.blue : "#00000055",
          fontSize: "14px",
        },

        input: {
          color: theme.colors.blue,
          background: "transparent",
        },
        defaultVariant: {
          borderColor: `${theme.colors.blue}`,
        },
      })}
      {...props}
    />
  );
}

export default MTKSelect;
