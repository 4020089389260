import {
  ActionIcon,
  Box,
  Grid,
  Select,
  Tabs,
  Title,
  createStyles,
} from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import MTKButton from "../../../components/Button";
import AppLayout from "../../../components/layout/AppLayout";
import MTKTabs from "../../../components/Tabs";
import { withLanguage } from "../../../lib/i18";
import { ReactComponent as Plus } from "../../../assets/images/icons/plus.svg";
import { getOrganisations } from "../../../api/Organisations";
import { getSchoolYears } from "../../../api/SchoolYear";
import { getClassrooms } from "../../../api/Classrooms";
import { getCourses, getCoursesOfClasssroom } from "../../../api/Courses";
import CoursesTab from "./components/CoursesTab";
import FindCoursesTab from "./components/FindCoursesTab";
import useIsSuperAdmin from "../../../hooks/useIsSuperAdmin";

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    position: "sticky",
    top: 0,
    padding: "30px 0",
    zIndex: 2,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "0px",
    paddingBottom: "10px",
  },
  headerText: {
    marginBottom: "20px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "300px",
  },
}));
function Courses({ type: pageTab }) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const isAdmin = useIsSuperAdmin();
  const [activeTab, setActiveTab] = useState(
    pageTab === "all" || pageTab === "my-courses" ? 0 : 1
  );
  useEffect(() => {
    setActiveTab(pageTab === "all" || pageTab === "my-courses" ? 0 : 1);
  }, [pageTab]);
  const [activeType, setActiveType] = useState(pageTab);

  const onChange = (active, tabKey) => {
    setActiveTab(active);
    setActiveType(tabKey);
    if (tabKey === "find-courses-and-games") {
      history.push(withLanguage(`/find-courses-and-games`));
    } else {
      history.push(withLanguage(`/courses/`));
    }
  };
  const [organisations, setOrganisations] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [courses, setCourses] = useState([]);

  //
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [selectedClassroom, setSelectedClassroom] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const [organisations, schoolYears, classrooms] = await Promise.all([
        getOrganisations(),
        getSchoolYears(),
        getClassrooms(),
      ]);
      const FilteredOrganisations = organisations.filter((d) =>
        classrooms.find((classroom) => classroom.organisationId === d.id)
      );
      setOrganisations(FilteredOrganisations);

      setSchoolYears(
        schoolYears.filter((d) =>
          classrooms.find((classroom) => classroom.schoolYearId === d.id)
        )
      );
      setClassrooms(classrooms);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const courses = await getCourses();
      setCourses(courses);
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <AppLayout loading={loading} title={t("Courses")}>
      <Box className={classes.header}>
        <Box
          mb="20px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box className={classes.headerText}>
            <Title order={3}>{t("Courses")}</Title>
          </Box>
          <MTKButton
            sx={{
              [`@media screen and (max-width: 900px)`]: {
                display: "none",
              },
            }}
            color="blue"
            leftIcon={<Plus />}
            onClick={() =>
              history.push(
                withLanguage(
                  `/courses/new?prefillId=${selectedOrganisation || ""}`
                )
              )
            }
          >
            {t("New Course")}
          </MTKButton>
        </Box>
        <Box
          sx={{
            overflowX: "auto",
          }}
        >
          {isAdmin ? (
            <MTKTabs active={activeTab} onTabChange={onChange}>
              <Tabs.Tab label={t("All Courses")} tabKey={"all"} />
              <Tabs.Tab
                label={t("Find Courses & Games")}
                tabKey={"find-courses-and-games"}
              />
            </MTKTabs>
          ) : (
            <MTKTabs active={activeTab} onTabChange={onChange}>
              <Tabs.Tab label={t("My Courses")} tabKey={"my-courses"} />
              <Tabs.Tab
                label={t("Find Courses & Games")}
                tabKey={"find-courses-and-games"}
              />
            </MTKTabs>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          display: "none",
          [`@media screen and (max-width: 900px)`]: {
            position: "fixed",
            bottom: "25px",
            right: "25px",
            display: "flex",
            flexDirection: "column",
            zIndex: "1",
          },
        }}
      >
        <ActionIcon
          component={Link}
          to={withLanguage("/courses/new")}
          size={"xl"}
          radius={"xl"}
          variant="filled"
          sx={(theme) => ({
            backgroundColor: theme.colors.blue,
            width: "50px",
            height: "50px",
          })}
          styles={(theme) => ({
            filled: {
              "&:hover": {
                backgroundColor: theme.colors.blue,
              },
            },
          })}
        >
          <Plus width={"40px"} />
          {/* {t("New Organisation")} */}
        </ActionIcon>
      </Box>
      {pageTab === "find-courses-and-games" ? (
        <FindCoursesTab />
      ) : (
        <CoursesTab
          classrooms={classrooms}
          organisations={organisations}
          schoolYears={schoolYears}
          selectedOrganisation={selectedOrganisation}
          setSelectedOrganisation={setSelectedOrganisation}
          selectedSchoolYear={selectedSchoolYear}
          setSelectedSchoolYear={setSelectedSchoolYear}
          selectedClassroom={selectedClassroom}
          setSelectedClassroom={setSelectedClassroom}
          courses={courses}
          setCourses={setCourses}
        />
      )}
    </AppLayout>
  );
}

export default Courses;
