import { Box, Image, Text, Title } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getFileDownloadURL } from "../../../api/Storage";
import placeholderImage from "../../../assets/images/icons/image.png";
import { withLanguage } from "../../../lib/i18";
import { getJewishSchoolYear } from "../../../utils/utils";

function ClassroomCard({
  name,
  coverImage,
  withYear = false,
  withOrg = false,
  id,
  ...props
}) {
  const [coverURL, setCoverURL] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (coverImage && coverImage?.id) {
      getFileDownloadURL({
        path: `Images/${coverImage.id}/image`,
      }).then((url) => setCoverURL(url));
    }
  }, [coverImage]);
  return (
    <Box
      // my="sm"
      sx={{
        position: "relative",
        padding: "18px",
        background: "rgb(239, 247, 255)",
        borderRadius: "22px",
        boxShadow: "rgb(0 0 0 / 7%) 0px 2px 4px 0px",
        cursor: "pointer",
      }}
      onClick={() => history.push(withLanguage(`/classrooms/${id}`))}
    >
      {withYear ? (
        <Box
          sx={{
            position: "absolute",
            right: "15px",
            top: "15px",
            background: "rgb(254, 215, 0)",
            color: "rgb(255, 255, 255)",
            fontSize: "12px",
            padding: "4px",
            // display: 'inline-block',
            borderRadius: "8px",
            zIndex: "6",
          }}
        >
          {getJewishSchoolYear(withYear)}
        </Box>
      ) : null}
      {coverURL ? (
        <Image width="150px" height={150} radius="lg" src={coverURL} />
      ) : (
        <PlaceHolderImage />
      )}
      {withOrg ? (
        <Text
          sx={{
            fontSize: "15px",
            color: "rgb(1, 158, 211)",
            marginBottom: "5px",
            marginTop: "20px",
          }}
        >
          {withOrg}
        </Text>
      ) : null}
      <Title
        sx={{
          maxWidth: "298px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: "20px",
          fontWeight: "bold",
          color: "rgb(1, 158, 211)",
          marginTop: withOrg ? 0 : "20px",
          //   paddingLeft: "18px",
          //   paddingRight: "18px",
          //   paddingBottom: "18px",
        }}
      >
        {name}
      </Title>
    </Box>
  );
}

const PlaceHolderImage = () => {
  return (
    <Box
      sx={{
        width: "150px",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgb(250, 250, 250)",
        borderRadius: "22px",
      }}
    >
      <Image width={54} src={placeholderImage} />
    </Box>
  );
};
export default ClassroomCard;
