import {
  getDocs,
  getDoc,
  collection,
  query,
  where,
  setDoc,
  deleteDoc,
  addDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { updateClassroom } from "./Classrooms";
import { duplicateGames, getGamesOfCourse } from "./Games";

export const getCourses = async () => {
  const res = await getDocs(collection(db, "Courses"));
  const CoursesList = res.docs.map((doc) => ({
    id: doc.id,

    ...doc.data(),
  }));
  return CoursesList;
};

export const getCoursesOfClasssroom = async (classroomId) => {
  const coursesRef = collection(db, "Courses");
  const q = query(coursesRef, where("classroomId", "==", classroomId));
  const querySnapshot = await getDocs(q);

  const res = querySnapshot;
  const coursesList = res.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return coursesList;
};

export const getCourseByID = async (id) => {
  const res = await getDoc(doc(db, "Courses", id));
  if (res.exists()) {
    return { id, ...res.data() };
  } else {
    return null;
  }
};

export const createCourse = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "Courses"), data);
    await updateClassroom(data.classroomId, {
      coursesOrder: arrayUnion(docRef.id),
    });
    return docRef;
  } catch (err) {
    console.log("create Course", err);
  }
};

export const updateCourse = async (id, data) => {
  try {
    await updateDoc(doc(collection(db, "Courses"), id), data);
    return { id };
  } catch (err) {
    console.log("updateCourses", err);
  }
};

export const deleteCourse = async (id, classroomId) => {
  try {
    await deleteDoc(doc(collection(db, "Courses"), id));
    if (classroomId) {
      await updateClassroom(classroomId, {
        coursesOrder: arrayRemove(id),
      });
    }
    return { id };
  } catch (err) {
    console.log("deleteCourses", err);
  }
};

export const duplicateCourse = async (courseId, distClassroomId) => {
  let course = await getCourseByID(courseId);

  if (course) {
    course.classroomId = distClassroomId;
    delete course.id;

    const newCourse = await createCourse({
      ...course,
      gamesOrder: [],
    });
    if (newCourse?.id) {
      const originalGames = await getGamesOfCourse(courseId);
      const originalGamesIds = originalGames.map((d) => d.id);

      originalGamesIds.sort((a, b) => {
        const indexA = course.gamesOrder.indexOf(a);
        const indexB = course.gamesOrder.indexOf(b);

        return indexA - indexB;
      });

      await duplicateGames(originalGamesIds, newCourse?.id);

      return newCourse.id;
    }
    return newCourse.id;
  }
};
