import React from "react";

import ReactFlagsSelect from "react-flags-select";
import {
  TextInput,
  InputWrapper,
  Box,
  Text,
  useMantineTheme,
} from "@mantine/core";
import "./index.scss";
function MTKCountryInput({
  label = "",
  value = "",
  labelColor = "",
  onChange = () => null,
  ...props
}) {
  const theme = useMantineTheme();
  return (
    <Box
      my="md"
      label={label}
      styles={{
        label: {
          color: "#00000055",
        },
      }}
      placeholder="Type Here..."
    >
      <Text
        sx={{
          fontSize: "14px",
          marginBottom: "4px",
          color: labelColor === "blue" ? theme.colors.blue : "#00000055",
          fontWeight: "500",
        }}
        color={labelColor === "blue" ? theme.colors.blue : "#00000055"}
      >
        {label}
      </Text>
      <ReactFlagsSelect
        searchable
        selected={value}
        onSelect={onChange}
        className="menu-flags"
        selectButtonClassName="menu-flags-btn"
        placeholder={"Select Here..."}
        {...props}
      />
    </Box>
  );
}

export default MTKCountryInput;
