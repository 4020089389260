import React, { lazy } from "react";
import {
  Redirect,
  Route,
  withRouter,
  Switch,
  useLocation,
} from "react-router-dom";
import { auth } from "../lib/firebase";

import PrivateRoute from "./PrivateRoute";
import { useAuthState } from "react-firebase-hooks/auth";
import i18n from "../lib/i18";
import OrganisationDetails from "../features/Organisations/OrganisationDetails";
import useIsSuperAdmin from "../hooks/useIsSuperAdmin";
import Classrooms from "../features/Classrooms/Classrooms";
import NewClassroom from "../features/Classrooms/NewClassroom";
import ClassroomDetails from "../features/Classrooms/ClassroomDetails";
import Courses from "../features/Courses/Courses";
import NewCourse from "../features/Courses/NewCourse";
import CourseDetails from "../features/Courses/CourseDetails";
import NewGame from "../features/Games/NewGame";
import GameDetails from "../features/Games/GameDetails";
import NewQuestion from "../features/Questions/NewQuestion";
import QuestionDetails from "../features/Questions/QuestionDetails";
import QuestionsPreview from "../features/Questions/QuestionsPreview";
import PublicRoute from "./PublicRoute";
import Logout from "../features/Auth/Logout";
// import SchoolOrganisations from "../features/Organisations/Organisations";
// import NewOrganisation from "../features/Organisations/NewOrganisation";

const Account = lazy(() => import("../features/Auth/Account"));
const SetupProfile = lazy(() => import("../features/Auth/SetupProfile"));
const ForgotPassword = lazy(() => import("../features/Auth/ForgotPassword"));
const TermsAndConditions = lazy(() =>
  import("../features/Home/TermsAndConditions")
);
const PrivacyPolicy = lazy(() => import("../features/Home/PrivacyPolicy"));
const CoockiesPolicy = lazy(() => import("../features/Home/CoockiesPolicy"));
const Contact = lazy(() => import("../features/Home/Contact"));
const Help = lazy(() => import("../features/Home/Help"));
const AdminLogin = lazy(() => import("../features/Auth/AdminLogin"));

const Home = lazy(() => import("../features/Home"));
const TeacherLogin = lazy(() => import("../features/Auth/TeacherLogin"));

const Organisations = lazy(() =>
  import("../features/Organisations/Organisations")
);
const NewOrganisation = lazy(() =>
  import("../features/Organisations/NewOrganisation")
);

const LocaleRoute = ({ match, location }) => {
  // get profile

  if (i18n.lang !== match.params.locale) {
    i18n.changeLanguage(match.params.locale);
  }

  const langPath = (path) => {
    return `${match.url}${path}`;
  };
  const isAdmin = useIsSuperAdmin();
  // const isAdmin = isSuperAdminF(profile);

  return (
    <Switch>
      {/* Landing */}

      <PublicRoute exact path={langPath("/")}>
        <Home />
      </PublicRoute>

      <PublicRoute exact path={langPath("/teacher/auth")}>
        <TeacherLogin />
      </PublicRoute>

      <PublicRoute exact path={langPath("/forgot-password")}>
        <ForgotPassword />
      </PublicRoute>

      <Route exact path={langPath("/terms-and-conditions")}>
        <TermsAndConditions />
      </Route>

      <Route exact path={langPath("/privacy-policy")}>
        <PrivacyPolicy />
      </Route>
      <Route exact path={langPath("/cookies-policy")}>
        <CoockiesPolicy />
      </Route>
      <Route exact path={langPath("/contact")}>
        <Contact />
      </Route>
      <PublicRoute exact path={langPath("/admin/login")}>
        <AdminLogin />
      </PublicRoute>
      <Route exact path={langPath("/help")}>
        <Help />
      </Route>
      <Route exact path={langPath("/teacher/setup-profile")}>
        <SetupProfile />
      </Route>

      <Route exact path={langPath("/account")}>
        <Account />
      </Route>
      <Route exact path={langPath("/logout")}>
        <Logout />
      </Route>
      {/* Organisations */}

      <PrivateRoute exact path={langPath("/organisations")}>
        <Redirect to={langPath("/organisations/schools")} />
      </PrivateRoute>

      <PrivateRoute exact path={langPath("/organisations/new")}>
        <NewOrganisation />
      </PrivateRoute>

      <PrivateRoute exact path={langPath("/organisations/schools")}>
        <Organisations type={"school"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/organisations/youth-movements")}>
        <Organisations type={"youthMovement"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/organisations/synagogues")}>
        <Organisations type={"synagogue"} />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={langPath("/organisations/:organisationId")}
        render={(props) => (
          <Redirect
            to={langPath(
              `/organisations/${props.match.params.organisationId}/about`
            )}
          />
        )}
      />
      <PrivateRoute
        exact
        path={langPath("/organisations/:organisationId/about")}
      >
        <OrganisationDetails type="about" />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={langPath("/organisations/:organisationId/classrooms")}
      >
        <OrganisationDetails type="classrooms" />
      </PrivateRoute>

      <PrivateRoute exact path={langPath("/classrooms")}>
        {isAdmin ? (
          <Redirect to={langPath("/classrooms/all")} />
        ) : (
          <Redirect to={langPath("/classrooms/my-classrooms")} />
        )}
      </PrivateRoute>

      {/* Classrooms */}
      {isAdmin && (
        <PrivateRoute exact path={langPath("/classrooms/all")}>
          <Classrooms type={"all"} />
        </PrivateRoute>
      )}
      <PrivateRoute exact path={langPath("/classrooms/my-classrooms")}>
        <Classrooms type={"my-classrooms"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/classrooms/shared-with-me")}>
        <Classrooms type={"shared-with-me"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/classrooms/new")}>
        <NewClassroom />
      </PrivateRoute>

      <PrivateRoute
        exact
        path={langPath("/classrooms/:classroomId")}
        render={(props) => (
          <Redirect
            to={langPath(
              `/classrooms/${props.match.params.classroomId}/courses` /// change
            )}
          />
        )}
      />

      <PrivateRoute exact path={langPath("/classrooms/:classroomId/about")}>
        <ClassroomDetails type={"about"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/classrooms/:classroomId/teachers")}>
        <ClassroomDetails type={"teachers"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/classrooms/:classroomId/courses")}>
        <ClassroomDetails type={"courses"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/classrooms/:classroomId/reports")}>
        <ClassroomDetails type={"reports"} />
      </PrivateRoute>

      <PrivateRoute exact path={langPath("/courses")}>
        {isAdmin ? (
          <Redirect to={langPath("/courses/all")} />
        ) : (
          <Redirect to={langPath("/courses/my-courses")} />
        )}
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/courses/all")}>
        <Courses type={"all"} />
      </PrivateRoute>

      <PrivateRoute exact path={langPath("/courses/my-courses")}>
        <Courses type={"my-courses"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/find-courses-and-games")}>
        <Courses type={"find-courses-and-games"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/courses/new")}>
        <NewCourse />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={langPath("/courses/:courseId")}
        render={(props) => (
          <Redirect
            to={langPath(`/courses/${props.match.params.courseId}/games`)}
          />
        )}
      />

      <PrivateRoute exact path={langPath("/courses/:courseId/about")}>
        <CourseDetails type={"about"} />
      </PrivateRoute>

      <PrivateRoute exact path={langPath("/courses/:courseId/reports")}>
        <CourseDetails type={"reports"} />
      </PrivateRoute>

      <PrivateRoute exact path={langPath("/courses/:courseId/games")}>
        <CourseDetails type={"games"} />
      </PrivateRoute>
      <PrivateRoute exact path={langPath("/courses/:courseId/games/new")}>
        <NewGame />
      </PrivateRoute>

      <PrivateRoute
        exact
        path={langPath("/courses/:courseId/games/:gameId")}
        render={(props) => (
          <Redirect
            to={langPath(
              `/courses/${props.match.params.courseId}/games/${props.match.params.gameId}/questions`
            )}
          />
        )}
      />
      <PrivateRoute
        exact
        path={langPath("/courses/:courseId/games/:gameId/about")}
      >
        <GameDetails type={"about"} />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={langPath("/courses/:courseId/games/:gameId/intro")}
      >
        <GameDetails type={"intro"} />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={langPath("/courses/:courseId/games/:gameId/questions")}
      >
        <GameDetails type={"questions"} />
      </PrivateRoute>
      <PrivateRoute
        exact
        path={langPath("/courses/:courseId/games/:gameId/reports")}
      >
        <GameDetails type={"reports"} />
      </PrivateRoute>

      <PrivateRoute
        exact
        path={langPath("/courses/:courseId/games/:gameId/questions/new")}
      >
        <NewQuestion />
      </PrivateRoute>

      <PrivateRoute
        exact
        path={langPath(
          "/courses/:courseId/games/:gameId/questions/:questionId/edit"
        )}
      >
        <QuestionDetails />
      </PrivateRoute>

      <PrivateRoute
        exact
        path={langPath(
          "/courses/:courseId/games/:gameId/questions/:questionId"
        )}
      >
        <QuestionsPreview />
      </PrivateRoute>

      {/* <PrivateRoute
        exact
        path={langPath(
          '/courses/:courseId/games/:gameId/questions/:questionId'
        )}
      >
        <QuestionPreviewReviewPage />
      </PrivateRoute> */}
      {/* 
      



      <ProtectedRoute exact path={langPath('/courses/duplicate')}>
        <DuplicateCoursePage />
      </ProtectedRoute>



     

     


      <ProtectedRoute
        exact
        path={langPath('/courses/:courseId/games/:gameId')}
        render={(props) => (
          <Redirect
            to={langPath(
              `/courses/${props.match.params.courseId}/games/${props.match.params.gameId}/questions`
            )}
          />
        )}
      />

      <ProtectedRoute
        exact
        path={langPath('/courses/:courseId/games/:gameId/about')}
      >
        <CourseGameAboutPage />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={langPath('/courses/:courseId/games/:gameId/intro')}
      >
        <CourseGameIntroduction />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={langPath('/courses/:courseId/games/:gameId/questions')}
      >
        <CourseGameQuestionsPage />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={langPath('/courses/:courseId/games/:gameId/reports')}
      >
        <CourseGameReportsPage />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={langPath('/courses/:courseId/games/:gameId/questions/new')}
      >
        <NewQuestionPage />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={langPath('/courses/:courseId/games/:gameId/questions/types')}
      >
        <QuestionTypes />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={langPath(
          '/courses/:courseId/games/:gameId/questions/:questionId'
        )}
      >
        <QuestionPreviewReviewPage />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={langPath(
          '/courses/:courseId/games/:gameId/questions/:questionId/review'
        )}
      >
        <QuestionPreviewReviewPage review />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path={langPath(
          '/courses/:courseId/games/:gameId/questions/:questionId/edit'
        )}
      >
        <EditQuestionPage />
      </ProtectedRoute> */}
    </Switch>
  );
};

const DEFAULT_LANG = "fr";

function Routes() {
  return (
    <Switch>
      <Route path="/:locale" component={LocaleRoute} />
      <Redirect to={`/${DEFAULT_LANG}`} />
    </Switch>
  );
}

export default withRouter(Routes);
