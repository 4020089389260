import { Box, Image, Button, Grid } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getGamesOfCourse } from "../../../../api/Games";
import { getFileDownloadURL } from "../../../../api/Storage";
import placeholderImage from "../../../../assets/images/icons/image.png";
import { withLanguage } from "../../../../lib/i18";
import GameCard from "../../../Games/components/GameCard";
import duplicateIcon from "../../../../assets/images/icons/duplicate-element.svg";
import { useModals } from "@mantine/modals";
import { duplicateCourse } from "../../../../api/Courses";

function CourseSection({
  course,
  schoolYear,
  organisation,
  classroom,

  selectedGameTopic,
  selectedGameSubTopic,
  selectedGameTopicType,
}) {
  const { classroomId, coverImage, description, id, name } = course;
  const [coverURL, setCoverURL] = useState(null);
  const history = useHistory();
  const [games, setGames] = useState(null);
  const { t } = useTranslation();
  const modals = useModals();
  useEffect(() => {
    if (coverImage && coverImage?.id) {
      getFileDownloadURL({
        path: `Images/${coverImage.id}/image`,
      }).then((url) => setCoverURL(url));
    }
  }, [coverImage]);

  useEffect(() => {
    getGamesOfCourse(id).then((games) => setGames(games));
  }, [id]);

  if (!games || games.length === 0) {
    return null;
  }

  const handleDuplicate = () => {
    modals.openContextModal("courseDuplication", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        organisationId: organisation?.id,
        classroomId,
        onCancel: () => console.log("Cancel"),
        onConfirm: async ({ organisation, classroom }) => {
          const newCourseID = await duplicateCourse(id, classroom);

          if (newCourseID) {
            history.push(withLanguage(`/courses/${newCourseID}/`));
          }
        },
      },
    });
  };
  const filteredGames = games;
  return (
    <Box mb="30px">
      <Box
        sx={{
          display: "flex",
          background: "#eff7ff",
          borderTopLeftRadius: "22px",
          borderTopRightRadius: "22px",
          padding: "20px",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        {coverURL ? (
          <ImageBackground url={coverURL} />
        ) : (
          <ImagePlaceholder>
            <Image src={placeholderImage} width={18} />
          </ImagePlaceholder>
        )}
        <Box
          sx={{
            flex: "1",
            ["@media screen and (max-width: 450px)"]: {
              order: "3",
              flex: "none",
              marginTop: "5px",
            },
          }}
        >
          <Box
            mb="5px"
            sx={{
              display: "flex",
            }}
          >
            <Name>{course.name}</Name>
            <Box
              sx={{
                background: "#fed700",
                color: "#fff",
                fontSize: "12px",
                padding: "5px",

                borderRadius: "8px",
              }}
            >
              {schoolYear?.key}
            </Box>
          </Box>
          <MetaInfoBottom>
            {organisation ? `${organisation.name} / ` : ""}
            {classroom ? `${classroom.name}` : ""}
          </MetaInfoBottom>
        </Box>

        <Box
          onClick={handleDuplicate}
          sx={{
            backgroundColor: "rgb(1, 158, 211)",
            border: "1px solid rgb(1, 158, 211)",
            color: "rgb(255, 255, 255)",
            padding: "13px 27px",
            borderRadius: "50px",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            display: "flex",
            cursor: "pointer",
          }}
        >
          <Image fit="contain" src={duplicateIcon} width={30} height={14} />
        </Box>
      </Box>
      <Main>
        <Box
          sx={{
            display: "flex",
            overflowX: "auto",
          }}
        >
          {filteredGames &&
            filteredGames.map((game) => {
              const { gameSubTopic, gameTopic, gameTopicType } = game;

              if (
                gameTopic &&
                selectedGameTopic !== "-all-" &&
                gameTopic !== selectedGameTopic
              ) {
                return null;
              }
              if (
                gameSubTopic &&
                selectedGameSubTopic !== "-all-" &&
                gameSubTopic !== selectedGameSubTopic
              ) {
                return null;
              }
              if (
                gameTopicType &&
                selectedGameTopicType !== "-all-" &&
                gameTopicType !== selectedGameTopicType
              ) {
                return null;
              }

              return (
                <Box
                  m="10px"
                  sx={{
                    minWidth: `300px`,
                    width: "30%",
                  }}
                >
                  <GameCard
                    courseId={id}
                    {...game}
                    hideAddGameToReport
                    withDuplicate
                    onClick={() =>
                      history.push(
                        withLanguage(
                          `/courses/${course.id}/games/${game.id}/questions/?toPreview=true&backToSearch=true`
                        )
                      )
                    }
                  />
                </Box>
              );
            })}
          {filteredGames && filteredGames.length < 1 && (
            <Box
              sx={{
                color: "#bcbcbc",
                fontSize: "14px",
              }}
            >
              {t(" No games with the selected game topic filters.")}
            </Box>
          )}
        </Box>
      </Main>
    </Box>
  );
}

const ImagePlaceholder = ({ children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#fafafa",
        width: "50px",
        height: "50px",
        borderRadius: "8px",
        marginRight: "20px",
      }}
    >
      {children}
    </Box>
  );
};
const ImageBackground = ({ children, url }) => {
  return (
    <Box
      sx={{
        marginRight: "20px",
        width: "50px",
        height: "50px",
        background: `url(${url})`,
        backgroundSize: "contain",
        borderRadius: "8px",
        border: "2px solid #fff",
      }}
    >
      {children}
    </Box>
  );
};

const Name = ({ children }) => (
  <Box
    sx={{
      color: "#019ed3",
      fontWeight: "bold",
      fontSize: "20px",
      marginRight: "10px",
    }}
  >
    {children}
  </Box>
);
const MetaInfoBottom = ({ children }) => (
  <Box
    sx={{
      fontSize: "13px",
      fontWeight: 400,
      color: "#019ed3",
    }}
  >
    {children}
  </Box>
);
const Main = ({ children }) => (
  <Box
    sx={{
      background: "#fafafa",
      borderBottomLeftRadius: "22px",
      borderBottomRightRadius: "22px",
    }}
  >
    {children}
  </Box>
);
const Games = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      padding: "20px",

      overflowX: "scroll",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": {
        display: "none",
      },

      "> div": {
        marginRight: "30px",
      },
    }}
  >
    {children}
  </Box>
);

export default CourseSection;
