export const getPointsOptions = () => [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
];

export const getCountDownOptions = (t) => [
  { label: "10s", value: "10" },
  { label: "20s", value: "20" },
  { label: "30s", value: "30" },
  { label: "60s", value: "60" },
  { label: "120s", value: "120" },
  { label: t("none"), value: "0" },
];
