import React, { useEffect, useMemo, useState } from "react";
import { Box, createStyles, Title, Tabs } from "@mantine/core";

import AppLayout from "../../../components/layout/AppLayout";
import { useTranslation } from "react-i18next";

import { useHistory, useParams } from "react-router-dom";
import { withLanguage } from "../../../lib/i18";
import {
  organisationSubTypes,
  organisationTypes,
} from "../../../constants/types";

import BackButtonCircle from "../../../components/BackButtonCircle";

import contentful from "../../../lib/contentful";

import useNewOrgamisationForm from "../NewOrganisation/Form";
import SubmitBottomBar from "../../../components/SubmitBottomBar";
import { getTeachers } from "../../../api/Users";
import {
  deleteOrganisation,
  getOrganizationByID,
} from "../../../api/Organisations";
import { downloadFileFromStorage } from "../../../api/Storage";
import MTKTabs from "../../../components/Tabs";
import { getSchoolYears } from "../../../api/SchoolYear";
import { getClassroomsOfOrganisation } from "../../../api/Classrooms";
import AboutTab from "./components/AboutTab";
import ClassroomTab from "./components/ClassroomTab";
import useIsSuperAdmin from "../../../hooks/useIsSuperAdmin";
import { isDirectorOfOrganisation } from "../../../utils/utils";
import { useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    position: "sticky",
    top: 0,
    padding: "50px 0",
    zIndex: 2,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "0px",
    [`@media screen and (max-width: 900px)`]: {
      padding: "30px 0",
      marginBottom: "0px",
    },
  },
  headerText: {
    marginBottom: "30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

function OrganisationDetails({ type: pageTab }) {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { organisationId } = useParams();
  const currentLang = i18n.language;

  const { form, onFormSubmit, loading, setDefaultData } =
    useNewOrgamisationForm({ update: true });

  const [pageContent, setPageContent] = useState(null);
  const [data, setData] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [teachers, setTeachers] = useState(null);
  const [schoolYears, setSchoolYears] = useState([]);
  const { profile } = useContext(GlobalContext);
  const [classrooms, setClassrooms] = useState([]);
  const [emptyView, setEmptyView] = useState(null);

  const [activeTab, setActiveTab] = useState(
    ["about", "classrooms"].indexOf(pageTab)
  );
  const [activeType, setActiveType] = useState(pageTab);

  const onChange = (active, tabKey) => {
    setActiveTab(active);
    setActiveType(tabKey);
    history.push(withLanguage(`/organisations/${organisationId}/${tabKey}`));
  };

  useEffect(() => {
    // console.log(type, activeType);
    if (pageTab !== activeType) {
      setActiveType(pageTab);
    }
  }, [pageTab, activeType]);

  useEffect(() => {
    const getData = async () => {
      const [teachers, schoolYears] = await Promise.all([
        getTeachers(),
        getSchoolYears(),
      ]);
      setTeachers(teachers);
      setSchoolYears(schoolYears);
    };
    getData();
  }, []);

  const { directors, type, name } = form.values;

  const orgSubType = useMemo(() => {
    if (!type) return [];
    const typeMap = organisationSubTypes[type];
    const orgSubTypes = Object.keys(typeMap).map((k) => {
      return {
        id: k,
        label: typeMap[k],
      };
    });
    return orgSubTypes
      .filter((o) => o.id !== "")
      .map((o) => ({
        value: o.id,
        label: o.label[currentLang],
      }));
  }, [type]);

  // Init
  //contenful
  useEffect(() => {
    // dispatch(setHasUnsavedChanges(false));
    contentful("ogranisationsItemsDescription").then((data) =>
      setPageContent(data.items[0])
    );
    contentful("emtyView").then((data) => {
      data.items.forEach((item) => {
        if (item.fields.type === "classrooms") {
          setEmptyView(item);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (organisationId) {
      getOrganizationByID(organisationId).then((data) => {
        setData(data);
      });
      getClassroomsOfOrganisation(organisationId).then((data) =>
        setClassrooms(data)
      );
    }
  }, [organisationId]);

  useEffect(() => {
    const getData = async () => {
      const dirctorsList = [];
      let audioFile = null;
      let coverImage = null;
      if (data.directors) {
        Object.keys(data.directors).map((key) => {
          const teacher = teachers.find((d) => d.id === key);
          if (teacher) {
            dirctorsList.push(teacher);
          }
        });
      }
      if (data?.audioFile && data?.audioFile?.id) {
        const file = await downloadFileFromStorage({
          path: `Audio/${data.audioFile.id}/audio`,
          name: data.audioFile?.name || "audioFile",
          type: data.audioFile?.type || "audio/mpeg",
        });
        audioFile = file;
      }

      if (data?.coverImage && data?.coverImage?.id) {
        const file = await downloadFileFromStorage({
          path: `Images/${data.coverImage.id}/image`,
          name: data.coverImage?.name || "coverImage",
          type: data.coverImage?.type || "coverImage",
        });
        coverImage = file;
      }

      setDefaultData(
        {
          ...data,
          directors: dirctorsList,
          coverImage,
          audioFile,
        },
        { ...data, id: organisationId }
      );
      setPageLoading(false);
    };
    if (teachers && data && pageLoading) {
      getData();
    }
  }, [teachers, data, pageLoading]);

  const orgTypes = [
    // { id: 'home', label: t(organisationTypes['home']) },
    { value: "school", label: t(organisationTypes["school"][currentLang]) },
    {
      value: "youthMovement",
      label: t(organisationTypes["youthMovement"][currentLang]),
    },
    {
      value: "synagogue",
      label: t(organisationTypes["synagogue"][currentLang]),
    },
  ];

  const onDeleteOrg = async () => {
    // setLoading
    await deleteOrganisation(organisationId);
    history.push(withLanguage("/organisations"));
  };

  const isAdmin = useIsSuperAdmin();
  const isDirector = isDirectorOfOrganisation(profile, data);

  // Render

  const getPageTitle = () => {
    return data ? data.name : t("Organisation");
  };

  if (profile && !isAdmin && !isDirector) {
    history.push(withLanguage("/organisations"));
    return null;
  }
  return (
    <AppLayout loading={pageLoading} title={getPageTitle()}>
      <Box className={classes.header}>
        <Box className={classes.headerText}>
          <BackButtonCircle mr="md" to={withLanguage("/organisations")} />
          <Title order={3}>{name}</Title>
        </Box>
        <MTKTabs active={activeTab} onTabChange={onChange}>
          <Tabs.Tab label={t("Settings")} tabKey={"about"}></Tabs.Tab>
          <Tabs.Tab label={t("Classrooms")} tabKey={"classrooms"}></Tabs.Tab>
        </MTKTabs>
      </Box>
      {activeType === "classrooms" && (
        <ClassroomTab
          schoolYears={schoolYears}
          emptyView={emptyView}
          classrooms={classrooms}
          organisationId={organisationId}
        />
      )}
      {activeType === "about" && (
        <AboutTab
          onDeleteOrg={onDeleteOrg}
          form={form}
          pageContent={pageContent}
          orgSubType={orgSubType}
          orgTypes={orgTypes}
          directors={directors}
        />
      )}

      {activeType === "about" && (
        <SubmitBottomBar
          okText={t("Save")}
          onCreate={(e) => {
            form.onSubmit(onFormSubmit)(e);
          }}
          // onCreate={form.onSubmit(onFormSubmit)}
          onCancel={() => history.push(withLanguage("/organisations/"))}
          loading={loading}
        />
      )}
    </AppLayout>
  );
}

export default OrganisationDetails;
