import React, { useState } from "react";
import { ContextModalProps, ModalsProvider } from "@mantine/modals";
import { Text, Button, Image, Box, Center, Title } from "@mantine/core";

import errorSignImage from "../../assets/images/icons/error-sign.png";
import { useTranslation } from "react-i18next";
import MTKButton from "../Button";
import MTKSelect from "../Select";
import { useEffect } from "react";
import {
  getOrganisations,
  getOrganisationsOfUser,
} from "../../api/Organisations";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { getClassrooms, updateClassroom } from "../../api/Classrooms";
import MTKInput from "../Input";
import { z } from "zod";
import { createStudent, getStudents } from "../../api/Users";
import MTKDateInput from "../DateInput";
import MTKCheckBox from "../CheckBox";
import { serverTimestamp } from "firebase/firestore";

function NewStudentModal({ context, id, innerProps }) {
  const { t } = useTranslation();
  const { studentsOfClassroom, classroom, classroomId, handleRefreshData } =
    innerProps;
  const [loading, setLoading] = useState(false);
  const [students, setStudents] = useState([]);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState(new Date());
  const [studentsFound, setStudentsFound] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(undefined);

  const [step, setStep] = useState("newStudentFirst");

  const { profile } = useContext(GlobalContext);

  useEffect(() => {
    const getData = async () => {
      const students = await getStudents();
      setStudents(students);
    };
    getData();
  }, []);

  const renderNewStudentFirst = () => (
    <Box>
      <Title order={3} my="md" align="center">
        {t("New Student")}
      </Title>
      <Text size="sm" align="center" my="md">
        {t(
          "Add a new student to your class by entering the associated email address."
        )}
      </Text>
      <Box>
        <MTKInput
          label={t("Email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Center
        px="xl"
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        my="lg"
        mt="50px"
      >
        <MTKButton
          variant="subtle"
          sx={{
            color: "rgb(1, 158, 211)",
          }}
          text
          onClick={() => {
            innerProps.onCancel();
            context.closeModal(id);
          }}
        >
          {t("Cancel")}
        </MTKButton>
        <MTKButton
          width="140px"
          // fullWidth
          loading={loading}
          color="blue"
          onClick={async () => {
            let emailValid = false;

            try {
              z.string().email().parse(email);
              emailValid = true;
            } catch (error) {
              emailValid = false;
            }

            if (!emailValid) {
              return false;
            }

            if (!emailValid) {
              setStep("invalidEmailError");
            } else {
              // const found = students.find((s) => s.email === email);
              const studentsFound = [];

              if (students) {
                for (const [key, value] of Object.entries(students)) {
                  const isStudentAlreadyPartOfClassroom =
                    studentsOfClassroom.filter((student) => key === student?.id)
                      .length > 0;

                  if (
                    value.email === email &&
                    !isStudentAlreadyPartOfClassroom
                  ) {
                    studentsFound.push({
                      id: key,
                      firstName: value.firstName,
                      name: value.name,
                      email: value.email,
                    });
                  }
                }
              }

              if (studentsFound.length > 0) {
                setStudentsFound(studentsFound);
                setStep("selectStudent");
              } else {
                setStep("newStudentSecond");
              }
            }
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Next")}
        </MTKButton>
      </Center>
    </Box>
  );

  const renderNewStudentSecond = () => (
    <Box>
      <Title order={3} my="md" align="center">
        {t("New Student")}
      </Title>
      <Text size="sm" align="center" my="md">
        {t(
          "There is no account associated to this email. Enter a first and last name to create a new student."
        )}
      </Text>
      <Box>
        <Text size="sm" align="center" my="md">
          {t("If the student joins the app later we will merge his data.")}
        </Text>
        <MTKInput
          label={t("First Name")}
          required
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <MTKInput
          label={t("Last Name")}
          required
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <MTKDateInput
          label={t("Birthday")}
          required
          value={birthday}
          onChange={(e) => setBirthday(e)}
        />
        <MTKInput
          label={t("Email")}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Box>
      <Center
        px="xl"
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        my="lg"
        mt="xl"
      >
        <MTKButton
          variant="subtle"
          sx={{
            color: "rgb(1, 158, 211)",
          }}
          text
          onClick={() => {
            context.closeModal(id);
          }}
        >
          {t("Cancel")}
        </MTKButton>
        <MTKButton
          width="140px"
          // fullWidth
          loading={loading}
          color="blue"
          onClick={async () => {
            if (
              firstName.length < 1 ||
              lastName.length < 1 ||
              birthday.length < 1 ||
              email.length < 1
            ) {
              setStep("missingData");
              return;
            }
            setLoading(true);

            let currentStudents = {};

            if (classroom.hasOwnProperty("students")) {
              currentStudents = { ...classroom.students };
            }

            let studentId = "";

            try {
              const studentRef = await createStudent({
                email,
                firstName,
                name: lastName,
                birthday,
                type: "student",
                createdAt: serverTimestamp(),
              });

              studentId = studentRef.id;
            } catch (error) {
              console.log(error);
              //   closeModal();
              return;
            }

            currentStudents[studentId] = "student";

            try {
              await updateClassroom(classroomId, {
                students: currentStudents,
              });
              handleRefreshData();
            } catch (error) {
              console.log(error);
            }
            setLoading(false);
            context.closeModal(id);
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Next")}
        </MTKButton>
      </Center>
    </Box>
  );
  const renderSelectStudent = () => (
    <Box>
      <Title order={3} my="md" align="center">
        {t("Select Student")}
      </Title>
      <Text size="sm" align="center" my="md">
        {t(
          "There is one or more students associated with this email address, select the student you would like to add"
        )}
      </Text>
      <Box mx="35px" my="20px">
        {studentsFound.map((d) => (
          <MTKCheckBox
            checked={selectedStudent?.id === d.id}
            onChange={(e) =>
              e.target.checked
                ? setSelectedStudent(d)
                : setSelectedStudent(null)
            }
            label={
              <Box>
                <Box>
                  {d.firstName} {d.name}
                </Box>
                <Box>{d.email}</Box>
              </Box>
            }
          />
        ))}
      </Box>
      <Box
        px="xl"
        sx={{
          //   display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        my="lg"
        mt="xl"
      >
        <MTKButton
          fullWidth
          my="md"
          loading={loading}
          color="blue"
          disabled={!selectedStudent}
          onClick={async () => {
            if (!selectedStudent) {
              setStep("noStudentSelectedError");
              return;
            }
            setLoading(true);
            let currentStudents = {};

            if (classroom.hasOwnProperty("students")) {
              currentStudents = { ...classroom.students };
            }
            currentStudents[selectedStudent.id] = "student";
            try {
              await updateClassroom(classroomId, { students: currentStudents });
              handleRefreshData();
            } catch (e) {
              setLoading(false);
              console.log(e);
            }
            setLoading(false);
            context.closeModal(id);
            // closeModal();
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Add Student")}
        </MTKButton>

        <MTKButton
          fullWidth
          my="md"
          loading={loading}
          variant="outline"
          color="blue"
          onClick={async () => {
            setStep("newStudentSecond");
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Add Someone Else")}
        </MTKButton>
        <MTKButton
          fullWidth
          my="md"
          variant="subtle"
          sx={{
            color: "rgb(1, 158, 211)",
          }}
          text
          onClick={() => {
            setStep("newStudentFirst");
          }}
        >
          {t("Back")}
        </MTKButton>
      </Box>
    </Box>
  );

  const renderErrorInvalidEmail = () => {
    return (
      <Box>
        <Title order={3} my="md" align="center">
          {t("Email invalid")}
        </Title>
        <Text size="sm" align="center" my="md">
          {t("Sorry, but the entered email is not valid.")}
        </Text>
        <MTKButton
          width="140px"
          // fullWidth
          loading={loading}
          color="blue"
          onClick={async () => {
            setStep("newStudentFirst");
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Ok")}
        </MTKButton>
      </Box>
    );
    //   <Modal
    //     title={t('Email invalid')}
    //     description={t('Sorry, but the entered email is not valid.')}
    //     okCallback={() => setStep('newStudentFirst')}
  };
  const renderErrorMissingData = () => {
    return (
      <Box>
        <Title order={3} my="md" align="center">
          {t("Missing data")}
        </Title>
        <Text size="sm" align="center" my="md">
          {t("Please fill out all the fields required.")}
        </Text>
        <MTKButton
          width="140px"
          // fullWidth
          loading={loading}
          color="blue"
          onClick={async () => {
            setStep("newStudentSecond");
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Ok")}
        </MTKButton>
      </Box>
    );
  };
  const renderErrorNoStudentSelected = () => {
    return (
      <Box>
        <Title order={3} my="md" align="center">
          {t("No selection")}
        </Title>
        <Text size="sm" align="center" my="md">
          {t("Please select a student.")}
        </Text>
        <MTKButton
          width="140px"
          // fullWidth
          loading={loading}
          color="blue"
          onClick={async () => {
            setStep("selectStudent");
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Ok")}
        </MTKButton>
      </Box>
    );
    //   <Modal
    //     title={t('Email invalid')}
    //     description={t('Sorry, but the entered email is not valid.')}
    //     okCallback={() => setStep('newStudentFirst')}
  };

  if (step === "newStudentFirst") {
    return renderNewStudentFirst();
  } else if (step === "selectStudent") {
    return renderSelectStudent();
  } else if (step === "newStudentSecond") {
    return renderNewStudentSecond();
  } else if (step === "invalidEmailError") {
    return renderErrorInvalidEmail();
  } else if (step === "missingDataError") {
    return renderErrorMissingData();
  } else if (step === "noStudentSelectedError") {
    return renderErrorNoStudentSelected();
  } else {
    return null;
  }
}

export default NewStudentModal;
