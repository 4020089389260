import {
  getDocs,
  getDoc,
  collection,
  query,
  where,
  setDoc,
  deleteDoc,
  addDoc,
  doc,
  updateDoc,
  orderBy,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { getGameByID, updateGame } from "./Games";

export const getQuestionsOfGame = async (gameId) => {
  const questionsRef = collection(db, "Games", gameId, "Questions");
  const q = query(questionsRef, orderBy("userQuestionId", "asc"));

  const querySnapshot = await getDocs(q);

  const res = querySnapshot;
  const questionsList = res.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return questionsList;
};

export const createQuestion = async (gameId, data) => {
  try {
    const questionsRef = collection(db, "Games", gameId, "Questions");

    const docRef = await addDoc(questionsRef, data);
    await updateGame(gameId, {
      questionsOrder: arrayUnion(docRef.id),
    });
    return docRef;
  } catch (err) {
    console.log("create Question", err);
  }
};
export const updateQuestion = async (questionId, gameId, data) => {
  try {
    const questionRef = doc(db, "Games", gameId, "Questions", questionId);

    const docRef = await updateDoc(questionRef, data);
    return docRef;
  } catch (err) {
    console.log("create Question", err);
  }
};
export const publishQuestion = async (questionId, gameId) => {
  try {
    const questionRef = doc(db, "Games", gameId, "Questions", questionId);

    const docRef = await updateDoc(questionRef, {
      publishQuestionInGame: true,
    });
    return docRef;
  } catch (err) {
    console.log("create Question", err);
  }
};
export const unPublishQuestion = async (questionId, gameId) => {
  try {
    const questionRef = doc(db, "Games", gameId, "Questions", questionId);

    const docRef = await updateDoc(questionRef, {
      publishQuestionInGame: false,
    });
    return docRef;
  } catch (err) {
    console.log("create Question", err);
  }
};

export const getQuestionByID = async (gameId, questionId) => {
  const questionRef = doc(db, "Games", gameId, "Questions", questionId);

  const res = await getDoc(questionRef);
  if (res.exists()) {
    return { id: res.id, ...res.data() };
  } else {
    return null;
  }
};

export const deleteQuestion = async (questionId, gameId) => {
  try {
    const questionRef = doc(db, "Games", gameId, "Questions", questionId);
    await deleteDoc(questionRef);

    await updateGame(gameId, {
      questionsOrder: arrayRemove(questionId),
    });

    return { id: questionId };
  } catch (err) {
    console.log("deleteQuestion", err);
  }
};

export const deleteQuestions = async (questionIds = [], gameId) => {
  try {
    await Promise.all(questionIds.map((id) => deleteQuestion(id, gameId)));
    return true;
  } catch (err) {
    console.log("deleteQuestions", err);
  }
};

export const duplicateQuestion = async (questionId, gameId, { newGameId }) => {
  let question = await getQuestionByID(gameId, questionId);
  const questions = await getQuestionsOfGame(newGameId);

  if (question) {
    question.gameId = newGameId;
    question.userQuestionId = `Question ${questions.length + 1}`;
    delete question.id;
    const newQst = await createQuestion(newGameId, {
      ...question,
    });

    return newQst.id;
  }
};
export const duplicateQuestions = async (
  questionIds = [],
  gameId,
  { newGameId }
) => {
  const questions = await getQuestionsOfGame(newGameId);
  const newQuestionsData = await Promise.all(
    questionIds.map(async (id, i) => {
      let question = await getQuestionByID(gameId, id);
      if (question) {
        question.gameId = newGameId;
        question.userQuestionId = `Question ${questions.length + 1 + i}`;
        delete question.id;
        const newQst = await createQuestion(newGameId, {
          ...question,
        });
        if (newGameId === gameId) {
          const newQuestionData = await getQuestionByID(newGameId, newQst.id);
          return newQuestionData;
        }
        return newQst.id;
      }
    })
  );

  return newQuestionsData;
};
