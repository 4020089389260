import { Box, Grid } from "@mantine/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import MTKButton from "../../../../components/Button";
import MTKSwitch from "../../../../components/Switch";
import { ReactComponent as PlusIcon } from "../../../../assets/images/icons/plus.svg";
import CourseCard from "../../../Courses/components/CourseCard";
import { OrderCoursesModal } from "../../components/OrderCoursesModal";
import { useHistory } from "react-router-dom";
import { withLanguage } from "../../../../lib/i18";
import { duplicateCourse } from "../../../../api/Courses";
import { useModals } from "@mantine/modals";

function CoursesTab({
  courses = [],
  coursesOrder,
  classroomId,
  organisationId,
  saveCoursesOrder,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const orderedCourses = useMemo(() => {
    if (coursesOrder && Array.isArray(coursesOrder)) {
      let ordered = coursesOrder.map((d) =>
        courses.find((course) => course.id === d)
      );
      ordered = [
        ...ordered,
        ...courses.filter((d) => !ordered.find((or) => or.id === d.id)),
      ];

      return ordered;
    } else {
      return courses;
    }
  }, [courses, coursesOrder]);

  const modals = useModals();

  const handleDuplicate = (id) => {
    modals.openContextModal("courseDuplication", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        organisationId: organisationId,
        classroomId,
        onCancel: () => console.log("Cancel"),
        onConfirm: async ({ organisation, classroom }) => {
          const newCourseID = await duplicateCourse(id, classroom);

          if (newCourseID) {
            history.push(withLanguage(`/courses/${newCourseID}/`));
          }
        },
      },
    });
  };
  const orderCourses = () => {
    modals.openContextModal("order", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        color: "blue",
        sortedItems: orderedCourses,
        saveNewOrder: saveCoursesOrder,
        title: t("Order Courses"),
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          // setMediaUrl(null);
          // onChange(null);
        },
      },
    });
  };
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
        mb="40px"
      >
        <Box>
          <MTKSwitch label={t("Equal Weighting")} checked readOnly />
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <MTKButton compact color="blue" onClick={orderCourses}>
            {t("Order Courses")}
          </MTKButton>
          <MTKButton
            mx="sm"
            compact
            color="blue"
            leftIcon={<PlusIcon />}
            onClick={() =>
              history.push(
                withLanguage(
                  `/courses/new?back=/classrooms/${classroomId}/courses&prefillId=${classroomId}`
                )
              )
            }
          >
            {t("New Course")}
          </MTKButton>
        </Box>
      </Box>
      {/* <OrderCoursesModal data={courses} /> */}

      <Grid mb="100px">
        {orderedCourses.map((course) => {
          return (
            <Grid.Col md={12} lg={4} key={course.id}>
              <CourseCard
                {...course}
                withDuplicate
                handleDuplicate={() => handleDuplicate(course.id)}
              />
            </Grid.Col>
          );
        })}
      </Grid>
    </Box>
  );
}

export default CoursesTab;
