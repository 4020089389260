import { Switch } from "@mantine/core";
import React from "react";

function MTKSwitch({ label, checked, color = "blue", ...props }) {
  return (
    <Switch
      my="md"
      label={label}
      checked={checked}
      styles={(theme) => ({
        input: {
          // background: theme.colors.blue,
          backgroundColor: checked ? theme.colors[color] : theme.colors.gray[4],
          //   borderColor: theme.colors.blue,
        },
      })}
      {...props}
    />
  );
}

export default MTKSwitch;
