import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import EN from "../translations/en/resource.json";
import FR from "../translations/fr/resource.json";
import NL from "../translations/nl/resource.json";
import HE from "../translations/he/resource.json";

const resources = {
  en: {
    translation: EN,
  },
  fr: {
    translation: FR,
  },
  nl: {
    translation: NL,
  },
  he: {
    translation: HE,
  },
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

export const withLanguage = (path) => {
  return `/${i18n.language}${path}`;
};
