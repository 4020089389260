import {
  getStorage,
  ref,
  uploadBytes,
  getBytes,
  getDownloadURL,
  updateMetadata,
} from "firebase/storage";

const storage = getStorage();

export const uploadFileToStorage = async ({ file, path, metadata = null }) => {
  const storageRef = ref(storage, path);
  try {
    const res = await uploadBytes(storageRef, file);
    if (metadata) {
      await updateMetadata(storageRef, metadata);
    }
    return res;
  } catch (error) {
    console.log("from upload file", error);
  }
};

export const downloadFileFromStorage = async ({
  path,
  name = "file",
  type = "",
}) => {
  const storageRef = ref(storage, path);
  try {
    const res = await getBytes(storageRef);
    const file = new File([res], name, {
      type,
    });
    return file;
  } catch (error) {
    console.log("from download file", error);
  }
};

export const getFileDownloadURL = async ({ path }) => {
  const storageRef = ref(storage, path);
  try {
    const url = await getDownloadURL(storageRef);

    return url;
  } catch (error) {
    return null;
  }
};
