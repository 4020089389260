import {
  getDocs,
  getDoc,
  collection,
  query,
  where,
  setDoc,
  deleteDoc,
  addDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { getUserById } from "./Users";

//todo : add validators

export const getClassrooms = async () => {
  const res = await getDocs(collection(db, "Classrooms"));
  const ClassroomsList = res.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return ClassroomsList;
};
export const getClassroomsOfUser = async (id, allowList = []) => {
  const classroomList = await getClassrooms();
  return classroomList?.filter(
    (classroom) =>
      classroom?.createdByUserId === id ||
      (classroom?.teachers && classroom?.teachers[id]) ||
      allowList?.includes(classroom.id)
  );
};
export const getClassromByID = async (id) => {
  const res = await getDoc(doc(db, "Classrooms", id));
  if (res.exists()) {
    return { id, ...res.data() };
  } else {
    return null;
  }
};
export const getClassroomsOfOrganisation = async (organisationID) => {
  const yearsRef = collection(db, "Classrooms");
  const q = query(yearsRef, where("organisationId", "==", organisationID));
  const querySnapshot = await getDocs(q);

  const res = querySnapshot;
  const ClassroomsList = res.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
  return ClassroomsList;
};

export const createClassroom = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "Classrooms"), data);
    return docRef;
  } catch (err) {
    console.log("create Classroom", err);
  }
};
export const updateClassroom = async (id, data) => {
  try {
    await updateDoc(doc(collection(db, "Classrooms"), id), data);
    return { id };
  } catch (err) {
    console.log("updateClassrooms", err);
  }
};
export const deleteClassroom = async (id) => {
  try {
    await deleteDoc(doc(collection(db, "Classrooms"), id));
    return { id };
  } catch (err) {
    console.log("deleteClassrooms", err);
  }
};

export const getStudentsOfClassroom = async (id) => {
  const classroom = await getClassromByID(id);

  if (classroom?.students) {
    const studentsIds = Object.keys(classroom.students);
    const students = await Promise.all(
      studentsIds.map(async (studentsId) => {
        return await getUserById(studentsId);
      })
    );
    return students.filter((d) => d);
  } else {
    return [];
  }
};

export const getReportsOfClassroom = async (id) => {
  if (id) {
    const reportsRef = collection(db, "ClassroomReports");
    const q = query(reportsRef, where("classroomId", "==", id));
    const querySnapshot = await getDocs(q);

    const reports = querySnapshot.docs.map((d) => ({ ...d.data() }));

    return reports;
  } else {
    return [];
  }
};
