import React, { useEffect, useState } from "react";
import { Box, Checkbox, createStyles, Image, Menu, Text } from "@mantine/core";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  deleteQuestion,
  duplicateQuestion,
  getQuestionByID,
  getQuestionsOfGame,
  publishQuestion,
  unPublishQuestion,
} from "../../../../api/Questions";
import { useMemo } from "react";
import placeholderImage from "../../../../assets/images/icons/image.png";
import puzzleIcon from "../../../../assets/images/icons/puzzle.svg";
import drawingIcon from "../../../../assets/images/icons/drawing.svg";
import diffIcon from "../../../../assets/images/icons/7differences.svg";
import listIcon from "../../../../assets/images/icons/list.svg";
import questionMark from "../../../../assets/images/icons/question-mark.svg";
import clickIcon from "../../../../assets/images/icons/click.svg";
import { useTranslation } from "react-i18next";
import MTKCheckBox from "../../../../components/CheckBox";
import MTKButton from "../../../../components/Button";
import { withLanguage } from "../../../../lib/i18";
import MTKSwitch from "../../../../components/Switch";
import { ReactComponent as Plus } from "../../../../assets/images/icons/plus.svg";
import contentful from "../../../../lib/contentful";
import questionIcon from "../../../../assets/images/icons/question.svg";
import plusIcon from "../../../../assets/images/icons/Group 7.svg";
import plus from "../../../../assets/images/icons/plus.svg";
import { ReactComponent as PreviewQuestionIcon } from "../../../../assets/images/icons/magnifying-glass-new.svg";
import { ReactComponent as EditQuestionIcon } from "../../../../assets/images/icons/pen-new.svg";
import { ReactComponent as DuplicateQuestionIcon } from "../../../../assets/images/icons/duplicate-new.svg";
import { ReactComponent as PublishQuestionIcon } from "../../../../assets/images/icons/eye-new.svg";
import { ReactComponent as UnpublishQuestionIcon } from "../../../../assets/images/icons/crossed-eye-new.svg";
import { ReactComponent as DeleteQuestionIcon } from "../../../../assets/images/icons/trash-can-new.svg";

import { capitalize, getQueryParams } from "../../../../utils/utils";
import { useModals } from "@mantine/modals";
import { useScrollIntoView } from "@mantine/hooks";
import { getFileDownloadURL } from "../../../../api/Storage";
const useStyles = createStyles({
  dotContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 30px",
    cursor: "pointer",
  },
  dot: {
    backgroundColor: "rgb(39, 71, 132)",
    width: "10px",
    height: "10px",
    borderRadius: "7px",
    marginLeft: "5px",
  },
  footer: {
    background: "#e6f0e2",
    height: "60px",
    width: "calc(100% + 100px )",

    // position: "sticky",
    // bottom: "-50px",
    position: "absolute",
    bottom: "0px",
    display: "flex",
    justifyContent: "space-between",
    zIndex: "100",

    alignItems: "center",

    // ["@media screen and (min-width: 1400px)"]: {
    //   marginTop: "300px",
    //   position: "sticky",
    //   bottom: "0px",
    // },

    // ["@media screen and (min-width: 2000px)"]: {
    //   marginTop: "800px",
    // },

    ["@media screen and (max-width: 900px)"]: {
      width: "calc(100% + 65px)",
      // position: "sticky",
      bottom: "-15px",
    },

    // ["@media screen and (max-width: 1250px)"]: {
    //   position: "absolute",
    //   bottom: "0px",
    //   /*width: calc(100% + )*/
    // },
  },
  footerTotal: {
    background: "rgb(39, 71, 132)",
    color: "white",
    padding: "8px",
    borderRadius: "22px",
    cursor: "pointer",
    width: "130px",
    textAlign: "center",
    ["@media screen and (max-width: 700px)"]: {
      width: "100px",
      fontSize: "14px",
      padding: "5px",
    },
  },
});

function QuestionsTab({
  selectedQuestions,
  setSelectedQuestions,
  classroom,
  organisation,
  questions,
  setQuestions,
}) {
  const { gameId, courseId } = useParams();
  const [totalQuestionsPoints, setTotalQuestionsPoints] = useState(0);
  const { t } = useTranslation();
  const history = useHistory();
  const { classes } = useStyles();
  const [emptyView, setEmptyView] = useState(null);
  //Questions total points
  useEffect(() => {
    const totalPoints = questions?.map((q) =>
      q.publishQuestionInGame ? q.points : 0
    );
    const sum = totalPoints?.reduce(add, 0);

    function add(accumulator, a) {
      return accumulator + a;
    }
    // if{!sum}{
    //   setTotalQuestionsPoints(0)
    // } else {
    setTotalQuestionsPoints(sum);
    // }
  }, [questions]);

  useEffect(() => {
    contentful("emtyView").then((data) => {
      data.items.forEach((item) => {
        if (item.fields.type === "questions") {
          setEmptyView(item);
        }
      });
    });
  }, []);
  return (
    <Box>
      {questions.map((d, index, arr) => (
        <QuestionCell
          last={index === arr.length - 1}
          index={index}
          {...d}
          total={totalQuestionsPoints}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          setQuestions={setQuestions}
          gameId={gameId}
          courseId={courseId}
          classroom={classroom}
          organisation={organisation}
          key={d.id}
        />
      ))}

      {questions.length > 0 ? (
        <Box
          className={classes.footer}
          sx={{
            // minWidth: "calc(100vw - 280px);",
            marginLeft: "-50px",
            marginBottom: "-50px",
            position: questions.length >= 2 ? "sticky" : "absolute",
            bottom: questions.length >= 2 ? "-50px" : 0,
            ["@media screen and (max-width: 700px)"]: {
              bottom: "-15px",
              marginLeft: "-15px",
              width: "calc(100% + 30px);",
              padding: "0 5px",
              position: "sticky",
            },
            // marginTop: questions.length >= 1 ? '250px' : '0',
          }}
          show={questions.length >= 1}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              flex: 1,
              // minWidth: "calc(100vw - 280px) !important",
              height: "60px",
              ["@media screen and (max-width: 700px)"]: {
                justifyContent: "space-between",
              },
            }}
          >
            <MTKSwitch
              color="red"
              label={t("Random order")}
              // checked={false}
              // checked={data.isQuestionsOrderRandom}
              // callback={() => {
              //   toggleIsQuestionsOrderRandomField();
              // }}
            />
            <Box
              sx={{
                marginTop: "-60px",
                ["@media screen and (max-width: 700px)"]: {
                  width: "100%",
                  fontSize: "14px",
                  position: "absolute",
                  marginTop: "0px",
                  top: "-35px",
                  display: "flex",
                  justifyContent: "center",
                },
              }}
            >
              <MTKButton
                leftIcon={<Plus />}
                color="green"
                onClick={() =>
                  history.push(
                    withLanguage(
                      `/courses/${courseId}/games/${gameId}/questions/new`
                    )
                  )
                }
              >
                {t("New Question")}
              </MTKButton>
            </Box>
            <Box className={classes.footerTotal}>Total / 10</Box>
          </Box>
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            height: "350px",
          }}
        >
          <div
            style={{
              position: "relative",
              left: "0px",
              top: "0px",
            }}
          >
            <img src={questionIcon} alt="Icon" height="128" width="115" />
            <img
              src={plusIcon}
              alt="Icon"
              height="60"
              width="54"
              style={{
                marginLeft: "-30px",
              }}
            />
          </div>
          <h1>
            {t(
              emptyView?.fields[
                `title${capitalize(withLanguage("").substring(1))}`
              ]
            )}
          </h1>
          <p>
            {
              emptyView?.fields[
                `description${capitalize(withLanguage("").substring(1))}`
              ]
            }
          </p>
          <MTKButton
            icon="plus.svg"
            color="green"
            onClick={() =>
              history.push(
                withLanguage(
                  `/courses/${courseId}/games/${gameId}/questions/new`
                )
              )
            }
            leftIcon={<Image src={plus} />}
          >
            {t("New Question")}
          </MTKButton>
        </div>
      )}
    </Box>
  );
}

export default QuestionsTab;

const QuestionCell = ({
  index,
  userQuestionId,
  type,
  statementMediaFiles = [],
  statement,
  publishQuestionInGame,
  points,
  total,
  answers,
  id,
  selectedQuestions,
  setSelectedQuestions,
  gameId,
  courseId,
  setQuestions,
  organisation,
  classroom,
  last = false,
}) => {
  const history = useHistory();
  const location = useLocation();
  const isPublished = publishQuestionInGame;
  const modals = useModals();
  const { t } = useTranslation();
  const { scrollToQuestion } = getQueryParams(location);
  const { scrollIntoView, targetRef } = useScrollIntoView({ offset: 60 });
  const [coverURL, setCoverURL] = useState(null);

  useEffect(() => {
    if (scrollToQuestion && scrollToQuestion === id) {
      scrollIntoView();
    }
  }, [scrollIntoView]);

  useEffect(() => {
    if (Array.isArray(statementMediaFiles)) {
      const image = statementMediaFiles.find((d) => d.type === "image");
      if (image && image?.id) {
        getFileDownloadURL({
          path: `Images/${image.id}/image`,
        }).then((url) => {
          setCoverURL(url);
        });
      }
    }
  }, [statementMediaFiles]);

  const onClickEdit = () => {
    history.push(
      withLanguage(`/courses/${courseId}/games/${gameId}/questions/${id}/edit/`)
    );
  };
  const onClickPreview = () => {
    // TODO
    history.push(
      withLanguage(`/courses/${courseId}/games/${gameId}/questions/${id}`)
    );
  };

  const onClickPublish = () => {
    publishQuestion(id, gameId).then(() =>
      setQuestions((q) =>
        q.map((d) => (d.id === id ? { ...d, publishQuestionInGame: true } : d))
      )
    );
  };
  const onClickUnpublish = () => {
    unPublishQuestion(id, gameId).then(() =>
      setQuestions((q) =>
        q.map((d) => (d.id === id ? { ...d, publishQuestionInGame: false } : d))
      )
    );
  };
  const onClickDelete = () => {
    modals.openContextModal("confirmation", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Delete Question"),
        modalBody: t("Are you sure to delete this question?"),
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          deleteQuestion(id, gameId).then(() =>
            setQuestions((q) => q.filter((d) => d.id !== id))
          );
        },
      },
    });
  };

  const onClickDuplicate = () => {
    modals.openContextModal("questionDuplication", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Delete Question"),
        modalBody: t("Are you sure to delete this question?"),
        courseId,
        organisationId: organisation.id,
        classroomId: classroom.id,
        onCancel: () => console.log("Cancel"),
        onConfirm: async ({ organisation, classroom, course, game }) => {
          const newQuestionId = await duplicateQuestion(id, gameId, {
            newGameId: game,
          });
          const question = await getQuestionByID(game, newQuestionId);
          setQuestions((qs) => [...qs, question]);

          if (newQuestionId) {
            history.push(
              withLanguage(
                `/courses/${course}/games/${game}/questions?scrollToQuestion=${newQuestionId}`
              )
            );
          }
        },
      },
    });
  };
  // const onClickDelete=() => {
  //   handleDeleteQuestion(question);
  // }
  return (
    <Box
      sx={{
        display: "flex",
        marginTop: "15px",
        minHeight: "135px",
        maxWidth: "100%",
        alignItems: "center",
        paddingBottom: last ? "100px" : "0",
      }}
      ref={targetRef}
    >
      <Box
        sx={{
          fontSize: "20px",
          color: "#d4dae6",
          fontWeight: "500",
          paddingRight: "10px",
          width: "40px",
          minWidth: "40px",
          maxWidth: "40px",
        }}
      >
        {index + 1}
      </Box>
      <Box
        sx={{
          minHeight: "135px",
          flex: 1,
          display: "flex",
          flexDirection: "row",
          borderRadius: "22px",
          boxShadow: "4px 4px 4px 0px #00000022",
          background: isPublished ? "#edf7e9" : "#F8E9E2",
          justifyContent: "space-between",
          [`@media screen and (max-width: 1200px)`]: {
            flexDirection: "column",
          },
        }}
      >
        {/* //left side */}
        <Box
          sx={{
            display: "flex",
            opacity: isPublished ? "1.0" : "0.5",
            maxWidth: "400px",
            width: "400px",
            alignItems: "center",
            flex: 1,
            paddingLeft: "20px",
            "@media screen and (max-width: 1680px)": {
              maxWidth: "150px",
              width: "150px",
              padding: "20px",
            },
          }}
        >
          <MTKCheckBox
            // mx="sm"
            checked={selectedQuestions.includes(id)}
            color="blue"
            onChange={(e) => {
              const checked = e.target.checked;
              if (checked) {
                setSelectedQuestions((q) => [...q, id]);
              } else {
                setSelectedQuestions((q) => q.filter((d) => d !== id));
              }
            }}
          />
          {coverURL ? (
            <Image
              mx="md"
              my="auto"
              src={coverURL}
              width={90}
              height={60}
              radius="11px"
            />
          ) : (
            <PlaceHolderImage />
          )}

          <Box
            sx={{
              height: "41px",
              margin: "0px 0px 0px 20px",
              [`@media screen and (max-width: 1200px)`]: {
                margin: "0px",
              },
            }}
            mt="md"
          >
            <Box
              sx={{
                fontSize: "18px",
                color: "rgb(39, 71, 132)",
                maxWidth: "231px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                cursor: "pointer",
                textOverflow: "ellipsis",
                "@media screen and (max-width: 900px)": {
                  fontSize: "14px",
                },
              }}
              onClick={() => {
                history.push(
                  withLanguage(
                    `/courses/${courseId}/games/${gameId}/questions/${id}/edit/`
                  )
                );
              }}
            >
              {userQuestionId}
            </Box>
            <QuestionCellType type={type} />
          </Box>
        </Box>
        {/* end left side */}

        {/* right side start */}
        <Box
          sx={{
            display: "flex",
            background: "#fafafa",
            flex: 1,
            borderRadius: "22px",
            zIndex: "2",
            alignItems: "center",
            justifyContent: "space-between",
            "@media screen and (max-width: 1680px)": {
              maxWidth: "65%",
              // justifyContent: "flex-end",
            },
            [`@media screen and (max-width: 1200px)`]: {
              maxWidth: "100%",
            },
            [`@media screen and (max-width: 900px)`]: {
              maxWidth: "100%",
              flexDirection: "column",
            },
          }}
        >
          <Box
            sx={{
              textAlign: "left",
              color: "#274784",
              marginBottom: "10px",
              fontWeight: "500",
              fontSize: "15px",

              // maxWidth: "310px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              padding: "20px 20px",
            }}
          >
            <Box
              sx={{
                maxWidth: "100px",
                width: "100px",
              }}
            >
              {statement}
            </Box>
            {type === "multiple" ? (
              <Box
                sx={{
                  display: "grid",

                  gridTemplateColumns: "1fr 1fr",
                  gridGap: "10px",
                }}
              >
                <Answer empty={answers[0]?.answer === ""}>
                  {answers[0]?.answer}
                </Answer>
                <Answer empty={answers[1]?.answer === ""}>
                  {answers[1]?.answer}
                </Answer>
                <Answer empty={answers[2]?.answer === ""}>
                  {answers[2]?.answer}
                </Answer>
                <Answer empty={answers[3]?.answer === ""}>
                  {answers[3]?.answer}
                </Answer>
              </Box>
            ) : (
              <OpenAnswer />
            )}
          </Box>
          <Box
            sx={{
              [`@media screen and (max-width: 900px)`]: {
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                padding: "10px",
              },
            }}
          >
            <QuestionScore
              isPublished={isPublished}
              publishQuestionInGame={publishQuestionInGame}
              points={points}
              total={total}
            />

            <Dots
              publishQuestionInGame={publishQuestionInGame}
              onClickPreview={onClickPreview}
              onClickEdit={onClickEdit}
              onClickPublish={onClickPublish}
              onClickUnpublish={onClickUnpublish}
              onClickDelete={onClickDelete}
              onClickDuplicate={onClickDuplicate}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Answer = ({ empty, children }) => {
  return (
    <Box
      sx={{
        opacity: empty ? 0.5 : 1.0,
        /* background: red; */
        border: "1px solid #274784",
        textAlign: "center",
        padding: "3px 5px",
        fontSize: "13px",
        width: "150px",
        minHeight: "23px",

        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        "@media screen and (max-width: 1150px)": {
          width: "120px",
        },
      }}
    >
      {children}
    </Box>
  );
};

const OpenAnswer = ({}) => {
  return (
    <Box
      sx={{
        width: "310px",
        border: "1px solid #274784",
        padding: "3px 5px",
        height: "46px",
      }}
    ></Box>
  );
};
const PlaceHolderImage = () => {
  return (
    <Box
      mx="md"
      my="auto"
      sx={{
        width: "90px",
        minWidth: "90px",
        height: "60px",
        minHeight: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgb(250, 250, 250)",
        borderRadius: "11px",
      }}
    >
      <Image width={24} src={placeholderImage} />
    </Box>
  );
};

const QuestionCellType = ({ type }) => {
  return (
    <Box
      sx={{
        marginTop: "8px",
        fontSize: "14px",
        color: "#274784",
        display: "flex",
        whiteSpace: "nowrap",
        alignItems: "center",
      }}
    >
      {type === "open" ? (
        <>
          <Image mr="sm" src={questionMark} width="12px" />
          Open Question
        </>
      ) : null}
      {type === "multiple" ? (
        <>
          <Image mr="sm" src={listIcon} width="12px" />
          Multiple Choice
        </>
      ) : null}
      {type === "puzzle" ? (
        <>
          <Image mr="sm" src={puzzleIcon} width="25px" />
          Puzzle Question
        </>
      ) : null}
      {type === "drawing" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image mr="sm" src={drawingIcon} width="25px" />
          Drawing Question
        </div>
      )}
      {type === "click" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image mr="sm" src={clickIcon} width="25px" />
          Click Question
        </div>
      )}
      {type === "differences" && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Image mr="sm" src={diffIcon} width="25px" />7 Differences
          </div>
        </>
      )}
    </Box>
  );
};

const QuestionScore = ({
  isPublished,
  publishQuestionInGame,
  points,
  total,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: isPublished ? "1.0" : "0.5",
      }}
    >
      <Box
        sx={{
          border: "1px solid #e6f0e2",
          background: isPublished ? "#e7f0e3" : "#ebc1e3",
          borderRadius: "50%",
          height: "50px",
          width: "50px",
          textAlign: "center",
          paddingTop: "14px",
          color: "#274784",
        }}
      >
        <span>{points}</span>
      </Box>
      <Box
        sx={{
          background: "#274784",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "25px",
          width: "50px",
          color: "white",
          borderRadius: "13px",
          marginLeft: "-10px",
          fontSize: "0.8rem",
          padding: 0,
        }}
      >
        {publishQuestionInGame ? `${parseInt((points * 100) / total)}%` : "0%"}
      </Box>
    </Box>
  );
};

const Dots = ({
  publishQuestionInGame = false,
  onClickPreview,
  onClickEdit,
  onClickDuplicate,
  onClickUnpublish,
  onClickPublish,
  onClickDelete,
}) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Menu
      //   trigger="hover"
      control={
        <Box className={classes.dotContainer}>
          <Box className={classes.dot} />
          <Box className={classes.dot} />
          <Box className={classes.dot} />
        </Box>
      }
    >
      <Menu.Item
        icon={<PreviewQuestionIcon />}
        onClick={() => {
          onClickPreview();
        }}
      >
        {t("Preview question")}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          onClickEdit();
        }}
        icon={<EditQuestionIcon />}
      >
        {t("Edit question")}
      </Menu.Item>
      <Menu.Item
        onClick={() => onClickDuplicate()}
        icon={<DuplicateQuestionIcon />}
      >
        {t("Duplicate question")}
      </Menu.Item>
      <Menu.Item
        icon={
          publishQuestionInGame ? (
            <UnpublishQuestionIcon />
          ) : (
            <PublishQuestionIcon />
          )
        }
        onClick={() => {
          if (publishQuestionInGame) {
            onClickUnpublish();
          } else {
            onClickPublish();
          }
        }}
      >
        {publishQuestionInGame
          ? t("Unpublish question")
          : t("Publish question")}
      </Menu.Item>
      <Menu.Item
        color="red"
        icon={<DeleteQuestionIcon />}
        onClick={() => onClickDelete()}
      >
        {t("Delete question")}
      </Menu.Item>
    </Menu>
  );
};
