import { Box, Button, Grid, Text, Title } from "@mantine/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import AudioUploader from "../../../../components/AudioUploader";
import MTKButton from "../../../../components/Button";
import MTKInput from "../../../../components/Input";
import MediaUploader from "../../../../components/MediaUploader";
import MTKSelect from "../../../../components/Select";
import MTKSwitch from "../../../../components/Switch";
import MTKTextarea from "../../../../components/Textarea";
import { withLanguage } from "../../../../lib/i18";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";

import { ReactComponent as PlusIcon } from "../../../../assets/images/icons/plus.svg";
import { ReactComponent as TrashCanIcon } from "../../../../assets/images/icons/trash-can.svg";
import {
  ageDropdownList,
  capitalize,
  getGameTopicTypes,
  getGameTopics,
  getGameSubTopics,
  getPricingCategories,
} from "../../../../utils/utils";
import { Link } from "react-router-dom";
import useIsSuperAdmin from "../../../../hooks/useIsSuperAdmin";
const stringToHtml = (text) => {
  return ReactHtmlParser(documentToHtmlString(text));
};
function AboutTab({ pageContent, form, onDelete, organisation }) {
  const { t } = useTranslation();

  const { ageLimitFrom, ageLimitTo, gameTopicType, gameTopic, name } =
    form.values;

  const isAdmin = useIsSuperAdmin();
  const gameTopics = useMemo(
    () => getGameTopics(gameTopicType),
    [gameTopicType]
  );
  const gameSubTopic = useMemo(
    () => getGameSubTopics(gameTopicType, gameTopic),
    [gameTopicType, gameTopic]
  );

  return (
    <Grid gutter="xl">
      <Grid.Col md={12} lg={6}>
        <Section
          color="green"
          title={
            pageContent?.fields[
              `gameInformationTitle${capitalize(withLanguage("").substring(1))}`
            ]
          }
          description={stringToHtml(
            pageContent?.fields[
              `gameInformationDescription${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          )}
        >
          <MediaUploader
            ratio={1}
            {...form.getInputProps("coverImage")}
            recommendedAspectRatio="1/1 (512*512)"
          />
          <MTKInput
            label={`${t("Name")}`}
            required
            labelColor="blue"
            //   color="green"
            {...form.getInputProps("name")}
          />
          <AudioUploader color="green" {...form.getInputProps("audioFile")} />

          <MTKTextarea
            label={t("Description")}
            minRows={4}
            {...form.getInputProps("description")}
          />
          <MTKSelect
            data={ageDropdownList.map((d) => ({
              label: d || "",
              value: `${d || ""}`,
            }))}
            onChange={(value) => {
              if (!ageLimitTo) {
                form.setFieldValue("ageLimitTo", "12");
              }
              form.setFieldValue("ageLimitFrom", value);
            }}
            value={`${ageLimitFrom}`}
            label={t("Age From")}
          />
          <MTKSelect
            data={ageDropdownList
              .filter((d) => (ageLimitFrom && +ageLimitFrom > d ? false : true))
              .map((d) => ({ value: `${d || ""}`, label: d || "" }))}
            label={t("Age To")}
            {...form.getInputProps("ageLimitTo")}
            value={`${ageLimitTo}`}
          />
        </Section>
        <Box
          mt="lg"
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Button
            color="red"
            radius={"xl"}
            size="md"
            leftIcon={<TrashCanIcon color="white" />}
            onClick={onDelete}
          >
            {t("Delete")}
          </Button>
        </Box>
      </Grid.Col>

      <Grid.Col md={12} lg={6}>
        {isAdmin && organisation?.type === "home" && (
          <Section mt="40px" mb="60px" color="green" title={t("Pricing")}>
            <MTKSelect
              label={`${t("Category")}`}
              {...form.getInputProps("pricingCategory")}
              data={getPricingCategories()}
            />
          </Section>
        )}
        <Section
          mt="40px"
          mb="60px"
          color="green"
          title={
            pageContent?.fields[
              `availabilityTitle${capitalize(withLanguage("").substring(1))}`
            ]
          }
          description={stringToHtml(
            pageContent?.fields[
              `availabilityDescription${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          )}
        >
          <MTKSwitch
            label={`${t("Game is available for kids")}`}
            required
            labelColor="blue"
            color="green"
            size={"md"}
            {...form.getInputProps("gameIsAvailableForKids", {
              type: "checkbox",
            })}
          />
        </Section>
        <Section
          mt="40px"
          mb="60px"
          color="green"
          title={
            pageContent?.fields[
              `topicsTitle${capitalize(withLanguage("").substring(1))}`
            ]
          }
          description={
            <>
              {stringToHtml(
                pageContent?.fields[
                  `topicsDescription${capitalize(
                    withLanguage("").substring(1)
                  )}`
                ]
              )}
              <Text
                underline
                color="#bbbbbb"
                to={withLanguage("/contact")}
                display="inline"
                component={Link}
              >
                {t("Contact Form")}
              </Text>
              <div style={{ marginTop: 20 }}>
                <MTKButton
                  color="green"
                  leftIcon={<PlusIcon />}
                  onClick={() => {
                    window.open(withLanguage(`/contact`), "_blank");
                  }}
                >
                  {t("New Topic")}
                </MTKButton>
              </div>
            </>
          }
        >
          <MTKSelect
            label={`${t("Type")}`}
            required
            data={getGameTopicTypes()}
            {...form.getInputProps("gameTopicType")}
            onChangeTopic={form.getInputProps("gameTopic").onChange}
            onChangeSubTopic={form.getInputProps("gameSubTopic").onChange}
          />

          <MTKSelect
            label={`${t("Topic")}`}
            data={["---", ...gameTopics]}
            {...form.getInputProps("gameTopic")}
            disabled={!gameTopicType}
            onChangeSubTopic={form.getInputProps("gameSubTopic").onChange}
            required={false}
          />

          <MTKSelect
            label={`${t("Sub-Topic")}`}
            data={["---", ...gameSubTopic]}
            {...form.getInputProps("gameSubTopic")}
            disabled={!gameTopic}
            required={false}
          />
        </Section>
      </Grid.Col>
    </Grid>
  );
}

export default AboutTab;

const Section = ({ title = "", children, ...props }) => {
  return (
    <Box {...props}>
      <Title
        sx={{
          fontSize: "24px",
          color: "rgb(147, 213, 120)",
          paddingLeft: "10px",
          marginTop: "0px",
          marginBottom: "20px",
        }}
      >
        {title}
      </Title>
      <Box
        sx={{
          background: "rgb(237, 247, 233)",
          borderRadius: "22px",
          padding: "20px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
