import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  createStyles,
  Image,
  Title,
  Tabs,
  Center,
  Modal,
  Text,
  Button,
  LoadingOverlay,
} from "@mantine/core";

import AppLayout from "../../../components/layout/AppLayout";
import { useTranslation } from "react-i18next";

import { Link, useHistory, useParams } from "react-router-dom";
import { withLanguage } from "../../../lib/i18";
import {
  organisationSubTypes,
  organisationTypes,
} from "../../../constants/types";
import useIsSuperAdmin from "../../../hooks/useIsSuperAdmin";
import BackButtonCircle from "../../../components/BackButtonCircle";
import FormSection from "../../../components/layout/FormSection";

import { ReactComponent as PlusIcon } from "../../../assets/images/icons/plus.svg";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";
import contentful from "../../../lib/contentful";
import { capitalize } from "../../../utils/utils";
import MediaUploader from "../../../components/MediaUploader";
import MTKInput from "../../../components/Input";
import MTKSelect from "../../../components/Select";
import MTKTextarea from "../../../components/Textarea";
import MTKPhoneInput from "../../../components/PhoneInput";
import MTKCountryInput from "../../../components/CountryInput";
import MTKButton from "../../../components/Button";
import AudioUploader from "../../../components/AudioUploader";
import useNewOrgamisationForm from "./Form";
import SubmitBottomBar from "../../../components/SubmitBottomBar";
import { getTeachers } from "../../../api/Users";
import { z } from "zod";
import errorSignImage from "../../../assets/images/icons/error-sign.png";

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",

    position: "sticky",
    top: 0,
    padding: "50px 0",
    zIndex: 2,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "50px",
  },
}));

function NewOrganisation() {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const currentLang = i18n.language;
  const { form, onFormSubmit, loading } = useNewOrgamisationForm();

  const [pageContent, setPageContent] = useState(null);
  const { directors, type } = form.values;
  const orgSubType = useMemo(() => {
    if (!type) return [];
    const typeMap = organisationSubTypes[type];
    const orgSubTypes = Object.keys(typeMap).map((k) => {
      return {
        id: k,
        label: typeMap[k],
      };
    });
    return orgSubTypes
      .filter((o) => o.id !== "")
      .map((o) => ({
        value: o.id,
        label: o.label[currentLang],
      }));
  }, [type]);

  // Init
  //contenful
  useEffect(() => {
    // dispatch(setHasUnsavedChanges(false));
    contentful("ogranisationsItemsDescription").then((data) =>
      setPageContent(data.items[0])
    );
  }, []);
  const stringToHtml = (text) => {
    return ReactHtmlParser(documentToHtmlString(text));
  };

  const orgTypes = [
    // { id: 'home', label: t(organisationTypes['home']) },
    { value: "school", label: t(organisationTypes["school"][currentLang]) },
    {
      value: "youthMovement",
      label: t(organisationTypes["youthMovement"][currentLang]),
    },
    {
      value: "synagogue",
      label: t(organisationTypes["synagogue"][currentLang]),
    },
  ];

  return (
    <AppLayout>
      <Box className={classes.header}>
        <BackButtonCircle mr="md" to={withLanguage("/organisations")} />
        <Title order={3}>{t("New Organisation")}</Title>
      </Box>
      <FormSection
        title={
          pageContent?.fields[
            `generalDescTitle${capitalize(withLanguage("").substring(1))}`
          ]
        }
        // description={t('NewOrganisationGeneralInformation')}
        description={stringToHtml(
          pageContent?.fields[
            `generalDescDescription${capitalize(withLanguage("").substring(1))}`
          ]
        )}
      >
        <MediaUploader
          ratio={1}
          {...form.getInputProps("coverImage")}
          recommendedAspectRatio="1/1 (512*512)"
        />
        <MTKInput
          label={`${t("Name")}`}
          required
          labelColor="blue"
          {...form.getInputProps("name")}
        />
        <AudioUploader {...form.getInputProps("audioFile")} />
        <MTKSelect
          data={orgTypes}
          label={t("Type")}
          required
          {...form.getInputProps("type")}
        />
        <MTKSelect
          label={`${t("Sub Type")}`}
          data={orgSubType}
          {...form.getInputProps("subType")}
        />

        <MTKTextarea
          label={t("Description")}
          minRows={4}
          {...form.getInputProps("description")}
        />
      </FormSection>

      <FormSection
        mt="40px"
        title={
          pageContent?.fields[
            `contactDetailsTitle${capitalize(withLanguage("").substring(1))}`
          ]
        }
        description={stringToHtml(
          pageContent?.fields[
            `contactDetailsDescription${capitalize(
              withLanguage("").substring(1)
            )}`
          ]
        )}
      >
        <MTKInput
          type="email"
          label={t("Email")}
          labelColor="blue"
          {...form.getInputProps("email")}
        />
        <MTKPhoneInput
          label={t("Phone Number")}
          labelColor="blue"
          {...form.getInputProps("phoneNumber")}
        />
      </FormSection>

      <FormSection
        mt="40px"
        title={
          pageContent?.fields[
            `addressTitle${capitalize(withLanguage("").substring(1))}`
          ]
        }
        description={stringToHtml(
          pageContent?.fields[
            `adressDescription${capitalize(withLanguage("").substring(1))}`
          ]
        )}
      >
        <MTKCountryInput
          {...form.getInputProps("country")}
          label={t("Country")}
          labelColor="blue"
        />
        <MTKInput
          label={t("City")}
          labelColor="blue"
          {...form.getInputProps("city")}
        />
        <MTKInput
          label={t("Zip")}
          labelColor="blue"
          {...form.getInputProps("zip")}
        />
        <MTKInput
          label={t("Address")}
          labelColor="blue"
          {...form.getInputProps("address")}
        />
      </FormSection>
      <FormSection
        mt="60px"
        mb="60px"
        layout="row"
        title={
          pageContent?.fields[
            `directorsTitle${capitalize(withLanguage("").substring(1))}`
          ]
        }
        description={stringToHtml(
          pageContent?.fields[
            `directorsDescription${capitalize(withLanguage("").substring(1))}`
          ]
        )}
      >
        <Box
          p="xl"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            {directors.map((d) => (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "30px",
                }}
              >
                <Box sx={(theme) => ({ color: theme.colors.blue })}>
                  {d?.firstName} {d?.name}
                </Box>
                <Button
                  color={"red"}
                  radius="lg"
                  compact
                  onClick={() =>
                    form.setFieldValue(
                      "directors",
                      directors.filter((dir) => d.id !== dir.id)
                    )
                  }
                >
                  {t("Remove")}
                </Button>
              </Box>
            ))}
          </Box>
          <DirectorsBtn {...form.getInputProps("directors")} />
        </Box>
      </FormSection>
      <SubmitBottomBar
        onCreate={form.onSubmit(onFormSubmit)}
        onCancel={() => history.push(withLanguage("/organisations/"))}
        loading={loading}
      />
    </AppLayout>
  );
}

export const DirectorsBtn = ({ value: directorList, onChange }) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [email, setEmail] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [step, setStep] = useState("initial");

  useEffect(() => {
    const getData = async () => {
      const teachers = await getTeachers();
      setTeachers(teachers);
    };
    getData();
  }, []);

  const checkIfAddingAllowed = () => {
    let emailValid = false;
    try {
      z.string().email().parse(email);
      emailValid = true;
    } catch (error) {
      emailValid = false;
    }
    if (!emailValid) {
      return false;
    }

    const found = directorList.find((d) => d.email === email);
    if (found) return false;

    const emailExists = teachers.find((t) => t.email === email);
    if (!emailExists) return false;
    return true;
  };
  const addDirectorCallback = (email) => {
    const directorsCopy = [...directorList];

    const teacher = teachers.find((t) => t.email === email);
    if (!teacher) {
      setStep("error");
      return;
    }

    const data = { id: teacher.id };

    if ("firstName" in teacher) {
      data.firstName = teacher.firstName;
    }

    if ("name" in teacher) {
      data.name = teacher.name;
    }
    directorsCopy.push(data);

    onChange(directorsCopy);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    const allowed = checkIfAddingAllowed(email);
    if (!allowed) {
      setStep("error");
      setEmail("");
      return;
    }
    addDirectorCallback(email);
    setOpened(false);
    setStep("initial");
    setEmail("");
  };
  return (
    <>
      <Modal
        radius={"lg"}
        shadow="md"
        opened={opened}
        onClose={() => {
          setOpened(false);
          setStep("initial");
          setEmail("");
        }}
        centered
        padding={"xl"}
        title={null}
      >
        {step === "initial" && (
          <Box p="xl">
            <Title order={3} mb="sm">
              {t("Add Director")}
            </Title>
            <Text size="lg" mb="lg">
              {t("Enter an email address of a teacher.")}
            </Text>
            <form onSubmit={onSubmit}>
              <MTKInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mb="xl"
                type="email"
                label={t("Email")}
              />
              <Box
                sx={{
                  display: "flex",
                }}
                px="xl"
                mt="50px"
              >
                <MTKButton variant="light" fullWidth color="blue">
                  {" "}
                  {t("Cancel")}
                </MTKButton>
                <MTKButton type="submit" color="blue" fullWidth>
                  {t("Add")}
                </MTKButton>
              </Box>
            </form>
          </Box>
        )}
        {step === "error" && (
          <Box>
            <Center>
              <Image src={errorSignImage} my="md" width={84} />
            </Center>
            <Title order={3} my="md" align="center">
              {t("Error")}
            </Title>
            <Text size="lg" align="center" my="md">
              {t("Email address invalid or already added to the list!")}
            </Text>
            <Center>
              <MTKButton
                width="140px"
                // fullWidth
                color="blue"
                mt="md"
                onClick={() => {
                  setEmail("");
                  setOpened(false);
                }}
              >
                {t("OK")}
              </MTKButton>
            </Center>
          </Box>
        )}
      </Modal>

      <MTKButton
        onClick={() => setOpened(true)}
        leftIcon={<PlusIcon />}
        mx="auto"
        color="blue"
      >
        {t("Add Director")}
      </MTKButton>
    </>
  );
};
export default NewOrganisation;
