import { Box, Image, Title } from "@mantine/core";
import React, { useState, useEffect } from "react";
import placeholderImage from "../../../assets/images/icons/image.png";
import duplicateIcon from "../../../assets/images/icons/duplicate-element.svg";
import { getFileDownloadURL } from "../../../api/Storage";
import { useHistory } from "react-router-dom";
import { withLanguage } from "../../../lib/i18";

function CourseCard({
  name = "",
  coverImage,
  id,
  withOrg = false,
  withClassroom = false,
  withDuplicate = false,
  handleDuplicate = () => null,
}) {
  const [coverURL, setCoverURL] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (coverImage && coverImage?.id) {
      getFileDownloadURL({
        path: `Images/${coverImage.id}/image`,
      }).then((url) => setCoverURL(url));
    }
  }, [coverImage]);
  return (
    <Box
      sx={{
        position: "relative",
        background: "rgb(239, 247, 255)",
        borderRadius: "22px",
        padding: "18px",

        boxShadow: "rgb(0 0 0 / 7%) 0px 2px 4px 0px",
        cursor: "pointer",
        paddingRight: "15px",
        overflow: "hidden",
      }}
    >
      <Box onClick={() => history.push(withLanguage(`/courses/${id}`))}>
        {coverURL ? (
          <Image width="150px" height={150} radius="lg" src={coverURL} />
        ) : (
          <PlaceHolderImage />
        )}
      </Box>
      <Title
        onClick={() => history.push(withLanguage(`/courses/${id}`))}
        mb={withOrg || withClassroom ? "50px" : 0}
        sx={{
          maxWidth: "298px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          fontSize: "20px",
          fontWeight: "bold",
          color: "rgb(1, 158, 211)",
          marginTop: "20px",
          //   paddingLeft: "18px",
          //   paddingRight: "18px",
          //   paddingBottom: "18px",
        }}
      >
        {name}
      </Title>
      {withDuplicate && (
        <Box
          sx={{
            position: "absolute",
            right: "10px",
            bottom: "10px",
            backgroundColor: "rgb(1, 158, 211)",
            border: "1px solid rgb(1, 158, 211)",
            color: "rgb(255, 255, 255)",
            padding: "13px 27px",
            borderRadius: "50px",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            display: "flex",
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={handleDuplicate}
        >
          <Image fit="contain" src={duplicateIcon} width={30} height={14} />
        </Box>
      )}
      {withOrg && withClassroom && (
        <Box
          sx={{
            marginTop: "18px",
            background: "rgb(250, 250, 250)",
            padding: "18px",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "rgb(1, 158, 211)",
            position: "absolute",
            width: "100%",
            right: "0",
            bottom: "0",
          }}
        >
          {withOrg} / {withClassroom}
        </Box>
      )}
    </Box>
  );
}

export default CourseCard;
const PlaceHolderImage = ({ ...props }) => {
  return (
    <Box
      sx={{
        width: "150px",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgb(250, 250, 250)",
        borderRadius: "22px",
      }}
      {...props}
    >
      <Image width={54} src={placeholderImage} />
    </Box>
  );
};
