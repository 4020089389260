import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import MTKButton from "../Button";
import audioImage from "../../assets/images/icons/audio.png";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

import { Button, Center, Image, Stack, Text, Title } from "@mantine/core";
import { Modal } from "@mantine/core";

import PlayAudioFile from "./components/PlayAudioFile";
import UploadOrRecordAudio from "./components/UploadOrRecordAudio";
import RecordAudioFile from "./components/RecordAudioFile";
function AudioUploader({
  value,
  onChange,
  color = "blue",
  fullWidth = false,
  ...props
}) {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [step, setStep] = useState(value ? "playAudioWithDelete" : "initial");
  const [isNewFile, setIsNewFile] = useState(false);
  useEffect(() => {
    if (value && !isNewFile && opened) {
      setStep("playAudioWithDelete");
    }
  }, [value, isNewFile, opened]);

  const handleUploadFile = (event) => {
    event.preventDefault();
    const files = event.target.files;

    if (files && files[0]) {
      const file = files[0];
      onChange(file);
      setIsNewFile(true);
      setStep("playAudio");
    }
  };
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {
          setIsNewFile(false);
          setOpened(false);
          value ? setStep("playAudioWithDelete") : setStep("initial");
        }}
        // title="Introduce yourself!"
        centered
        padding={"xl"}
        radius="xl"
      >
        {step === "initial" ? (
          <UploadOrRecordAudio
            handleUploadFile={handleUploadFile}
            setStep={setStep}
          />
        ) : step === "playAudio" ? (
          <PlayAudioFile
            file={value}
            setStep={setStep}
            onChange={onChange}
            setOpened={setOpened}
            setIsNewFile={setIsNewFile}
          />
        ) : step === "playAudioWithDelete" ? (
          <PlayAudioFile
            setOpened={setOpened}
            widthDelete
            file={value}
            setStep={setStep}
            onChange={onChange}
            setIsNewFile={setIsNewFile}
          />
        ) : (
          <RecordAudioFile
            setOpened={setOpened}
            widthDelete
            file={value}
            setStep={setStep}
            onChange={onChange}
            setIsNewFile={setIsNewFile}
            cancelCallback={() => {
              setIsNewFile(false);
              setOpened(false);
              value ? setStep("playAudioWithDelete") : setStep("initial");
            }}
          />
        )}
      </Modal>
      <MTKButton
        onClick={() => setOpened(true)}
        my="lg"
        color={color}
        fullWidth={fullWidth}
        leftIcon={<Image width={15} src={audioImage} />}
        {...props}
      >
        {value ? t("Audio clip") : t("Upload Audio")}
      </MTKButton>
    </>
  );
}

{
  /* const RecordAudioFile = ({
  file = null,
  setStep,
  onChange,
  widthDelete = false,
  setOpened,
}) => {
  const selectFileRef = useRef();
  const { t } = useTranslation();
  const [audioUrl, setAudioUrl] = useState("");
  useEffect(() => {
    if (file) {
      const audioUrl = URL.createObjectURL(file);
      setAudioUrl(audioUrl);
    }
  }, [file]);

  return (
    <Stack align={"center"}>
      <MTKButton
        onClick={() => (widthDelete ? setOpened(false) : onChange(null))}
        variant="light"
        color="gray"
      >
        {t("Cancel")}
      </MTKButton>
    </Stack>
  );
}; */
}

export default AudioUploader;
