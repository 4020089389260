import React, { useRef } from "react";
import { Stack, Button, Title, Text, Image } from "@mantine/core";
import { useTranslation } from "react-i18next";
import selectImage from "../../../assets/images/icons/select.png";
import recordImage from "../../../assets/images/icons/record.png";

const UploadOrRecordAudio = ({ handleUploadFile, setStep }) => {
  const { t } = useTranslation();

  const selectFileRef = useRef();
  return (
    <Stack align={"center"}>
      <Title order={3}>{t("Add an audio")}</Title>
      <Text>{t("How would you like to add your audio file?")}</Text>
      <input
        type="file"
        onChange={handleUploadFile}
        ref={selectFileRef}
        accept=".mp3"
        style={{ display: "none" }}
      />
      <Button
        radius={"lg"}
        color="indigo"
        fullWidth
        mx="lg"
        mt="lg"
        onClick={() => selectFileRef.current.click()}
        leftIcon={<Image width={15} src={selectImage} />}
      >
        {t("Select audio file")}
      </Button>
      <Button
        radius={"lg"}
        color="red"
        fullWidth
        mx="lg"
        mb="lg"
        onClick={() => setStep("record")}
        leftIcon={<Image width={20} src={recordImage} />}
      >
        {t("Record an audio")}
      </Button>
    </Stack>
  );
};

export default UploadOrRecordAudio;
