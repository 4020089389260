import { Box, Grid, Select } from "@mantine/core";
import React, { useMemo, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import GlobalContext from "../../../../context/GlobalContext";
import useIsSuperAdmin from "../../../../hooks/useIsSuperAdmin";
import {
  getClassroomsWithCourses,
  getFilteredCourses,
  getJewishSchoolYear,
  getOrganisationsWithClassroomAndCourse,
  getOrganisationsWithClassrooms,
  getSchoolYearsWithClassrooms,
} from "../../../../utils/utils";
import CourseCard from "../../components/CourseCard";

function CoursesTab({
  classrooms,
  organisations,
  schoolYears,
  selectedOrganisation,
  setSelectedOrganisation,
  selectedSchoolYear,
  setSelectedSchoolYear,
  selectedClassroom,
  setSelectedClassroom,
  courses,
  setCourses,
}) {
  const { t } = useTranslation();
  const { profile } = useContext(GlobalContext);
  const [filteredOrganisations, setFilteredOrganisations] = useState([]);
  const [classroomsWithCourses, setClassroomsWithCourses] = useState([]);
  const [schoolYearsWithClassrooms, setSchoolYearsWithClassrooms] = useState(
    []
  );
  const isAdmin = useIsSuperAdmin();

  const filteredClassrooms = useMemo(() => {
    let filteredClassrooms = classroomsWithCourses;
    if (selectedOrganisation) {
      filteredClassrooms = filteredClassrooms.filter(
        (d) => d.organisationId === selectedOrganisation
      );
    }
    if (filteredClassrooms?.length > 0) {
      setSelectedClassroom(filteredClassrooms[0].id);
    }
    return filteredClassrooms;
  }, [selectedOrganisation, , classroomsWithCourses]);

  const filteredCourses = useMemo(() => {
    return getFilteredCourses({
      organisations: filteredOrganisations,
      classrooms: filteredClassrooms,
      schoolYears: schoolYearsWithClassrooms,
      selectedOrganisation,
      selectedClassroom,
      selectedSchoolYear,
      courses,
      profile,
      isAdmin,
    });
  }, [
    selectedOrganisation,
    selectedSchoolYear,
    classroomsWithCourses,
    filteredClassrooms,
    filteredOrganisations,
    schoolYearsWithClassrooms,
    profile,
    selectedClassroom,
    courses,
    isAdmin,
  ]);

  useEffect(() => {
    if (!organisations || !classrooms || !courses || !profile) return [];
    const filteredOrgs = getOrganisationsWithClassroomAndCourse({
      organisations,
      classrooms,
      courses,
      teacherId: isAdmin ? null : profile.id,
    });
    setFilteredOrganisations(filteredOrgs);
    if (filteredOrgs?.length > 0) {
      setSelectedOrganisation(filteredOrgs[0]?.id);
    }
    const classroomsWithCourses = getClassroomsWithCourses({
      organisations,
      classrooms,
      courses,
      teacherId: isAdmin ? null : profile.id,
    });
    setClassroomsWithCourses(classroomsWithCourses);

    const schoolYearsWithClassrooms = getSchoolYearsWithClassrooms({
      classrooms: classroomsWithCourses,
      schoolYears,
    });
    console.log({
      classroomsWithCourses,
      classrooms,
    });
    setSchoolYearsWithClassrooms(schoolYearsWithClassrooms);
    if (schoolYearsWithClassrooms?.length > 0) {
      setSelectedSchoolYear(schoolYearsWithClassrooms[0].id);
    }
  }, [organisations, classrooms, courses, profile, schoolYears, isAdmin]);

  console.log({ courses });

  return (
    <Box mt="60px">
      <Grid gutter={"xl"}>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("Organisation")}
            value={selectedOrganisation}
            onChange={(value) => {
              setSelectedOrganisation(value);
            }}
            data={filteredOrganisations.map((d) => {
              return {
                value: d.id,
                label: d.name,
              };
            })}
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("Classroom")}
            value={selectedClassroom}
            onChange={(value) => {
              setSelectedClassroom(value);
            }}
            data={filteredClassrooms.map((d) => {
              return {
                value: d.id,
                label: d.name,
              };
            })}
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("School Year")}
            value={selectedSchoolYear}
            onChange={(value) => {
              setSelectedSchoolYear(value);
            }}
            data={schoolYearsWithClassrooms.map((d) => {
              return {
                value: d.id,
                label: getJewishSchoolYear(d.key),
              };
            })}
          />
        </Grid.Col>
      </Grid>
      <Box mt="20px">
        {courses?.length > 0 ? (
          <Grid gutter={"xl"}>
            {filteredCourses.map((d) => (
              <Grid.Col lg={4} md={12} sm={12}>
                <CourseCard
                  {...d}
                  withClassroom={
                    filteredClassrooms.find((d) => d.id === selectedClassroom)
                      ?.name
                  }
                  withOrg={
                    organisations.find((d) => d.id === selectedOrganisation)
                      ?.name
                  }
                />
              </Grid.Col>
            ))}
          </Grid>
        ) : (
          <Box
            mt="40px"
            sx={{
              color: "rgb(188, 188, 188)",
              fontSize: "14px",
            }}
          >
            {t("There are no courses.")}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default CoursesTab;
