import {
  Box,
  Breadcrumbs,
  createStyles,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  deleteClassroom,
  getClassromByID,
  getClassroomsOfUser,
  updateClassroom,
} from "../../../api/Classrooms";
import {
  getOrganisations,
  getOrganizationByID,
} from "../../../api/Organisations";
import { getSchoolYears } from "../../../api/SchoolYear";
import { downloadFileFromStorage } from "../../../api/Storage";
import BackButtonCircle from "../../../components/BackButtonCircle";
import AppLayout from "../../../components/layout/AppLayout";
import SubmitBottomBar from "../../../components/SubmitBottomBar";
import MTKTabs from "../../../components/Tabs";
import { withLanguage } from "../../../lib/i18";
import {
  getJewishSchoolYear,
  isCreatorOfClassroom as isCreatorOfClassroomF,
  isDirectorOfOrganisation,
  isTeacherOfClassroom as isTeacherOfClassroomF,
} from "../../../utils/utils";
import useClassroomForm from "../NewClassroom/useClassroomForm";
import AboutTab from "./components/AboutTab";
import { ReactComponent as KeyIcon } from "../../../assets/images/icons/key.svg";
import MTKBreadcrumbs from "../../../components/Breadcrumbs";
import TeachersTab from "./components/TeachersTab";
import { getTeachers } from "../../../api/Users";
import { getCoursesOfClasssroom } from "../../../api/Courses";
import { useModals } from "@mantine/modals";
import CoursesTab from "./components/CoursesTab";
import LoadingScreen from "../../../components/layout/LoadingScreen";
import ReportsTab from "./components/ReportsTab";
import useIsSuperAdmin from "../../../hooks/useIsSuperAdmin";
import { useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    position: "sticky",
    top: 0,
    padding: "50px 0",
    zIndex: 2,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "50px",
    [`@media screen and (max-width: 900px)`]: {
      marginBottom: "0px",
    },
  },
  headerText: {
    marginBottom: "30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
}));

function ClassroomDetails({ type: pageTab }) {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { classroomId } = useParams();
  const currentLang = i18n.language;
  const [pageContent, setPageContent] = useState(null);
  const [data, setData] = useState(null);
  const [orgData, setOrgData] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const modals = useModals();
  const { form, onFormSubmit, loading, setDefaultData } = useClassroomForm({
    update: true,
  });
  const { name, schoolYearId, password, organisationId } = form.values;

  const [schoolYears, setSchoolYears] = useState(null);
  const [organisations, setOrganisations] = useState(null);

  const { profile } = useContext(GlobalContext);
  useEffect(() => {
    if (organisationId) {
      getOrganizationByID(organisationId).then((data) => {
        setOrgData(data);
      });
    }
  }, [organisationId]);

  useEffect(() => {
    if (classroomId) {
      getClassromByID(classroomId).then(async (data) => {
        let teachers = [];

        if (data.teachers) {
          const ids = Object.keys(data.teachers);
          if (ids.length > 0) {
            teachers = await getTeachers();
            teachers = teachers.filter((d) => ids.includes(d.id));
          }
        }
        const courses = await getCoursesOfClasssroom(classroomId);
        setData({ ...data, teachers, courses });
      });
    }
  }, [classroomId]);

  useEffect(() => {
    const getData = async () => {
      const [schoolYears, organisations] = await Promise.all([
        getSchoolYears(),
        getOrganisations(),
      ]);

      setSchoolYears(schoolYears);
      setOrganisations(organisations);
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      let audioFile = null;
      let coverImage = null;
      let ageLimitTo = null;
      let ageLimitFrom = null;

      if (data?.ageLimit) {
        ageLimitFrom = data?.ageLimit?.from ? `${data?.ageLimit?.from}` : null;
        ageLimitTo = data?.ageLimit?.to ? `${data?.ageLimit?.to}` : null;
      }

      if (data?.audioFile && data?.audioFile?.id) {
        const file = await downloadFileFromStorage({
          path: `Audio/${data.audioFile.id}/audio`,
          name: data.audioFile?.name || "audioFile",
          type: data.audioFile?.type || "audio/mpeg",
        });
        audioFile = file;
      }

      if (data?.coverImage && data?.coverImage?.id) {
        const file = await downloadFileFromStorage({
          path: `Images/${data.coverImage.id}/image`,
          name: data.coverImage?.name || "coverImage",
          type: data.coverImage?.type || "coverImage",
        });
        coverImage = file;
      }

      setDefaultData(
        {
          ...data,
          coverImage,
          audioFile,
          ageLimitFrom,
          ageLimitTo,
        },
        { ...data, id: classroomId }
      );
      setPageLoading(false);
    };
    if (data && pageLoading) {
      getData();
    }
  }, [data, pageLoading]);

  const onRemoveTeacher = (id) => {
    modals.openContextModal("confirmation", {
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Remove teacher"),
        modalBody: t("Are you sure you want to remove the teacher?"),
        onCancel: () => console.log("Cancel"),
        onConfirm: async () => {
          const { teachers } = data;
          const teachersObj = {};
          const filtredTeachers = teachers.filter((d) => d.id !== id);

          filtredTeachers.forEach((d) => {
            teachersObj[d.id] = "teacher";
          });
          setData((d) => ({ ...d, teachers: filtredTeachers }));
          await updateClassroom(classroomId, { teachers: teachersObj });
        },
      },
    });
  };
  const addTeacherCallback = (teacher) => {
    if (!teacher) return;
    const { teachers } = data;
    const teachersObj = {};

    [...teachers, teacher].forEach((d) => {
      teachersObj[d.id] = "teacher";
    });
    setData((d) => ({ ...d, teachers: [...teachers, teacher] }));
    updateClassroom(classroomId, { teachers: teachersObj });
  };

  const [activeTab, setActiveTab] = useState(
    ["about", "teachers", "courses", "reports"].indexOf(pageTab)
  );
  const [activeType, setActiveType] = useState(pageTab);

  const onChange = (active, tabKey) => {
    setActiveTab(active);
    setActiveType(tabKey);
    history.push(withLanguage(`/classrooms/${classroomId}/${tabKey}`));
  };

  useEffect(() => {
    // console.log(type, activeType);
    if (pageTab !== activeType) {
      setActiveType(pageTab);
    }
  }, [pageTab, activeType]);

  const onDeleteClassroom = async () => {
    // setLoading
    await deleteClassroom(classroomId);
    history.push(withLanguage("/classrooms"));
  };
  const currentSchoolYear = schoolYears?.find(
    (d) => d.id === schoolYearId
  )?.key;

  // if (!data) return <LoadingScreen />;
  const { teachers, courses, coursesOrder } = data || {};

  const getPageTitle = () => {
    return name || t("Classroom");
  };

  // Variables

  const isAdmin = useIsSuperAdmin();
  const isDirector = isDirectorOfOrganisation(profile, orgData);
  const isCreatorOfClassroom = isCreatorOfClassroomF(profile, data);
  const isTeacherOfClassroom = isTeacherOfClassroomF(profile, data);

  // const isTeacher = isTeacherF(profile);

  // Render
  const saveCoursesOrder = async (games) => {
    const coursesIds = games.map((c) => c.id);

    const data = {
      coursesOrder: coursesIds,
    };

    try {
      await updateClassroom(classroomId, data);
      setData((d) => ({ ...d, coursesOrder: coursesIds }));
    } catch (error) {
      console.log(error);
    }
  };

  if (
    profile &&
    !isAdmin &&
    !isDirector &&
    !isCreatorOfClassroom &&
    !isTeacherOfClassroom
  ) {
    return null;
  }

  return (
    <AppLayout loading={!data || !name} title={getPageTitle()}>
      <Box className={classes.header}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          mb="40px"
        >
          <Box
            mb="md"
            sx={{
              minWidth: "300px",
              maxWidth: "100%",
              overflowX: "hidden",
            }}
          >
            <MTKBreadcrumbs
              data={[
                {
                  title: orgData?.name,
                  url:
                    isAdmin || isDirector
                      ? `/organisations/${organisationId}`
                      : "",
                },
              ]}
            />
            <Box
              mb="md"
              sx={{
                display: "flex",
              }}
            >
              <BackButtonCircle
                mr="md"
                onClick={() => history.push(withLanguage("/classrooms"))}
              />
              <Title order={3}>{name}</Title>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                color: "rgb(1, 158, 211)",
                display: "flex",
                borderRadius: "8px",
                marginRight: "15px",
                fontSize: "12px",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid rgb(1, 158, 211)",
                background: "rgb(255, 255, 255)",
                maxHeight: "30px",
              }}
            >
              <Box
                sx={{
                  background: "rgb(1, 158, 211)",
                  padding: "5px 8px",
                  borderTopLeftRadius: "5px",
                  borderBottomLeftRadius: "5px",
                }}
              >
                <KeyIcon />
              </Box>
              <Text size="12px" px="sm" weight={"bold"}>
                {password}
              </Text>
            </Box>
            <Box
              sx={{
                background: "rgb(254, 215, 0)",
                color: "rgb(255, 255, 255)",
                fontSize: "11px",
                padding: "5px",
                display: "block",
                borderRadius: "8px",
              }}
            >
              {t("School Year")}
              {getJewishSchoolYear(currentSchoolYear)}
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            overflowX: "auto",
          }}
        >
          <MTKTabs active={activeTab} onTabChange={onChange}>
            <Tabs.Tab label={t("Settings")} tabKey={"about"}></Tabs.Tab>
            <Tabs.Tab label={t("Teachers")} tabKey={"teachers"}></Tabs.Tab>
            <Tabs.Tab label={t("Courses")} tabKey={"courses"}></Tabs.Tab>
            <Tabs.Tab label={t("Reports")} tabKey={"reports"}></Tabs.Tab>
          </MTKTabs>
        </Box>
      </Box>

      {activeType === "about" && (
        <AboutTab
          onDeleteClassroom={onDeleteClassroom}
          form={form}
          organisations={organisations}
          schoolYears={schoolYears}
        />
      )}
      {activeType === "teachers" && (
        <TeachersTab
          courses={courses}
          teachers={teachers}
          onRemoveTeacher={onRemoveTeacher}
          addTeacherCallback={addTeacherCallback}
        />
      )}
      {activeType === "courses" && (
        <CoursesTab
          courses={courses}
          teachers={teachers}
          coursesOrder={coursesOrder}
          classroomId={classroomId}
          organisationId={organisationId}
          saveCoursesOrder={saveCoursesOrder}
        />
      )}
      {activeType === "reports" && (
        <ReportsTab
          courses={courses}
          teachers={teachers}
          coursesOrder={coursesOrder}
          classroomId={classroomId}
          classroom={data}
        />
      )}

      {activeType === "about" && (
        <SubmitBottomBar
          okText={t("Save")}
          onCreate={form.onSubmit(onFormSubmit)}
          onCancel={() => history.push(withLanguage("/classrooms/"))}
          loading={loading || !data}
        />
      )}
    </AppLayout>
  );
}

export default ClassroomDetails;
