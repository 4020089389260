import {
  getDocs,
  getDoc,
  collection,
  query,
  where,
  setDoc,
  deleteDoc,
  addDoc,
  doc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { updateCourse } from "./Courses";
import { duplicateQuestions, getQuestionsOfGame } from "./Questions";

export const getGamesOfCourse = async (courseId) => {
  const gamesRef = collection(db, "Games");
  const q = query(gamesRef, where("courseId", "==", courseId));
  const querySnapshot = await getDocs(q);

  const res = querySnapshot;
  const gamesList = res.docs
    .filter((doc) => {
      return doc.exists();
    })
    .map((doc) => ({
      ...doc.data(),
      id: doc.id,
    }));
  return gamesList;
};
// /Courses/6YQK1VEyy4jc3Kp1iIfC/OtherGames/20r0glvveFNZB0lub5Iy
export const getOtherGamesOfCourse = async (courseId) => {
  const otherGamesRef = collection(db, "Courses", courseId, "OtherGames");
  const querySnapshot = await getDocs(otherGamesRef);

  const res = querySnapshot;
  const gamesList = res.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  return gamesList;
};
export const createGame = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "Games"), data);
    await updateCourse(data.courseId, {
      gamesOrder: arrayUnion(docRef.id),
    });

    return docRef;
  } catch (err) {
    console.log("create Course", err);
  }
};
export const createCourseOtherGame = async (courseId, data) => {
  try {
    const otherGamesRef = collection(db, "Courses", courseId, "OtherGames");

    const docRef = await addDoc(otherGamesRef, data);
    return docRef;
  } catch (err) {
    console.log("create Course", err);
  }
};
export const updateCourseOtherGame = async (courseId, gameId, data) => {
  try {
    const otherGamesRef = doc(db, "Courses", courseId, "OtherGames", gameId);
    await updateDoc(otherGamesRef, data);

    return gameId;
  } catch (err) {
    console.log("create Course", err);
  }
};
export const deleteCourseOtherGame = async (courseId, gameId) => {
  try {
    const otherGamesRef = doc(db, "Courses", courseId, "OtherGames", gameId);

    await deleteDoc(otherGamesRef);
    return gameId;
  } catch (err) {
    console.log("create Course", err);
  }
};
export const updateGame = async (id, data) => {
  try {
    await updateDoc(doc(collection(db, "Games"), id), data);
    return { id };
  } catch (err) {
    console.log("updateGames", err);
  }
};
export const deleteGame = async (id, courseId) => {
  try {
    await deleteDoc(doc(collection(db, "Games"), id));
    if (courseId) {
      await updateCourse(courseId, {
        gamesOrder: arrayRemove(id),
      });
    }
    return { id };
  } catch (err) {
    console.log("deleteGamess", err);
  }
};
export const getGameByID = async (id) => {
  const res = await getDoc(doc(db, "Games", id));
  if (res.exists()) {
    return { id: res.id, ...res.data() };
  } else {
    return null;
  }
};

export const duplicateGame = async (gameId, distCourseId) => {
  let game = await getGameByID(gameId);

  if (game) {
    game.courseId = distCourseId;
    delete game.id;
    const newGame = await createGame({
      ...game,
      questionsOrder: [],
    });
    if (newGame?.id) {
      const originalQuestions = await getQuestionsOfGame(gameId);

      const originalQuestionsIds = originalQuestions.map((d) => d.id);

      originalQuestionsIds.sort((a, b) => {
        const indexA = game.questionsOrder.indexOf(a);
        const indexB = game.questionsOrder.indexOf(b);

        return indexA - indexB;
      });

      await duplicateQuestions(originalQuestionsIds, gameId, {
        newGameId: newGame.id,
      });

      return newGame.id;
    }
    return newGame.id;
  }
};

export const duplicateGames = async (gamesIds, distCourseId) => {
  await Promise.all(gamesIds.map((id) => duplicateGame(id, distCourseId)));
};
