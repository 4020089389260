import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import UrlPattern from "url-pattern";
import i18n from "../../lib/i18";
import { useLocation } from "react-router-dom";
import { Select } from "@mantine/core";

export default function LanguageSwitcher({
  width = "160px",
  size = "default",
  margin = "0px 20px 0px 0px",
}) {
  const { t } = useTranslation();
  const [langs, setLangs] = useState([]);
  const [currentLang, setCurrentLang] = useState(undefined);
  const history = useHistory();
  const location = useLocation();

  const onLangChange = (value) => {
    history.push(generateLanguage(value, location));
    i18n.changeLanguage(value);

    setCurrentLang(value);
  };

  useEffect(() => {
    const setLanguages = () => {
      const currentLang = i18n.language;

      const menuEN = {
        label: t("English"),
        value: "en",
        onClick: () => {
          history.push(generateLanguage("en", location));
          i18n.changeLanguage("en");
          setLanguages();
        },
      };

      const menuFR = {
        label: t("French"),
        value: "fr",
        onClick: () => {
          history.push(generateLanguage("fr", location));
          i18n.changeLanguage("fr");
          setLanguages();
        },
      };

      const menuHE = {
        label: t("Hebrew"),
        value: "he",
        onClick: () => {
          history.push(generateLanguage("he", location));
          i18n.changeLanguage("he");
          setLanguages();
        },
      };

      let items = [];
      if (currentLang === "en") {
        items = [menuEN, menuFR, menuHE];
        // setCurrentLang(menuEN .value);
      } else if (currentLang === "fr") {
        items = [menuFR, menuEN, menuHE];
        // setCurrentLang(menuFR.value);
      } else if (currentLang === "he") {
        items = [menuEN, menuFR, menuHE];
        // setCurrentLang(menuHE.value);
      }
      setCurrentLang(currentLang);
      document.dir = i18n.dir();

      setLangs(items);
    };
    setLanguages();
  }, [history, location, t]);

  const generateLanguage = (locale, location) => {
    const pattern = new UrlPattern("/:locale(/*)");
    const match = pattern.match(location.pathname);

    let path = `/${locale}`;
    if (match && match["_"]) {
      path = `${path}/${match["_"]}`;
    }

    return path;
  };

  return (
    <Select
      value={currentLang}
      onChange={onLangChange}
      data={langs}
      size="md"
      radius={24}
      style={{
        borderRadius: "24px",
      }}
      sx={{
        // height: "64px",
        width,
        margin,
      }}
    />
  );
  //   return (
  //     <Dropdown
  //       size={size}
  //       width={width}
  //       margin={margin}
  //       mainItem={currentLang}
  //       items={langs}
  //     />
  //   );
}
