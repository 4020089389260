export const organisationTypes = {
  home: {
    en: "Home",
    fr: "Maison",
    he: "Maison",
  },
  school: {
    en: "School",
    fr: "École",
    he: "École",
  },
  youthMovement: {
    en: "Youth Movement",
    fr: "Mouvement de jeunesse",
    he: "Mouvement de jeunesse",
  },
  synagogue: {
    en: "Synagogue",
    fr: "Synagogue",
    he: "Synagogue",
  },
};

export const organisationSubTypes = {
  home: {
    "": {
      en: "Home",
      fr: "Maison",
      he: "Maison",
    },
  },
  school: {
    "": {
      en: "",
      fr: "",
      he: "",
    },
    schoolSubType1: {
      en: "School Sub Type 1 (EN)",
      fr: "School Sub Type 1 (FR)",
      he: "School Sub Type 1 (HE)",
    },
    kindergarten: {
      en: "Kindergarten (EN)",
      fr: "Kindergarten (FR)",
      he: "Kindergarten (HE)",
    },
  },
  youthMovement: {
    "": {
      en: "",
      fr: "",
      he: "",
    },
    youthMovementSubType1: {
      en: "Youth Movement Sub Type 1 (EN)",
      fr: "Youth Movement Sub Type 1 (FR)",
      he: "Youth Movement Sub Type 1 (HE)",
    },
  },
  synagogue: {
    "": {
      en: "",
      fr: "",
      he: "",
    },
    synagogueSubType1: {
      en: "Synagogue Sub Type 1 (EN)",
      fr: "Synagogue Sub Type 1 (FR)",
      he: "Synagogue Sub Type 1 (HE)",
    },
  },
};
