import { Box, createStyles, Title } from "@mantine/core";
import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import BackButtonCircle from "../../../components/BackButtonCircle";
import AppLayout from "../../../components/layout/AppLayout";
import {
  ageDropdownList,
  generateRandomPassword,
  getJewishSchoolYear,
  getQueryParams,
} from "../../../utils/utils";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";
import contentful from "../../../lib/contentful";
import { capitalize } from "../../../utils/utils";
import { useTranslation } from "react-i18next";
import { withLanguage } from "../../../lib/i18";
import FormSection from "../../../components/layout/FormSection";
import MediaUploader from "../../../components/MediaUploader";
import MTKInput from "../../../components/Input";
import AudioUploader from "../../../components/AudioUploader";
import MTKSelect from "../../../components/Select";
import MTKCountryInput from "../../../components/CountryInput";
import MTKTextarea from "../../../components/Textarea";
import MTKButton from "../../../components/Button";
import useIsSuperAdmin from "../../../hooks/useIsSuperAdmin";
import useClassroomForm from "./useClassroomForm";
import { getSchoolYears } from "../../../api/SchoolYear";
import { getOrganisations } from "../../../api/Organisations";
import GlobalContext from "../../../context/GlobalContext";
import { organisationTypes } from "../../../constants/types";
import SubmitBottomBar from "../../../components/SubmitBottomBar";

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",

    position: "sticky",
    top: 0,
    padding: "50px 0",
    zIndex: 2,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "50px",
  },
}));
function NewClassroom() {
  const location = useLocation();
  const queryParams = getQueryParams(location);
  const history = useHistory();
  const { profile } = useContext(GlobalContext);
  const { prefillId, back } = queryParams;
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const [pageContent, setPageContent] = useState(null);
  const isAdmin = useIsSuperAdmin();
  const { form, onFormSubmit, loading } = useClassroomForm();
  const [schoolYears, setSchoolYears] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [orgTypes, setOrgTypes] = useState([]);
  const [selectedOrgType, setSelectedOrgType] = useState(null);

  const getOrganisationTypeOptions = () => {
    const currentLang = i18n.language;
    const orgTypes = { ...organisationTypes };
    if (profile?.type === "teacher") {
      delete orgTypes.home;
    }

    const options = Object.keys(orgTypes).map((key) => ({
      value: key,
      label: orgTypes[key][currentLang],
    }));

    return options;
  };

  useEffect(() => {
    if (organisations) {
      const orgTypes = getOrganisationTypeOptions(profile);

      setOrgTypes(orgTypes);
    }
  }, [organisations]);

  useEffect(() => {
    console.log({ prefillId });
    if (prefillId && organisations && orgTypes) {
      const prefilledOrg = organisations.find((d) => d.id === prefillId);
      if (prefilledOrg) {
        const orgType = orgTypes.find(
          (o) => o.value === prefilledOrg.type
        ).value;

        setSelectedOrgType(orgType);
        form.setFieldValue("organisationId", prefilledOrg.id);
      }
    }
  }, [prefillId, organisations, orgTypes]);

  useEffect(() => {
    const getData = async () => {
      const [schoolYears, organisations] = await Promise.all([
        getSchoolYears(),
        getOrganisations(),
      ]);

      setSchoolYears(schoolYears);
      setOrganisations(organisations);
    };
    getData();
  }, []);
  // Init
  //contenful
  useEffect(() => {
    // dispatch(setHasUnsavedChanges(false));
    contentful("classroomsItemsDescription").then((data) =>
      setPageContent(data.items[0])
    );
  }, []);

  const stringToHtml = (text) => {
    return ReactHtmlParser(documentToHtmlString(text));
  };
  // if (!schoolYears || !organisations || !orgTypes) return "loading ...";

  const { ageLimitFrom, ageLimitTo, name } = form.values;
  return (
    <AppLayout
      loading={!schoolYears || !organisations || !orgTypes}
      title={name}
    >
      <Box className={classes.header}>
        <BackButtonCircle
          mr="md"
          to={back ? withLanguage(back) : withLanguage("/classrooms")}
        />
        <Title order={3}>{t("New Classroom")}</Title>
      </Box>
      <FormSection
        title={
          pageContent?.fields[
            `generalDescTitle${capitalize(withLanguage("").substring(1))}`
          ]
        }
        description={stringToHtml(
          pageContent?.fields[
            `generalDescDescription${capitalize(withLanguage("").substring(1))}`
          ]
        )}
      >
        <MediaUploader
          ratio={1}
          {...form.getInputProps("coverImage")}
          recommendedAspectRatio="1/1 (512*512)"
        />
        <MTKInput
          label={`${t("Name")}`}
          required
          labelColor="blue"
          {...form.getInputProps("name")}
        />
        <AudioUploader {...form.getInputProps("audioFile")} />
        <MTKSelect
          data={orgTypes}
          label={t("Organisation Type")}
          required
          value={selectedOrgType}
          onChange={(v) => {
            setSelectedOrgType(v);
            form.setFieldValue("organisationId", "");
          }}
          // {...form.getInputProps("type")}
        />
        <MTKSelect
          label={`${t("Organisation")}`}
          required
          data={organisations
            .filter((d) => d.type === selectedOrgType)
            .map((d) => ({ value: d.id, label: d.name }))}
          // data={orgSubType}
          {...form.getInputProps("organisationId")}
        />
        <MTKCountryInput
          {...form.getInputProps("country")}
          label={t("Country")}
          labelColor="blue"
        />
        <MTKSelect
          label={t("School Year")}
          required
          data={schoolYears.map((d) => ({
            value: d.id,
            label: getJewishSchoolYear(d.key),
          }))}
          {...form.getInputProps("schoolYearId")}
        />
        <MTKTextarea
          label={t("Description")}
          minRows={4}
          {...form.getInputProps("description")}
        />
        <MTKSelect
          data={ageDropdownList.map((d) => ({
            label: d || "",
            value: `${d || ""}`,
          }))}
          onChange={(value) => {
            if (!ageLimitTo) {
              form.setFieldValue("ageLimitTo", "12");
            }
            form.setFieldValue("ageLimitFrom", value);
          }}
          value={`${ageLimitFrom}`}
          label={t("Age From")}
        />
        <MTKSelect
          data={ageDropdownList
            .filter((d) => (ageLimitFrom && ageLimitFrom > d ? false : true))
            .map((d) => ({ value: `${d || ""}`, label: d || "" }))}
          label={t("Age To")}
          {...form.getInputProps("ageLimitTo")}
          value={`${ageLimitTo}`}
        />
      </FormSection>

      <FormSection
        mt="40px"
        title={
          pageContent?.fields[
            `codeTitle${capitalize(withLanguage("").substring(1))}`
          ]
        }
        description={stringToHtml(
          pageContent?.fields[
            `codeDescription${capitalize(withLanguage("").substring(1))}`
          ]
        )}
      >
        <MTKInput
          label={`${t("Access Code")}`}
          required
          labelColor="blue"
          {...form.getInputProps("password")}
        />
        {isAdmin && (
          <MTKButton
            onClick={() =>
              form.setFieldValue("password", generateRandomPassword(6))
            }
            color="blue"
          >
            {t("Auto Generate")}
          </MTKButton>
        )}
      </FormSection>
      <SubmitBottomBar
        onCreate={form.onSubmit(onFormSubmit)}
        onCancel={() => history.push(withLanguage("/classrooms/"))}
        loading={loading}
      />
    </AppLayout>
  );
}

export default NewClassroom;
