import { TextInput, InputWrapper, Input } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

function MTKInput({
  label = "",
  type = "text",
  value = "",
  labelColor = "",
  onChange = () => null,
  ...props
}) {
  const { t } = useTranslation();
  return (
    <InputWrapper
      my="md"
      label={label}
      styles={(theme) => ({
        label: {
          color: labelColor === "blue" ? theme.colors.blue : "#00000055",
        },
      })}
      placeholder={t("Type Here...")}
      {...props}
    >
      <Input
        value={value}
        onChange={onChange}
        type={type}
        size="md"
        styles={(theme) => ({
          input: {
            color: theme.colors.blue,
            background: "transparent",
          },
          defaultVariant: {
            borderColor: `${theme.colors.blue}`,
          },
        })}
        placeholder={t("Type Here...")}
        {...props}
      />
    </InputWrapper>
  );
}

export default MTKInput;
