import React from "react";
import { useTranslation } from "react-i18next";
import MTKInput from "../../../../components/Input";
import FormSection from "../../../../components/layout/FormSection";
import MTKSwitch from "../../../../components/Switch";
import { withLanguage } from "../../../../lib/i18";
import { capitalize } from "../../../../utils/utils";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";

const stringToHtml = (text) => {
  return ReactHtmlParser(documentToHtmlString(text));
};

const Open = ({ form, questionMeta }) => {
  const { t } = useTranslation();

  const { answers } = form.values;

  const answer = answers[0] || { answer: "", correctAutomatically: false };

  return (
    <FormSection
      color="green"
      layout="row"
      margin="0px 0px 40px 0px"
      title={
        questionMeta?.fields[
          `openQuestionConfigurationTitle${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      }
      description={stringToHtml(
        questionMeta?.fields[
          `${"open"}QuestionConfigurationDescription${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      )}
    >
      <MTKSwitch
        checked={answer?.correctAutomatically}
        color="green"
        label={t("Correct correction automatically")}
        onChange={(e) =>
          form.setFieldValue("answers", [
            { ...answer, correctAutomatically: e.target.checked },
          ])
        }
      />

      <MTKInput
        title={`${t("Correct Answer")}`}
        {...form.getInputProps("answers.answer")}
        value={answer.answer}
        onChange={(e) => {
          form.setFieldValue("answers", [
            { ...answer, answer: e.target.value },
          ]);
        }}
        disabled={!answer.correctAutomatically}
      />
    </FormSection>
  );
};

export default Open;
