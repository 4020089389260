import {
  getDocs,
  getDoc,
  collection,
  query,
  doc,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../lib/firebase";

//todo : add validators

export const getTeachers = async () => {
  try {
    //   const res = await getDocs(collection(db, "Organisations"));
    const usersRef = collection(db, "Users");
    const q = query(usersRef, where("type", "==", "teacher"));

    const querySnapshot = await getDocs(q);
    const teachers = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return teachers;
  } catch (error) {
    console.log("getTeachers", error);
  }
};

export const getStudents = async () => {
  try {
    //   const res = await getDocs(collection(db, "Organisations"));
    const usersRef = collection(db, "Users");
    const q = query(usersRef, where("type", "==", "student"));

    const querySnapshot = await getDocs(q);
    const students = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return students;
  } catch (error) {
    console.log("getStudents", error);
  }
};
export const createStudent = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "Users"), data);
    return docRef;
  } catch (err) {
    console.log("create student", err);
  }
};
export const updateStudent = async (id, data) => {
  try {
    const docRef = await updateDoc(doc(db, "Users", id), data);
    return docRef;
  } catch (err) {
    console.log("update student", err);
  }
};
export const getUserById = async (id) => {
  try {
    const res = await getDoc(doc(db, "Users", id));
    if (res.exists) {
      return { id: res.id, ...res.data() };
    } else {
      return null;
    }
  } catch (error) {
    console.log("getTeachers", error);
  }
};
