import React, { Suspense, useEffect } from "react";
import { Button, Global, MantineProvider } from "@mantine/core";
import { useState } from "react";
import rtlPlugin from "stylis-plugin-rtl";
import i18 from "./lib/i18";
import Routes from "./routes";
import MTKTheme from "./styles/theme";
import { ModalsProvider } from "@mantine/modals";

import BH from "./components/BH";
import GlobalContext from "./context/GlobalContext";

// fonts
import lapsusPro from "./assets/fonts/LapsusPro/LapsusPro-Bold.otf";
import robotoReg from "./assets/fonts/Roboto/Roboto-Regular.ttf";
import robotoMed from "./assets/fonts/Roboto/Roboto-Medium.ttf";
import robotoBold from "./assets/fonts/Roboto/Roboto-Bold.ttf";

import ErrorModal from "./components/modals/ErrorModal";
import SuccessModal from "./components/modals/SuccessModal";
import { auth } from "./lib/firebase";
import { getProfile } from "./api/Auth";
import ConfirmationModal from "./components/modals/ConfirmationModal";
import LoadingScreen from "./components/layout/LoadingScreen";
import { HelmetProvider } from "react-helmet-async";
import QuestionDuplicationModal from "./components/modals/QuestionDuplicationModal";
import GameDuplicationModal from "./components/modals/GameDuplicationModal";
import CourseDuplicationModal from "./components/modals/CourseDuplicationModal";
import EditOfflineGameModal from "./components/modals/EditOfflineGameModal";
import NewStudentModal from "./components/modals/NewStudentModal";
import DeleteStudentsModal from "./components/modals/DeleteStudentsModal";
import OrderModal from "./components/modals/OrderDNDModal";
import EditStudentModal from "./components/modals/EditStudentModal";

function App() {
  const [rtl, setRtl] = useState(false);
  const [state, setState] = useState({
    user: null,
  });
  useEffect(() => {
    i18.on("languageChanged", function (lng) {
      setRtl(i18.dir() === "rtl");
    });
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const profile = await getProfile(user.uid);
        if (profile) {
          setState({ profile, user, isLoaded: true });
        } else {
          setState({ user, profile: null, isLoaded: true });
        }
      } else {
        setState({ user: null, profile: null, isLoaded: true });
      }
    });
  }, []);

  return (
    <GlobalContext.Provider value={state}>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{ ...MTKTheme, dir: rtl ? "rtl" : "ltr" }}
        emotionOptions={
          rtl
            ? // rtl cache
              { key: "mantine-rtl", stylisPlugins: [rtlPlugin] }
            : // ltr cache
              { key: "mantine" }
        }
      >
        <ModalsProvider
          modals={{
            error: ErrorModal,
            success: SuccessModal,
            confirmation: ConfirmationModal,
            questionDuplication: QuestionDuplicationModal,
            gameDuplication: GameDuplicationModal,
            courseDuplication: CourseDuplicationModal,
            editOfflineGame: EditOfflineGameModal,
            newClassroomStudent: NewStudentModal,
            editStudent: EditStudentModal,
            deleteClassroomStudent: DeleteStudentsModal,
            order: OrderModal,
          }}
        >
          <div dir={rtl ? "rtl" : "ltr"}>
            <BH />
            <Global
              styles={[
                {
                  "@font-face": {
                    fontFamily: "LapsusPro",
                    src: `url('${lapsusPro}')`,
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontDisplay: "swap",
                  },
                },
                {
                  "@font-face": {
                    fontFamily: "Roboto",
                    src: `url('${robotoReg}') `,
                    fontWeight: 400,
                    fontStyle: "normal",
                  },
                },
                {
                  "@font-face": {
                    fontFamily: "Roboto",
                    src: `url('${robotoMed}') `,
                    fontWeight: 500,
                    fontStyle: "normal",
                  },
                },
                {
                  "@font-face": {
                    fontFamily: "Roboto",
                    src: `url('${robotoBold}') `,
                    fontWeight: 700,
                    fontStyle: "normal",
                  },
                },
                {
                  body: {
                    margin: 0,
                    fontFamily: `'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
                'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                    sans-serif`,
                  },
                },
              ]}
            />
            <Suspense fallback={<LoadingScreen />}>
              <HelmetProvider>
                <Routes />
              </HelmetProvider>
            </Suspense>
          </div>
        </ModalsProvider>
      </MantineProvider>
    </GlobalContext.Provider>
  );
}

export default App;
