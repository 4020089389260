import { TextInput, InputWrapper, Input } from "@mantine/core";
import React from "react";
import { DatePicker } from "@mantine/dates";
function MTKDateInput({
  label = "",
  type = "text",
  value = "",
  onChange = () => null,
  ...props
}) {
  return (
    <DatePicker
      my="md"
      label={label}
      placeholder="Type Here..."
      value={value}
      onChange={onChange}
      styles={(theme) => ({
        label: {
          color: "#00000055",
          fontSize: "14px",
        },
        input: {
          color: theme.colors.blue,
        },
        defaultVariant: {
          borderColor: `${theme.colors.blue}`,
        },
      })}
      size="md"
      {...props}
    >
      {/* <Input
        value={value}
        onChange={onChange}
        type={type}
        size="md"
        styles={(theme) => ({
          input: {
            color: theme.colors.blue,
          },
          defaultVariant: {
            borderColor: `${theme.colors.blue}`,
          },
        })}
        placeholder="Type Here..."
        {...props}
      /> */}
    </DatePicker>
  );
}

export default MTKDateInput;
