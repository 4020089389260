import React from "react";
import { ContextModalProps, ModalsProvider } from "@mantine/modals";
import { Text, Button, Image, Box, Center, Title } from "@mantine/core";

import errorSignImage from "../../assets/images/icons/error-sign.png";
import { useTranslation } from "react-i18next";
import MTKButton from "../Button";

function ErrorModal({ context, id, innerProps }) {
  const { t } = useTranslation();

  return (
    <Box>
      <Center>
        <Image src={errorSignImage} my="md" width={84} />
      </Center>
      <Title order={3} my="md" align="center">
        {innerProps.title || t("Error")}
      </Title>
      <Text size="sm" align="center" my="md">
        {innerProps.modalBody}
      </Text>
      <Center>
        <MTKButton
          width="140px"
          // fullWidth
          color="blue"
          mt="md"
          onClick={() => context.closeModal(id)}
        >
          {t("OK")}
        </MTKButton>
      </Center>
    </Box>
  );
}

export default ErrorModal;
