import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { getOrganisations } from "../../../api/Organisations";
import { getSchoolYears } from "../../../api/SchoolYear";
import GlobalContext from "../../../context/GlobalContext";
import useIsSuperAdmin from "../../../hooks/useIsSuperAdmin";
import {
  ageDropdownList,
  capitalize,
  getJewishSchoolYear,
  getQueryParams,
  languageOptions,
} from "../../../utils/utils";
import contentful from "../../../lib/contentful";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";
import { Box, createStyles, Title } from "@mantine/core";
import AppLayout from "../../../components/layout/AppLayout";
import SubmitBottomBar from "../../../components/SubmitBottomBar";
import MTKSwitch from "../../../components/Switch";
import useCourseForm from "./useCourseForm";
import { organisationTypes } from "../../../constants/types";
import { withLanguage } from "../../../lib/i18";
import FormSection from "../../../components/layout/FormSection";
import MediaUploader from "../../../components/MediaUploader";
import MTKInput from "../../../components/Input";
import AudioUploader from "../../../components/AudioUploader";
import MTKSelect from "../../../components/Select";
import MTKTextarea from "../../../components/Textarea";
import BackButtonCircle from "../../../components/BackButtonCircle";
import {
  getClassrooms,
  getClassroomsOfOrganisation,
} from "../../../api/Classrooms";

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",

    position: "sticky",
    top: 0,
    padding: "50px 0",
    zIndex: 2,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "50px",
  },
}));
function NewCourse() {
  const location = useLocation();
  const queryParams = getQueryParams(location);
  const history = useHistory();
  const { profile } = useContext(GlobalContext);
  const { prefillId, back } = queryParams;
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const [pageContent, setPageContent] = useState(null);
  const isAdmin = useIsSuperAdmin();
  const { form, onFormSubmit, loading } = useCourseForm();
  const [schoolYears, setSchoolYears] = useState([]);
  const [organisations, setOrganisations] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [orgTypes, setOrgTypes] = useState([]);
  const [selectedOrgType, setSelectedOrgType] = useState(null);

  const getOrganisationTypeOptions = () => {
    const currentLang = i18n.language;
    const orgTypes = { ...organisationTypes };
    if (profile?.type === "teacher") {
      delete orgTypes.home;
    }

    const options = Object.keys(orgTypes).map((key) => ({
      value: key,
      label: orgTypes[key][currentLang],
    }));

    return options;
  };
  useEffect(() => {
    if (organisations) {
      const orgTypes = getOrganisationTypeOptions(profile);

      setOrgTypes(
        orgTypes.filter((d) => organisations.find((o) => o.type === d.value))
      );
    }
  }, [organisations]);

  useEffect(() => {
    if (prefillId && organisations?.length > 0 && orgTypes?.length > 0) {
      const prefilledOrg = organisations.find((d) =>
        d.classrooms.find((classroom) => prefillId === classroom.id)
      );
      if (prefilledOrg) {
        const orgType = orgTypes?.find(
          (o) => o?.value === prefilledOrg?.type
        ).value;

        setSelectedOrgType(orgType);
        form.setFieldValue("organisationId", prefilledOrg.id);
        form.setFieldValue("classroomId", prefillId);
      }
    }
  }, [prefillId, organisations, orgTypes]);

  useEffect(() => {
    const getData = async () => {
      const [schoolYears, organisations] = await Promise.all([
        getSchoolYears(),
        getOrganisations(),
        getClassrooms(),
      ]);

      const organisationsWithClassrooms = (
        await Promise.all(
          organisations.map(async (d) => {
            const classrooms = await getClassroomsOfOrganisation(d.id);

            if (classrooms && classrooms?.length > 0) {
              return { ...d, classrooms };
            } else {
              return false;
            }
          })
        )
      ).filter((d) => d);
      const filteredYears = schoolYears.filter((year) =>
        organisationsWithClassrooms.find((o) =>
          o.classrooms.some((classroom) => classroom.schoolYearId === year.id)
        )
      );
      setSchoolYears(filteredYears);
      setOrganisations(organisationsWithClassrooms);
    };
    getData();
  }, []);

  // Init
  //contenful

  //contenful
  useEffect(() => {
    contentful("coursesItemsDescription").then((data) =>
      setPageContent(data.items[0])
    );
  }, []);

  const stringToHtml = (text) => {
    return ReactHtmlParser(documentToHtmlString(text));
  };
  const { ageLimitFrom, ageLimitTo, organisationId } = form.values;

  const classroomsData = useMemo(() => {
    if (organisationId) {
      const org = organisations.find((d) => d.id === organisationId);
      if (org && org?.classrooms && org?.classrooms?.length > 0) {
        if (prefillId) {
          form.setFieldValue("classroomId", prefillId);
        } else {
          form.setFieldValue("classroomId", org.classrooms[0].id);
        }
        return org.classrooms;
      } else {
        form.setFieldValue("classroomId", "");
        return [];
      }
    } else {
      form.setFieldValue("classroomId", "");
      return [];
    }
  }, [organisationId, prefillId]);
  // if (!schoolYears || !organisations || !orgTypes) return "loading ...";

  return (
    <AppLayout
      loading={!schoolYears || !organisations || !orgTypes}
      title={t("New Course")}
    >
      <Box className={classes.header}>
        <BackButtonCircle mr="md" to={withLanguage(back ? back : "/courses")} />
        <Title order={3}>{t("New Course")}</Title>
      </Box>
      <FormSection
        title={
          pageContent?.fields[
            `generalInformationTitle${capitalize(
              withLanguage("").substring(1)
            )}`
          ]
        }
        description={stringToHtml(
          pageContent?.fields[
            `generalInformationDescription${capitalize(
              withLanguage("").substring(1)
            )}`
          ]
        )}
      >
        <MediaUploader
          ratio={1}
          {...form.getInputProps("coverImage")}
          recommendedAspectRatio="1/1 (512*512)"
        />
        <MTKInput
          label={`${t("Name")}`}
          required
          labelColor="blue"
          {...form.getInputProps("name")}
        />
        <AudioUploader {...form.getInputProps("audioFile")} />
        <MTKSelect
          data={orgTypes}
          label={t("Organisation Type")}
          required
          value={selectedOrgType}
          onChange={(v) => setSelectedOrgType(v)}
          // {...form.getInputProps("type")}
        />
        <MTKSelect
          label={`${t("Organisation")}`}
          required
          data={organisations
            .filter((d) => d.type === selectedOrgType)
            .map((d) => ({ value: d.id, label: d.name }))}
          // data={orgSubType}
          {...form.getInputProps("organisationId")}
        />
        <MTKSelect
          label={`${t("Classroom")}`}
          required
          data={classroomsData.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          // data={orgSubType}
          {...form.getInputProps("classroomId")}
        />
        <MTKSelect
          label={t("School Year")}
          required
          data={schoolYears.map((d) => ({
            value: d.id,
            label: getJewishSchoolYear(d.key),
          }))}
          {...form.getInputProps("schoolYearId")}
        />
        <MTKSelect
          label={`${t("Language")}`}
          required
          data={languageOptions}
          // data={orgSubType}
          {...form.getInputProps("languageId")}
        />

        <MTKTextarea
          label={t("Description")}
          minRows={4}
          {...form.getInputProps("description")}
        />
        <MTKSelect
          data={ageDropdownList.map((d) => ({
            label: d || "",
            value: `${d || ""}`,
          }))}
          onChange={(value) => {
            if (!ageLimitTo) {
              form.setFieldValue("ageLimitTo", "12");
            }
            form.setFieldValue("ageLimitFrom", value);
          }}
          value={`${ageLimitFrom}`}
          label={t("Age From")}
        />
        <MTKSelect
          data={ageDropdownList
            .filter((d) => (ageLimitFrom && ageLimitFrom > d ? false : true))
            .map((d) => ({ value: `${d || ""}`, label: d || "" }))}
          label={t("Age To")}
          {...form.getInputProps("ageLimitTo")}
          value={`${ageLimitTo}`}
        />
      </FormSection>

      <FormSection
        mt="40px"
        mb="60px"
        title={
          pageContent?.fields[
            `availabilityTitle${capitalize(withLanguage("").substring(1))}`
          ]
        }
        description={stringToHtml(
          pageContent?.fields[
            `availabilityDescription${capitalize(
              withLanguage("").substring(1)
            )}`
          ]
        )}
      >
        <MTKSwitch
          label={`${t("Kids can see the course in the classrooom")}`}
          required
          labelColor="blue"
          size={"md"}
          {...form.getInputProps("kidsCanSeeCourseInClassroom", {
            type: "checkbox",
          })}
        />
        <MTKSwitch
          size={"md"}
          label={`${t("Show the course to other teachers in search results")}`}
          required
          labelColor="blue"
          {...form.getInputProps("showToOtherTeachersInSearch", {
            type: "checkbox",
          })}
        />
      </FormSection>
      <SubmitBottomBar
        onCreate={form.onSubmit(onFormSubmit)}
        onCancel={() => history.push(withLanguage("/courses/"))}
        loading={loading}
      />
    </AppLayout>
  );
}

export default NewCourse;
