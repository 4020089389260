const theme = {
  colors: {
    green: "rgb(147, 213, 120)",
    blue: "rgb(1, 158, 211)",
    white: "#fff",
  },
  //   primaryColor: "blue",
};

export default theme;
