import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  createStyles,
  Image,
  TextInput,
  Menu,
  CloseButton,
  Burger,
  Center,
  LoadingOverlay,
  DEFAULT_THEME,
} from "@mantine/core";

import searchIcon from "../../../assets/images/icons/magnifying-glass.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NavLink } from "react-router-dom";
import { withLanguage } from "../../../lib/i18";
import LanguageSwitcher from "../../LanguageSwitcher";
import { checkFormHasUnsavedChanges, getUsername } from "../../../utils/utils";
import GlobalContext from "../../../context/GlobalContext";

import arrowDownImage from "../../../assets/images/icons/arrow-down.svg";
import userImage from "../../../assets/images/icons/user-circle.png";
import logo from "../../../assets/images/logos/my-torah-kids.png";
import boyGirlImage from "../../../assets/images/misc/boy-and-girl.png";
import { getProfile } from "../../../api/Auth";

const useStyles = createStyles((theme, _params, getRef) => ({
  content: {
    background: "rgb(250, 250, 250);",
    padding: "27px 40px",
    display: "flex",
    flex: "1 1 0%",
    flexDirection: "column;",
    overflowY: "auto",
    [`@media  (max-width: 900px)`]: {
      padding: "10px",
      // overflow: "hidden",
    },
    position: "relative",
  },
  header: {
    flexShrink: 0,
    display: "flex",
    marginBottom: "14px",
    minHeight: "auto",
    [`@media  (max-width: 900px)`]: {
      display: "none",
    },
  },
  headerMobile: {
    display: "none",
    [`@media  (max-width: 900px)`]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },
  mobileMenu: {
    position: "absolute",
    zIndex: "7",

    width: "98%",
    height: "98%",
    background: "rgb(250, 250, 250);",
    overflow: "hidden",
  },
  contentBox: {
    boxShadow: "rgb(0 0 0 / 7%) 0px 2px 4px 0px",
    borderRadius: "22px",
    background: "rgb(255, 255, 255)",
    padding: "0px 50px 50px",
    overflowY: "auto",
    marginBottom: "20px",
    flex: "1 1 0%",
    [`@media  (max-width: 900px)`]: {
      marginBottom: "0px",
      padding: "0px 15px 15px",
    },
  },
}));

function MTKContent({ children, loading = false }) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const history = useHistory();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <Box className={classes.content}>
      <LoadingOverlay visible={loading} loader={customLoader} />
      <Box className={classes.header}>
        <Box
          sx={{
            flex: "1 1 0%;",
            // display: flex;
          }}
        >
          <TextInput
            placeholder="Search"
            styles={{
              input: {
                borderRadius: "22px",
                height: "46px",
                border: 0,
                padding: "14px 40px",
              },
              rightSection: {
                marginRight: "14px",
              },
              root: {
                borderRadius: "22px",
                width: "340px",
                fontSize: "16px",
                border: 0,
                boxShadow: "rgb(0 0 0 / 7%) 0px 2px 4px 0px",
                [`@media screen and (max-width: 1150px)`]: {
                  width: "170px",
                },
                [`@media screen and (max-width: 950px)`]: {
                  display: "none",
                },

                // padding: "14px 70px 14px 40px",
              },
            }}
            rightSection={<Image src={searchIcon} width="17px" />}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AccountMenu width={180} />
          <LanguageSwitcher width={160} />
        </Box>
      </Box>

      <Box className={classes.headerMobile}>
        <Image src={logo} width={75} />

        <Box
          sx={{
            background: "white",
            width: "46px",
            height: "46px",
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            boxShadow: "rgb(0 0 0 / 7%) 0px 2px 4px 0px",
          }}
          onClick={() => setMobileMenuOpen(true)}
        >
          <Burger size={"sm"} />
        </Box>
      </Box>

      {mobileMenuOpen && (
        <Box className={classes.mobileMenu}>
          <Box
            sx={{
              background: "white",
              width: "46px",
              height: "46px",
              borderRadius: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              boxShadow: "rgb(0 0 0 / 7%) 0px 2px 4px 0px",
              position: "absolute",
              top: "0px",
              right: "5px",
              zIndex: "10",
            }}
            onClick={() => setMobileMenuOpen(false)}
          >
            <CloseButton iconSize={22} />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              width: "100%",
              top: "5px",
            }}
          >
            <AccountMenu width={180} />
            <LanguageSwitcher width={160} />
          </Box>
          <Center>
            <Image
              sx={{
                marginTop: "120px",
              }}
              width={147}
              src={logo}
            />
          </Center>
          <Box
            mt="xl"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MobileMenuItem
              title={t("Organisations")}
              to={withLanguage("/organisations")}
            />
            <MobileMenuItem
              title={t("Classrooms")}
              to={withLanguage("/classrooms")}
            />
            <MobileMenuItem
              title={t("Courses")}
              to={withLanguage("/courses")}
            />
            <MobileMenuItem
              title={t("Contacts")}
              to={withLanguage("contacts")}
            />

            <Image src={boyGirlImage} mt="xl" width={180} />
          </Box>
        </Box>
      )}

      <Box className={classes.contentBox}>{children}</Box>
    </Box>
  );
}

const AccountMenu = ({ width }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { profile } = useContext(GlobalContext);
  const [userName, setUserName] = useState(getUsername(profile));

  useEffect(() => {
    if (userName === "User" || !userName) {
      getProfile(profile.id).then((res) => {
        setUserName(res.firstName);
      });
    }
  }, [userName, profile.id]);

  return (
    <Menu
      withArrow
      trigger="hover"
      control={
        <Box
          sx={{
            background: "rgb(255, 255, 255);",
            color: "rgb(0, 0, 0);",
            margin: " 0px 20px 0px 0px;",
            maxWidth: "210px;",
            boxShadow: "rgb(0 0 0 / 7%) 0px 2px 4px 0px;",
            display: "flex",
            padding: "13px 30px;",
            borderRadius: " 22px;",
            cursor: " pointer;",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Image src={userImage} width={15} mr="sm" />
          {t(userName)}
          <Image src={arrowDownImage} width={15} ml="sm" />
        </Box>
      }
    >
      <Menu.Item
        onClick={() => {
          checkFormHasUnsavedChanges(() =>
            history.push(withLanguage("/account"))
          );
        }}
      >
        {t("Account")}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          checkFormHasUnsavedChanges(() => history.push(withLanguage("/help")));
        }}
      >
        {t("Help")}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          checkFormHasUnsavedChanges(() =>
            history.push(withLanguage("/contact"))
          );
        }}
      >
        {t("Contact")}
      </Menu.Item>
      <Menu.Item
        color={"red"}
        onClick={() => {
          checkFormHasUnsavedChanges(() =>
            history.push(withLanguage("/logout"))
          );
        }}
      >
        {t("Logout")}
      </Menu.Item>
    </Menu>
  );
};

const MobileMenuItem = ({ title, to }) => {
  const history = useHistory();
  const [active, setActive] = useState(false);
  return (
    <NavLink
      to={to}
      onClick={(e) => {
        e.preventDefault();
        checkFormHasUnsavedChanges(() => {
          history.push(to);
        });
      }}
      isActive={(match, location) => {
        setActive(match !== null);
      }}
      style={{
        textDecoration: "none",
      }}
    >
      <Box
        sx={{
          color: active ? "#019ed3" : "#00000077",
          fontWeight: active ? "bold" : "normal",
          fontSize: "18px",
          /* padding: 15px 50px; */
          width: "230px",
          padding: " 20px",
          background: active ? "#019ed3" : "transparent",
          color: active ? "#fff" : "#000",
          cursor: "pointer",
          textAlign: "center",
          borderRadius: "22px",
          marginBottom: " 10px",
        }}
      >
        {title}
      </Box>
    </NavLink>
  );
};

const customLoader = (
  <svg
    width="54"
    height="54"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    stroke={DEFAULT_THEME.colors.blue[6]}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);
export default MTKContent;
