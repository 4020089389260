import { Box, Grid, Image } from "@mantine/core";
import React, { useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import MTKButton from "../../../../components/Button";
import MTKSwitch from "../../../../components/Switch";
import { ReactComponent as PlusIcon } from "../../../../assets/images/icons/plus.svg";
import plusIcon from "../../../../assets/images/icons/Group 7.svg";
import gameIcon from "../../../../assets/images/icons/game.svg";

import GameCard from "../../../Games/components/GameCard";
import { withLanguage } from "../../../../lib/i18";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import contentful from "../../../../lib/contentful";
import { capitalize } from "../../../../utils/utils";
// import { OrderGamesModal } from "../../components/OrderGamesModal";
import { useModals } from "@mantine/modals";

function GamesTab({
  games = [],
  gamesOrder,
  addGameToReport,
  removeGameFromReport,
  courseId = "",
  classroomId = null,
  organisationId = null,
  saveGamesOrder,
}) {
  const { t } = useTranslation();
  const history = useHistory();
  const modals = useModals();

  const [emptyView, setEmptyView] = useState(null);

  const orderedGames = useMemo(() => {
    if (gamesOrder && Array.isArray(gamesOrder)) {
      const ordered = [
        ...gamesOrder
          .map((d) => games.find((game) => game.id === d))
          .filter((d) => d),
        ...games.filter((d) => !gamesOrder.includes(d.id)),
      ];
      return ordered;
    } else {
      return games;
    }
  }, [gamesOrder, games]);

  useEffect(() => {
    contentful("emtyView").then((data) => {
      data.items.forEach((item) => {
        if (item.fields.type === "questions") {
          setEmptyView(item);
        }
      });
    });
  }, []);

  const orderGames = () => {
    modals.openContextModal("order", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        sortedItems: orderedGames,
        saveNewOrder: saveGamesOrder,
        title: t("Order Games"),
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          // setMediaUrl(null);
          // onChange(null);
        },
      },
    });
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        mb="40px"
      >
        <Box></Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <MTKButton color="green" onClick={orderGames}>
            {t("Order Games")}
          </MTKButton>
          <MTKButton
            mx="md"
            color="green"
            leftIcon={<PlusIcon />}
            onClick={() =>
              history.push(withLanguage(`/courses/${courseId}/games/new`))
            }
          >
            {t("New Game")}
          </MTKButton>
        </Box>
      </Box>
      {/* <OrderGamesModal data={games} /> */}
      {orderedGames.length > 0 ? (
        <Grid>
          {orderedGames.map((game) => {
            return (
              <Grid.Col md={12} lg={4} key={game.id}>
                <GameCard
                  courseId={courseId}
                  classroomId={classroomId}
                  organisationId={organisationId}
                  {...game}
                  withDuplicate
                  addGameToReport={addGameToReport}
                  removeGameFromReport={removeGameFromReport}
                />
              </Grid.Col>
            );
          })}
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            height: "400px",
            marginTop: "-80px",
          }}
        >
          <div
            style={{
              position: "relative",
              left: "0px",
              top: "0px",
            }}
          >
            <img src={gameIcon} alt="Icon" height="128" width="115" />
            <img
              src={plusIcon}
              alt="Icon"
              height="60"
              width="54"
              style={{
                marginLeft: "-30px",
              }}
            />
          </div>
          <h1>
            {
              emptyView?.fields[
                `title${capitalize(withLanguage("").substring(1))}`
              ]
            }
          </h1>
          <p>
            {
              emptyView?.fields[
                `description${capitalize(withLanguage("").substring(1))}`
              ]
            }
          </p>
          <MTKButton
            leftIcon={<PlusIcon />}
            color="green"
            onClick={() =>
              history.push(withLanguage(`/courses/${courseId}/games/new`))
            }
          >
            {t("New Game")}
          </MTKButton>
        </div>
      )}
    </Box>
  );
}

export default GamesTab;
