import { ActionIcon, Box, TextInput, Title } from "@mantine/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MTKButton from "../../../../components/Button";
import { ReactComponent as SignUpIcon } from "../../../../assets/images/icons/box-arrow.svg";
import MTKInput from "../../../../components/Input";
import {
  Edit,
  EditCircle,
  Minus,
  Plus,
  Search,
  Upload,
} from "tabler-icons-react";
import MTKTable from "../../../../components/Table";
import { useParams } from "react-router-dom";
import {
  getReportsOfClassroom,
  getStudentsOfClassroom,
} from "../../../../api/Classrooms";
import { useState } from "react";
import { getStudentsAnswerOfGame } from "../../../../api/StudentsAnswers";
import { getQuestionsOfGame } from "../../../../api/Questions";
import { calculateTotalGameReportStudentPercentage } from "../../../Games/GameDetails/components/ReportsTab";
import { useMemo } from "react";
import { memo } from "react";
import { useFocusTrap, useHover } from "@mantine/hooks";
import {
  createCourseOtherGame,
  deleteCourseOtherGame,
  getGamesOfCourse,
  getOtherGamesOfCourse,
  updateCourseOtherGame,
} from "../../../../api/Games";
import { useModals } from "@mantine/modals";
import { getCoursesOfClasssroom } from "../../../../api/Courses";
import { calculateTotalCourseMean } from "../../../Courses/CourseDetails/components/ReportsTab";
import MTKCheckBox from "../../../../components/CheckBox";

function ReportsTab({ classroom, classroomId, organisation, courseId }) {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [coursesWithGamesAndAnswers, setCoursesWithGamesAndAnswers] = useState(
    []
  );
  const [selectedStudents, setSelectedStudents] = useState([]);

  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(true);

  const modals = useModals();

  useEffect(() => {
    if (classroomId && refreshData) {
      getStudentsOfClassroom(classroomId).then((res) => setStudents(res));
      getCoursesOfClasssroom(classroomId).then((res) => setCourses(res));
      getReportsOfClassroom(classroomId).then((res) => setReportData(res));
      setRefreshData(false);
    }
  }, [classroomId, refreshData]);

  const handleRefreshData = () => {
    setRefreshData(true);
  };
  useEffect(() => {
    const getData = async () => {
      const coursesWithGamesAndAnswers = await Promise.all(
        courses.map(async (course) => {
          const games = await getGamesOfCourse(course.id);
          const otherGames = await getOtherGamesOfCourse(course.id);

          return { ...course, gamesWithAnswer: games, otherGames };
        })
      );
      setCoursesWithGamesAndAnswers(coursesWithGamesAndAnswers);
    };
    if (courses) {
      getData();
    }
  }, [courses]);

  const handleAddStudent = () => {
    modals.openContextModal("newClassroomStudent", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        classroom,
        classroomId,
        handleRefreshData,
        studentsOfClassroom: students,
      },
    });
  };
  const handleDeleteStudents = () => {
    modals.openContextModal("deleteClassroomStudent", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        classroom,
        classroomId,
        handleRefreshData,
        selectedStudents,
        studentsOfClassroom: students,
      },
    });
  };
  const data = React.useMemo(
    () =>
      students
        .map((student) => {
          const data = reportData?.find((d) => d.studentId === student.id);
          const mean = data?.report?.mean * 10;
          if (!data || !mean || (!student?.firstName && !student?.name)) {
            return null;
          }
          return {
            name: `${student?.firstName || ""} ${student?.name || ""}`,
            mean: isNaN(mean) ? "-" : mean?.toFixed(2),
            data: data?.report || {},
            id: student.id,
            studentData: student,
          };
        })
        .filter((d) => d),
    [students, reportData]
  );
  // return null;

  const columns = React.useMemo(
    () => [
      {
        id: "select",
        width: "50px",
        disableSortBy: true,

        Header: (...props) => {
          const table = {};
          return (
            <MTKCheckBox
              checked={students.every((d) => selectedStudents.includes(d.id))}
              onChange={(e) =>
                setSelectedStudents((d) => {
                  return e.target.checked ? students.map((d) => d.id) : [];
                })
              }
            />
          );
        },
        accessor: ({ id }) => {
          return (
            <MTKCheckBox
              checked={selectedStudents.includes(id)}
              onChange={(e) =>
                setSelectedStudents((d) =>
                  e.target.checked ? [...d, id] : d.filter((d) => d !== id)
                )
              }
            />
          );
        },
      },
      {
        Header: "Students",
        // accessor: "name", // accessor is the "key" in the data
        isSticky: true,
        accessor: ({ name, studentData }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box>{name}</Box>

              <ActionIcon
                ml="5px"
                onClick={() => {
                  modals.openContextModal("editStudent", {
                    //   title: t('Delete Media File'),
                    centered: true,
                    radius: "lg",
                    innerProps: {
                      student: studentData,
                      handleRefreshData: handleRefreshData,
                    },
                  });
                }}
              >
                <EditCircle color="rgb(1, 158, 211)" />
              </ActionIcon>
            </Box>
          );
        },
      },
      {
        Header: "Mean",
        accessor: "mean",
        isSticky: true,
        width: "100px",
      },
      ...coursesWithGamesAndAnswers.map((course, i) => ({
        Header: course.name,
        accessor: ({ data }) => {
          const { coursesWithScore } = data;
          const courseData = coursesWithScore?.find(
            (d) => d.courseID === course.id
          );
          if (courseData) {
            return (courseData.mean * 10)?.toFixed(2);
          } else {
            return "-";
          }
        },
      })),
    ],
    [coursesWithGamesAndAnswers, selectedStudents, students]
  );
  return (
    <Box>
      <Box
        mb="25px"
        sx={{
          //   display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <MTKButton
            mt="md"
            mr="md"
            leftIcon={<Minus size={15} />}
            color="red"
            disabled={selectedStudents.length === 0}
            onClick={handleDeleteStudents}
          >
            {t("Remove")}
          </MTKButton>
          <MTKButton
            mt="md"
            leftIcon={<Upload size="15" />}
            color="blue"
            disabled
            mr="md"
          >
            {t("Export")}
          </MTKButton>
          <MTKButton
            mt="md"
            mr="md"
            leftIcon={<Plus size="15" />}
            color="blue"
            onClick={handleAddStudent}
          >
            {t("Add Students")}
          </MTKButton>
        </Box>
        <MTKInput
          sx={{
            minWidth: "400px",
            maxWidth: "500px",
          }}
          leftIcon={<Search />}
          label={t("Search")}
          placeholder={t("Search a Student...")}
        />
      </Box>

      <Box>
        <MTKTable
          loading={loading}
          data={data}
          columns={columns}
          tableText={t("Students")}
          noDataText={t("No Students added yet")}
        ></MTKTable>
      </Box>
    </Box>
  );
}

export default ReportsTab;

// export const calculateTotalClassroomMean = (
//   coursesWithGamesAndAnswers,
//   studentId
// ) => {
//   if (!coursesWithGamesAndAnswers || !studentId) {
//     return "";
//   }

//   let mean = 0;
//   const subMean = [];
//   const coursesWithScore = {};

//   coursesWithGamesAndAnswers.map((course) => {
//     const { gamesWithAnswer, otherGames } = course;

//     const { mean: courseMean } = calculateTotalCourseMean(
//       gamesWithAnswer,
//       otherGames,
//       studentId,
//       true
//     );
//     coursesWithScore[course.id] = courseMean ? courseMean.toFixed(2) : null;
//     subMean.push(courseMean);
//   });

//   //   return {};
//   let subMeanOnlyPositive = subMean.filter((d) => d);
//   mean =
//     subMeanOnlyPositive.reduce((a, b) => a + b, 0) / subMeanOnlyPositive.length;
//   if (!mean) {
//     mean = 0;
//   }
//   mean = mean.toFixed(2);
//   return { mean, coursesWithScore };
// };
