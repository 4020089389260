import React, { useState, useEffect } from "react";

function MTKCard({
  title,
  content,
  callback = () => {},
  width,
  link,
  background = "#fff",
  textColor,
  icon = "",
  height,
  iconMargin = "",
  animated = true,
}) {
  const [hover, setHover] = useState(false);
  const [styles, setStyles] = useState(cardStyles);

  useEffect(() => {
    if (hover && animated) {
      setStyles({ ...cardStyles, ...onHover });
    } else {
      setStyles(cardStyles);
    }
  }, [hover, setHover]);

  const handler = () => {
    callback(link);
  };
  return (
    <div
      style={{
        ...styles,
        width,
        height,
        background,
        color: textColor,
        marginTop: "20px",
        cursor: animated ? "pointer" : "",
      }}
      onClick={handler}
      onMouseEnter={(e) => setHover(true)}
      onMouseLeave={(e) => setHover(false)}
    >
      {icon === "" ? null : (
        <img src={icon || null} alt="image" style={{ margin: iconMargin }} />
      )}

      <p
        style={{
          fontSize: "22px",
          marginTop: "20px",
          marginBottom: "14px",
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "12px", textAlign: "center" }}>{content}</p>
    </div>
  );
}

const cardStyles = {
  color: "#93D578",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "280px",
  paddingLeft: "22px",
  paddingRight: "22px",
  paddingBottom: "15px",
  cursor: "pointer",
  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  background: "#000",

  borderRadius: "22px",
};
const onHover = {
  transform: "translateY(-3px) scale(1.1)",
  transition: "0.2s",
};

export default MTKCard;
