import { Anchor, Breadcrumbs, createStyles } from "@mantine/core";
import React from "react";
import { Link } from "react-router-dom";
import { withLanguage } from "../../lib/i18";

const useStyles = createStyles((theme, _params, getRef) => ({
  anchor: {
    fontSize: "12px",
    fontWeight: "400",
    color: "rgb(178, 178, 178)",
    position: "absolute",
    left: " 48px",
    top: " -20px",
    cursor: "pointer",
    textDecoration: "none",
  },
}));
function MTKBreadcrumbs({ data = [] }) {
  const { classes } = useStyles();
  return (
    <Breadcrumbs
      ml="47px"
      styles={{
        separator: {
          color: "rgb(178, 178, 178)",
          margin: "0 3px",
        },
      }}
    >
      {data.map((d) => (
        <Anchor
          sx={{
            fontSize: "12px",
            fontWeight: "400",
            color: "rgb(178, 178, 178)",
          }}
          className={classes.anchor}
          component={Link}
          to={withLanguage(d.url)}
        >
          {d.title}
        </Anchor>
      ))}
    </Breadcrumbs>
  );
}

export default MTKBreadcrumbs;
