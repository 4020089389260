import React, { useEffect } from "react";
import { useHistory } from "react-router";

import { logout as firebaseLogout } from "../../../lib/firebase";
import LoadingScreen from "../../../components/layout/LoadingScreen";
import { withLanguage } from "../../../lib/i18";
// Component

export default function Logout() {
  const history = useHistory();

  // Init

  useEffect(() => {
    const logout = async () => {
      try {
        await firebaseLogout();
      } catch (error) {
        console.log("Logout error", error);
      }
      history.push(withLanguage("/teacher/auth"));
    };
    logout();
  }, [history]);

  // Render

  return <LoadingScreen />;
}
