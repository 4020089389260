import React, { useContext, useState, useEffect } from "react";
import { Box, Title, Button, Grid, Stack } from "@mantine/core";
import {
  ageDropdownList,
  capitalize,
  generateRandomPassword,
  getJewishSchoolYear,
} from "../../../../utils/utils";
import { withLanguage } from "../../../../lib/i18";
import MediaUploader from "../../../../components/MediaUploader";
import MTKInput from "../../../../components/Input";
import AudioUploader from "../../../../components/AudioUploader";
import MTKSelect from "../../../../components/Select";
import MTKTextarea from "../../../../components/Textarea";
import MTKPhoneInput from "../../../../components/PhoneInput";
import MTKCountryInput from "../../../../components/CountryInput";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashCanIcon } from "../../../../assets/images/icons/trash-can.svg";
import { useModals } from "@mantine/modals";
import useIsSuperAdmin from "../../../../hooks/useIsSuperAdmin";
import MTKButton from "../../../../components/Button";
import { organisationTypes } from "../../../../constants/types";
import GlobalContext from "../../../../context/GlobalContext";

function AboutTab({ form, onDeleteClassroom, schoolYears, organisations }) {
  const { t, i18n } = useTranslation();
  const modals = useModals();
  const isAdmin = useIsSuperAdmin();
  const { profile } = useContext(GlobalContext);
  const [orgTypes, setOrgTypes] = useState(null);
  const [selectedOrgType, setSelectedOrgType] = useState(null);

  const { ageLimitFrom, ageLimitTo, organisationId } = form.values;

  useEffect(() => {
    if (organisations && organisationId) {
      const currentLang = i18n.language;
      const orgTypes = { ...organisationTypes };

      if (profile?.type === "teacher") {
        delete orgTypes.home;
      }

      const options = Object.keys(orgTypes).map((key) => ({
        value: key,
        label: orgTypes[key][currentLang],
      }));

      setOrgTypes(options);

      const org = organisations.find((d) => d.id === organisationId);
      if (org) {
        setSelectedOrgType(org.type);
      }
    }
  }, [organisations, organisationId]);

  const onDelete = () => {
    modals.openContextModal("confirmation", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Delete classroom"),
        modalBody: t(
          "Are you sure you want to delete this classroom? All elements inside this classroom will be deleted. Duplicate the courses and/or games you want to keep."
        ),
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          onDeleteClassroom();
        },
      },
    });
  };
  return (
    <Box>
      <Grid gutter="xl">
        <Grid.Col md={12} lg={6}>
          <Section title={t("General Information")}>
            <MediaUploader
              ratio={1}
              {...form.getInputProps("coverImage")}
              recommendedAspectRatio="1/1 (512*512)"
            />
            <MTKInput
              label={`${t("Name")}`}
              required
              labelColor="blue"
              {...form.getInputProps("name")}
            />
            <AudioUploader {...form.getInputProps("audioFile")} />
            <MTKSelect
              data={orgTypes || []}
              label={t("Organisation Type")}
              required
              value={selectedOrgType}
              onChange={(v) => setSelectedOrgType(v)}
            />
            <MTKSelect
              label={`${t("Organisation")}`}
              required
              data={organisations
                ?.filter((d) => d.type === selectedOrgType)
                .map((d) => ({ value: d.id, label: d.name }))}
              // data={orgSubType}
              {...form.getInputProps("organisationId")}
            />
            <MTKCountryInput
              {...form.getInputProps("country")}
              label={t("Country")}
              labelColor="blue"
            />
            <MTKSelect
              label={t("School Year")}
              required
              data={schoolYears?.map((d) => ({
                value: d.id,
                label: getJewishSchoolYear(d.key),
              }))}
              {...form.getInputProps("schoolYearId")}
            />
            <MTKTextarea
              label={t("Description")}
              minRows={4}
              {...form.getInputProps("description")}
            />
            <MTKSelect
              data={ageDropdownList.map((d) => ({
                label: d || "",
                value: `${d || ""}`,
              }))}
              onChange={(value) => {
                if (!ageLimitTo) {
                  form.setFieldValue("ageLimitTo", 12);
                }
                form.setFieldValue("ageLimitFrom", value || "");
              }}
              value={`${ageLimitFrom}`}
              label={t("Age From")}
            />
            <MTKSelect
              test
              data={ageDropdownList
                .filter((d) =>
                  ageLimitFrom && ageLimitFrom > d ? false : true
                )
                .map((d) => ({ value: `${d || ""}`, label: d || "" }))}
              label={t("Age To")}
              {...form.getInputProps("ageLimitTo")}
              value={`${ageLimitTo}`}
            />
          </Section>
        </Grid.Col>
        <Grid.Col md={12} lg={6}>
          <Section title={t("Password")}>
            <MTKInput
              label={`${t("Access Code")}`}
              required
              labelColor="blue"
              {...form.getInputProps("password")}
            />
            {isAdmin && (
              <MTKButton
                onClick={() =>
                  form.setFieldValue("password", generateRandomPassword(6))
                }
                color="blue"
              >
                {t("Auto Generate")}
              </MTKButton>
            )}
          </Section>
        </Grid.Col>
      </Grid>

      <Box
        mt="lg"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="red"
          radius={"xl"}
          size="md"
          leftIcon={<TrashCanIcon color="white" />}
          onClick={onDelete}
        >
          {t("Delete")}
        </Button>
      </Box>
    </Box>
  );
}
const Section = ({ title = "", children }) => {
  return (
    <Box>
      <Title
        sx={{
          fontSize: "24px",
          color: "rgb(1, 158, 211)",
          paddingLeft: "10px",
          marginTop: "0px",
          marginBottom: "20px",
        }}
      >
        {title}
      </Title>
      <Box
        sx={{
          background: "rgb(239, 247, 255)",
          borderRadius: "22px",
          padding: "20px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
export default AboutTab;
