import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  TextInput,
  InputWrapper,
  Box,
  Text,
  useMantineTheme,
} from "@mantine/core";

function MTKPhoneInput({
  label = "",
  value = "",
  colorLabel = "blue",
  onChange = () => null,
  labelColor = "",
  ...props
}) {
  const theme = useMantineTheme();
  return (
    <Box
      my="xs"
      label={label}
      styles={{
        label: {
          color: "#00000055",
        },
      }}
      placeholder="Type Here..."
    >
      <Text
        sx={{
          fontSize: "14px",
          marginBottom: "4px",
          color: labelColor === "blue" ? theme.colors.blue : "#00000055",
          fontWeight: "500",
        }}
        color={labelColor === "blue" ? theme.colors.blue : "#00000055"}
      >
        {label}
      </Text>
      <PhoneInput
        value={value}
        onChange={onChange}
        searchStyle={{
          marginLeft: 50,
        }}
        buttonStyle={{
          borderColor: "#019ed3",
          background: "transparent",
          border: "1px solid #019ed3 ",
          borderRight: "0px",
          borderBottomLeftRadius: "5px",
        }}
        inputStyle={{
          padding: 21,
          paddingLeft: 50,
          width: "100%",
          fontSize: 16,
          borderColor: "#019ed3",
          background: "transparent",
          color: "#019ed3",
        }}
        // containerStyle={{
        //   marginBottom: 25,
        // }}
        placeholder={"Type Here..."}
        inputProps={{
          name: "phone",
          // required: true,
          // autoFocus: true,
        }}
        {...props}
      />
    </Box>
  );
}

export default MTKPhoneInput;
