import React, { useEffect, useState } from "react";
import MTKButton from "../../../../components/Button";
import { Box, Center, Image, Modal, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import TeacherCard from "../../components/TeacherCard";
import { z } from "zod";
import MTKInput from "../../../../components/Input";
import { ReactComponent as PlusIcon } from "../../../../assets/images/icons/plus.svg";
import errorSignImage from "../../../../assets/images/icons/error-sign.png";
import { getTeachers } from "../../../../api/Users";

function TeachersTab({
  teachers,
  courses = [],
  onRemoveTeacher,
  addTeacherCallback,
}) {
  const { t } = useTranslation();
  const [allTeachersList, setallTeachersList] = useState([]);

  useEffect(() => {
    getTeachers().then(setallTeachersList);
  }, []);

  return (
    <Box>
      <Box
        mb="30px"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <AddTeacherBTN
          teachers={teachers}
          addTeacherCallback={addTeacherCallback}
          allTeachersList={allTeachersList}
        />
      </Box>
      <Box>
        {teachers && teachers.length > 0 ? (
          teachers.map((teacher) => (
            <TeacherCard
              {...teacher}
              onRemoveTeacher={onRemoveTeacher}
              courses={courses.filter((d) => d.createdByUserId === teacher.id)}
            />
          ))
        ) : (
          <Text
            sx={{
              color: "rgb(188, 188, 188)",
              fontSize: "14px",
            }}
          >
            {t("No teachers have been added yet.")}
          </Text>
        )}
      </Box>
    </Box>
  );
}

export const AddTeacherBTN = ({
  allTeachersList,
  teachers,
  addTeacherCallback,
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [email, setEmail] = useState("");
  const [step, setStep] = useState("initial");

  const checkIfAddingAllowed = () => {
    let emailValid = false;
    try {
      z.string().email().parse(email);
      emailValid = true;
    } catch (error) {
      emailValid = false;
    }
    if (emailValid) {
      const found = teachers.find((t) => t.email === email);
      if (found) {
        return false;
      }
      return true;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const allowed = checkIfAddingAllowed(email);
    if (!allowed) {
      setStep("error");
      return;
    }
    console.log("this");
    const teacher = allTeachersList.find((d) => d.email === email);
    if (!teacher) {
      setStep("error");
    } else {
      addTeacherCallback(teacher);
      setOpened(false);
      setStep("initial");
    }
  };
  return (
    <>
      <Modal
        radius={"lg"}
        shadow="md"
        opened={opened}
        onClose={() => {
          setOpened(false);
          setEmail("");
          setStep("initial");
        }}
        centered
        padding={"xl"}
        title={null}
      >
        {step === "initial" && (
          <Box p="xl">
            <Title order={3} mb="sm">
              {t("New Teacher")}
            </Title>
            <Text size="lg" mb="lg">
              {t(
                "Add a new teacher to your class by entering his/her email address."
              )}
            </Text>
            <form onSubmit={onSubmit}>
              <MTKInput
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mb="xl"
                type="email"
                label={t("Email")}
              />
              <Box
                sx={{
                  display: "flex",
                }}
                px="xl"
                mt="50px"
              >
                <MTKButton
                  variant="light"
                  fullWidth
                  color="blue"
                  onClick={() => {
                    setOpened(false);
                    setEmail("");
                    setStep("initial");
                  }}
                >
                  {t("Cancel")}
                </MTKButton>
                <MTKButton type="submit" color="blue" fullWidth>
                  {t("Add")}
                </MTKButton>
              </Box>
            </form>
          </Box>
        )}
        {step === "error" && (
          <Box>
            <Center>
              <Image src={errorSignImage} my="md" width={84} />
            </Center>
            <Title order={3} my="md" align="center">
              {t("Error")}
            </Title>
            <Text size="lg" align="center" my="md">
              {t("Email address invalid or already added to the list!")}
            </Text>
            <Center>
              <MTKButton
                width="140px"
                // fullWidth
                color="blue"
                mt="md"
                onClick={() => {
                  setEmail("");
                  setOpened(false);
                  setStep("initial");
                }}
              >
                {t("OK")}
              </MTKButton>
            </Center>
          </Box>
        )}
      </Modal>

      <MTKButton
        onClick={() => setOpened(true)}
        leftIcon={<PlusIcon />}
        // mx="auto"
        color="blue"
      >
        {t("Add Teacher")}
      </MTKButton>
    </>
  );
};
export default TeachersTab;
