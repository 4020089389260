import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  createStyles,
  Title,
  Tabs,
  Select,
  Grid,
  ActionIcon,
} from "@mantine/core";

import AppLayout from "../../../components/layout/AppLayout";
import { useTranslation } from "react-i18next";

import { Link, useHistory } from "react-router-dom";
import { withLanguage } from "../../../lib/i18";

import { getOrganisations } from "../../../api/Organisations";
import MTKTabs from "../../../components/Tabs";
import { getSchoolYears } from "../../../api/SchoolYear";
import { ReactComponent as Plus } from "../../../assets/images/icons/plus.svg";
import { getClassrooms } from "../../../api/Classrooms";
import MTKButton from "../../../components/Button";
import { getJewishSchoolYear } from "../../../utils/utils";
import ClassroomCard from "../components/ClassroomCard";
import GlobalContext from "../../../context/GlobalContext";
const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    position: "sticky",
    top: 0,
    padding: "40px 0",

    zIndex: 2,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "0px",
    paddingBottom: "10px",
  },
  headerText: {
    marginBottom: "30px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: "350px",
  },
}));

function Classrooms({ type: pageTab }) {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { profile } = useContext(GlobalContext);
  const [classrooms, setClassrooms] = useState([]);
  const [filteredClassrooms, setFilteredClassrooms] = useState([]);
  const [schoolYears, setSchoolYears] = useState([]);
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const [organisations, setOrganisations] = useState([]);
  const [filteredOrganisations, setFilteredOrganisations] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    pageTab === "all" ? 0 : ["my-classrooms", "shared-with-me"].indexOf(pageTab)
  );
  const [activeType, setActiveType] = useState(pageTab);

  const onChange = (active, tabKey) => {
    setActiveTab(active);
    setActiveType(tabKey);
    history.push(withLanguage(`/classrooms/${tabKey}`));
  };

  const getMyClassrooms = () => {
    // if director: check classroom organisation
    if (classrooms && organisations && profile) {
      return classrooms.filter((c) => {
        const isCreator = c.createdByUserId === profile.id;
        let isDirector = false;
        const org = organisations.find((o) => o?.id === c?.organisationId);
        if (org) {
          if ("directors" in org) {
            isDirector = profile.id in org.directors;
          }
        }
        return isCreator || isDirector;
      });
    }
    return [];
  };
  const getSharedClassrooms = () => {
    if (classrooms && profile) {
      return classrooms.filter((c) => {
        if ("teachers" in c) {
          return (
            c.createdByUserId !== profile.id &&
            Object.keys(c.teachers)?.includes(profile.id)
          );
        }
        return false;
      });
    }
    return [];
  };
  useEffect(() => {
    if (classrooms && selectedOrganisation && selectedSchoolYear) {
      //   "my-classrooms", "shared-with-me";
      switch (pageTab) {
        case "all":
          setFilteredClassrooms(
            classrooms.filter(
              (d) =>
                d.schoolYearId === selectedSchoolYear &&
                selectedOrganisation === d.organisationId
            )
          );
          break;
        case "my-classrooms":
          setFilteredClassrooms(
            getMyClassrooms().filter(
              (d) =>
                d.schoolYearId === selectedSchoolYear &&
                selectedOrganisation === d.organisationId
            )
          );
          break;
        case "shared-with-me":
          setFilteredClassrooms(
            getSharedClassrooms().filter(
              (d) =>
                d.schoolYearId === selectedSchoolYear &&
                selectedOrganisation === d.organisationId
            )
          );
          break;

        default:
          break;
      }
    }
  }, [classrooms, selectedOrganisation, selectedSchoolYear, pageTab]);

  const getOrganisationsOfSharedClassrooms = () => {
    if (!organisations || !classrooms) return [];

    const organisationIds = getSharedClassrooms().map((c) => c.organisationId);
    const uniqueOrgIds = [...new Set(organisationIds)];
    const orgs = organisations.filter((o) => uniqueOrgIds.includes(o.id));
    if (orgs.length > 0) {
      setSelectedOrganisation(orgs[0].id);
    }
    return orgs;
  };

  const getOrganisationsOfMyClassrooms = () => {
    if (!organisations || !classrooms) return [];

    const organisationIds = getMyClassrooms().map((c) => c.organisationId);
    const uniqueOrgIds = [...new Set(organisationIds)];
    const orgs = organisations.filter((o) => uniqueOrgIds.includes(o.id));
    if (orgs.length > 0) {
      setSelectedOrganisation(orgs[0].id);
    }
    return orgs;
  };

  useEffect(() => {
    if (classrooms && organisations) {
      switch (pageTab) {
        case "all":
          setFilteredOrganisations(organisations);
          break;
        case "my-classrooms":
          setFilteredOrganisations(getOrganisationsOfMyClassrooms());
          break;
        case "shared-with-me":
          setFilteredOrganisations(getOrganisationsOfSharedClassrooms());
          break;

        default:
          break;
      }
    }
  }, [classrooms, organisations, pageTab]);
  useEffect(() => {
    if (pageTab !== activeType) {
      setActiveType(pageTab);
    }
  }, [pageTab, activeType]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const [schoolYears, organisations, classrooms] = await Promise.all([
        getSchoolYears(),
        getOrganisations(),
        getClassrooms(),
      ]);
      const filteredYears = schoolYears.filter((year) =>
        classrooms.find((classroom) => classroom.schoolYearId === year.id)
      );
      setSchoolYears(filteredYears);

      const filteredOrgs = organisations.filter((org) =>
        classrooms.find((classroom) => classroom.organisationId === org.id)
      );
      setOrganisations(filteredOrgs);

      setSelectedSchoolYear(
        filteredYears.length > 0 ? filteredYears[0].id : null
      );
      setClassrooms(classrooms);
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <AppLayout loading={loading} title={t("Classrooms")}>
      <Box className={classes.header}>
        <Box
          mb="20px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Box className={classes.headerText}>
            <Title order={3}>{"Classrooms"}</Title>
          </Box>
          <MTKButton
            sx={{
              [`@media screen and (max-width: 900px)`]: {
                display: "none",
              },
            }}
            color="blue"
            leftIcon={<Plus />}
            onClick={() =>
              history.push(
                withLanguage(
                  `/classrooms/new?prefillId=${selectedOrganisation || ""}`
                )
              )
            }
          >
            {t("New Classroom")}
          </MTKButton>
        </Box>
        {pageTab === "all" ? (
          <MTKTabs>
            <Tabs.Tab label={t("All Classrooms")} tabKey={"all"}></Tabs.Tab>
          </MTKTabs>
        ) : (
          <MTKTabs active={activeTab} onTabChange={onChange}>
            <Tabs.Tab label={t("My Classrooms")} tabKey={"my-classrooms"} />
            <Tabs.Tab label={t("Shared with me")} tabKey={"shared-with-me"} />
          </MTKTabs>
        )}
      </Box>
      <Box
        sx={{
          display: "none",
          [`@media screen and (max-width: 900px)`]: {
            position: "fixed",
            bottom: "25px",
            right: "25px",
            display: "flex",
            flexDirection: "column",
            zIndex: "2",
          },
        }}
      >
        <ActionIcon
          component={Link}
          to={withLanguage("/classrooms/new")}
          size={"xl"}
          radius={"xl"}
          variant="filled"
          sx={(theme) => ({
            backgroundColor: theme.colors.blue,
            width: "50px",
            height: "50px",
          })}
          styles={(theme) => ({
            filled: {
              "&:hover": {
                backgroundColor: theme.colors.blue,
              },
            },
          })}
        >
          <Plus width={"40px"} />
          {/* {t("New Organisation")} */}
        </ActionIcon>
      </Box>
      <Box mt="40px">
        <Grid gutter={"xl"}>
          <Grid.Col lg={4} md={12}>
            <Select
              variant="filled"
              labelProps={{
                style: {
                  color: "rgb(178, 178, 178)",
                },
              }}
              // key={selectedOrganisation}
              //   mx="20px"
              label={t("Organisation")}
              value={selectedOrganisation}
              onChange={(value) => setSelectedOrganisation(value)}
              data={filteredOrganisations?.map((d) => {
                return {
                  value: d.id,
                  label: d.name,
                };
              })}
            />
          </Grid.Col>
          <Grid.Col lg={4} md={12}>
            <Select
              variant="filled"
              labelProps={{
                style: {
                  color: "rgb(178, 178, 178)",
                },
              }}
              //   mx="20px"
              label={t("School Year")}
              value={selectedSchoolYear}
              onChange={(value) => setSelectedSchoolYear(value)}
              data={schoolYears?.map((d) => {
                return {
                  value: d.id,
                  label: getJewishSchoolYear(d.key),
                };
              })}
            />
          </Grid.Col>
        </Grid>
        <Box>
          {filteredClassrooms?.length > 0 ? (
            <Grid gutter={"xl"}>
              {filteredClassrooms?.map((d) => (
                <Grid.Col lg={4} md={12} sm={12}>
                  <ClassroomCard
                    {...d}
                    withYear={
                      schoolYears?.find((d) => d.id === selectedSchoolYear)?.key
                    }
                    withOrg={
                      organisations?.find((d) => d.id === selectedOrganisation)
                        ?.name
                    }
                  />
                </Grid.Col>
              ))}
            </Grid>
          ) : (
            <Box
              mt="40px"
              sx={{
                color: "rgb(188, 188, 188)",
                fontSize: "14px",
              }}
            >
              {t("There are no classrooms.")}
            </Box>
          )}
        </Box>
      </Box>
    </AppLayout>
  );
}

export default Classrooms;
