import React from "react";
import { ContextModalProps, ModalsProvider } from "@mantine/modals";
import { Text, Button, Image, Box, Center, Title } from "@mantine/core";

import errorSignImage from "../../assets/images/icons/error-sign.png";
import { useTranslation } from "react-i18next";
import MTKButton from "../Button";

function ConfirmationModal({ context, id, innerProps }) {
  const { t } = useTranslation();
  return (
    <Box>
      <Center>
        <Image src={errorSignImage} my="md" width={84} />
      </Center>
      <Title order={3} my="md" align="center">
        {innerProps.title}
      </Title>
      <Text size="sm" align="center" my="md">
        {innerProps.modalBody}
      </Text>

      <Center
        px="xl"
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        my="lg"
        mt="xl"
      >
        <MTKButton
          variant="subtle"
          sx={{
            color: "rgb(1, 158, 211)",
          }}
          text
          onClick={() => {
            innerProps.onCancel();
            context.closeModal(id);
          }}
        >
          {t("No")}
        </MTKButton>
        <MTKButton
          width="140px"
          // fullWidth
          color="blue"
          onClick={() => {
            innerProps.onConfirm();
            context.closeModal(id);
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Yes")}
        </MTKButton>
      </Center>
    </Box>
  );
}

export default ConfirmationModal;
