import React from "react";
import { useMemo } from "react";
import YouTube from "react-youtube";
import "./youtubeStyle.css";

function YoutubePlayer({ url }) {
  const getYoutubeIdFromLink = (url) => {
    var regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };
  const videoID = useMemo(() => url && getYoutubeIdFromLink(url), [url]);

  const opts = {
    // height: "390",
    width: "90%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <YouTube
      videoId={videoID}
      opts={opts}
      containerClassName={"youtubeContainer"}
    />
  );
}

export default YoutubePlayer;
