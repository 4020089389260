import React, { useState } from "react";
import { ContextModalProps, ModalsProvider } from "@mantine/modals";
import { Text, Button, Image, Box, Center, Title } from "@mantine/core";

import errorSignImage from "../../assets/images/icons/error-sign.png";
import { useTranslation } from "react-i18next";
import MTKButton from "../Button";
import MTKSelect from "../Select";
import { useEffect } from "react";
import {
  getOrganisations,
  getOrganisationsOfUser,
  getOrganisationsUserHasAccessTo,
} from "../../api/Organisations";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { getClassrooms, getClassroomsOfUser } from "../../api/Classrooms";
import { getCourses } from "../../api/Courses";
import { getGamesOfCourse } from "../../api/Games";
import useIsSuperAdmin from "../../hooks/useIsSuperAdmin";

function CourseDuplicationModal({ context, id, innerProps }) {
  const { t } = useTranslation();
  const { courseId, classroomId, organisationId } = innerProps;
  const [loading, setLoading] = useState(true);
  const [organisation, setOrganisation] = useState(organisationId);
  const [organisations, setOrganisations] = useState(null);
  const [classroom, setClassroom] = useState(classroomId);
  const [classrooms, setClassrooms] = useState(null);

  const { profile } = useContext(GlobalContext);

  const isAdmin = useIsSuperAdmin();
  useEffect(() => {
    const getData = async () => {
      const [organisations, classrooms] = await Promise.all([
        isAdmin
          ? getOrganisations()
          : getOrganisationsUserHasAccessTo(profile.id),
        isAdmin ? getClassrooms() : getClassroomsOfUser(profile.id),
      ]);
      setOrganisations(organisations);

      setClassrooms(classrooms);
      setLoading(false);
    };
    getData();
  }, []);

  return (
    <Box>
      <Title order={3} my="md" align="center">
        {t("Duplicate Course")}
      </Title>
      <Text size="sm" align="center" my="md">
        {t(
          "Select the organisation and classroom you would like to copy your course into."
        )}
      </Text>
      <Box>
        <MTKSelect
          label={t("Organisation")}
          required
          onChange={(value) => {
            setOrganisation(value);
            setClassroom(null);
          }}
          data={organisations?.map((d) => ({
            value: d.id,
            label: d.name,
          }))}
          value={organisation}
        />
        <MTKSelect
          label={t("Classroom")}
          required
          onChange={(value) => {
            setClassroom(value);
          }}
          value={classroom}
          data={classrooms
            ?.filter((d) => d.organisationId === organisation)
            ?.map((d) => ({
              value: d.id,
              label: d.name,
            }))}
        />
      </Box>
      <Center
        px="xl"
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        my="lg"
        mt="xl"
      >
        <MTKButton
          variant="subtle"
          sx={{
            color: "rgb(1, 158, 211)",
          }}
          text
          onClick={() => {
            innerProps.onCancel();
            context.closeModal(id);
          }}
        >
          {t("No")}
        </MTKButton>
        <MTKButton
          width="140px"
          // fullWidth
          loading={loading}
          disabled={!organisation || !classroom}
          color="blue"
          onClick={async () => {
            setLoading(true);
            await innerProps.onConfirm({
              organisation,
              classroom,
            });
            context.closeModal(id);
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Yes")}
        </MTKButton>
      </Center>
    </Box>
  );
}

export default CourseDuplicationModal;
