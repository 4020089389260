import { Box } from "@mantine/core";
import React from "react";

function index() {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "5px",
        left: "5px",
        zIndex: "20",
      }}
    >
      B’’H
    </Box>
  );
}

export default index;
