import React, { useState, useEffect } from "react";
import openIcon from "../../../assets/images/icons/Open question.svg";
import multipleIcon from "../../../assets/images/icons/Multiple choice.svg";
import drawingIcon from "../../../assets/images/icons/drawing.svg";
import clickIcon from "../../../assets/images/icons/click.svg";
import diffIcon from "../../../assets/images/icons/7differences.svg";
import puzzleIcon from "../../../assets/images/icons/puzzle.svg";
import linkingIcon from "../../../assets/images/icons/linking.svg";

import { Box } from "@mantine/core";
import { withLanguage } from "../../../lib/i18";
import { capitalize } from "../../../utils/utils";

function compare(a, b) {
  if (a.fields.position > b.fields.position) return 1;
  if (a.fields.position < b.fields.position) return -1;
  return 0;
}

const QuestionTypes = ({
  questionTypes = [],

  setSelectedQuestionType,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        paddingTop: "20px",
      }}
    >
      <div style={divStyles}>
        {questionTypes?.sort(compare).map((question, index) => (
          <Card
            title={
              question.fields[
                `title${capitalize(withLanguage("").substring(1))}`
              ]
            }
            content={
              question?.fields[
                `description${capitalize(withLanguage("").substring(1))}`
              ]
            }
            callback={
              isTypeAvailable(question.fields.type, availableQuestions)
                ? setSelectedQuestionType
                : () => {}
            }
            link={question.fields.type}
            textColor="#93D578"
            icon={getCorrectIcon(getId(question.fields.type))}
            height={"210px"}
            width={"210px"}
          />
        ))}
        {/* <Card
          textColor="#93D578"
          height={'210px'}
          width={'210px'}
          animated={false}
        /> */}
      </div>
    </Box>
  );
};

const getId = (type) => {
  let id = 0;
  availableQuestions.forEach((q) => {
    if (q.type === type) {
      id = q.id;
    }
  });
  return id;
};

const getTypeFromName = (name) => {
  let type = null;
  availableQuestions.forEach((q) => {
    if (q.name === name) {
      type = q.type;
    }
  });
  return type;
};

const getCorrectIcon = (id) => {
  if (id === 0) {
    return openIcon;
  } else if (id === 1) {
    return drawingIcon;
  } else if (id === 2) {
    return puzzleIcon;
  } else if (id === 3) {
    return linkingIcon;
  } else if (id === 4) {
    return diffIcon;
  } else if (id === 5) {
    return clickIcon;
  } else if (id === 6) {
    return multipleIcon;
  }
};

export const isTypeAvailable = (questionType, arr) => {
  let isAvailable = false;

  arr.forEach((q) => {
    if (q.type === questionType && q.available) {
      isAvailable = true;
    }
  });

  return isAvailable;
};

export const availableQuestions = [
  {
    name: "Open question",
    type: "open",
    id: 0,
    available: true,
    description: "Kid should answer the question with text",
  },
  {
    name: "Multiple choice",
    type: "multiple",
    id: 6,
    available: true,
    description: "Kid can select one answer among many",
  },
  {
    name: "Puzzle",
    type: "puzzle",
    id: 2,
    available: true,
    description: "Kid should complete a puzzle",
  },
  {
    name: "Drawing",
    type: "drawing",
    id: 1,
    available: true,
    description: "lorem ipsum delort mahrez",
  },
  {
    name: "Click question",
    type: "click",
    id: 5,
    available: true,
    description: "lorem ipsum delort mahrez",
  },
  { name: "7 Differences", type: "differences", id: 4, available: true },
  { name: "Linking", type: "linking", id: 3, available: false },
];

const divStyles = {
  width: "80%",

  display: "grid",
  gap: "1px",
  gridTemplateColumns: "repeat(auto-fit, minmax(210px, 1fr))",
  paddingTop: "20px",
  marginTop: "-32px",
};

const Card = ({
  title,
  content,
  callback = () => {},
  width,
  link,
  background = "#fff",
  textColor,
  icon = "",
  height,
  iconMargin = "",
  animated = true,
}) => {
  const [hover, setHover] = useState(false);
  const [styles, setStyles] = useState(cardStyles);

  useEffect(() => {
    if (hover && animated) {
      setStyles({ ...cardStyles, ...onHover });
    } else {
      setStyles(cardStyles);
    }
  }, [hover, setHover]);

  const handler = () => {
    callback(link);
  };

  return (
    <div
      style={{
        ...styles,
        width,
        height,
        background,
        color: textColor,
        marginTop: "20px",
        cursor: animated ? "pointer" : "",
      }}
      onClick={handler}
      onMouseEnter={(e) => setHover(true)}
      onMouseLeave={(e) => setHover(false)}
    >
      {icon === "" ? null : (
        <img src={icon || null} alt="image" style={{ margin: iconMargin }} />
      )}

      <p
        style={{
          fontSize: "22px",
          marginTop: "20px",
          marginBottom: "14px",
          fontWeight: 500,
          textAlign: "center",
        }}
      >
        {title}
      </p>
      <p style={{ fontSize: "12px", textAlign: "center" }}>{content}</p>
    </div>
  );
};

const cardStyles = {
  color: "#93D578",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "280px",
  paddingLeft: "22px",
  paddingRight: "22px",
  paddingBottom: "15px",
  paddingTop: "15px",
  cursor: "pointer",
  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
  background: "#000",

  borderRadius: "22px",
};
const onHover = {
  transform: "translateY(-3px) scale(1.1)",
  transition: "0.2s",
};

export default QuestionTypes;
