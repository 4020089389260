import { Box, Button, Text } from "@mantine/core";
import React, { useState, useRef, useEffect } from "react";
import FormSection from "../../../../components/layout/FormSection";
import MediaUploader from "../../../../components/MediaUploader";
import { withLanguage } from "../../../../lib/i18";
import {
  capitalize,
  checkIfSameFile,
  generateRandomId,
} from "../../../../utils/utils";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import MTKInput from "../../../../components/Input";
import MTKTextarea from "../../../../components/Textarea";
import MTKButton from "../../../../components/Button";
import GalleryUploader from "../../../../components/GalleryUploader";
import z from "zod";
import { useForm, zodResolver } from "@mantine/form";
import AudioUploader from "../../../../components/AudioUploader";
import SubmitBottomBar from "../../../../components/SubmitBottomBar";
import {
  downloadFileFromStorage,
  getFileDownloadURL,
  uploadFileToStorage,
} from "../../../../api/Storage";
import { getGameByID, updateGame } from "../../../../api/Games";
import { useParams } from "react-router-dom";
import { useModals } from "@mantine/modals";
const stringToHtml = (text) => {
  return ReactHtmlParser(documentToHtmlString(text));
};
const generateItem = () => {
  const newItem = {
    id: generateRandomId(),
    url: null,
    type: null,
  };
  return newItem;
};

function IntroTab({ pageContent }) {
  const [files, setFiles] = useState([]);
  const pdfInputRef = useRef();
  const { t } = useTranslation();
  const { gameId } = useParams();
  const modals = useModals();
  const [loading, setLoading] = useState(false);
  // original unformated game data
  const [data, setData] = useState(null);
  // defualt data for form
  const [defaultData, setDefaultData] = useState(null);

  // form init
  const schema = z.object({
    introPDFUrl: z.string().optional(),
    introDescription: z.string().optional(),
    // gameIntroMediaFiles: z.array(
    //   z.object({
    //     id: z.string(),
    //     type: z.string(),
    //     url: z.string().url(),
    //   })
    // ),

    introPDF: z.instanceof(File).or(z.literal(null)),
    introAudio: z.instanceof(File).or(z.literal(null)),
  });
  const form = useForm({
    schema: zodResolver(schema),
    initialValues: {
      introDescription: "",
      gameIntroMediaFiles: [],
      introPDF: null,
      introPDFUrl: "",
      introAudio: null,
    },
  });
  //
  const onPDFDelete = () => {
    modals.openContextModal("confirmation", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Delete PDF File"),
        modalBody: t("Are you sure to delete this PDF File?"),
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          form.setFieldValue("introPDF", null);
        },
      },
    });
  };
  useEffect(() => {
    const getData = async () => {
      const data = await getGameByID(gameId);
      let introPDF = null;
      let introAudio = null;

      if (data.introPDF && data.introPDF.id) {
        const file = await downloadFileFromStorage({
          path: `PDF/${data.introPDF.id}/pdf`,
          name: data.introPDF?.name,
          type: data.introPDF?.type,
        });
        introPDF = file;
      }

      if (data.introAudio && data.introAudio.id) {
        const file = await downloadFileFromStorage({
          path: `Audio/${data.introAudio.id}/audio`,
          name: data.introAudio?.name,
          type: data.introAudio?.type,
        });
        introAudio = file;
      }

      setDefaultData({
        ...data,
      });
      setData(data);
      form.setValues({
        ...data,
        introAudio,
        introPDF,
      });
      if (data?.gameIntroMediaFiles) {
        setFiles(data?.gameIntroMediaFiles);
      } else {
        setFiles([generateItem()]);
      }
    };
    if (gameId) {
      getData();
    }
  }, [gameId]);

  const onFormSubmit = async (values) => {
    setLoading(true);
    const {
      introPDF = null,
      introAudio = null,
      gameIntroMediaFiles,
      introDescription = "",
      introPDFUrl = "",
    } = values;

    const data = {
      introDescription,
      introPDFUrl,
    };
    if (introPDF) {
      if (
        defaultData &&
        defaultData.introPDF &&
        checkIfSameFile(introPDF, defaultData.introPDF)
      ) {
        data.introPDF = defaultData.introPDF;
      } else {
        const randomId = generateRandomId();
        const pFile = {
          id: randomId,
          type: introPDF.type,
          name: introPDF.name,
          size: introPDF.size,
        };
        data.introPDF = pFile;

        try {
          await uploadFileToStorage({
            file: introPDF,
            path: `PDF/${pFile.id}/pdf`,
          });
        } catch (error) {
          console.log("pdf upload error", error);
        }
      }
    } else {
      data.introPDF = null;
    }
    if (introAudio) {
      if (
        defaultData &&
        defaultData.introAudio &&
        checkIfSameFile(introAudio, defaultData.introAudio)
      ) {
        data.introAudio = defaultData.introAudio;
      } else {
        const randomId = generateRandomId();
        const aFile = {
          id: randomId,
          type: introAudio.type,
          name: introAudio.name,
          size: introAudio.size,
        };
        data.introAudio = aFile;

        try {
          await uploadFileToStorage({
            file: introAudio,
            path: `Audio/${aFile.id}/audio`,
          });
        } catch (error) {
          console.log("audio upload error", error);
        }
      }
    } else {
      data.introAudio = null;
    }

    // const mediaFilesOG = [...gameIntroMediaFilesOG];
    const mediaFiles = [];

    //   // Delete files if necessary
    //   for (const mediaFile of mediaFilesOG) {
    //     if (gameIntroMediaFiles.find((mf) => mf.id === mediaFile.id)) {
    //       // Do nothing
    //     } else {
    //       // Got deleted
    //       try {
    //         const storage = firebase.storage();

    //         if (mediaFile.type === 'image') {
    //           const imageRef = `Images/${mediaFile.id}/image`;
    //           const imageStorageRef = storage.ref(imageRef);
    //           await imageStorageRef.delete();

    //           const thumbRef = `Images/${mediaFile.id}/image_200x200`;
    //           let thumbStorageRef = storage.ref(thumbRef);
    //           await thumbStorageRef.delete();
    //         } else if (mediaFile.type === 'video') {
    //           const videoRef = `Videos/${mediaFile.id}/video`;
    //           let videoStorageRef = storage.ref(videoRef);
    //           await videoStorageRef.delete();
    //         }
    //       } catch (error) {
    //         console.log('Delete error', error);
    //       }
    //     }
    //   }

    // Add files if necessary
    for (const mediaFile of files) {
      let url = "";
      if (mediaFile.isNew) {
        // Got added
        if ("file" in mediaFile) {
          try {
            // setCurrentlyUploadingFile(mediaFile?.obj?.name);
            let ref;
            if (mediaFile.type === "image") {
              ref = `Images/${mediaFile.id}/image`;
            } else if (mediaFile.type === "video") {
              ref = `Videos/${mediaFile.id}/video`;
            }

            if (ref) {
              await uploadFileToStorage({
                file: mediaFile.file,
                path: ref,
              });
              url = await getFileDownloadURL({ path: ref });
            }
          } catch (error) {
            console.log("Upload error", error);
          }
          // setCurrentlyUploadingFile(undefined);
        }
      } else {
        url = mediaFile.url;
      }

      if (url) {
        mediaFiles.push({
          id: mediaFile.id,
          type: mediaFile.type,
          isYoutube: !!mediaFile.isYoutube,
          url,
        });
      }
    }
    data.gameIntroMediaFiles = mediaFiles;
    await updateGame(gameId, data);
    modals.openContextModal("success", {
      // title: "Test modal from context",
      centered: true,
      radius: "lg",
      innerProps: {
        modalBody: t("Game updated."),
        onOk: () => null,
      },
      onCancel: () => null,
    });
    setLoading(false);
  };

  const { introPDF } = form.values;
  return (
    <Box>
      <FormSection
        color="green"
        title={
          pageContent?.fields[
            `gameIntroductionTitle${capitalize(withLanguage("").substring(1))}`
          ]
        }
        description={stringToHtml(
          pageContent?.fields[
            `gameIntroductionDescription${capitalize(
              withLanguage("").substring(1)
            )}`
          ]
        )}
      >
        <GalleryUploader
          ratio={16 / 9}
          // {...form.getInputProps("coverImage")}
          files={files}
          setFiles={setFiles}
          recommendedAspectRatio="16/10 (1900*1200)"
        />

        <MTKTextarea
          label={t("Description or Message")}
          minRows={4}
          {...form.getInputProps("introDescription")}
        />
        <MTKInput
          label={`${t("URL")}`}
          labelColor="blue"
          //   color="green"
          {...form.getInputProps("introPDFUrl")}
        />
        <Text mb="md" color={"dimmed"}>
          {t("or")}
        </Text>
        <Box>
          {introPDF ? (
            <Button
              radius="xl"
              size="md"
              fullWidth
              color="red"
              onClick={onPDFDelete}
            >
              {t("Delete PDF")} {`(${form.values.introPDF?.name})`}
            </Button>
          ) : (
            <>
              <Button
                sx={{
                  background: "rgb(39, 71, 132)",
                }}
                radius="xl"
                size="md"
                fullWidth
                onClick={() => pdfInputRef.current.click()}
              >
                {form.values.introPDF?.name || t("Upload PDF")}
              </Button>
              <input
                ref={pdfInputRef}
                style={{
                  display: "none",
                }}
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    form.setFieldValue("introPDF", file);
                  }
                }}

                // {...form.getInputProps("introPDF")}
              />
            </>
          )}

          <AudioUploader
            {...form.getInputProps("introAudio")}
            color="green"
            fullWidth
            my="md"
          >
            {t("Upload Audio")}
          </AudioUploader>
        </Box>
      </FormSection>
      <SubmitBottomBar
        okText={t("Save")}
        onCreate={form.onSubmit(onFormSubmit)}
        color="green"
        // onCreate={() => {
        //   console.log(form.errors);
        // }}
        // onCancel={() => history.push(withLanguage("/courses/"))}
        loading={loading}
      />
    </Box>
  );
}

export default IntroTab;
