import { Box } from "@mantine/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MTKButton from "../../../../components/Button";
import { ReactComponent as SignUpIcon } from "../../../../assets/images/icons/box-arrow.svg";
import MTKInput from "../../../../components/Input";
import { Search } from "tabler-icons-react";
import MTKTable from "../../../../components/Table";
import { useParams } from "react-router-dom";
import {
  getReportsOfClassroom,
  getStudentsOfClassroom,
} from "../../../../api/Classrooms";
import { useState } from "react";
import { getStudentsAnswerOfGame } from "../../../../api/StudentsAnswers";

function ReportsTab({ questions, classroom, organisation }) {
  const { t } = useTranslation();
  const { courseId, gameId } = useParams();
  const [students, setStudents] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [studentsAnswer, setStudentsAnswers] = useState([]);

  useEffect(() => {
    if (classroom && classroom?.id) {
      getStudentsOfClassroom(classroom.id).then((res) => setStudents(res));
      getReportsOfClassroom(classroom.id).then((res) => setReportData(res));
    }
  }, [classroom]);

  useEffect(() => {
    if (gameId)
      getStudentsAnswerOfGame(gameId).then((res) => setStudentsAnswers(res));
  }, [gameId]);

  const data = React.useMemo(
    () =>
      students
        .map((student) => {
          const classData = reportData.find((d) => d.studentId === student.id);
          const courseData =
            classData &&
            classData?.report?.coursesWithScore.find(
              (d) => d.courseID === courseId
            );

          const gameData =
            courseData &&
            courseData?.gamesWithScore.find((d) => d.gameID === gameId);

          // const totalPercentage = calculateTotalGameReportStudentPercentage(
          //   questions,
          //   answers?.answers
          // );
          const mean = gameData?.mean * 100;

          if (!gameData || !mean || (!student?.firstName && !student?.name)) {
            return null;
          }
          return {
            name: `${student?.firstName || ""} ${student?.name || ""}`,
            total: isNaN(mean) ? "-" : mean.toFixed(0) + "%",
            questionsData: gameData?.questionsWithScore,
          };
        })
        .filter((d) => d),
    [students, courseId, gameId, reportData, questions]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "Students",
        accessor: "name", // accessor is the "key" in the data
        isSticky: true,
      },
      {
        Header: "Total",
        accessor: "total",
        // isSticky: true,
      },
      ...questions.map((question, i) => ({
        Header: question.userQuestionId,
        accessor: ({ questionsData }) => {
          const answer = questionsData?.find(
            (ans) => ans.questionID === question.id
          );
          if (answer) {
            return `${answer.points}/${answer.totalPoints}`;
          } else {
            return "-";
          }
          // return "hi";
        },
      })),
    ],
    [questions]
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <MTKButton mt="md" leftIcon={<SignUpIcon />} disabled color="green">
          {t("Sign Up")}
        </MTKButton>
        <MTKInput
          sx={{
            minWidth: "400px",
          }}
          leftIcon={<Search />}
          label={t("Search")}
          placeholder={t("Search a Student...")}
        />
      </Box>
      <Box>
        <MTKTable
          color="green"
          data={data}
          columns={columns}
          tableText={t("Students")}
          noDataText={t("No Students added yet")}
        ></MTKTable>
      </Box>
    </Box>
  );
}

export default ReportsTab;

export const calculateTotalGameReportStudentPercentage = (
  questions,
  answers,
  returnNumber = false
) => {
  if (!questions || !answers) {
    return "";
  }

  let totalPercentage = 0;
  const subPercentages = [];

  answers.map((a) => {
    const { mark, questionId } = a;
    const question = questions.find((q) => q.id === questionId);
    let subPercentage = 0;

    if (question && "points" in question) {
      if (question.type === "multiple") {
        const correctAnswerId = question?.answers?.find(
          (a) => a.isCorrectAnswer
        )?.answerId;
        if (correctAnswerId === a.questionAnswerId) {
          subPercentage = 100;
        }
      } else if (question.type === "click" || question.type === "differences") {
        const gridAnswer = question?.answers[0]?.gridAnswer;
        const studentGridAnswer = a?.gridAnswer;
        if (!gridAnswer || !studentGridAnswer) {
          subPercentage = 0;
        } else {
          if (gridAnswer.every((d) => studentGridAnswer.includes(d))) {
            subPercentage = 100;
          } else {
            subPercentage = 0;
          }
        }
      } else if (question.type === "puzzle") {
        if (a?.isCorrect) {
          subPercentage = 100;
        } else {
          subPercentage = 0;
        }
      } else {
        subPercentage = parseInt(
          (parseInt(mark) / parseInt(question.points)) * 100
        );
      }
    }

    subPercentages.push(subPercentage);
  });
  if (subPercentages.length > 0) {
    totalPercentage = subPercentages.reduce((a, b) => a + b) / questions.length;
    if (!returnNumber) {
      totalPercentage = totalPercentage.toFixed(2) + "%";
    }
  }

  return totalPercentage;
};
export const getQuestionScore = (question, answer) => {
  if (!question || !answer) {
    return "";
  }

  // let totalPercentage = 0;
  // const subPercentages = [];
  let points = 0;
  if (question.type === "multiple") {
    const correctAnswerId = question?.answers?.find(
      (a) => a.isCorrectAnswer
    )?.answerId;
    if (answer?.questionAnswerId === correctAnswerId) {
      points = question?.points;
    }
  } else if (question.type === "click" || question.type === "differences") {
    const gridAnswer = question?.answers[0]?.gridAnswer;
    const studentGridAnswer = answer?.gridAnswer;
    if (!gridAnswer || !studentGridAnswer) {
      points = 0;
    } else {
      if (gridAnswer.every((d) => studentGridAnswer.includes(d))) {
        points = 1;
      } else {
        points = 0;
      }
    }
  } else if (question.type === "puzzle") {
    if (answer?.isCorrect) {
      points = 1;
    } else {
      points = 0;
    }
  } else {
    if (answer && "mark" in answer) {
      points = answer.mark;
    }
  }

  return `${points}/${question?.points}`;
};
