import { Box, Image, Title } from "@mantine/core";
import React, { useState, useEffect } from "react";
import placeholderImage from "../../../assets/images/icons/image-green.png";
import duplicateIcon from "../../../assets/images/icons/duplicate-element.svg";
import { getFileDownloadURL } from "../../../api/Storage";
import { useHistory } from "react-router-dom";
import { withLanguage } from "../../../lib/i18";
import MTKButton from "../../../components/Button";
import { t } from "i18next";
import { ReactComponent as PlusIcon } from "../../../assets/images/icons/plus.svg";
import { ReactComponent as BookmarkIcon } from "../../../assets/images/icons/bookmark-green.svg";
import { useModals } from "@mantine/modals";
import { duplicateGame } from "../../../api/Games";

function GameCard({
  name = "",
  coverImage,
  id,
  withOrg = false,
  withClassroom = false,
  withDuplicate = false,
  addedToReport = false,
  hideAddGameToReport = false,
  removeGameFromReport,
  addGameToReport,
  courseId,
  organisationId,
  classroomId,
  onClick,
}) {
  const [coverURL, setCoverURL] = useState(null);
  const history = useHistory();
  const modals = useModals();

  useEffect(() => {
    if (coverImage && coverImage?.id) {
      getFileDownloadURL({
        path: `Images/${coverImage.id}/image`,
      }).then((url) => setCoverURL(url));
    }
  }, [coverImage]);

  const handleDuplicate = () => {
    modals.openContextModal("gameDuplication", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        courseId,
        organisationId,
        classroomId,
        onCancel: () => console.log("Cancel"),
        onConfirm: async ({ organisation, classroom, course, game }) => {
          const newGameID = await duplicateGame(id, course);

          if (newGameID) {
            history.push(withLanguage(`/courses/${course}/games`));
          }
        },
      },
    });
  };
  return (
    <Box
      sx={{
        position: "relative",
        background: "rgb(237, 247, 233)",
        borderRadius: "22px",
        padding: "18px",

        boxShadow: "rgb(0 0 0 / 7%) 0px 2px 4px 0px",
        cursor: "pointer",
        paddingRight: "15px",
        overflow: "hidden",
      }}
    >
      {hideAddGameToReport ? null : !addedToReport ? (
        <Box
          sx={{
            position: "absolute",
            right: "10px",
            top: "10px",
            zIndex: "2",
          }}
        >
          <MTKButton
            compact
            size="sm"
            color="green"
            leftIcon={<PlusIcon />}
            onClick={() => addGameToReport(id)}
          >
            {t("Add to Report")}
          </MTKButton>
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            right: "25px",
            top: "0px",
            cursor: "pointer",
            zIndex: "2",
          }}
          onClick={() => removeGameFromReport(id)}
        >
          <BookmarkIcon />
        </Box>
      )}
      {withDuplicate && (
        <Box
          onClick={handleDuplicate}
          sx={{
            position: "absolute",
            right: "10px",
            bottom: "10px",
            backgroundColor: "rgb(147, 213, 120)",
            border: "1px solid rgb(147, 213, 120)",
            color: "rgb(255, 255, 255)",
            padding: "13px 27px",
            borderRadius: "50px",
            justifyContent: "center",
            alignItems: "center",
            width: "50px",
            height: "50px",
            display: "flex",
            cursor: "pointer",
          }}
        >
          <Image fit="contain" src={duplicateIcon} width={30} height={14} />
        </Box>
      )}
      <Box
        onClick={
          onClick
            ? onClick
            : () =>
                history.push(withLanguage(`/courses/${courseId}/games/${id}`))
        }
      >
        {coverURL ? (
          <Image width="150px" height={150} radius="lg" src={coverURL} />
        ) : (
          <PlaceHolderImage />
        )}
        <Title
          mb={withOrg || withClassroom ? "50px" : 0}
          sx={{
            maxWidth: "298px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            fontSize: "20px",
            fontWeight: "bold",
            color: "rgb(147, 213, 120)",
            marginTop: "20px",
            //   paddingLeft: "18px",
            //   paddingRight: "18px",
            //   paddingBottom: "18px",
          }}
          onClick={
            onClick
              ? onClick
              : () =>
                  history.push(withLanguage(`/courses/${courseId}/games/${id}`))
          }
        >
          {name}
        </Title>

        {withOrg && withClassroom && (
          <Box
            sx={{
              marginTop: "18px",
              background: "rgb(250, 250, 250)",
              padding: "18px",
              fontSize: "12px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "rgb(147, 213, 120)",
              position: "absolute",
              width: "100%",
              right: "0",
              bottom: "0",
            }}
          >
            {withOrg} / {withClassroom}
          </Box>
        )}
      </Box>
    </Box>
  );
}
const PlaceHolderImage = () => {
  return (
    <Box
      sx={{
        width: "150px",
        height: "150px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgb(250, 250, 250)",
        borderRadius: "22px",
      }}
    >
      <Image width={54} src={placeholderImage} />
    </Box>
  );
};
export default GameCard;
