import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";

function useIsSuperAdmin() {
  const { profile } = useContext(GlobalContext);

  if (profile) {
    return profile.type === "superAdmin";
  }
  return false;
}

export default useIsSuperAdmin;
