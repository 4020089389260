import { Button, Text, Box } from "@mantine/core";
import React from "react";

function MTKButton({
  color,
  width,
  children,
  boxShadow,
  textColor,
  margin,
  text = false,

  ...props
}) {
  if (text) {
    return (
      <Button
        styles={(theme) => ({
          root: {
            backgroundColor: "transparent",
            width: width,
            boxShadow,
            borderRadius: "25px",
            height: "46px",
            fontSize: "16px",
            // color: textColor,
            margin,
            "&:hover": {
              backgroundColor: "transparent",
            },
          },

          leftIcon: {
            //   marginRight: 15,
          },
        })}
        {...props}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button
      styles={(theme) => ({
        root: {
          width: width,
          boxShadow,
          borderRadius: "25px",
          height: "46px",
          fontSize: "16px",
          margin,
          color: textColor,
        },
        filled: {
          backgroundColor: theme.colors[color],
        },
        outline: {
          backgroundColor: "white",
          borderColor: theme.colors[color],
          color: theme.colors[color],
          "&:hover": {
            backgroundColor: "white",
            borderColor: theme.colors[color],
            color: theme.colors[color],
          },
        },
        light: {
          backgroundColor: "white",
          // borderColor: theme.colors[color],
          color: theme.colors[color],
          "&:hover": {
            backgroundColor: "white",
            borderColor: theme.colors[color],
            color: theme.colors[color],
          },
        },
        leftIcon: {
          //   marginRight: 15,
        },
      })}
      {...props}
    >
      {children}
    </Button>
  );
}

export default MTKButton;
