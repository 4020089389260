import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import {
  getDocs,
  getDoc,
  collection,
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";

import { auth, db } from "../lib/firebase";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

export const login = async ({ email, password }) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signUp = async ({ email, password }) => {
  return createUserWithEmailAndPassword(auth, email, password);
};

export const createTeacher = async ({ email, password }) => {
  const result = await signUp({ email, password });
  const { user } = result;

  if (user) {
    const userRef = doc(db, `Users/${user.uid}`);

    const data = {
      createdAt: serverTimestamp(),
      email,
      type: "teacher",
    };
    await setDoc(userRef, data);
  }
};
export const logout = async () => {
  return signOut(auth);
};

export const sendResetPasswordEmail = async (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const updateUser = async ({ id, data }) => {
  const userRef = doc(db, `Users/${id}`);

  return updateDoc(userRef, { ...data });
};

export const getProfile = async (id) => {
  const userRef = doc(db, `Users/${id}`);
  const snapshot = await getDoc(userRef);

  if (snapshot.exists) {
    return { id, ...snapshot.data() };
  } else {
    console.error("No profil has been found");
  }
};
