import { Box, Image } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AudioUploader from "../../../../components/AudioUploader";
import MTKButton from "../../../../components/Button";
import MTKInput from "../../../../components/Input";
import FormSection from "../../../../components/layout/FormSection";
import MediaUploader from "../../../../components/MediaUploader";
import { withLanguage } from "../../../../lib/i18";
import { capitalize } from "../../../../utils/utils";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";
import MTKSwitch from "../../../../components/Switch";
import MTKSelect from "../../../../components/Select";

const stringToHtml = (text) => {
  return ReactHtmlParser(documentToHtmlString(text));
};

const Puzzle = ({ form, questionMeta }) => {
  const { t } = useTranslation();
  const { answers } = form.values;

  const answer = answers[0];
  const [difficulties, setDifficulties] = useState([6, 12, 20, 30, 42, 56]);
  if (!answer) return null;

  // difficulty, checked
  return (
    <FormSection
      color="green"
      layout="row"
      margin="0px 0px 40px 0px"
      title={
        questionMeta?.fields[
          `puzzleQuestionConfigurationTitle${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      }
      description={stringToHtml(
        questionMeta?.fields[
          `${"puzzle"}QuestionConfigurationDescription${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      )}
    >
      <MTKSwitch
        checked={answer.checked}
        color="green"
        label={t("Display model to the student")}
        onChange={(e) =>
          form.setFieldValue("answers", [
            { ...answer, checked: e.target.checked },
          ])
        }
        margin="10px 0px 10px 0px"
      />

      <MTKSelect
        title={"Puzzle difficulty"}
        data={difficulties.map((d) => ({
          value: "" + d,
          label: "" + d,
        }))}
        onChange={(value) =>
          form.setFieldValue("answers", [{ ...answer, difficulty: value }])
        }
        value={answer.difficulty}
      />

      <MediaUploader
        value={answer.media}
        onChange={(file) =>
          form.setFieldValue("answers", [{ ...answer, media: file }])
        }
        width={"100%"}
        ratio={16 / 10}
        recommendedAspectRatio="16/10 (1900*1200)"
      />
    </FormSection>
  );
};

export default Puzzle;
