import {
  Box,
  createStyles,
  Divider,
  Text,
  Title,
  Grid,
  Tabs,
  Button,
  SimpleGrid,
} from "@mantine/core";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import AudioUploader from "../../../components/AudioUploader";
import BackButtonCircle from "../../../components/BackButtonCircle";
import MTKInput from "../../../components/Input";
import AppLayout from "../../../components/layout/AppLayout";
import FormSection from "../../../components/layout/FormSection";
import MediaUploader from "../../../components/MediaUploader";
import MTKSelect from "../../../components/Select";
import SubmitBottomBar from "../../../components/SubmitBottomBar";
import MTKSwitch from "../../../components/Switch";
import MTKTextarea from "../../../components/Textarea";
import contentful from "../../../lib/contentful";
import { withLanguage } from "../../../lib/i18";
import {
  ageDropdownList,
  capitalize,
  getGameSubTopics,
  getGameTopics,
  getGameTopicTypes,
  getJewishSchoolYear,
  getQueryParams,
} from "../../../utils/utils";
import useGameForm from "../NewGame/useGameForm";

import MTKBreadcrumbs from "../../../components/Breadcrumbs";
import { getCourseByID } from "../../../api/Courses";
import { getClassromByID } from "../../../api/Classrooms";
import { getOrganizationByID } from "../../../api/Organisations";
import { getSchoolYearByID } from "../../../api/SchoolYear";
import { deleteGame, getGameByID, updateGame } from "../../../api/Games";
import { downloadFileFromStorage } from "../../../api/Storage";
import MTKTabs from "../../../components/Tabs";
import { useModals } from "@mantine/modals";
import AboutTab from "./components/AboutTab";
import IntroTab from "./components/IntroTab";
import QuestionsTab from "./components/QuestionsTab";
import MTKButton from "../../../components/Button";
import { ReactComponent as ExportIcon } from "../../../assets/images/icons/box-with-arrow.svg";
import {
  deleteQuestions,
  duplicateQuestions,
  getQuestionsOfGame,
  updateQuestion,
} from "../../../api/Questions";
import { generateGameExportPdf } from "../../../api/functions";
import ReportsTab from "./components/ReportsTab";

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",

    position: "sticky",
    top: 0,
    padding: "40px 0",
    paddingBottom: "0",
    zIndex: 3,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "40px",
  },
}));

function GameDetails({ type: pageTab }) {
  const { courseId, gameId } = useParams();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const modals = useModals();

  const location = useLocation();
  const queryParams = getQueryParams(location);
  const { toPreview, toReview, studentId, backToSearch } = queryParams;

  const { form, onFormSubmit, loading, setDefaultData } = useGameForm({
    courseId,
    update: true,
  });
  const [data, setData] = useState(null);
  const [course, setCourse] = useState(null);
  const [classroom, setClassroom] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [schoolYear, setSchoolYear] = useState(null);

  const [pageContent, setPageContent] = useState(null);
  const [newGameOptions, setNewGameOptions] = useState(null);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [exportPdfUrl, setExportPdfUrl] = useState(false);
  useEffect(() => {
    const getData = async () => {
      const course = await getCourseByID(courseId);
      const classroom = await getClassromByID(course.classroomId);
      const schoolYear = await getSchoolYearByID(classroom.schoolYearId);
      const organisation = await getOrganizationByID(classroom.organisationId);

      setCourse(course);
      setClassroom(classroom);
      setOrganisation(organisation);
      setSchoolYear(schoolYear);
    };
    if (courseId) {
      getData();
    }
  }, [courseId]);

  useEffect(() => {
    if (gameId) {
      getQuestionsOfGame(gameId).then((res) => setQuestions(res));
    }
  }, [gameId]);

  useEffect(() => {
    if (!questions || questions.length === 0) return;

    let questionId = "n-a";

    if (questions && questions.length > 0) {
      questionId = questions[0].id;
    }

    if (toPreview === "true") {
      // let path = `/courses/${courseId}/games/${gameId}/questions/${questions[0].id}`;
      let path = `/courses/${courseId}/games/${gameId}/questions/${questionId}`;
      if (backToSearch) {
        path = `${path}/?backToSearch=true`;
      }
      history.replace(withLanguage(path));
    } else if (toReview === "true") {
      // let path = `/courses/${courseId}/games/${gameId}/questions/${questions[0].id}/?studentId=${studentId}`;
      let path = `/courses/${courseId}/games/${gameId}/questions/${questionId}/?studentId=${studentId}`;
      if (backToSearch) {
        path = `${path}&backToSearch=true`;
      }
      history.replace(withLanguage(path));
    }
  }, [questions]);

  useEffect(() => {
    const getData = async () => {
      const data = await getGameByID(gameId);
      let audioFile = null;
      let coverImage = null;
      let ageLimitTo = null;
      let ageLimitFrom = null;

      if (data?.audioFile && data?.audioFile?.id) {
        const file = await downloadFileFromStorage({
          path: `Audio/${data.audioFile.id}/audio`,
          name: data.audioFile?.name || "audioFile",
          type: data.audioFile?.type || "audio/mpeg",
        });
        audioFile = file;
      }

      if (data?.coverImage && data?.coverImage?.id) {
        const file = await downloadFileFromStorage({
          path: `Images/${data.coverImage.id}/image`,
          name: data.coverImage?.name || "coverImage",
          type: data.coverImage?.type || "coverImage",
        });
        coverImage = file;
      }
      if (data?.ageLimit) {
        ageLimitFrom = data?.ageLimit?.from ? `${data?.ageLimit?.from}` : null;
        ageLimitTo = data?.ageLimit?.to ? `${data?.ageLimit?.to}` : null;
      }

      setDefaultData(
        {
          ...data,
          coverImage,
          audioFile,
          ageLimitFrom,
          ageLimitTo,
        },
        { ...data, id: gameId }
      );
      setData(data);
    };
    if (gameId) {
      getData();
    }
  }, [gameId]);

  useEffect(() => {
    contentful("gamesItemsDescription").then((data) => {
      setPageContent(data.items[0]);
    });
    contentful("newGameOptions").then((data) => {
      setNewGameOptions(data.items);
    });
  }, []);
  const handleBulkDelete = () => {
    modals.openContextModal("confirmation", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Delete Question"),
        modalBody: t("Are you sure you want to delete this Question?"),
        onCancel: () => console.log("Cancel"),
        onConfirm: async () => {
          await deleteQuestions(selectedQuestions, gameId);
          setQuestions((q) =>
            q.filter((d) => !selectedQuestions.includes(d.id))
          );
          setSelectedQuestions([]);
        },
      },
    });
  };
  const [activeTab, setActiveTab] = useState(
    ["about", "intro", "questions", "reports"].indexOf(pageTab)
  );
  const [activeType, setActiveType] = useState(pageTab);

  const onChange = (active, tabKey) => {
    setActiveTab(active);
    setActiveType(tabKey);
    history.push(
      withLanguage(`/courses/${courseId}/games/${gameId}/${tabKey}`)
    );
  };

  const exportPdf = async () => {
    setExportInProgress(true);
    try {
      const url = await generateGameExportPdf(gameId);
      setExportInProgress(false);
      setExportPdfUrl(url);
    } catch (error) {
      setExportInProgress(false);
    }
  };
  const handleBulkDuplicate = () => {
    modals.openContextModal("questionDuplication", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        courseId,
        organisationId: organisation.id,
        classroomId: classroom.id,
        onCancel: () => console.log("Cancel"),
        onConfirm: async ({ organisation, classroom, course, game }) => {
          const newQuestions = await duplicateQuestions(
            selectedQuestions,
            gameId,
            {
              newGameId: game,
            }
          );
          if (gameId === gameId) {
            setQuestions((qs) => [...qs, ...newQuestions]);
          }
          if (newQuestions) {
            history.push(
              withLanguage(`/courses/${course}/games/${game}/questions`)
            );
          }
        },
      },
    });
  };
  const handleDelete = () => {
    modals.openContextModal("confirmation", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Delete Game"),
        modalBody: t("Are you sure you want to delete this Game?"),
        onCancel: () => console.log("Cancel"),
        onConfirm: async () => {
          await deleteGame(gameId, courseId);
          history.push(withLanguage(`/courses/${courseId}/games`));
        },
      },
    });
  };
  const saveQuestionsOrder = async (questions) => {
    const questionsIds = questions.map((c) => c.id);

    const data = {
      questionsOrder: questionsIds,
    };

    try {
      await updateGame(gameId, data);
      setData((d) => ({ ...d, questionsOrder: questionsIds }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleOrder = () => {
    modals.openContextModal("order", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        sortedItems: orderedQuestions,
        saveNewOrder: saveQuestionsOrder,
        title: t("Order Questions"),
        isQuestion: true,
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          // setMediaUrl(null);
          // onChange(null);
        },
      },
    });
  };
  useEffect(() => {
    // console.log(type, activeType);
    if (pageTab !== activeType) {
      setActiveType(pageTab);
    }
  }, [pageTab, activeType]);
  const { name, gameIsAvailableForKids } = form.values;
  const { questionsOrder = [] } = data || {};

  const orderedQuestions = useMemo(() => {
    if (questionsOrder && Array.isArray(questionsOrder)) {
      const ordered = [
        ...questionsOrder
          .map((d) => questions.find((question) => question.id === d))
          .filter((d) => d),
        ...questions.filter((d) => !questionsOrder.includes(d.id)),
      ];
      return ordered;
    } else {
      return questions;
    }
  }, [questionsOrder, questions]);

  return (
    <AppLayout title={name} loading={!data}>
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <Box className={classes.header}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
            mb="10px"
          >
            <Box
              mb="md"
              sx={{
                minWidth: "350px",
              }}
            >
              <MTKBreadcrumbs
                data={[
                  {
                    title: organisation?.name,
                    url: `/organisations/${organisation?.id}`,
                  },
                  {
                    title: classroom?.name,
                    url: `/classrooms/${classroom?.id}`,
                  },
                  {
                    title: course?.name,
                    url: `/courses/${course?.id}`,
                  },
                ]}
              />
              <Box
                mb="md"
                mt="xs"
                sx={{
                  display: "flex",
                }}
              >
                <BackButtonCircle
                  color="green"
                  mr="md"
                  onClick={() =>
                    history.push(withLanguage(`/courses/${courseId}/games`))
                  }
                />
                <Title order={3}>{name}</Title>
                <Box
                  sx={{
                    fontSize: "14px",

                    border: gameIsAvailableForKids
                      ? "1px solid rgb(147, 213, 120)"
                      : "1px solid rgb(255, 70, 70)",
                    color: gameIsAvailableForKids
                      ? "rgb(147, 213, 120)"
                      : "rgb(255, 70, 70)",
                    padding: "3px 10px",
                    borderRadius: "8px",
                    marginLeft: "15px",
                    cursor: "pointer",
                  }}
                >
                  {gameIsAvailableForKids ? t("Published") : t("Unpublished")}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  background: "rgb(254, 215, 0)",
                  color: "rgb(255, 255, 255)",
                  fontSize: "12px",
                  padding: "5px",
                  display: "block",
                  borderRadius: "8px",
                }}
              >
                {t("School Year")}
                {getJewishSchoolYear(schoolYear?.key)}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                overflowX: "auto",
              }}
            >
              <MTKTabs color="green" active={activeTab} onTabChange={onChange}>
                <Tabs.Tab label={t("Settings")} tabKey={"about"}></Tabs.Tab>
                <Tabs.Tab label={t("Introduction")} tabKey={"intro"}></Tabs.Tab>
                <Tabs.Tab
                  label={t("Questions")}
                  tabKey={"questions"}
                ></Tabs.Tab>
                <Tabs.Tab label={t("Reports")} tabKey={"reports"}></Tabs.Tab>
              </MTKTabs>
            </Box>
            {pageTab === "questions" && (
              <SimpleGrid
                cols={4}
                ml="auto"
                mt="25px"
                breakpoints={[
                  { maxWidth: "md", cols: 4, spacing: "md" },
                  { maxWidth: "sm", cols: 2, spacing: "sm" },
                  { maxWidth: "xs", cols: 2, spacing: "sm" },
                ]}
              >
                <MTKButton
                  loading={exportInProgress}
                  color="green"
                  leftIcon={<ExportIcon />}
                  sx={{
                    width: "100%",
                  }}
                  onClick={() => {
                    if (exportPdfUrl) {
                      window.open(exportPdfUrl, "_blank");
                    } else {
                      exportPdf();
                    }
                  }}
                >
                  {exportPdfUrl ? t("Download Export PDF") : t("Export")}
                </MTKButton>
                <MTKButton
                  sx={{
                    width: "100%",
                  }}
                  color="green"
                  onClick={handleOrder}
                >
                  {t("Order Questions")}
                </MTKButton>
                <MTKButton
                  sx={{
                    width: "100%",
                  }}
                  color="green"
                  disabled={selectedQuestions.length === 0}
                  onClick={handleBulkDuplicate}
                >
                  {t("Duplicate")}
                </MTKButton>
                <MTKButton
                  sx={{
                    width: "100%",
                  }}
                  color="red"
                  disabled={selectedQuestions.length === 0}
                  onClick={handleBulkDelete}
                >
                  {t("Delete")}
                </MTKButton>
              </SimpleGrid>
            )}
          </Box>
        </Box>

        {pageTab === "about" && (
          <AboutTab
            pageContent={pageContent}
            form={form}
            onDelete={handleDelete}
            organisation={organisation}
          />
        )}
        {pageTab === "intro" && (
          <IntroTab pageContent={pageContent} form={form} data={data} />
        )}
        {pageTab === "questions" && (
          <QuestionsTab
            pageContent={pageContent}
            form={form}
            data={data}
            selectedQuestions={selectedQuestions}
            setSelectedQuestions={setSelectedQuestions}
            classroom={classroom}
            organisation={organisation}
            questions={orderedQuestions}
            setQuestions={setQuestions}
          />
        )}
        {pageTab === "reports" && (
          <ReportsTab
            classroom={classroom}
            organisation={organisation}
            questions={orderedQuestions}
          />
        )}

        {pageTab === "about" && (
          <SubmitBottomBar
            color="green"
            onCreate={form.onSubmit(onFormSubmit)}
            okText={t("Save")}
            // onCreate={() => console.log(form.errors)}
            onCancel={() => history.push(withLanguage("/courses/"))}
            loading={loading}
          />
        )}
      </Box>
    </AppLayout>
  );
}

export default GameDetails;
