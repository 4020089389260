import { Tabs } from "@mantine/core";
import React from "react";

function MTKTabs({ color = "blue", ...props }) {
  return (
    <Tabs
      variant="unstyled"
      position="center"
      styles={(theme) => ({
        tabsListWrapper: {
          border: "1px solid rgb(239, 239, 239)",
          borderRadius: "25px",
          boxShadow:
            "rgb(0 0 0 / 10%) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px",
          display: "flex",
          width: "max-content",
        },
        tabControl: {
          backgroundColor:
            theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.white,
          color: theme.colors[color],

          fontSize: "16px",
          padding: `22px 20px`,
          borderRadius: "25px",

          textAlign: "center",
          // padding: 22px 20px;
          // font-size: 16px;
          // margin-right: 10px;
          // height: 34px;
          // color: rgb(1, 158, 211),
          // background: rgb(250, 253, 254);
          // cursor: pointer;
          // border-radius: 22px;
          // border-bottom: 2px solid rgb(255, 255, 255);
        },

        tabActive: {
          backgroundColor: theme.colors[color],
          borderColor: theme.colors[color],
          color: theme.white,
          borderRadius: "25px",
          fontWeight: "bold",
        },
        tabLabel: {
          fontFamily: `"Roboto",-apple-system,BlinkMacSystemFont,"Segoe UI","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;`,
        },
      })}
      {...props}
    />
  );
}

export default MTKTabs;
