import { Box, createStyles, Divider, Text, Title } from "@mantine/core";
import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory, useParams } from "react-router-dom";
import AudioUploader from "../../../components/AudioUploader";
import BackButtonCircle from "../../../components/BackButtonCircle";
import MTKInput from "../../../components/Input";
import AppLayout from "../../../components/layout/AppLayout";
import FormSection from "../../../components/layout/FormSection";
import MediaUploader from "../../../components/MediaUploader";
import MTKSelect from "../../../components/Select";
import SubmitBottomBar from "../../../components/SubmitBottomBar";
import MTKSwitch from "../../../components/Switch";
import MTKTextarea from "../../../components/Textarea";
import contentful from "../../../lib/contentful";
import { withLanguage } from "../../../lib/i18";
import {
  ageDropdownList,
  capitalize,
  getGameSubTopics,
  getGameTopics,
  getGameTopicTypes,
  getJewishSchoolYear,
  getPricingCategories,
} from "../../../utils/utils";
import useGameForm from "./useGameForm";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";
import MTKButton from "../../../components/Button";
import { ReactComponent as PlusIcon } from "../../../assets/images/icons/plus.svg";
import MTKBreadcrumbs from "../../../components/Breadcrumbs";
import { getCourseByID } from "../../../api/Courses";
import { getClassromByID } from "../../../api/Classrooms";
import { getOrganizationByID } from "../../../api/Organisations";
import { getSchoolYearByID } from "../../../api/SchoolYear";
import MTKCard from "../../../components/layout/Card";
import lampIcon from "../../../assets/images/icons/lamp.svg";
import BrowseIcon from "../../../assets/images/icons/browse_game.svg";
import useIsSuperAdmin from "../../../hooks/useIsSuperAdmin";
const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",

    position: "sticky",
    top: 0,
    padding: "50px 0",
    zIndex: 2,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "10px",
  },
}));
const stringToHtml = (text) => {
  return ReactHtmlParser(documentToHtmlString(text));
};
function NewGame() {
  const { courseId } = useParams();
  const { classes } = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [showNewGameForm, setShowNewGameForm] = useState(false);
  const { form, onFormSubmit, loading } = useGameForm({ courseId });

  const [course, setCourse] = useState(null);
  const [classroom, setClassroom] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [schoolYear, setSchoolYear] = useState(null);

  const [pageContent, setPageContent] = useState(null);
  const [newGameOptions, setNewGameOptions] = useState(null);
  const isAdmin = useIsSuperAdmin();
  useEffect(() => {
    const getData = async () => {
      const course = await getCourseByID(courseId);
      const classroom = await getClassromByID(course.classroomId);
      const schoolYear = await getSchoolYearByID(classroom.schoolYearId);
      const organisation = await getOrganizationByID(classroom.organisationId);

      setCourse(course);
      setClassroom(classroom);
      setOrganisation(organisation);
      setSchoolYear(schoolYear);
    };
    if (courseId) {
      getData();
    }
  }, [courseId]);

  useEffect(() => {
    contentful("gamesItemsDescription").then((data) => {
      setPageContent(data.items[0]);
    });
    contentful("newGameOptions").then((data) => {
      setNewGameOptions(data.items);
    });
  }, []);
  const { ageLimitFrom, ageLimitTo, gameTopicType, gameTopic } = form.values;

  const gameTopics = useMemo(
    () => getGameTopics(gameTopicType),
    [gameTopicType]
  );
  const gameSubTopic = useMemo(
    () => getGameSubTopics(gameTopicType, gameTopic),
    [gameTopicType, gameTopic]
  );

  return (
    <AppLayout>
      <Box className={classes.header}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
          mb="20px"
        >
          <Box
            mb="md"
            sx={{
              minWidth: "350px",
            }}
          >
            <MTKBreadcrumbs
              data={[
                {
                  title: organisation?.name,
                  url: `/organisations/${organisation?.id}`,
                },
                {
                  title: classroom?.name,
                  url: `/classrooms/${classroom?.id}`,
                },
                {
                  title: course?.name,
                  url: `/courses/${course?.id}`,
                },
              ]}
            />
            <Box
              mb="md"
              sx={{
                display: "flex",
              }}
            >
              <BackButtonCircle mr="md" onClick={() => history.goBack()} />
              <Title order={3}>{t("New game")}</Title>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                background: "rgb(254, 215, 0)",
                color: "rgb(255, 255, 255)",
                fontSize: "12px",
                padding: "5px",
                display: "block",
                borderRadius: "8px",
              }}
            >
              {t("School Year")}
              {getJewishSchoolYear(schoolYear?.key)}
            </Box>
          </Box>
        </Box>
      </Box>
      {showNewGameForm ? (
        <>
          {" "}
          <FormSection
            color="green"
            title={
              pageContent?.fields[
                `gameInformationTitle${capitalize(
                  withLanguage("").substring(1)
                )}`
              ]
            }
            description={stringToHtml(
              pageContent?.fields[
                `gameInformationDescription${capitalize(
                  withLanguage("").substring(1)
                )}`
              ]
            )}
          >
            <MediaUploader
              ratio={1}
              {...form.getInputProps("coverImage")}
              recommendedAspectRatio="1/1 (512*512)"
            />
            <MTKInput
              label={`${t("Name")}`}
              required
              labelColor="blue"
              //   color="green"
              {...form.getInputProps("name")}
            />
            <AudioUploader color="green" {...form.getInputProps("audioFile")} />

            <MTKTextarea
              label={t("Description")}
              minRows={4}
              {...form.getInputProps("description")}
            />
            <MTKSelect
              data={ageDropdownList.map((d) => ({
                label: d || "",
                value: `${d || ""}`,
              }))}
              onChange={(value) => {
                if (!ageLimitTo) {
                  form.setFieldValue("ageLimitTo", "12");
                }
                form.setFieldValue("ageLimitFrom", value);
              }}
              value={`${ageLimitFrom}`}
              label={t("Age From")}
            />
            <MTKSelect
              data={ageDropdownList
                .filter((d) =>
                  ageLimitFrom && +ageLimitFrom > d ? false : true
                )
                .map((d) => ({ value: `${d || ""}`, label: d || "" }))}
              label={t("Age To")}
              {...form.getInputProps("ageLimitTo")}
              value={`${ageLimitTo}`}
            />
          </FormSection>
          {isAdmin && organisation?.type === "home" && (
            <FormSection
              id="pricing"
              color="green"
              layout="row"
              title={t("Pricing")}
              mt="40px"
            >
              <MTKSelect
                label={`${t("Category")}`}
                data={getPricingCategories()}
                {...form.getInputProps("pricingCategory")}
                // value={pricingCategory}
                // onChange={(option) => {
                //   changePricingCategory(option);
                // }}
              />
            </FormSection>
          )}
          <FormSection
            mt="40px"
            mb="60px"
            color="green"
            title={
              pageContent?.fields[
                `availabilityTitle${capitalize(withLanguage("").substring(1))}`
              ]
            }
            description={stringToHtml(
              pageContent?.fields[
                `availabilityDescription${capitalize(
                  withLanguage("").substring(1)
                )}`
              ]
            )}
          >
            <MTKSwitch
              label={`${t("Game is available for kids")}`}
              required
              labelColor="blue"
              color="green"
              size={"md"}
              {...form.getInputProps("gameIsAvailableForKids", {
                type: "checkbox",
              })}
            />
          </FormSection>
          <Divider />
          <FormSection
            mt="40px"
            mb="60px"
            color="green"
            title={
              pageContent?.fields[
                `topicsTitle${capitalize(withLanguage("").substring(1))}`
              ]
            }
            description={
              <>
                {stringToHtml(
                  pageContent?.fields[
                    `topicsDescription${capitalize(
                      withLanguage("").substring(1)
                    )}`
                  ]
                )}
                <Text
                  underline
                  color="#bbbbbb"
                  to={withLanguage("/contact")}
                  display="inline"
                  component={Link}
                >
                  {t("Contact Form")}
                </Text>
                <div style={{ marginTop: 20 }}>
                  <MTKButton
                    color="green"
                    leftIcon={<PlusIcon />}
                    onClick={() => {
                      window.open(withLanguage(`/contact`), "_blank");
                    }}
                  >
                    {t("New Topic")}
                  </MTKButton>
                </div>
              </>
            }
          >
            <MTKSelect
              label={`${t("Type")}`}
              required
              data={getGameTopicTypes()}
              {...form.getInputProps("gameTopicType")}
            />

            <MTKSelect
              label={`${t("Topic")}`}
              data={gameTopics}
              {...form.getInputProps("gameTopic")}
              disabled={!gameTopicType}
            />

            <MTKSelect
              label={`${t("Sub-Topic")}`}
              data={gameSubTopic}
              {...form.getInputProps("gameSubTopic")}
              disabled={!gameTopic}
            />
          </FormSection>
          <SubmitBottomBar
            color="green"
            onCreate={form.onSubmit(onFormSubmit)}
            // onCreate={() => console.log(form.errors)}
            onCancel={() => history.push(withLanguage("/courses/"))}
            loading={loading}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "40px",
            flexDirection: "row",
            "@media (max-width: 800px)": {
              flexDirection: "column",
            },
          }}
        >
          <MTKCard
            title={
              newGameOptions?.[0]?.fields[
                `title${capitalize(withLanguage("").substring(1))}`
              ]
            }
            background="#93D578"
            textColor="white"
            content={
              newGameOptions?.[0]?.fields[
                `description${capitalize(withLanguage("").substring(1))}`
              ]
            }
            icon={lampIcon}
            callback={() => setShowNewGameForm(true)}
            link={true}
            height={"248px"}
            width={"250px"}
            iconMargin={"0px 15px 0px 0px"}
          />

          <p
            style={{
              margin: "45px",
              color: "#93D578",
              fontSize: "20px",
            }}
          >
            or
          </p>
          <MTKCard
            height={"248px"}
            width={"250px"}
            title={
              newGameOptions?.[1]?.fields[
                `title${capitalize(withLanguage("").substring(1))}`
              ]
            }
            content={
              newGameOptions?.[1]?.fields[
                `description${capitalize(withLanguage("").substring(1))}`
              ]
            }
            icon={BrowseIcon}
            callback={(link) => {
              history.push(`${withLanguage("")}${link}`);
            }}
            link={"/find-courses-and-games"}
            textColor={"#93D578"}
          />
        </Box>
      )}
    </AppLayout>
  );
}

export default NewGame;
