import React, { useRef, useState, useEffect } from "react";
import { Stack } from "@mantine/core";
import { useTranslation } from "react-i18next";
import MTKButton from "../../Button";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
const PlayAudioFile = ({
  file = null,
  setStep,
  onChange,
  widthDelete = false,
  setOpened,
  setIsNewFile,
}) => {
  const selectFileRef = useRef();
  const { t } = useTranslation();
  const [audioUrl, setAudioUrl] = useState("");
  useEffect(() => {
    if (file) {
      const audioUrl = URL.createObjectURL(file);
      setAudioUrl(audioUrl);
    }
  }, [file]);

  return (
    <Stack align={"center"}>
      <AudioPlayer
        autoPlay
        src={audioUrl}
        showJumpControls={false}
        // onPlay={(e) => console.log("onPlay")}
        // onCanPlay={() => console.log("canPlay")}
        // onSeeking={() => console.log("onSeeking")}
        // onSeeked={() => console.log("onSeeked")}
        // other props here
      />
      <MTKButton
        radius={"lg"}
        color="blue"
        fullWidth
        mx="lg"
        mt="lg"
        onClick={() => {
          onChange(null);
          setStep("initial");
        }}
        // leftIcon={<Image width={15} src={selectImage} />}
      >
        {t("Replace recording")}
      </MTKButton>
      {widthDelete && (
        <MTKButton
          radius={"lg"}
          color="red"
          fullWidth
          mx="lg"
          mb="md"
          onClick={() => {
            onChange(null);
            setStep("initial");
          }}
        >
          {t("Delete")}
        </MTKButton>
      )}
      <MTKButton
        onClick={() => {
          setOpened(false);
          setIsNewFile(false);
        }}
        radius={"lg"}
        color="green"
        fullWidth
        mx="lg"
        mb="md"
      >
        {t("Ok")}
      </MTKButton>

      <MTKButton
        onClick={() => {
          if (widthDelete) {
            setOpened(false);
          } else {
            onChange(null);
            setOpened(false);
          }
        }}
        variant="light"
        color="gray"
      >
        {t("Cancel")}
      </MTKButton>
    </Stack>
  );
};

export default PlayAudioFile;
