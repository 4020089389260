import { Box, createStyles, Image, Menu, Text } from "@mantine/core";
import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import UserIcon from "../../../assets/images/icons/user.svg";
import { withLanguage } from "../../../lib/i18";

const useStyles = createStyles({
  container: {
    background: "rgb(239, 247, 255)",
    padding: "19px 17px",
    borderRadius: "22px",
    minWidth: "400px",
  },
  topPart: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
    color: "rgb(1, 158, 211)",
  },
  teacherName: {
    fontSize: "22px",
    fontWeight: "bold",
    marginBottom: "10px",
    maxWidth: "200px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  avatar: {
    width: "100px",
    height: "100px",
    borderRadius: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgb(250, 250, 250)",
    marginRight: "20px",
  },
  dotContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  dot: {
    backgroundColor: "rgb(39, 71, 132)",
    width: "10px",
    height: "10px",
    borderRadius: "7px",
    marginLeft: "5px",
  },
});
function TeacherCard({
  firstName = "",
  name = "",
  email = "",
  phoneNumber,
  courses = [],
  onRemoveTeacher,
  id,
}) {
  const { classes } = useStyles();
  return (
    <Box className={classes.container} my="lg">
      <Box className={classes.topPart}>
        <Box className={classes.avatarContainer}>
          <Box className={classes.avatar}>
            <Image src={UserIcon} width={48} height={48} />
          </Box>
          <Box>
            <Text className={classes.teacherName}>
              {firstName} {name}
            </Text>
            <Text>{email}</Text>
            <Text>{phoneNumber}</Text>
          </Box>
        </Box>
        <Dots
          id={id}
          phoneNumber={phoneNumber}
          email={email}
          onRemoveTeacher={onRemoveTeacher}
        />
      </Box>
      <Box>
        <Text color={"rgb(197, 197, 197)"} mb="10px" weight={"bold"}>
          {t("Courses")}
        </Text>
        <Box
          sx={{
            display: "flex",
          }}
        >
          {courses.map((d) => (
            <Badge title={d.name} />
          ))}
        </Box>
      </Box>
    </Box>
  );
}

const Dots = ({ phoneNumber = "", email, onRemoveTeacher, id }) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Menu
      //   trigger="hover"
      control={
        <Box className={classes.dotContainer}>
          <Box className={classes.dot} />
          <Box className={classes.dot} />
          <Box className={classes.dot} />
        </Box>
      }
    >
      <Menu.Item
        onClick={() => {
          window.location.href = `tel:${phoneNumber}`;
        }}
      >
        {t("Dial phone number")}
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          window.location.href = `mailto:${email}`;
        }}
      >
        {t("Write Email")}
      </Menu.Item>
      <Menu.Item color="red" onClick={() => onRemoveTeacher(id)}>
        {t("Remove Teacher")}
      </Menu.Item>
    </Menu>
  );
};

const Badge = ({ title = "", url = "" }) => {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(250, 250, 250)",
        padding: "10px",
        borderRadius: "8px",
        color: "rgb(1, 158, 211)",
        marginRight: "10px",
        marginBottom: "10px",
        textDecoration: "none",
      }}
      component={Link}
      to={withLanguage(url)}
    >
      {title}
    </Box>
  );
};
export default TeacherCard;
