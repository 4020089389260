import React, { useEffect, useRef, useState } from "react";
import { Dropzone, DropzoneStatus, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import {
  createStyles,
  Box,
  AspectRatio,
  Image,
  Center,
  ActionIcon,
  Button,
  Menu,
  LoadingOverlay,
  DEFAULT_THEME,
} from "@mantine/core";
import imageIcon from "../../assets/images/icons/image.png";
import MTKButton from "../Button";

import imageIconGreen from "../../assets/images/icons/image-green.png";
import { ReactComponent as PenIcon } from "../../assets/images/icons/pen-new.svg";
import { ReactComponent as TrashCanIcon } from "../../assets/images/icons/trash-can-new.svg";
import { ReactComponent as DownloadIcon } from "../../assets/images/icons/Oval 3.svg";
import { ReactComponent as DownloadIconGreen } from "../../assets/images/icons/Oval 4.svg";

import { useTranslation } from "react-i18next";
import { getFileType } from "../../utils/utils";
import { useModals } from "@mantine/modals";

const useStyles = createStyles((theme, _params, getRef) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    border: "1px dashed #019ed3",
    borderRadius: "22px",
    // padding: "50px",
    // cursor: "pointer",
    maxWidth: "500px",
    overflow: "hidden",
    "&:focus": {
      outline: "none !important",
    },
  },
}));

function MediaUploader({
  disabled = false,
  color = "blue",
  objectDescription = undefined,
  recommendedAspectRatio,
  ratio = 1,
  value: file,
  url,
  onChange,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [originalMediaUrl, setOriginalMediaUrl] = useState(null);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editStateActive, setEditStateActive] = useState(false);
  const modals = useModals();

  const dropzoneRef = useRef();
  const openRef = useRef();
  const open = () => openRef.current();

  const editStateRef = useRef();
  const onDrop = (files) => {
    if (files && files[0]) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      const fileType = getFileType(file);
      setMediaUrl(url);
      setFileType(fileType);
      // setFile(file);
      onChange(file);
    }
  };

  useEffect(() => {
    if (file && (file instanceof File || file instanceof Blob)) {
      const url = URL.createObjectURL(file);
      const fileType = getFileType(file);
      setMediaUrl(url);
      setFileType(fileType);
    }
  }, [file]);

  const downloadItem = () => {
    setLoading(true);
    fetch(mediaUrl)
      .then((res) => res.blob())
      .then((res) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const url = window.URL.createObjectURL(res);
        a.href = url;

        a.download = fileType === "image" ? "image.jpeg" : "video.mp4";
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
        setLoading(false);
      });
  };

  const editMedia = () => {
    setOriginalMediaUrl(mediaUrl);
    setMediaUrl(null);
    onChange(null);
    editStateRef.current = true;
    setTimeout(() => {
      open();
    }, 200);
  };

  const deleteMedia = () => {
    modals.openContextModal("confirmation", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Delete Media File"),
        modalBody: t("Are you sure to delete the media file?"),
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          setMediaUrl(null);
          onChange(null);
        },
      },
    });
  };
  return (
    <Box>
      <Box className={classes.container}>
        <AspectRatio ratio={ratio} sx={{ width: "100%" }}>
          <LoadingOverlay visible={loading} loader={customLoader} />

          {mediaUrl ? (
            <Box
              sx={{
                position: "relative",
              }}
            >
              {fileType === "video" && (
                <video
                  controls
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  styles={{
                    imageWrapper: {
                      width: "100%",
                      height: "100%",
                    },
                    figure: {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                  src={mediaUrl}
                  width={"100%"}
                  height={"100%"}
                />
              )}
              {fileType === "image" && (
                <Image
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                  styles={{
                    imageWrapper: {
                      width: "100%",
                      height: "100%",
                    },
                    figure: {
                      width: "100%",
                      height: "100%",
                    },
                  }}
                  src={mediaUrl}
                  width={"100%"}
                  height={"100%"}
                />
              )}
              <MediaControls
                floating={fileType === "image"}
                color={color}
                downloadItem={downloadItem}
                editMedia={editMedia}
                deleteMedia={deleteMedia}
              />
            </Box>
          ) : (
            <MTKDropzone
              dropzoneRef={dropzoneRef}
              openRef={openRef}
              onDrop={onDrop}
              color={color}
              disabled={disabled}
              objectDescription={objectDescription}
            />
          )}
        </AspectRatio>
      </Box>

      {recommendedAspectRatio && (
        <Box
          sx={{
            color: "#ababab",
            /* font-style: italic, */
            textAlign: "left",
            fontSize: "12px",
            marginTop: "15px",
            marginBottom: "30px",
          }}
        >
          {`${t("Recommended aspect ratio")}: ${recommendedAspectRatio}`}
        </Box>
      )}
    </Box>
  );
}

const MTKDropzone = ({
  onDrop,
  color,
  disabled,
  objectDescription,
  openRef,
  dropzoneRef,
}) => {
  const { t } = useTranslation();
  return (
    <Dropzone
      ref={dropzoneRef}
      openRef={openRef}
      multiple={false}
      onDrop={onDrop}
      onReject={(files) => console.log("rejected files", files)}
      styles={{
        root: {
          background: "transparent",
          border: "none",
          maxWidth: "500px",
          "&:hover": {
            background: "transparent",
            border: "none",
          },
        },
      }}
    >
      {(status) => (
        <Box
          onClick={() => {}}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            src={color === "green" ? imageIconGreen : imageIcon}
            width={40}
          />
          {!disabled && (
            <Box
              sx={{
                textAlign: "center",
                fontSize: "12px",
                color: "#019ed3",
                fontWeight: "500",
                marginTop: "15px",
              }}
            >
              {status.accepted ? (
                <Box
                  sx={{
                    height: "32px",
                    fontSize: "12px",
                  }}
                >
                  {t("Drop the file here")}
                </Box>
              ) : (
                <Box
                  sx={{
                    height: "32px",
                    fontSize: "12px",
                  }}
                >
                  <span>
                    {t("Drag and Drop or")} <u>{t("select")}</u>
                  </span>{" "}
                  {objectDescription ? t(objectDescription) : t("an image")}
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
    </Dropzone>
  );
};

const MediaControls = ({
  color,
  downloadItem,
  editMedia,
  deleteMedia,
  floating = true,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: floating ? "10px" : "none",
        top: !floating ? "10px" : "none",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={downloadItem}
      >
        {color === "green" ? <DownloadIconGreen /> : <DownloadIcon />}
      </Box>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Menu
          //   opened={true}
          //   trigger="hover"
          control={
            <Box
              px="md"
              sx={(theme) => ({
                background: theme.colors.blue,
                // width: "56px",
                height: "30px",
                textAlign: "center",
                borderRadius: "24px",
                color: "white",
                lineHeight: "30px",
              })}
            >
              {t("Edit")}
            </Box>
          }
        >
          <Menu.Item icon={<PenIcon />} onClick={editMedia}>
            {t("Edit")}
          </Menu.Item>
          <Menu.Item icon={<TrashCanIcon />} onClick={deleteMedia}>
            {t("Delete")}
          </Menu.Item>
        </Menu>
      </Box>
    </Box>
  );
};
const customLoader = (
  <svg
    width="54"
    height="54"
    viewBox="0 0 38 38"
    xmlns="http://www.w3.org/2000/svg"
    stroke={DEFAULT_THEME.colors.blue[6]}
  >
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)" strokeWidth="2">
        <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
);
export default MediaUploader;
