import React, { useState } from "react";
import { Text, Button, Image, Box, Center, Title } from "@mantine/core";

import { useTranslation } from "react-i18next";
import MTKButton from "../Button";

function EditOfflineGameModal({ context, id, innerProps }) {
  const { t } = useTranslation();

  return (
    <Box>
      <Title order={3} my="md" align="center">
        {t("Edit your offline game")}
      </Title>
      <Text size="sm" mt="md">
        {t("This is an offline game that has been added manually.")}
      </Text>
      <Text size="sm">
        {t("You can add or edit marks or edit the game total.")}
      </Text>
      <Text size="sm">{t("What do you want to do.")}</Text>

      <Center
        px="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        my="lg"
        mt="xl"
      >
        <MTKButton
          variant="subtle"
          sx={{
            color: "rgb(1, 158, 211)",
          }}
          text
          color="black"
          onClick={() => {
            innerProps.onEditGameTotal();
            // context.closeModal(id);
          }}
        >
          {t("Edit Game Total")}
        </MTKButton>
        <MTKButton
          fullWidth
          color="blue"
          my="md"
          onClick={async () => {
            await innerProps.onEditMarks();
            context.closeModal(id);
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Add or Edit marks")}
        </MTKButton>
        <MTKButton
          fullWidth
          color="red"
          my="md"
          onClick={async () => {
            await innerProps.onDelete();
            context.closeModal(id);
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Delete Offline Game")}
        </MTKButton>
        <MTKButton
          variant="subtle"
          sx={{
            color: "rgb(1, 158, 211)",
          }}
          text
          fullWidth
          color="black"
          onClick={() => {
            innerProps.onCancel();
            context.closeModal(id);
          }}
        >
          {t("Back")}
        </MTKButton>
      </Center>
    </Box>
  );
}

export default EditOfflineGameModal;
