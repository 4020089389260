import { Box, Button, Grid, Title } from "@mantine/core";
import { useModals } from "@mantine/modals";
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AudioUploader from "../../../../components/AudioUploader";
import MTKInput from "../../../../components/Input";
import MediaUploader from "../../../../components/MediaUploader";
import MTKSelect from "../../../../components/Select";
import MTKSwitch from "../../../../components/Switch";
import MTKTextarea from "../../../../components/Textarea";
import { organisationTypes } from "../../../../constants/types";
import GlobalContext from "../../../../context/GlobalContext";
import useIsSuperAdmin from "../../../../hooks/useIsSuperAdmin";
import { ReactComponent as TrashCanIcon } from "../../../../assets/images/icons/trash-can.svg";

import {
  ageDropdownList,
  getJewishSchoolYear,
  languageOptions,
} from "../../../../utils/utils";
import LoadingScreen from "../../../../components/layout/LoadingScreen";

function AboutTab({
  form,
  organisation,
  classroom,
  schoolYear,
  onDeleteCourse,
}) {
  const { t, i18n } = useTranslation();
  const modals = useModals();
  const isAdmin = useIsSuperAdmin();
  const { profile } = useContext(GlobalContext);
  const [orgTypes, setOrgTypes] = useState([]);
  const [selectedOrgType, setSelectedOrgType] = useState(null);

  const onDelete = () => {
    modals.openContextModal("confirmation", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Delete Course"),
        modalBody: t(
          "Are you sure you want to delete this Course? All elements inside this Course will be deleted. Duplicate the games you want to keep."
        ),
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          onDeleteCourse();
        },
      },
    });
  };

  const getOrganisationTypeOptions = () => {
    const currentLang = i18n.language;
    const orgTypes = { ...organisationTypes };
    if (profile?.type === "teacher") {
      delete orgTypes.home;
    }

    const options = Object.keys(orgTypes).map((key) => ({
      value: key,
      label: orgTypes[key][currentLang],
    }));

    return options;
  };
  useEffect(() => {
    if (organisation && profile) {
      const orgTypes = getOrganisationTypeOptions(profile);

      const orgType = orgTypes.find((d) => d.value === organisation.type);
      if (orgType) {
        setOrgTypes([orgType]);
        setSelectedOrgType(orgType?.value);
      }
    }
  }, [organisation, profile]);

  if (!classroom) return <LoadingScreen />;
  const {
    ageLimit = {
      from: null,
      to: null,
    },
  } = classroom;
  return (
    <Box>
      <Grid gutter="xl">
        <Grid.Col md={12} lg={6}>
          <Section title={t("General Information")}>
            <MediaUploader
              ratio={1}
              {...form.getInputProps("coverImage")}
              recommendedAspectRatio="1/1 (512*512)"
            />
            <MTKInput
              label={`${t("Name")}`}
              required
              labelColor="blue"
              {...form.getInputProps("name")}
            />
            <AudioUploader {...form.getInputProps("audioFile")} />
          </Section>
          <Section title={t("Availability")}>
            <MTKSwitch
              label={`${t("Kids can see the course in the classrooom")}`}
              required
              labelColor="blue"
              size={"md"}
              {...form.getInputProps("kidsCanSeeCourseInClassroom", {
                type: "checkbox",
              })}
            />
            <MTKSwitch
              size={"md"}
              label={`${t(
                "Show the course to other teachers in search results"
              )}`}
              required
              labelColor="blue"
              {...form.getInputProps("showToOtherTeachersInSearch", {
                type: "checkbox",
              })}
            />
          </Section>
        </Grid.Col>
        <Grid.Col md={12} lg={6}>
          <Section>
            <MTKSelect
              data={orgTypes}
              label={t("Organisation Type")}
              required
              value={selectedOrgType}
              onChange={(v) => setSelectedOrgType(v)}
              // {...form.getInputProps("type")}
              disabled
            />
            <MTKSelect
              disabled
              label={`${t("Organisation")}`}
              required
              data={[{ value: organisation?.id, label: organisation?.name }]}
              // data={orgSubType}
              value={organisation?.id}
            />

            <MTKSelect
              disabled
              label={`${t("Classroom")}`}
              required
              data={[{ value: classroom?.id, label: classroom?.name }]}
              // data={orgSubType}
              value={classroom?.id}
              // data={orgSubType}
            />
            <MTKSelect
              disabled
              label={t("School Year")}
              required
              data={[
                {
                  value: schoolYear?.id,
                  label: getJewishSchoolYear(schoolYear?.key),
                },
              ]}
              // data={orgSubType}
              value={schoolYear?.id}
            />
            <MTKSelect
              label={`${t("Language")}`}
              required
              data={languageOptions}
              // data={orgSubType}
              {...form.getInputProps("languageId")}
            />

            <MTKTextarea
              label={t("Description")}
              minRows={4}
              {...form.getInputProps("description")}
            />
            <MTKSelect
              data={ageDropdownList.map((d) => ({
                label: d || "",
                value: `${d || ""}`,
              }))}
              disabled
              //   onChange={(value) => {
              //     if (!ageLimitTo) {
              //       form.setFieldValue("ageLimitTo", "12");
              //     }
              //     form.setFieldValue("ageLimitFrom", value);
              //   }}
              value={`${ageLimit.from}`}
              label={t("Age From")}
            />
            <MTKSelect
              disabled
              value={`${ageLimit.to}`}
              data={ageDropdownList.map((d) => ({
                label: d || "",
                value: `${d || ""}`,
              }))}
              //     .map((d) => ({ value: `${d || ""}`, label: d || "" }))}
              //   label={t("Age To")}
              //   {...form.getInputProps("ageLimitTo")}
              //   value={`${ageLimitTo}`}
            />
          </Section>
        </Grid.Col>
      </Grid>

      <Box
        mt="lg"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="red"
          radius={"xl"}
          size="md"
          leftIcon={<TrashCanIcon color="white" />}
          onClick={onDelete}
        >
          {t("Delete")}
        </Button>
      </Box>
    </Box>
  );
}

const Section = ({ title = "", children }) => {
  return (
    <Box>
      <Title
        sx={{
          fontSize: "24px",
          color: "rgb(1, 158, 211)",
          paddingLeft: "10px",
          marginTop: "0px",
          marginBottom: "20px",
        }}
      >
        {title}
      </Title>
      <Box
        sx={{
          background: "rgb(239, 247, 255)",
          borderRadius: "22px",
          padding: "20px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
export default AboutTab;
