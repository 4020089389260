import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Center,
  LoadingOverlay,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import MTKInput from "../Input";
import MTKDateInput from "../DateInput";
import MTKSelect from "../Select";
import MTKPhoneInput from "../PhoneInput";
import MTKTextarea from "../Textarea";
import MTKButton from "../Button";
import { updateStudent } from "../../api/Users";

export default function EditStudentModal({ context, id, innerProps }) {
  const { t } = useTranslation();
  const { student, handleRefreshData } = innerProps;

  // State
  const genderOptions = [
    { value: "male", label: t("Male") },
    { value: "female", label: t("Female") },
  ];

  const [step, setStep] = useState("editStudent");

  const [email, setEmail] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthday, setBirthday] = useState(undefined);
  const [gender, setGender] = useState(undefined);
  const [phoneNumber1, setPhoneNumber1] = useState("");
  const [phoneNumber2, setPhoneNumber2] = useState("");
  const [note, setNote] = useState("");

  // Init

  useEffect(() => {
    setEmail(student?.email);

    if ("contactEmail" in student) {
      setContactEmail(student.contactEmail);
    }

    if ("firstName" in student) {
      setFirstName(student.firstName);
    }

    if ("name" in student) {
      setLastName(student.name);
    }

    if ("birthday" in student) {
      if (typeof student.birthday?.toDate === "function") {
        setBirthday(student.birthday?.toDate());
      } else {
        setBirthday(student.birthday);
      }
    }

    if ("gender" in student) {
      setGender(genderOptions.find((o) => o.id === student.gender));
    }

    if ("phoneNumber1" in student) {
      setPhoneNumber1(student.phoneNumber1);
    }

    if ("phoneNumber2" in student) {
      setPhoneNumber2(student.phoneNumber2);
    }

    if ("note" in student) {
      setNote(student.note);
    }
  }, [student]);

  // Functions

  const saveChanges = async () => {
    // Validation
    if (firstName.length < 1 || lastName.length < 1) {
      setStep("missingDataError");
      return;
    }

    if (contactEmail) {
      const emailValid = true;
      if (!emailValid) {
        setStep("invalidEmailError");
        return;
      }
    }

    try {
      setStep("loading");

      const data = { firstName, name: lastName };

      if (contactEmail) {
        data.contactEmail = contactEmail;
      }

      if (birthday) {
        data.birthday = birthday;
      }

      if (gender) {
        data.gender = gender.id;
      }

      if (phoneNumber1) {
        data.phoneNumber1 = phoneNumber1;
      }

      if (phoneNumber2) {
        data.phoneNumber2 = phoneNumber2;
      }

      if (note) {
        data.note = note;
      }
      await updateStudent(student.id, data);
      // await firestore.collection("Users").doc(student.id).update(data);
      handleRefreshData();
      setStep("success");
    } catch (error) {
      console.log(error);
      setStep("editStudent");
    }
  };

  // Render

  const renderEditStudent = () => (
    <Box>
      <Title order={3} my="md" align="center">
        {t("Edit Student")}
      </Title>
      <MTKInput
        readOnly
        placeholderColor="#00000055"
        margin="0px 0px 5px 0px"
        titleColor="#00000055"
        label={`${t("Login Email")} *`}
        inputColor="#000000"
        value={email}
      />

      <MTKInput
        placeholderColor="#00000055"
        margin="0px 0px 5px 0px"
        titleColor="#00000055"
        label={t("Contact Email")}
        inputColor="#000000"
        value={contactEmail}
        onChange={(e) => setContactEmail(e.target.value)}
      />

      <MTKInput
        placeholderColor="#00000055"
        margin="0px 0px 5px 0px"
        titleColor="#00000055"
        label={`${t("First Name")} *`}
        inputColor="#000000"
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
      />

      <MTKInput
        placeholderColor="#00000055"
        margin="0px 0px 5px 0px"
        titleColor="#00000055"
        label={`${t("Last Name")} *`}
        inputColor="#000000"
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
      />

      <MTKDateInput
        margin="0px 0px 5px 0px"
        type="date"
        label={`${t("Birthday")}`}
        titleColor="#00000055"
        placeholderColor="#00000055"
        placeholder={t("Select a birthday...")}
        inputColor="#000000"
        value={birthday}
        onChange={(date) => {
          setBirthday(date);
        }}
      />

      <MTKSelect
        margin="0px 0px 30px 0px"
        label={`${t("Gender")}`}
        titleColor="#00000055"
        selectorColor="#000"
        placeholder={t("Select a gender...")}
        placeholderColor="#00000055"
        value={gender}
        data={genderOptions}
        onChange={(option) => setGender(option)}
      />

      <MTKPhoneInput
        type="phoneNumber"
        label={t("Phone Number 1")}
        margin="0px 0px 15px 0px"
        value={phoneNumber1}
        placeholderColor="#00000055"
        titleColor="#00000055"
        onChange={(phoneNumber) => {
          setPhoneNumber1(phoneNumber);
        }}
      />

      <MTKPhoneInput
        type="phoneNumber"
        label={t("Phone Number 2")}
        margin="0px 0px 5px 0px"
        value={phoneNumber2}
        placeholderColor="#00000055"
        titleColor="#00000055"
        onChange={(phoneNumber) => {
          setPhoneNumber2(phoneNumber);
        }}
      />

      <MTKTextarea
        rows={4}
        placeholderColor="#00000055"
        margin="0px 0px 5px 0px"
        titleColor="#00000055"
        label={t("Note")}
        inputColor="#000000"
        value={note}
        onChange={(e) => setNote(e.target.value)}
      />

      <MTKButton
        // width="140px"

        mt="20px"
        fullWidth
        color="blue"
        onClick={async () => {
          saveChanges();
        }}
        //   onClick={() => context.closeModal(id)}
      >
        {t("Update")}
      </MTKButton>
    </Box>
  );
  const renderErrorInvalidEmail = () => {
    return (
      <Center
        sx={{
          flexDirection: "column",
        }}
      >
        <Title order={3} my="md" align="center">
          {t("Email invalid")}
        </Title>
        <Text size="sm" align="center" my="md">
          {t("Sorry, but the entered email is not valid.")}
        </Text>
        <MTKButton
          width="140px"
          // fullWidth
          color="blue"
          onClick={async () => {
            setStep("editStudent");
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Ok")}
        </MTKButton>
      </Center>
    );
  };

  const renderErrorMissingData = () => {
    return (
      <Center
        sx={{
          flexDirection: "column",
        }}
      >
        <Title order={3} my="md" align="center">
          {t("Missing data")}
        </Title>
        <Text size="sm" align="center" my="md">
          {t("Please fill out all the fields required.")}
        </Text>
        <MTKButton
          width="140px"
          // fullWidth
          color="blue"
          onClick={async () => {
            setStep("editStudent");
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Ok")}
        </MTKButton>
      </Center>
    );
  };

  const renderLoading = () => {
    return (
      <Box
        type="loading"
        title={t("Edit Student")}
        description={t("Student is being updated...")}
      >
        <LoadingOverlay />
      </Box>
    );
  };

  const renderSuccess = () => {
    return (
      <Center
        sx={{
          flexDirection: "column",
        }}
      >
        <Title order={3} my="md" align="center">
          {t("Success")}
        </Title>
        <Text size="sm" align="center" my="md">
          {t("Student has been updated")}
        </Text>
        <MTKButton
          width="140px"
          // fullWidth
          color="blue"
          onClick={() => context.closeModal(id)}
        >
          {t("Ok")}
        </MTKButton>
      </Center>
    );
  };

  if (step === "editStudent") {
    return renderEditStudent();
  } else if (step === "invalidEmailError") {
    return renderErrorInvalidEmail();
  } else if (step === "missingDataError") {
    return renderErrorMissingData();
  } else if (step === "loading") {
    return renderLoading();
  } else if (step === "success") {
    return renderSuccess();
  } else {
    return null;
  }
}
