import React, { useContext, useState } from "react";
import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";
import { serverTimestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { withLanguage } from "../../../lib/i18";
import GlobalContext from "../../../context/GlobalContext";
import {
  checkIfSameFile,
  generateRandomId,
  generateRandomPassword,
  isTeacher,
} from "../../../utils/utils";
import { uploadFileToStorage } from "../../../api/Storage";

import { useHistory } from "react-router-dom";
import { createClassroom, updateClassroom } from "../../../api/Classrooms";
import { createCourse, updateCourse } from "../../../api/Courses";

function useCourseForm({ update = false } = {}) {
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const { profile } = useContext(GlobalContext);
  const history = useHistory();
  const { t } = useTranslation();

  const schema = z.object({
    name: z
      .string()
      .min(
        2,
        t(
          "Please fill in the required fields in order to create a new organisation."
        )
      ),
    description: z.string().optional(),

    classroomId: z
      .string()
      .min(
        2,
        t(
          "Please fill in the required fields in order to create a new organisation."
        )
      ),
    languageId: z
      .string()
      .min(
        2,
        t(
          "Please fill in the required fields in order to create a new organisation."
        )
      ),
    ageLimitTo: z.string().or(z.literal(null)).optional(),

    ageLimitFrom: z.string().or(z.literal(null)).optional(),
    kidsCanSeeCourseInClassroom: z.boolean(),
    showToOtherTeachersInSearch: z.boolean(),

    coverImage: z.instanceof(File).or(z.literal(null)),
    audioFile: z.instanceof(File).or(z.literal(null)),
  });

  const onFormSubmit = async (values) => {
    setLoading(true);
    if (!profile) {
      setLoading(false);
      return;
    }
    const {
      name,
      description,
      classroomId,
      ageLimitTo = null,
      ageLimitFrom = null,
      languageId,
      kidsCanSeeCourseInClassroom,
      showToOtherTeachersInSearch,
      coverImage,
      audioFile,
    } = values;

    const data = {
      name,
      description,
      classroomId,
      ageLimit: {
        to: +ageLimitTo || null,
        from: +ageLimitFrom || null,
      },
      kidsCanSeeCourseInClassroom,
      showToOtherTeachersInSearch,
      languageId,
      createdByUserId: profile.id,
      coverImage: null,
      audioFile: null,
    };
    if (update) {
      data.updatedAt = serverTimestamp();
    } else {
      data.createdAt = serverTimestamp();
    }
    try {
      // Upload cover image
      if (coverImage) {
        if (
          originalData &&
          originalData.coverImage &&
          checkIfSameFile(coverImage, originalData.coverImage)
        ) {
          data.coverImage = originalData.coverImage;
        } else {
          const randomId = generateRandomId();
          const iFile = {
            id: randomId,
            type: coverImage.type,
            name: coverImage.name,
            size: coverImage.size,
          };
          data.coverImage = iFile;

          try {
            await uploadFileToStorage({
              file: coverImage,
              path: `Images/${iFile.id}/image`,
            });
          } catch (error) {
            console.log("Imageupload error", error);
          }
        }
      }
      // Upload audio file
      if (audioFile) {
        if (
          originalData &&
          originalData.audioFile &&
          checkIfSameFile(audioFile, originalData.audioFile)
        ) {
          data.audioFile = originalData.audioFile;
        } else {
          const randomId = generateRandomId();
          const aFile = {
            id: randomId,
            type: audioFile.type,
            name: audioFile.name,
            size: audioFile.size,
          };
          data.audioFile = aFile;
          try {
            await uploadFileToStorage({
              file: audioFile,
              path: `Audio/${aFile.id}/audio`,
            });
          } catch (error) {
            console.log("Audioupload error", error);
          }
        }
      }
      let res = null;
      if (update) {
        res = await updateCourse(originalData.id, data);
      } else {
        res = await createCourse(data);
      }

      if (!res.id) return;
      // resetFormValues();
      setLoading(false);
      history.push(withLanguage(`/courses/${res.id}`));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const form = useForm({
    schema: zodResolver(schema),
    initialValues: {
      name: "",
      description: "",
      classroomId: "",
      ageLimitFrom: null,
      ageLimitTo: null,
      kidsCanSeeCourseInClassroom: true,
      showToOtherTeachersInSearch: true,
      languageId: "",
      coverImage: null,
      audioFile: null,
    },
  });

  const setDefaultData = (data, rawData) => {
    setOriginalData(rawData);

    form.setValues(data);
  };
  return { form, loading, onFormSubmit, setDefaultData };
}

export default useCourseForm;
