import { Box, Grid, Select, Stack } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MTKButton from "../../../../components/Button";
import { withLanguage } from "../../../../lib/i18";
import { capitalize, getJewishSchoolYear } from "../../../../utils/utils";
import classroomIcon from "../../../../assets/images/icons/classroom.svg";
import plusIcon from "../../../../assets/images/icons/Group 7 blue.svg";
import { ReactComponent as Plus } from "../../../../assets/images/icons/plus.svg";
import ClassroomCard from "../../../Classrooms/components/ClassroomCard";
import { useHistory } from "react-router-dom";

function ClassroomTab({ schoolYears, emptyView, classrooms, organisationId }) {
  const { t } = useTranslation();
  const [selectedSchoolYear, setSelectedSchoolYear] = useState(null);
  const history = useHistory();
  useEffect(() => {
    if (schoolYears && schoolYears[0]?.id) {
      setSelectedSchoolYear(schoolYears[0].id);
    }
  }, [schoolYears]);

  return (
    <Box>
      {classrooms && classrooms.length === 0 ? (
        <EmptyView emptyViewData={emptyView} organisationId={organisationId} />
      ) : (
        <Box>
          <Box
            mb="xl"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Box mt="md">
              <Select
                variant="filled"
                labelProps={{
                  style: {
                    color: "rgb(178, 178, 178)",
                  },
                }}
                label={t("School Year")}
                value={selectedSchoolYear}
                onChange={(value) => setSelectedSchoolYear(value)}
                data={schoolYears.map((d) => {
                  return {
                    value: d.id,
                    label: getJewishSchoolYear(d.key),
                  };
                })}
              />
            </Box>

            <MTKButton
              mt="md"
              leftIcon={<Plus />}
              color="blue"
              onClick={() =>
                history.push(
                  withLanguage(
                    `/classrooms/new/?back=/organisations/${organisationId}/classrooms&prefillId=${
                      organisationId || ""
                    }`
                  )
                )
              }
            >
              {t("New Classroom")}
            </MTKButton>
          </Box>

          <Box>
            <Grid gutter={"lg"}>
              {classrooms
                .filter((d) => d.schoolYearId === selectedSchoolYear)
                .map((d) => (
                  <Grid.Col lg={4} md={12} sm={12}>
                    <ClassroomCard {...d} />
                  </Grid.Col>
                ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

const EmptyView = ({ emptyViewData, organisationId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    //   {classrooms && classrooms.length === 0 ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: "column",
        height: "350px",
      }}
    >
      <div
        style={{
          position: "relative",
          left: "0px",
          top: "0px",
          marginBottom: "-20px",
        }}
      >
        <img src={classroomIcon} alt="Icon" height="128" width="115" />
        <img
          src={plusIcon}
          alt="Icon"
          height="60"
          width="54"
          style={{
            marginLeft: "-30px",
          }}
        />
      </div>
      <h1>
        {t(
          emptyViewData?.fields[
            `title${capitalize(withLanguage("").substring(1))}`
          ]
        )}
      </h1>
      <p>
        {
          emptyViewData?.fields[
            `description${capitalize(withLanguage("").substring(1))}`
          ]
        }
      </p>
      <MTKButton
        leftIcon={<Plus />}
        color="blue"
        onClick={() =>
          history.push(
            withLanguage(
              `/classrooms/new/?back=/organisations/${organisationId}/classrooms`
            )
          )
        }
      >
        {t("New Classroom")}
      </MTKButton>
    </Box>
  );
};
export default ClassroomTab;
