import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { withLanguage } from "../lib/i18";
import GlobalContext from "../context/GlobalContext";
import LoadingScreen from "../components/layout/LoadingScreen";
import { getProfile } from "../api/Auth";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { auth } from "../lib/firebase";

export default function ProtectedRoute({ redirectTo = "", children, ...rest }) {
  const { profile, user, isLoaded } = useContext(GlobalContext);

  if (!isLoaded) {
    return <LoadingScreen />;
  } else if (isLoaded && !profile) {
    return <Redirect to={redirectTo} />;
  } else if (isLoaded && profile) {
    if (profile.type === "teacher") {
      if (!profile.profileSetupCompleted) {
        <Redirect to={withLanguage("/teacher/setup-profile")} />;
      } else {
        <Redirect to={redirectTo} />;
      }
    }
  }

  return <Route {...rest}>{children}</Route>;
}
