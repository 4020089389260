import { Box, Image, Tab, Tabs } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AudioUploader from "../../../../components/AudioUploader";
import MTKButton from "../../../../components/Button";
import MTKInput from "../../../../components/Input";
import FormSection from "../../../../components/layout/FormSection";
import MediaUploader from "../../../../components/MediaUploader";
import { withLanguage } from "../../../../lib/i18";
import { capitalize } from "../../../../utils/utils";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";

import ImageIcon from "../../../../assets/images/icons/image.png";
import checkImage from "../../../../assets/images/icons/checkmark.png";
import MTKTabs from "../../../../components/Tabs";
import MediaUploaderWithGrid from "../../../../components/MediaUploader/WithGrid";
const stringToHtml = (text) => {
  return ReactHtmlParser(documentToHtmlString(text));
};

// import CheckIcon from "../../../assets/images/icons/checkmark-rounded.svg";
// import ImageGreenIcon from "../../../assets/images/icons/image-green.png";

const Click = ({ form, questionMeta }) => {
  const { t } = useTranslation();
  const dimensions = ["2x2", "3x3", "4x4", "5x5", "6x6"];

  const { answers } = form.values;

  const answer = answers[0];

  const handleRatioChange = (ratio) => {
    form.setFieldValue("answers", [{ ...answer, ratio: ratio }]);
  };
  const handleSizeChange = (size) => {
    form.setFieldValue("answers", [
      { ...answer, gridSize: size, gridAnswer: [] },
    ]);
  };
  const handleAnswerChange = (newGridAnswer) => {
    form.setFieldValue("answers", [{ ...answer, gridAnswer: newGridAnswer }]);
  };
  const onAnswerMediaChange = (file) => {
    form.setFieldValue("answers", [{ ...answer, media: file }]);
  };

  if (!answer) return null;

  return (
    <FormSection
      color="green"
      layout="row"
      margin="0px 0px 40px 0px"
      title={
        questionMeta?.fields[
          `clickQuestionConfigurationTitle${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      }
      description={stringToHtml(
        questionMeta?.fields[
          `${"click"}QuestionConfigurationDescription${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      )}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <MTKTabs
          color="green"
          onTabChange={(_, key) => handleRatioChange(+key)}
          active={answer.ratio === 1 ? 0 : 1}
        >
          <Tabs.Tab
            tabKey={1}
            label={
              <Image fit="fill" src={ImageIcon} width={15} height={15}></Image>
            }
          />
          <Tabs.Tab
            tabKey={16 / 9}
            color={"green"}
            label={
              <Image fit="fill" src={ImageIcon} width={25} height={15}></Image>
            }
          />
        </MTKTabs>
        <MTKTabs
          color="green"
          onTabChange={(_, key) => handleSizeChange(key)}
          active={dimensions.findIndex((d) => d === answer.gridSize)}
        >
          {dimensions.map((dim, index) => (
            <Tabs.Tab tabKey={dim} key={dim} label={dim} />
          ))}
        </MTKTabs>
      </Box>
      <div
        style={{
          position: "relative",
          marginTop: "25px",
        }}
      >
        <MediaUploaderWithGrid
          ratio={answer.ratio}
          width={"100%"}
          gridSize={answer.gridSize}
          gridAnswer={answer.gridAnswer}
          handleAnswerChange={handleAnswerChange}
          value={answer.media}
          onChange={(file) => onAnswerMediaChange(file)}
        ></MediaUploaderWithGrid>
      </div>
    </FormSection>
  );
};

export default Click;
