import { Box, Image } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AudioUploader from "../../../../components/AudioUploader";
import MTKButton from "../../../../components/Button";
import MTKInput from "../../../../components/Input";
import FormSection from "../../../../components/layout/FormSection";
import MediaUploader from "../../../../components/MediaUploader";
import { withLanguage } from "../../../../lib/i18";
import { capitalize } from "../../../../utils/utils";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";
import MTKSwitch from "../../../../components/Switch";
const stringToHtml = (text) => {
  return ReactHtmlParser(documentToHtmlString(text));
};

const Drawing = ({ form, questionMeta }) => {
  const { t } = useTranslation();

  const { answers } = form.values;

  const answer = answers[0];
  if (!answer) return null;
  return (
    <FormSection
      color="green"
      layout="row"
      margin="0px 0px 40px 0px"
      title={
        questionMeta?.fields[
          `drawingQuestionConfigurationTitle${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      }
      description={stringToHtml(
        questionMeta?.fields[
          `${"drawing"}QuestionConfigurationDescription${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      )}
    >
      <MediaUploader
        type={"image"}
        value={answer.media}
        onChange={(file) =>
          form.setFieldValue("answers", [{ ...answer, media: file }])
        }
      />
      <MTKSwitch
        checked={answer.marked}
        color="green"
        label={t("Correct correction automatically")}
        onChange={(e) =>
          form.setFieldValue("answers", [
            { ...answer, marked: e.target.checked },
          ])
        }
        margin="10px 0px 10px 0px"
      />
    </FormSection>
  );
};

export default Drawing;
