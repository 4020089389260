import { Textarea } from "@mantine/core";
import React from "react";
import { useTranslation } from "react-i18next";

function MTKTextarea({ labelColor = "blue", label, ...props }) {
  const { t } = useTranslation();
  return (
    <Textarea
      styles={(theme) => ({
        label: {
          color: labelColor === "blue" ? theme.colors.blue : "#00000055",
          fontSize: "14px",
        },
        input: {
          color: theme.colors.blue,
          background: "transparent",
        },
        defaultVariant: {
          borderColor: `${theme.colors.blue}`,
        },
      })}
      size="md"
      placeholder={t("Type Here...")}
      label={label}
      {...props}
    />
  );
}

export default MTKTextarea;
