import React from "react";
import { Text, Button, Image, Box, Center, Title } from "@mantine/core";

import { useTranslation } from "react-i18next";
import MTKButton from "../Button";

function SuccessModal({ context, id, innerProps }) {
  const { t } = useTranslation();

  return (
    <Box>
      {/* <Center>
        <Image src={SuccessSignImage} my="md" width={84} />
      </Center> */}
      <Title order={3} my="md" align="center">
        {t("Success")}
      </Title>
      <Text size="sm" align="center" my="md">
        {innerProps.modalBody}
      </Text>
      <Center>
        <MTKButton
          width="140px"
          // fullWidth
          color="blue"
          mt="md"
          onClick={() => {
            if (innerProps?.onOk) {
              innerProps.onOk();
            }
            context.closeModal(id);
          }}
        >
          {t("OK")}
        </MTKButton>
      </Center>
    </Box>
  );
}

export default SuccessModal;
