import {
  createStyles,
  Box,
  Title,
  ActionIcon,
  Image,
  Center,
  Grid,
  Divider,
  Button,
  SimpleGrid,
} from "@mantine/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getClassromByID } from "../../../api/Classrooms";
import { getCourseByID } from "../../../api/Courses";
import { getGameByID } from "../../../api/Games";
import { getOrganizationByID } from "../../../api/Organisations";
import { getQuestionsOfGame } from "../../../api/Questions";
import { getSchoolYearByID } from "../../../api/SchoolYear";
import BackButtonCircle from "../../../components/BackButtonCircle";
import MTKBreadcrumbs from "../../../components/Breadcrumbs";
import MTKButton from "../../../components/Button";
import GalleryUploader from "../../../components/GalleryUploader";
import AppLayout from "../../../components/layout/AppLayout";
import MTKTextarea from "../../../components/Textarea";
import { withLanguage } from "../../../lib/i18";
import {
  getJewishSchoolYear,
  getPath,
  getQueryParams,
  isCreatorOfGame as isCreatorOfGameF,
  isDirectorOfOrganisation,
  isTeacherOfClassroom as isTeacherOfClassroomF,
} from "../../../utils/utils";

import audioImage from "../../../assets/images/icons/audio.png";
import {
  downloadFileFromStorage,
  getFileDownloadURL,
} from "../../../api/Storage";

import checkmarkRounded from "../../../assets/images/icons/checkmark-rounded.svg";
import redCircleWithWhiteCross from "../../../assets/images/icons/red-circle-with-white-cross.svg";
import placeholderImage from "../../../assets/images/icons/image.png";
import { ReactComponent as LeftArrow } from "../../../assets/images/icons/left-navigation-arrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/icons/right-navigation-arrow.svg";
import MTKInput from "../../../components/Input";
import MediaUploaderWithGrid from "../../../components/MediaUploader/WithGrid";
import { useMemo } from "react";
import useIsSuperAdmin from "../../../hooks/useIsSuperAdmin";
import { useContext } from "react";
import GlobalContext from "../../../context/GlobalContext";

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",

    position: "sticky",
    top: 0,
    padding: "50px 0",
    zIndex: 3,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "50px",
  },
}));
function QuestionsPreview() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const queryParams = getQueryParams(location);
  let { studentId = "", backToSearch } = queryParams;

  const { courseId, gameId, questionId } = useParams();
  const { profile } = useContext(GlobalContext);
  const [course, setCourse] = useState(null);
  const [classroom, setClassroom] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const [schoolYear, setSchoolYear] = useState(null);
  const [game, setGame] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(questionId);

  useEffect(() => {
    if (questionId) {
      setSelectedQuestion(questionId);
    }
  }, [questionId]);
  useEffect(() => {
    const getData = async () => {
      const game = await getGameByID(gameId);
      const course = await getCourseByID(courseId);
      const classroom = await getClassromByID(course.classroomId);
      const schoolYear = await getSchoolYearByID(classroom.schoolYearId);
      const organisation = await getOrganizationByID(classroom.organisationId);
      const questions = await getQuestionsOfGame(gameId);

      setCourse(course);
      setClassroom(classroom);
      setOrganisation(organisation);
      setSchoolYear(schoolYear);
      setGame(game);
      setQuestions(questions);
    };
    if (courseId && gameId) {
      getData();
    }
  }, [courseId, gameId]);

  const question = useMemo(() => {
    return questions?.find((q) => q.id === selectedQuestion);
  }, [selectedQuestion, questions]);

  const nextQuestion = () => {
    const nextQuestionIndex =
      questions?.findIndex((d) => d.id === selectedQuestion) + 1;
    if (nextQuestionIndex > questions?.length - 1) {
      return;
    }
    history.push(
      withLanguage(
        `/courses/${courseId}/games/${gameId}/questions/${questions[nextQuestionIndex].id}`
      )
    );
  };
  const prevQuestion = () => {
    const nextQuestionIndex =
      questions?.findIndex((d) => d.id === selectedQuestion) - 1;
    if (nextQuestionIndex < 0) {
      return;
    }
    history.push(
      withLanguage(
        `/courses/${courseId}/games/${gameId}/questions/${questions[nextQuestionIndex].id}`
      )
    );
  };
  const questionByID = (id) => {
    const nextQuestionIndex = questions?.findIndex((d) => d.id === id);

    if (nextQuestionIndex < 0) {
      return;
    }
    history.push(
      withLanguage(
        `/courses/${courseId}/games/${gameId}/questions/${questions[nextQuestionIndex].id}`
      )
    );
  };
  const isAdmin = useIsSuperAdmin();
  const isCreatorOfGame = isCreatorOfGameF(profile, game);
  const isTeacherOfClassroom = isTeacherOfClassroomF(profile, classroom);
  const isDirector = isDirectorOfOrganisation(profile, organisation);
  const inReview = studentId && studentId.length > 0;
  console.log({ course });
  return (
    <AppLayout loading={!questions}>
      <Box className={classes.header}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
          }}
          mb="10px"
        >
          <Box
            mb="md"
            sx={{
              minWidth: "350px",
            }}
          >
            <MTKBreadcrumbs
              data={[
                {
                  title: organisation?.name,
                  url: `/organisations/${organisation?.id}`,
                },
                {
                  title: classroom?.name,
                  url: `/classrooms/${classroom?.id}`,
                },
                {
                  title: course?.name,
                  url: `/courses/${course?.id}`,
                },
              ]}
            />
            <Box
              mb="md"
              mt="xs"
              sx={{
                display: "flex",
              }}
            >
              <BackButtonCircle
                color="green"
                mr="md"
                onClick={() => history.goBack()}
              />
              <Title order={3}>{game?.name}</Title>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                background: "rgb(254, 215, 0)",
                color: "rgb(255, 255, 255)",
                fontSize: "12px",
                padding: "5px",
                display: "block",
                borderRadius: "8px",
              }}
            >
              {t("School Year")}
              {getJewishSchoolYear(schoolYear?.key)}
            </Box>
          </Box>
        </Box>

        <Box
          mx="auto"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
          }}
        >
          <LeftArrow
            style={{
              cursor: "pointer",
            }}
            onClick={prevQuestion}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                // marginLeft: "30px",
                fontSize: "24px",
                fontWeight: 700,
                color: "rgb(39, 71, 132)",
              }}
            >
              {question?.userQuestionId}
            </Box>
            {!inReview &&
              (isAdmin ||
                isCreatorOfGame ||
                isTeacherOfClassroom ||
                isDirector) && (
                <Button
                  my="md"
                  compact
                  sx={{
                    background: "rgb(1, 158, 211)",
                  }}
                  onClick={() =>
                    history.push(
                      withLanguage(
                        `/courses/${courseId}/games/${gameId}/questions/${questionId}/edit`
                      )
                    )
                  }
                >
                  {t("Edit Question")}
                </Button>
              )}

            <Box
              sx={{
                display: "flex",
              }}
            >
              {questions?.map((q) => (
                <Dot
                  onClick={() => questionByID(q.id)}
                  id={q.id}
                  key={q.id}
                  active={selectedQuestion === q.id}
                />
              ))}
            </Box>
          </Box>
          <RightArrow
            style={{
              cursor: "pointer",
            }}
            onClick={nextQuestion}
          />
        </Box>
      </Box>

      <Box>
        <QuestionCell {...question} />
      </Box>
    </AppLayout>
  );
}

const QuestionCell = ({
  statementMediaFiles = [],
  statementAudioFile,
  statement,
  feedbacks = [],
  answers,
  type,
  id,
  ...props
}) => {
  const [audioFile, setAudioFile] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (statementAudioFile && statementAudioFile?.id) {
      getFileDownloadURL({
        path: `QuestionStatement/${statementAudioFile.id}/audio-file`,
        // name: statementAudioFile?.name,
        // type: statementAudioFile?.type,
      }).then((file) => {
        setAudioFile(file);
      });
    }
  }, [statementAudioFile]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Section>
        <GalleryUploader key={id} readyOnly files={statementMediaFiles} />
        <Box
          mt="lg"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <MTKTextarea
            value={statement}
            sx={{
              width: "85%",
            }}
          />
          {audioFile && (
            <AudioBtn
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              audioFile={audioFile}
            />
          )}
        </Box>
      </Section>

      {type === "multiple" && (
        <SimpleGrid
          mt="20px"
          cols={2}
          spacing="0"
          sx={{
            width: "600px",
          }}
        >
          {answers.map((d, i) => (
            <Answer
              {...d}
              index={i}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
            />
          ))}
        </SimpleGrid>
      )}
      {type === "click" && (
        <Box
          mt="20px"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "85%",
          }}
        >
          {answers.map((d, i) => (
            <Answer
              click
              {...d}
              index={i}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
            />
          ))}
        </Box>
      )}
      {type === "differences" && (
        <Box
          mt="20px"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "85%",
          }}
        >
          {answers.map((d, i) => (
            <Answer
              differences
              {...d}
              index={i}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
            />
          ))}
        </Box>
      )}
      {type === "puzzle" && (
        <Box
          mt="20px"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "85%",
          }}
        >
          {answers.slice(0, 1).map((d, i) => (
            <Answer
              puzzle
              {...d}
              index={i}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
            />
          ))}
        </Box>
      )}
      {type === "drawing" && (
        <Box
          mt="20px"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            width: "85%",
          }}
        >
          {answers.slice(0, 1).map((d, i) => (
            <Answer
              drawing
              {...d}
              index={i}
              setIsPlaying={setIsPlaying}
              isPlaying={isPlaying}
            />
          ))}
        </Box>
      )}
      <Box
        mt="40px"
        sx={{
          width: "600px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <FeedBackSection
          icon={checkmarkRounded}
          text={t("Correct Answer Feedback")}
          {...feedbacks[0]}
          setIsPlaying={setIsPlaying}
          isPlaying={isPlaying}
        />
        <FeedBackSection
          icon={redCircleWithWhiteCross}
          text={t("Wrong Answer Feedback")}
          {...feedbacks[1]}
          setIsPlaying={setIsPlaying}
          isPlaying={isPlaying}
        />
      </Box>
    </Box>
  );
};

const Section = ({ children }) => {
  return (
    <Box
      p="md"
      sx={{
        background: "rgb(237, 247, 233)",
        borderRadius: "22px",
        maxWidth: "80%",
        minWidth: "600px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {children}
    </Box>
  );
};

const FeedBackSection = ({
  children,
  icon,
  text,
  audio,
  isPlaying,
  setIsPlaying,
  answer,
  media,
  ...props
}) => {
  const [audioFile, setAudioFile] = useState(null);
  const [mediaFile, setMediaFile] = useState(null);

  useEffect(() => {
    if (media && media?.id) {
      getFileDownloadURL({
        path: getPath(media.type, media.id),
      }).then((file) => {
        setMediaFile(file);
      });
    }
  }, [media]);
  useEffect(() => {
    if (audio && audio?.id) {
      getFileDownloadURL({
        path: getPath(audio.type, audio.id),
      }).then((file) => {
        setAudioFile(file);
      });
    }
  }, [audio]);
  return (
    <Box
      sx={{
        // position: relative;
        background: "rgb(237, 247, 233)",
        borderRadius: "22px",
        padding: "20px",
        // margin: "0px 5px 0px 0px",
        minHeight: "200px",
        width: "250px",
      }}
    >
      <Center>
        <Image src={icon} mx="auto" width={37} height={37} display="flex" />
      </Center>
      <div
        style={{
          fontSize: "16px",
          fontWeight: "700",
          textAlign: "center",
          color: "#274784",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        {text}
      </div>

      {mediaFile ? (
        <Image
          radius={"30px"}
          src={mediaFile}
          width={"210px"}
          height={"131px"}
        />
      ) : (
        <PlaceholderImage />
      )}
      <MTKInput value={answer} disabled placeholder="" />
      <Box
        mt="md"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {audioFile && (
          <AudioBtn
            small
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            audioFile={audioFile}
          />
        )}
      </Box>
    </Box>
  );
};
const Answer = ({
  children,
  icon,
  text,
  audio,
  isPlaying,
  setIsPlaying,
  answer,
  media,
  index,
  isCorrectAnswer,
  click = false,
  puzzle = false,
  drawing = false,
  differences = false,
  gridSize,
  ratio,
  gridAnswer,
  mediaCompare,
  ...props
}) => {
  const [audioFile, setAudioFile] = useState(null);
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaFileCompaire, setMediaFileComaire] = useState(null);

  useEffect(() => {
    if (media && media?.id) {
      getFileDownloadURL({
        path: getPath(media.type, media.id),
      }).then((file) => {
        setMediaFile(file);
      });
    }
  }, [media]);

  useEffect(() => {
    if (mediaCompare && mediaCompare?.id) {
      getFileDownloadURL({
        path: getPath(mediaCompare.type, mediaCompare.id),
      }).then((file) => {
        setMediaFileComaire(file);
      });
    }
  }, [mediaCompare]);
  useEffect(() => {
    if (audio && audio?.id) {
      getFileDownloadURL({
        path: getPath(audio.type, audio.id),
      }).then((file) => {
        setAudioFile(file);
      });
    }
  }, [audio]);
  return (
    <Box
      m="5px"
      sx={{
        position: "relative",
        background: "rgb(237, 247, 233)",
        borderRadius:
          click || puzzle || drawing || differences
            ? "25px"
            : `${index === 0 ? "25px" : 0} ${index === 1 ? "25px" : 0} ${
                index === 3 ? "25px" : 0
              } ${index === 2 ? "25px" : 0} `,
        padding: "20px",
        // margin: "0px 5px 0px 0px",
        minHeight: "260px",
        height: click || puzzle || drawing || differences ? "auto" : "260px",
        width: click || puzzle || drawing || differences ? "100%" : "280px",
      }}
    >
      <Box
        sx={{
          fontSize: "16px",
          fontWeight: 700,
          color: "rgb(39, 71, 132)",
          marginBottom: "1rem",
        }}
      >
        {click ? "Correct Answer" : ""}
        {puzzle ? "Puzzle Image" : ""}
        {differences ? "Game Image" : ""}
      </Box>
      {mediaFile ? (
        click || differences ? (
          <MediaUploaderWithGrid
            diabled
            ratio={ratio}
            // value={mediaFile}
            url={mediaFile}
            gridSize={gridSize}
            gridAnswer={gridAnswer}
          />
        ) : (
          <Image
            radius={"30px"}
            src={mediaFile}
            width={puzzle || drawing ? "100%" : "210px"}
            height={puzzle || drawing ? "auto" : "131px"}
          />
        )
      ) : (
        <PlaceholderImage />
      )}

      {differences && (
        <>
          <Divider my="40px" />
          <Box
            // mt="40px"
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              color: "rgb(39, 71, 132)",
              marginBottom: "1rem",
            }}
          >
            Comparaison Image
          </Box>{" "}
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: 700,
              color: "rgb(39, 71, 132)",
              marginBottom: "1rem",
            }}
          >
            {click ? "Correct Answer" : ""}
            {puzzle ? "Puzzle Image" : ""}
          </Box>
          {mediaFileCompaire ? (
            <Image
              radius={"30px"}
              src={mediaFileCompaire}
              width={"100%"}
              height={"auto"}
            />
          ) : (
            <PlaceholderImage />
          )}
        </>
      )}
      <Box
        mt="10px"
        sx={{
          marginRight: "auto",
          width: "70%",
          fontSize: "18px",
          fontWeight: 700,
          color: "rgb(39, 71, 132)",
        }}
      >
        {answer}
      </Box>
      {/* <MTKInput value={answer} disabled placeholder="" /> */}
      <Box
        mt="md"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {audioFile && (
          <AudioBtn
            small
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            audioFile={audioFile}
          />
        )}
      </Box>
      {isCorrectAnswer && !click && !puzzle && !drawing && !differences && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: "0",
          }}
        >
          <Image src={checkmarkRounded} width={"35px"} height={"35px"} />
        </Box>
      )}
    </Box>
  );
};

const PlaceholderImage = () => {
  return (
    <Box
      sx={{
        width: "210px",
        height: "131px",
        borderRadius: "22px",
        background: "rgb(250, 250, 250)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Image src={placeholderImage} width="53px" />
    </Box>
  );
};
export default QuestionsPreview;

const AudioBtn = ({ isPlaying, setIsPlaying, audioFile }) => {
  return (
    <ActionIcon
      mx="md"
      color={"grape"}
      sx={{
        background: "rgb(39, 71, 132)",
        height: "40px",
        width: "40px",
        "& hover": {
          background: "rgb(39, 71, 132)",
          height: "40px",
          width: "40px",
        },
      }}
      radius="30px"
      onClick={(e) => {
        if (isPlaying) {
          isPlaying.pause();
          setIsPlaying(null);
        } else {
          const player = new Audio(audioFile);
          player.play();
          setIsPlaying(player);
        }
      }}
    >
      <Image src={audioImage} width="10px" />
    </ActionIcon>
  );
};

const Dot = ({ id, active = false, onClick, ...props }) => {
  return (
    <Box
      sx={{
        background: "rgb(39, 71, 132)",
        opacity: active ? 1 : "0.15",
        borderRadius: "50%",
        width: "10px",
        height: "10px",
        marginRight: "5px",
        marginBottom: "5px",
        cursor: "pointer",
      }}
      onClick={onClick}
      {...props}
    ></Box>
  );
};
