import { v4 as uuid_v4 } from "uuid";
import queryString from "query-string";
import topics from "../constants/types.json";
import pricingCategories from "../constants/pricingCategories.json";
import { getProfile } from "../api/Auth";
//gameTo
export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function checkFormHasUnsavedChanges(callback) {
  return callback();
}
//getUsername
export function reorderArray(array1, array2) {
  if (array1 && array2) {
    const reorderedArray = [];

    for (const id of array1) {
      const matchedObject = array2.find((obj) => obj.value === id);
      if (matchedObject) {
        reorderedArray.push(matchedObject);
      }
    }

    return reorderedArray;
  } else {
    return [];
  }
}

export const getPricingCategories = () => {
  const currentLng = getCurrentLng();
  const arr = [];

  for (const [k, v] of Object.entries(pricingCategories)) {
    arr.push({ value: k, label: v.name[currentLng] });
  }

  return arr;
};

export function getUsername(profile) {
  let username = "User";

  if (profile) {
    if (profile.type === "superAdmin") {
      username = "Admin";
    } else if (profile.type === "teacher") {
      if (profile.firstName) {
        username = `${profile.firstName}`;
      }
    }
  }

  return username;
}

export function isDirectorOfOrganisation(profile, organisation) {
  if (profile && organisation) {
    if (organisation?.directors) {
      return !!organisation.directors[profile.id];
    }
  }
  return false;
}
export const isCreatorOfGame = (profile, game) => {
  if (profile && game) {
    return game.createdByUserId === profile.id;
  }
  return false;
};
//getJewishSchoolYear

export function isTeacherOfClassroom(profile, classroom) {
  if (profile && classroom) {
    if ("teachers" in classroom) {
      if (Array.isArray(classroom.teachers)) {
        return classroom?.teachers?.find((d) => d.id === profile.id);
      } else {
        return classroom?.teachers[profile.id];
      }
    }
    return false;
  }
  return false;
}
//uploadFile
export function isCreatorOfClassroom(profile, classroom) {
  if (profile && classroom) {
    return classroom.createdByUserId === profile.id;
  }
  return false;
}

export function getOrganisationsWithClassrooms(
  organisations,
  classrooms,
  teacherId
) {
  if (!organisations || !classrooms) return [];

  const organisationIdsFromClassrooms = classrooms.map((c) => {
    if (teacherId) {
      const org = organisations?.find((o) => o.id === c.organisationId);
      if (
        c.createdByUserId === teacherId ||
        ("teachers" in c && teacherId in c.teachers) ||
        (org && "directors" in org && teacherId in org.directors)
      ) {
        return c.organisationId;
      } else {
        return undefined;
      }
    } else {
      return c.organisationId;
    }
  });

  const ids = organisationIdsFromClassrooms.filter((id) => id !== undefined);
  const uniqueIds = [...new Set(ids)];
  const orgs = organisations.filter((o) => uniqueIds.includes(o.id));

  return orgs;
}
export const getOrganisationsWithClassroomAndCourse = ({
  organisations,
  classrooms,
  courses,
  teacherId,
}) => {
  if (!organisations || !classrooms || !courses) return [];

  const orgsWithClassrooms = organisations;

  const classroomIds = courses.map((c) => c.classroomId);

  const classroomsWithCourses = classrooms.filter((c) => {
    let included = false;
    const foundCourse = courses.find((course) => course.classroomId === c.id);

    if (!foundCourse) {
      return included;
    }

    if (teacherId) {
      const org = organisations?.find((o) => o.id === c.organisationId);
      if (
        c.createdByUserId === teacherId ||
        ("teachers" in c && teacherId in c.teachers) ||
        (org && "directors" in org && teacherId in org.directors)
      ) {
        included = true;
      } else {
        included = false;
      }
    } else {
      included = classroomIds.includes(c.id);
    }
    return included;
  });
  const orgIds = classroomsWithCourses.map((c) => {
    if (teacherId) {
      const org = organisations.find((o) => o.id === c.organisationId);
      if (
        c.createdByUserId === teacherId ||
        ("teachers" in c && teacherId in c.teachers) ||
        (org && "directors" in org && teacherId in org.directors)
      ) {
        return c.organisationId;
      } else {
        return undefined;
      }
    }
    return c.organisationId;
  });

  const filteredIds = orgIds.filter((o) => o !== undefined);
  const filteredOrgs = orgsWithClassrooms.filter((o) =>
    filteredIds.includes(o.id)
  );
  return filteredOrgs;
};
export function getClassroomsWithCourses({
  organisations,
  classrooms,
  courses,
  teacherId,
}) {
  if (!organisations || !classrooms || !courses) return [];

  const classroomIdsFromCourses = courses.map((c) => c.classroomId);

  let classroomsWithCourses = classrooms.filter((c) =>
    classroomIdsFromCourses.includes(c.id)
  );
  if (teacherId) {
    classroomsWithCourses = classroomsWithCourses.filter((c) => {
      const org = organisations?.find((o) => o.id === c.organisationId);
      return (
        c.createdByUserId === teacherId ||
        ("teachers" in c && teacherId in c.teachers) ||
        (org && "directors" in org && teacherId in org.directors)
      );
    });
  }

  return classroomsWithCourses;
}
export function getSchoolYearsWithClassrooms({ schoolYears, classrooms }) {
  if (!classrooms || !schoolYears) {
    return [];
  }
  const schoolYearIdsFromClassrooms = classrooms.map((c) => c.schoolYearId);
  const uniqueIds = [...new Set(schoolYearIdsFromClassrooms)];
  return schoolYears.filter((s) => uniqueIds.includes(s.id));
}
export function getClassroom(id, classrooms) {
  if (!classrooms || !id) return undefined;
  return classrooms.find((c) => c.id === id);
}

export const getFilteredCourses = ({
  organisations,
  classrooms,
  schoolYears,
  courses,
  selectedOrganisation,
  selectedClassroom,
  selectedSchoolYear,
  profile,
  isAdmin = false,
}) => {
  if (courses && classrooms && organisations && schoolYears) {
    const filteredCourses = courses.filter((c) => {
      const courseClassroom = getClassroom(c.classroomId, classrooms);
      if (!courseClassroom) return;

      const courseSchoolYear = schoolYears.find(
        (year) => year.id === courseClassroom.schoolYearId
      );

      if (!courseSchoolYear) return;

      const courseOrganisation = organisations.find(
        (org) => org.id === courseClassroom.organisationId
      );
      if (!courseOrganisation) return;

      if (isAdmin) {
        return (
          courseClassroom?.id === selectedClassroom &&
          courseOrganisation?.id === selectedOrganisation &&
          courseSchoolYear?.id === selectedSchoolYear
        );
      } else {
        return (
          courseClassroom?.id === selectedClassroom &&
          courseOrganisation?.id === selectedOrganisation &&
          courseSchoolYear?.id === selectedSchoolYear &&
          (c?.createdByUserId === profile?.id ||
            courseClassroom.createdByUserId === profile?.id ||
            ("teachers" in courseClassroom &&
              profile?.id in courseClassroom.teachers) ||
            ("directors" in courseOrganisation &&
              profile?.id in courseOrganisation.directors))
        );
      }
    });
    return filteredCourses;
  } else {
    return [];
  }
};
export function getFileType(file) {
  if (typeof file === "undefined") {
    return;
  }

  if (file.type.match("image.*")) {
    return "image";
  }

  if (file.type.match("video.*")) {
    return "video";
  }

  if (file.type.match("audio.*")) {
    return "audio";
  }
  if (file.type.match("application/pdf")) {
    return "pdf";
  }

  return "other";
}

export function generateRandomId() {
  return uuid_v4();
}

export const checkIfSameFile = (fileA, fileB) => {
  return (
    fileA?.type === fileB?.type &&
    fileA?.name === fileB?.name &&
    fileA?.size === fileB?.size
  );
};

export function getJewishSchoolYear(schoolYear) {
  if (schoolYear === undefined) return "";
  const parts = schoolYear.split("-");
  const partA = parseInt(parts[0]) + 3760;
  const partB = parseInt(parts[1]) + 3760;
  return `${schoolYear} (${partA}-${partB})`;
}

export function getQueryParams(location) {
  return queryString.parse(location.search);
}

export function isTeacher(profile) {
  if (profile) {
    return profile.type === "teacher";
  }
  return false;
}
export function generateRandomPassword(length = 10) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
export const ageDropdownList = [null, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export function getCurrentLng() {
  return window.localStorage.i18nextLng || "";
}
export const languageOptions = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "fr",
    label: "Français",
  },
  {
    value: "he",
    label: "Hebrew",
  },
];
export function getGameTopicTypes(withAll = false) {
  const currentLng = getCurrentLng();
  const types = [];

  for (const t in topics) {
    types.push({ value: t, label: topics[t].name[currentLng] });
  }

  if (withAll) {
    types.unshift({
      value: "-all-",
      label: "- All -",
    });
  }

  return types;
}

export function getGameTopics(gameTopicType, withAll = false) {
  const currentLng = getCurrentLng();
  const arr = [];

  if (!gameTopicType) {
    for (const [k, v] of Object.entries(topics)) {
      const x = v;
      for (const t in x.topics) {
        arr.push({ value: t, label: x.topics[t].name[currentLng] });
      }
    }
  } else {
    const x = topics[gameTopicType];

    if (x) {
      for (const t in x.topics) {
        arr.push({ value: t, label: x.topics[t].name[currentLng] });
      }
    }
  }

  if (withAll) {
    arr.unshift({
      value: "-all-",
      label: "- All -",
    });
  }

  return arr;
}

export function compareDates(date1, date2) {
  // Convert to JavaScript Date objects
  const jsDate1 = new Date(
    date1.createdAt.seconds * 1000 + date1.createdAt.nanoseconds / 1e6
  );
  const jsDate2 = new Date(
    date2.createdAt.seconds * 1000 + date2.createdAt.nanoseconds / 1e6
  );

  // Compare the dates
  return jsDate1 - jsDate2;
}

export function getFrenchMonthName(month) {
  const frenchMonthNames = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  return frenchMonthNames[month];
}
//getFilteredCourses
export function formatDateFrench(dateObj) {
  // Convert to JavaScript Date object
  const jsDate = new Date(dateObj.seconds * 1000 + dateObj.nanoseconds / 1e6);

  // Extract day, month, and year components
  const day = jsDate.getDate();
  const monthName = getFrenchMonthName(jsDate.getMonth());
  const year = jsDate.getFullYear();

  // Construct the date string in the desired format
  return `${day} ${monthName} ${year}`;
}

export function getGameSubTopics(gameTopicType, gameTopic, withAll = false) {
  const currentLng = getCurrentLng();
  const arr = [];

  // console.log('gameTopicType', gameTopicType);
  // console.log('gameTopic', gameTopic);

  if (!gameTopicType) {
    for (const [k, v] of Object.entries(topics)) {
      if (gameTopic && gameTopic in v.topics) {
        const x = v.topics[gameTopic];
        for (const t in x.subTopics) {
          arr.push({ value: t, label: x.subTopics[t].name[currentLng] });
        }
      }
    }
  } else {
    if (!gameTopic) return [];
    // const y =
    const x = topics[gameTopicType]?.topics[gameTopic];
    if (!x) return [];

    // console.log('x', x);

    for (const t in x.subTopics) {
      arr.push({ value: t, label: x.subTopics[t].name[currentLng] });
    }
  }

  if (withAll) {
    arr.unshift({
      value: "-all-",
      label: "- All -",
    });
  }

  return arr;
}

export function getResourceInfo(file) {
  const mediaType = getFileType(file);
  let resource;
  let rKey;

  if (mediaType === "image") {
    resource = "Images";
    rKey = "image";
  } else if (mediaType === "video") {
    resource = "Videos";
    rKey = "video";
  } else if (mediaType === "pdf") {
    resource = "PDF";
    rKey = "pdf";
  } else if (mediaType === "audio") {
    resource = "Audio";
    rKey = "audio";
  }

  return { resource, rKey };
}

export const getPath = (type, id) => {
  let resource = null;
  let rKey = null;

  if (type.startsWith("image")) {
    resource = "Images";
    rKey = "image";
  } else if (type.startsWith("video")) {
    resource = "Videos";
    rKey = "video";
  } else if (type.startsWith("pdf")) {
    resource = "PDF";
    rKey = "pdf";
  } else if (type.startsWith("audio")) {
    resource = "Audio";
    rKey = "audio";
  }
  return `/${resource}/${id}/${rKey}`;
};

export function withDefaultTitle(title) {
  return `${title} | My Torah Kids`;
}
