import React, { useContext, useState } from "react";
import { useForm, zodResolver } from "@mantine/form";
import { z } from "zod";
import { serverTimestamp } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { withLanguage } from "../../../lib/i18";
import GlobalContext from "../../../context/GlobalContext";
import { checkIfSameFile, generateRandomId } from "../../../utils/utils";
import { uploadFileToStorage } from "../../../api/Storage";
import {
  createOrganisation,
  updateOrganisation,
} from "../../../api/Organisations";
import { useHistory } from "react-router-dom";
import { useModals } from "@mantine/modals";

function useNewOrgamisationForm({ update = false } = {}) {
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const { profile } = useContext(GlobalContext);
  const history = useHistory();
  const { t } = useTranslation();
  const modals = useModals();
  const schema = z.object({
    city: z.string().optional(),
    type: z
      .string()
      .min(
        2,
        t(
          "Please fill in the required fields in order to create a new organisation."
        )
      ),
    subType: z.string().optional(),
    name: z
      .string()
      .min(
        2,
        t(
          "Please fill in the required fields in order to create a new organisation."
        )
      ),
    description: z.string().optional(),
    email: z.string().email().or(z.literal("")).optional(),
    phoneNumber: z.string().optional(),
    zip: z.string().optional(),
    address: z.string().optional(),
    coverImage: z.instanceof(File).or(z.literal(null)),
    audioFile: z.instanceof(File).or(z.literal(null)),
    // directors: z.array([]),
  });

  const onFormSubmit = async (values) => {
    setLoading(true);
    if (!profile) {
      setLoading(false);
      return;
    }
    const {
      directors,
      coverImage,
      audioFile,
      city,
      type,
      subType,
      name,
      description,
      email,
      phoneNumber,
      zip,
      country = "",
      address,
    } = values;

    const data = {
      type,
      subType,
      name,
      description,
      email,
      phoneNumber,
      country,
      city,
      zip,
      address,
      createdByUserId: profile.id,
      coverImage: null,
      audioFile: null,
    };
    if (update) {
      data.updatedAt = serverTimestamp();
    } else {
      data.createdAt = serverTimestamp();
    }
    if (directors.length > 0) {
      const dirs = {};
      for (const d of directors) {
        dirs[d.id] = "director";
      }
      data.directors = dirs;
    } else {
      data.directors = {};
    }

    try {
      // Upload cover image
      if (coverImage) {
        if (
          originalData &&
          originalData.coverImage &&
          checkIfSameFile(coverImage, originalData.coverImage)
        ) {
          data.coverImage = originalData.coverImage;
        } else {
          const randomId = generateRandomId();
          const iFile = {
            id: randomId,
            type: coverImage.type,
            name: coverImage.name,
            size: coverImage.size,
          };
          data.coverImage = iFile;

          try {
            await uploadFileToStorage({
              file: coverImage,
              path: `Images/${iFile.id}/image`,
            });
          } catch (error) {
            console.log("Imageupload error", error);
          }
        }
      }
      // Upload audio file
      if (audioFile) {
        if (
          originalData &&
          originalData.audioFile &&
          checkIfSameFile(audioFile, originalData.audioFile)
        ) {
          data.audioFile = originalData.audioFile;
        } else {
          const randomId = generateRandomId();
          const aFile = {
            id: randomId,
            type: audioFile.type,
            name: audioFile.name,
            size: audioFile.size,
          };
          data.audioFile = aFile;
          try {
            await uploadFileToStorage({
              file: audioFile,
              path: `Audio/${aFile.id}/audio`,
            });
          } catch (error) {
            console.log("Audioupload error", error);
          }
        }
      }
      let res = null;
      if (update) {
        res = await updateOrganisation(originalData.id, data);
        modals.openContextModal("success", {
          // title: "Test modal from context",
          centered: true,
          radius: "lg",
          innerProps: {
            modalBody: t("Organisation updated."),
          },
        });
      } else {
        res = await createOrganisation(data);
      }

      if (!res.id) return;
      // resetFormValues();
      setLoading(false);
      history.push(withLanguage(`/organisations/${res.id}`));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const form = useForm({
    schema: zodResolver(schema),
    initialValues: {
      coverImage: null,
      audioFile: null,
      type: "",
      subType: "",
      name: "",
      description: "",
      email: "",
      phoneNumber: "",
      city: "",
      zip: "",
      country: "",
      address: "",
      directors: [],
    },
  });

  const setDefaultData = (data, rawData) => {
    setOriginalData(rawData);
    form.setValues(data);
  };
  return { form, loading, onFormSubmit, setDefaultData };
}

export default useNewOrgamisationForm;
