import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

export default function Head({ children }) {
  return (
    <Helmet>
      {children}

      {/* Fonts */}

      <Fragment>
        {/* LapsusPro */}
        <link
          // rel="preload"
          href="/fonts/LapsusPro/LapsusPro-Bold.otf"
          as="font"
          crossOrigin=""
        />

        {/* Roboto */}
        <link
          // rel="preload"
          href="/fonts/Roboto/Roboto-Regular.ttf"
          as="font"
          crossOrigin=""
        />

        <link
          // rel="preload"
          href="/fonts/Roboto/Roboto-Medium.ttf"
          as="font"
          crossOrigin=""
        />

        <link
          // rel="preload"
          href="/fonts/Roboto/Roboto-Bold.ttf"
          as="font"
          crossOrigin=""
        />
      </Fragment>
    </Helmet>
  );
}
