import React from "react";
import { DirectorsBtn } from "../../NewOrganisation";
import { Box, Title, Button, Grid, Stack } from "@mantine/core";
import { capitalize } from "../../../../utils/utils";
import { withLanguage } from "../../../../lib/i18";
import MediaUploader from "../../../../components/MediaUploader";
import MTKInput from "../../../../components/Input";
import AudioUploader from "../../../../components/AudioUploader";
import MTKSelect from "../../../../components/Select";
import MTKTextarea from "../../../../components/Textarea";
import MTKPhoneInput from "../../../../components/PhoneInput";
import MTKCountryInput from "../../../../components/CountryInput";
import { useTranslation } from "react-i18next";
import { ReactComponent as TrashCanIcon } from "../../../../assets/images/icons/trash-can.svg";
import { useModals } from "@mantine/modals";

function AboutTab({
  form,
  pageContent,
  orgSubType,
  orgTypes,
  directors,
  onDeleteOrg,
}) {
  const { t } = useTranslation();
  const modals = useModals();

  const onDelete = () => {
    modals.openContextModal("confirmation", {
      //   title: t('Delete Media File'),
      centered: true,
      radius: "lg",
      innerProps: {
        title: t("Delete organisation"),
        modalBody: t("Are you sure you want to delete this organisation?"),
        onCancel: () => console.log("Cancel"),
        onConfirm: () => {
          onDeleteOrg();
        },
      },
    });
  };
  return (
    <Box>
      <Grid gutter="xl">
        <Grid.Col md={12} lg={6}>
          <Section
            title={
              pageContent?.fields[
                `generalDescTitle${capitalize(withLanguage("").substring(1))}`
              ]
            }
          >
            <MediaUploader
              ratio={1}
              {...form.getInputProps("coverImage")}
              recommendedAspectRatio="1/1 (512*512)"
            />
            <MTKInput
              label={`${t("Name")}`}
              required
              labelColor="blue"
              {...form.getInputProps("name")}
            />
            <AudioUploader {...form.getInputProps("audioFile")} />
            <MTKSelect
              data={orgTypes}
              label={t("Type")}
              required
              {...form.getInputProps("type")}
            />
            <MTKSelect
              label={`${t("Sub Type")}`}
              data={orgSubType}
              {...form.getInputProps("subType")}
            />

            <MTKTextarea
              label={t("Description")}
              minRows={4}
              {...form.getInputProps("description")}
            />
          </Section>
        </Grid.Col>
        <Grid.Col md={12} lg={6}>
          <Stack spacing={"xl"}>
            <Section
              title={
                pageContent?.fields[
                  `contactDetailsTitle${capitalize(
                    withLanguage("").substring(1)
                  )}`
                ]
              }
            >
              <MTKInput
                type="email"
                label={t("Email")}
                labelColor="blue"
                {...form.getInputProps("email")}
              />
              <MTKPhoneInput
                label={t("Phone Number")}
                labelColor="blue"
                {...form.getInputProps("phoneNumber")}
              />
            </Section>
            <Section
              title={
                pageContent?.fields[
                  `addressTitle${capitalize(withLanguage("").substring(1))}`
                ]
              }
            >
              <MTKCountryInput
                {...form.getInputProps("country")}
                label={t("Country")}
                labelColor="blue"
              />
              <MTKInput
                label={t("City")}
                labelColor="blue"
                {...form.getInputProps("city")}
              />
              <MTKInput
                label={t("Zip")}
                labelColor="blue"
                {...form.getInputProps("zip")}
              />
              <MTKInput
                label={t("Address")}
                labelColor="blue"
                {...form.getInputProps("address")}
              />
            </Section>
            <Section
              title={
                pageContent?.fields[
                  `directorsTitle${capitalize(withLanguage("").substring(1))}`
                ]
              }
            >
              <Box
                p="xl"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box>
                  {directors.map((d) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "30px",
                      }}
                    >
                      <Box sx={(theme) => ({ color: theme.colors.blue })}>
                        {d?.firstName} {d?.name}
                      </Box>
                      <Button
                        color={"red"}
                        radius="lg"
                        compact
                        onClick={() =>
                          form.setFieldValue(
                            "directors",
                            directors.filter((dir) => d.id !== dir.id)
                          )
                        }
                      >
                        {t("Remove")}
                      </Button>
                    </Box>
                  ))}
                </Box>
                <DirectorsBtn {...form.getInputProps("directors")} />
              </Box>
            </Section>
          </Stack>
        </Grid.Col>
      </Grid>

      <Box
        mt="lg"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="red"
          radius={"xl"}
          size="md"
          leftIcon={<TrashCanIcon color="white" />}
          onClick={onDelete}
        >
          {t("Delete")}
        </Button>
      </Box>
    </Box>
  );
}
const Section = ({ title = "", children }) => {
  return (
    <Box>
      <Title
        sx={{
          fontSize: "24px",
          color: "rgb(1, 158, 211)",
          paddingLeft: "10px",
          marginTop: "0px",
          marginBottom: "20px",
        }}
      >
        {title}
      </Title>
      <Box
        sx={{
          background: "rgb(239, 247, 255)",
          borderRadius: "22px",
          padding: "20px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
export default AboutTab;
