import { Box, Image, SimpleGrid } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AudioUploader from "../../../../components/AudioUploader";
import MTKButton from "../../../../components/Button";
import MTKInput from "../../../../components/Input";
import FormSection from "../../../../components/layout/FormSection";
import MediaUploader from "../../../../components/MediaUploader";
import { withLanguage } from "../../../../lib/i18";
import { capitalize } from "../../../../utils/utils";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";

import checkImage from "../../../../assets/images/icons/checkmark.png";
const stringToHtml = (text) => {
  return ReactHtmlParser(documentToHtmlString(text));
};

const Multiple = ({ questionData = {}, questionMeta, form }) => {
  const { t } = useTranslation();

  // const deleteAnswerMediaFile = async (answerIndex) => {

  //   let newAnswer = Object.assign({}, questionData.answers[answerIndex]);
  //   delete newAnswer.media;
  //   const tempAnswers = questionData.answers;
  //   tempAnswers[answerIndex] = newAnswer;
  //   changeQuestionData({ answers: tempAnswers });
  //   setQuestionAnswerOneMediaFile(undefined);
  //   console.log("question deleted");
  // };
  const { answers } = form.values;

  const onAnswerMediaChange = (file, index) => {
    form.setFieldValue(
      "answers",
      answers.map((answer, i) =>
        i === index ? { ...answer, media: file } : answer
      )
    );
  };
  const onAnswerTextChange = (text, index) => {
    form.setFieldValue(
      "answers",
      answers.map((answer, i) =>
        i === index ? { ...answer, answer: text } : answer
      )
    );
  };
  const onAnswerAudioChange = (file, index) => {
    form.setFieldValue(
      "answers",
      answers.map((answer, i) =>
        i === index ? { ...answer, audio: file } : answer
      )
    );
  };
  const onAnswerCorrectAnswerChange = (value, index) => {
    form.setFieldValue(
      "answers",
      answers.map((answer, i) =>
        i === index
          ? { ...answer, isCorrectAnswer: true }
          : { ...answer, isCorrectAnswer: false }
      )
    );
  };

  return (
    <FormSection
      transparentBg
      color="green"
      margin="0px 0px 40px 0px"
      contentPadding="0px"
      layout="row"
      title={
        questionMeta?.fields[
          `multipleQuestionConfigurationTitle${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      }
      description={stringToHtml(
        questionMeta?.fields[
          `${"multiple"}QuestionConfigurationDescription${capitalize(
            withLanguage("").substring(1)
          )}`
        ]
      )}
    >
      <Answers>
        {answers.map((answer, index) => (
          <Answer key={answers.id}>
            <MediaUploader
              ratio={4 / 3}
              value={answer.media}
              onChange={(file) => onAnswerMediaChange(file, index)}
              recommendedAspectRatio="4/3 (800*600)"
              isInGame={true}
              objectDescription={t("a video or image")}
            />
            <MTKInput
              required
              label={`${t("Answer")}`}
              {...form.getInputProps("answers.answer")}
              value={answer.answer}
              onChange={(e) => onAnswerTextChange(e.target.value, index)}
            />
            <AudioUploader
              sx={{
                maxWidth: "90%",
              }}
              color="green"
              // size="small"
              value={answer.audio}
              onChange={(file) => onAnswerAudioChange(file, index)}
            />
            <CheckBtn
              value={answer.isCorrectAnswer}
              onChange={(value) => onAnswerCorrectAnswerChange(value, index)}
            />
          </Answer>
        ))}
      </Answers>
    </FormSection>
  );
};

const Answers = ({ children }) => (
  <SimpleGrid
    cols={2}
    breakpoints={[{ maxWidth: 1500, cols: 1, spacing: "md" }]}
  >
    {children}
  </SimpleGrid>
);
const Answer = ({ children }) => (
  <Box
    sx={{
      background: "#edf7e9",
      borderRadius: "22px",
      padding: "30px 20px",
    }}
  >
    {children}
  </Box>
);
export default Multiple;

const CheckBtn = ({ value, onChange }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <Box
        onClick={() => onChange(true)}
        sx={{
          backgroundColor: value ? "rgb(147, 213, 120)" : "transparent",
          border: " 2px solid rgb(147, 213, 120)",
          color: "rgb(147, 213, 120)",
          padding: "0px",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          width: "35px",
          height: "35px",
          display: "flex",
          cursor: "pointer",
        }}
      >
        {value && <Image src={checkImage} width={14} height={14} />}
      </Box>
    </Box>
  );
};
