import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Image,
  Stack,
  Box,
  Text,
  Title,
  Group,
  Center,
} from "@mantine/core";
// Note: Needed to support recording on Safari. See here: https://github.com/ai/audio-recorder-polyfill
import AudioRecorder from "audio-recorder-polyfill";
import Stopwatch from "timer-stopwatch";
import errorSign from "../../../assets/images/icons/error-sign.png";
import microphone from "../../../assets/images/icons/microphone.svg";

import playImage from "../../../assets/images/icons/play.png";
import pauseImage from "../../../assets/images/icons/pause.png";
import stopImage from "../../../assets/images/icons/stop.png";
import resetImage from "../../../assets/images/icons/reset.png";
window.MediaRecorder = AudioRecorder;

var mediaRecorder;
var stopRecording = false;
var resetRecording = false;
var cancelRecording = false;
var chunks = [];

export default function RecordAudioFile({
  type = "",
  title = "00:01",
  description = "",
  cancelCallback = undefined,
  setStep,
  onChange,
  setIsNewFile,
}) {
  const stopwatch = new Stopwatch(0);

  const { t } = useTranslation();

  const options = { mimeType: "audio/wav" };

  const [paused, setPaused] = useState(true);
  const [started, setStarted] = useState(false);
  const [endRecording, setEndRecording] = useState(false);

  // Timer
  const [timer, setTimer] = useState(0);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);
  const countRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState(null);

  useEffect(() => {
    let interval = null;

    if (!paused && started) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
    // setTime(stopwatch.ms);
  }, [paused, started, setPaused, setStarted]);

  function msToMnAndSds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }

  function startTimer() {
    // stopwatch.start();
    if (mediaRecorder) {
      setIsPaused(true);
      countRef.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    }
  }

  function pauseTimer() {
    stopwatch.stop();
    clearInterval(countRef.current);
    setIsPaused(true);
  }

  function resumeTimer() {
    if (mediaRecorder) {
      stopwatch.start();
      setIsPaused(false);
      countRef.current = setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    }
  }

  function resetTimer() {
    if (mediaRecorder) {
      stopwatch.ms = 0;
      setTime(0);
      clearInterval(countRef.current);
      setIsPaused(false);
      setTimer(0);
    }
  }

  // useEffect(() => {
  //   restartRecording();
  // }, []);

  function stopRunningTracks() {
    // Need to stop the tracks from all streams to really stop the recording
    // Without that the user would be stuck in the record mode
    // See here: https://stackoverflow.com/questions/44274410/mediarecorder-stop-doesnt-clear-the-recording-icon-in-the-tab
    mediaRecorder.stream.getTracks().forEach((track) => track.stop());

    if (stopRecording) {
      executeStopRecordingTasks();
      resetTimer();
      return;
    }

    if (resetRecording) {
      executeResetRecordingTasks();
      return;
    }

    if (cancelRecording) {
      cancelRecording = false;
      chunks = [];
      resetTimer();
    }
  }

  function executeResetRecordingTasks() {
    resetRecording = false;
    restartRecording();
    resetTimer();
    setPaused(false);
  }

  async function executeStopRecordingTasks() {
    stopRecording = false;
    const audioBlob = new Blob(chunks, {
      type: "audio/wav",
    });
    audioBlob.name = "record.wav";

    const wav = new File([audioBlob], "newRecording.wav", {
      type: audioBlob.type,
      lastModified: Date.now(),
    });
    onChange(wav);
    setStep("playAudioWithDelete");
    setIsNewFile(true);
    mediaRecorder?.stop();
    chunks = [];
  }

  function restartRecording() {
    // Ensure to reset previous recorded audio
    chunks = [];
    startRecording();
  }

  function startRecording() {
    // setStarted(true);
    setPaused(true);
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia(
          // constraints - only audio needed for this app
          {
            audio: true,
          }
        )
        // Success callback
        .then(function (stream) {
          mediaRecorder = new MediaRecorder(stream, options);

          mediaRecorder.addEventListener("dataavailable", (event) => {
            if (event.data.size > 0) {
              chunks.push(event.data);
            }
          });

          mediaRecorder.addEventListener("stop", () => {
            stopRunningTracks();
          });

          mediaRecorder.addEventListener("start", () => {
            if (isPaused) {
              resumeTimer();
            } else {
              startTimer();
            }
          });

          mediaRecorder.start();
        })

        // Error callback
        .catch(function (err) {
          setErrorMsg("The following getUserMedia error occurred: " + err);
          console.log("The following getUserMedia error occurred: " + err);
        });
    } else {
      setErrorMsg("getUserMedia not supported on your browser!");
      console.log("getUserMedia not supported on your browser!");
    }
  }

  function toggleRecording() {
    try {
      if (endRecording) {
        mediaRecorder.stop();
        setPaused(true);
      }

      if (paused) {
        startRecording();
        // stopwatch.start();
        setPaused(false);
        mediaRecorder.resume();
      } else {
        mediaRecorder.pause();
        setPaused(true);
        // stopwatch.stop();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // const getSeconds = `0${timer % 60}`.slice(-2);
  // const minutes = `${Math.floor(timer / 60)}`;
  // const getMinutes = `0${minutes % 60}`.slice(-2);

  return (
    <Stack>
      <Box>
        {(type === "error" || errorMsg) && (
          <>
            <Image src={errorSign} width={82} margin="0px 0px 30px 0px" />
          </>
        )}
        {errorMsg}
      </Box>
      {title.length > 0 && (
        <Title
          sx={{
            fontSize: "64px",
            marginTop: "0",
          }}
          align={"center"}
        >
          {msToMnAndSds(time)}
        </Title>
      )}
      {description.length > 0 && <Text align={"center"}>{description}</Text>}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {started ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              padding: "0 25px",
            }}
          >
            <Button
              leftIcon={
                <Image width={8} src={paused ? playImage : pauseImage} />
              }
              // iconWidth="8px"

              width="300px"
              mb="md"
              radius={"lg"}
              color="indigo"
              fullWidth
              onClick={() => {
                toggleRecording();
              }}
              // containerDisplay="block"
              // color="darkBlue"
            >
              {paused ? t("Resume Recording") : t("Pause Recording")}
            </Button>

            <Button
              // icon="stop.png"
              leftIcon={<Image width={10} src={stopImage} />}
              // iconWidth="10px"
              // title={t('End Recording')}
              width="300px"
              mb="md"
              radius={"lg"}
              color="cyan"
              fullWidth
              onClick={() => {
                setEndRecording(true);
                stopRecording = true;
                if (paused) {
                  executeStopRecordingTasks();
                } else {
                  mediaRecorder?.stop();
                }
              }}
              // containerDisplay="block"
              // margin="10px 0px"
              // mobileMargin="10px 0px"
            >
              {t("End Recording")}
            </Button>

            <Button
              leftIcon={<Image width={16} src={resetImage} />}
              icon="reset.png"
              iconWidth="16px"
              title={t("Reset Recording")}
              width="300px"
              onClick={() => {
                setStep("initial");
                onChange(null);
              }}
              mb="md"
              radius={"lg"}
              color="red"
              fullWidth
            >
              {t("Reset Recording")}
            </Button>
            {/* <Button width="150px" onClick={() => yesCallback()}>
              {t("Yes")}
            </Button> */}
          </Box>
        ) : (
          <a href="#">
            <img
              src={microphone}
              onClick={() => {
                setStarted(true);

                toggleRecording();
              }}
              height={"111px"}
              width={"111px"}
              style={{
                margin: "15px 0",
              }}
            />
          </a>
        )}

        <Button
          width="200px"
          onClick={() => {
            if (!paused) {
              if (mediaRecorder) {
                cancelRecording = true;
                mediaRecorder?.stop();
              }
            } else {
              chunks = [];
            }
            // onChange(null);
            cancelCallback();
          }}
          color="grey"
          mt="lg"
          variant="subtle"
        >
          {t("Cancel")}
        </Button>
      </Box>
    </Stack>
  );
}
