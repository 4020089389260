import { Box, TextInput, Title } from "@mantine/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import MTKButton from "../../../../components/Button";
import { ReactComponent as SignUpIcon } from "../../../../assets/images/icons/box-arrow.svg";
import MTKInput from "../../../../components/Input";
import { Edit, Plus, Search, Upload } from "tabler-icons-react";
import MTKTable from "../../../../components/Table";
import { useParams } from "react-router-dom";
import {
  getReportsOfClassroom,
  getStudentsOfClassroom,
} from "../../../../api/Classrooms";
import { useState } from "react";
import { getStudentsAnswerOfGame } from "../../../../api/StudentsAnswers";
import { getQuestionsOfGame } from "../../../../api/Questions";
import { calculateTotalGameReportStudentPercentage } from "../../../Games/GameDetails/components/ReportsTab";
import { useMemo } from "react";
import { memo } from "react";
import { useFocusTrap } from "@mantine/hooks";
import {
  createCourseOtherGame,
  deleteCourseOtherGame,
  getOtherGamesOfCourse,
  updateCourseOtherGame,
} from "../../../../api/Games";
import { useModals } from "@mantine/modals";

function ReportsTab({
  questions,
  classroom,
  organisation,
  courseId,
  games,
  otherGames = [],
  refreshData,
}) {
  const { t } = useTranslation();
  const [addNewGame, setAddNewGame] = useState(false);
  const [editOfflineGame, setEditOfflineGame] = useState(false);
  const [students, setStudents] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [gamesWithAnswer, setGamesWithAnswer] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [newGameName, setNewGameName] = useState("");
  const [newGameMarks, setNewGameMarks] = useState({});
  const [loading, setLoading] = useState(false);

  const modals = useModals();
  useEffect(() => {
    if (classroom && classroom?.id) {
      getStudentsOfClassroom(classroom.id).then((res) => setStudents(res));
      getReportsOfClassroom(classroom.id).then((res) => setReportData(res));
    }
  }, [classroom]);

  // useEffect(() => {
  //   const getData = async () => {
  //     const gamesWithAnswer = await Promise.all(
  //       games.map(async (game) => {
  //         const answers = await getStudentsAnswerOfGame(game.id);
  //         const questions = await getQuestionsOfGame(game.id);
  //         return { ...game, studentsAnswer: answers, questions };
  //       })
  //     );

  //     setGamesWithAnswer(gamesWithAnswer);
  //   };
  //   if (games) {
  //     getData();
  //   }
  // }, [games]);

  // useEffect(() => {
  //   if (!students || !gamesWithAnswer) return null;
  //   const studentsData = students.map((d) => ({
  //     id: d.id,
  //     ...calculateTotalCourseMean(gamesWithAnswer, otherGames, d.id),
  //   }));
  //   setStudentsData(studentsData);
  // }, [students, gamesWithAnswer, otherGames]);

  const handleAddNewOfflineGame = async () => {
    if (!newGameName) return;
    setLoading(true);
    const newGame = {
      name: newGameName,
      students: newGameMarks,
    };
    await createCourseOtherGame(courseId, newGame);

    setNewGameMarks({});
    setNewGameName("");
    setAddNewGame(false);
    setEditOfflineGame(null);
    refreshData();
    setLoading(false);
  };
  const handleUpdateOfflineGame = async () => {
    if (!newGameName || !editOfflineGame) return;
    setLoading(true);
    const newGame = {
      name: newGameName,
      students: newGameMarks,
    };
    await updateCourseOtherGame(courseId, editOfflineGame, newGame);

    setNewGameMarks({});
    setNewGameName("");
    setAddNewGame(false);
    setEditOfflineGame(null);
    refreshData();
    setLoading(false);
  };
  const handleDeleteOfflineGame = async (id) => {
    if (!id) return;
    return new Promise((resolve, reject) => {
      modals.openContextModal("confirmation", {
        //   title: t('Delete Media File'),
        id: "confirmation",
        centered: true,
        radius: "lg",
        innerProps: {
          title: t("Delete This Offline Question"),
          modalBody: "",
          onCancel: () => {
            resolve(false);
            console.log("Cancel");
          },
          onConfirm: async () => {
            await deleteCourseOtherGame(courseId, id);
            setNewGameMarks({});
            setNewGameName("");
            setAddNewGame(false);
            setEditOfflineGame(null);
            refreshData();
            setLoading(false);
            resolve(true);
          },
        },
      });
    });
  };
  const handleEditOfflineGame = (id) => {
    const offlineGame = otherGames.find((d) => d.id === id);

    modals.openContextModal("editOfflineGame", {
      //   title: t('Delete Media File'),
      centered: true,
      id: "editOfflineGame",
      radius: "lg",
      innerProps: {
        onCancel: () => console.log("Cancel"),
        onEditGameTotal: () => {
          // setEditOfflineGame(id);
          // handleUpdateOfflineGame();
        },
        onEditMarks: () => {
          setNewGameMarks(offlineGame.students);
          setNewGameName(offlineGame.name);
          setAddNewGame(false);
          setEditOfflineGame(id);
        },
        onDelete: async () => {
          setEditOfflineGame(null);
          await handleDeleteOfflineGame(id);
        },
      },
    });
  };
  const onHeaderClick = (col) => {
    if (col.isOffline) {
      return {
        onClick: () => {
          handleEditOfflineGame(col.id);
        },
      };
    }
    return {};
  };
  const data = React.useMemo(
    () =>
      students
        .filter((d) => d.firstName || d.name)
        .map((student) => {
          const data = reportData?.find((d) => d.studentId === student.id);
          const courseData =
            data &&
            data?.report?.coursesWithScore?.find(
              (c) => c.courseID === courseId
            );
          if (
            !data ||
            !courseData?.mean ||
            (!student?.firstName && !student?.name)
          ) {
            return null;
          }

          return {
            name: `${student?.firstName || ""} ${student?.name || ""}`,
            mean:
              !courseData?.mean || isNaN(courseData?.mean)
                ? "-"
                : (courseData?.mean * 10).toFixed(2),
            data: courseData || {},
            id: student.id,
          };
        })
        .filter((d) => d),
    [students, studentsData]
  );

  const columns = React.useMemo(
    () =>
      [
        {
          Header: "Students",
          accessor: "name", // accessor is the "key" in the data
          isSticky: true,
        },
        {
          Header: "Mean",
          accessor: "mean",
          // isSticky: true,
          // width: "100px",
        },
        ...games.map((game, i) => ({
          Header: game.name,
          accessor: ({ data }) => {
            const { gamesWithScore } = data;
            const gameData = gamesWithScore?.find((g) => g.gameID === game.id);
            console.log(gameData, gamesWithScore, game.id);
            const mean = gameData?.mean * 10;
            if (gameData) {
              return isNaN(mean) ? "-" : mean.toFixed(2);
            } else {
              return "-";
            }
          },
        })),
        ...otherGames.map((game, i) => ({
          id: game.id,
          isOffline: true,
          editable: editOfflineGame === game.id,
          name: game.name,
          Header: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box mr="sm">{game.name}</Box> <Edit size={15} />
            </Box>
          ),
          disableSortBy: true,

          accessor: ({ data = {} }) => {
            const { otherGamesWithScore } = data;

            if (otherGamesWithScore && otherGamesWithScore[game.id]) {
              return otherGamesWithScore[game.id];
            } else {
              return "-";
            }
          },
        })),
        addNewGame
          ? {
              id: "newCol",
              disableSortBy: true,
              width: "150px",
              Header: "newCol",
              accessor: null,
            }
          : null,
      ].filter((d) => d),
    [games, addNewGame, editOfflineGame]
  );

  console.log({ courseId });
  console.log(classroom.id);

  return (
    <Box>
      {addNewGame || editOfflineGame ? (
        <Box
          mb="25px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            order={2}
            sx={{
              color: "rgb(147, 213, 120)",
            }}
          >
            {addNewGame ? t("Adding New Grades") : t("Editing Grades")}
          </Title>
          <Box>
            <MTKButton
              onClick={() => {
                setAddNewGame(false);
                setEditOfflineGame(null);
              }}
              variant="light"
              color="blue"
            >
              {t("Cancel")}
            </MTKButton>

            <MTKButton
              onClick={
                addNewGame ? handleAddNewOfflineGame : handleUpdateOfflineGame
              }
              color="green"
              loading={loading}
            >
              {t("Save")}
            </MTKButton>
          </Box>
        </Box>
      ) : (
        <Box
          mb="25px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box>
            <MTKButton
              mt="md"
              mr="md"
              leftIcon={<Upload size={15} />}
              color="blue"
            >
              {t("Export")}
            </MTKButton>
            <MTKButton
              mt="md"
              leftIcon={<Plus size="15" />}
              color="green"
              onClick={() => {
                setAddNewGame(true);
                setEditOfflineGame(null);
              }}
            >
              {t("Add Marks")}
            </MTKButton>
          </Box>
          <MTKInput
            sx={{
              minWidth: "300px",
            }}
            leftIcon={<Search />}
            label={t("Search")}
            placeholder={t("Search a Student...")}
          />
        </Box>
      )}
      <Box mb="100px">
        <MTKTable
          loading={loading}
          data={data}
          columns={columns}
          tableText={t("Students")}
          noDataText={t("No Students added yet")}
          clickableHeader={onHeaderClick}
          onNewColHeaderChange={(v) => setNewGameName(v)}
          onNewColValuesChange={(v, id) =>
            setNewGameMarks((marks) => ({ ...marks, [id]: v }))
          }
        ></MTKTable>
      </Box>
    </Box>
  );
}

export default ReportsTab;

// export const calculateTotalCourseMean = (
//   gamesWithAnswer,
//   otherGames,
//   studentId,
//   returnNumber = false
// ) => {
//   if (!gamesWithAnswer) {
//     return "";
//   }

//   let mean = 0;
//   const subMean = [];
//   const gamesWithScore = {};
//   const otherGamesWithScore = {};
//   gamesWithAnswer.map((game) => {
//     const { studentsAnswer, questions } = game;
//     let total = 0;
//     const answers = studentsAnswer?.find((d) => d.studentId === studentId);
//     if (answers?.answers) {
//       total = calculateTotalGameReportStudentPercentage(
//         questions,
//         answers?.answers,
//         true
//       );
//     }
//     gamesWithScore[game.id] = (total / 10).toFixed(2);
//     subMean.push(total);
//   });
//   otherGames.map((d) => {
//     if (d && d?.students) {
//       if (d.students[studentId]) {
//         otherGamesWithScore[d.id] = d.students[studentId];
//         subMean.push(d.students[studentId] * 10);
//       }
//     }
//   });
//   let subMeanOnlyPositive = subMean.filter((d) => d);
//   mean =
//     subMeanOnlyPositive.reduce((a, b) => a + b, 0) /
//     subMeanOnlyPositive.length /
//     10;
//   if (!returnNumber) {
//     mean = mean.toFixed(2);
//   }
//   return { mean, gamesWithScore, otherGamesWithScore };
// };
