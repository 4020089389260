import React from "react";
import { Box, Container, createStyles, BackgroundImage } from "@mantine/core";

import backgroundImage from "../../../assets/images/backgrounds/main.jpg";
import { useTranslation } from "react-i18next";

import MTKSideBar from "../SideBar";
import MTKContent from "../Content";
import Head from "../Head";
import { withDefaultTitle } from "../../../utils/utils";

const useStyles = createStyles((theme, _params, getRef) => ({
  container: {
    // z-index: 900;
    background: " rgb(255, 255, 255);",
    width: "calc(100vw - 60px);",
    height: "calc(100vh - 60px);",
    boxShadow:
      "rgb(0 0 0 / 13%) 0px 5px 5px 0px, rgb(0 0 0 / 13%) 0px 15px 35px 0px;",
    position: "fixed;",
    inset: "30px;",
    borderRadius: "22px;",
    display: " flex;",
    overflow: "hidden;",
    padding: "0",

    [`@media screen and (max-width: 900px)`]: {
      width: "calc(100vw - 20px);",
      height: "calc(100vh - 20px);",
      inset: "10px;",
    },
  },
}));

export default function AppLayout({ children, loading = false, title = "" }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Head>
        <title>{withDefaultTitle(title)}</title>
      </Head>
      <BackgroundImage
        src={backgroundImage}
        sx={{
          position: "fixed",
          top: 0,
          left: 0,

          width: "100%",
          height: "100vh",
        }}
      />
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          background: "rgb(195 179 179 / 33%)",
          width: "100%",
          height: "100vh",
        }}
      ></Box>
      <Container fluid className={classes.container}>
        <MTKSideBar />
        <MTKContent loading={loading}>{children}</MTKContent>
      </Container>
    </Box>
  );
}
