import {
  Table,
  createStyles,
  Box,
  TextInput,
  NumberInput,
} from "@mantine/core";
import React from "react";
import { useTable, useSortBy, usePagination, useRowSelect } from "react-table";
import { ReactComponent as ArrowDown } from "../../assets/images/icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../assets/images/icons/arrow-up.svg";
import { useTranslation } from "react-i18next";
import MTKInput from "../Input";
import MTKCheckBox from "../CheckBox";
const useStyles = createStyles((theme, _params, getRef) => ({
  root: {
    background: "rgb(239, 247, 255)",
    borderRadius: "22px",
    padding: "30px 40px",
  },
  table: {
    overflow: "auto hidden",
    whiteSpace: "nowrap",
  },
  body: {
    color: "rgb(1, 158, 211)",
    padding: "15px 0px",
    borderTop: "2px solid rgb(224, 239, 250)",
    cursor: "pointer",
  },
  head: {
    marginBottom: "20px",
  },
  th: {
    background: "rgb(239, 247, 255)",
    fontWeight: 600,
    color: "rgb(0, 0, 0) !important",
    cursor: "pointer",
    width: " 28%",
    margin: " 0px 10px 0px 0px",
    width: "200px",
    // minWidth: "200px",
  },
  td: {
    background: "rgb(239, 247, 255)",

    width: "200px",
    // minWidth: "200px",
  },
}));

function MTKTable({
  tableText = "Organisations",
  data = [],
  columns = [],
  noDataText = "",
  onRowClick = () => null,
  color = "blue",
  addColMode = false,
  onNewColHeaderChange,
  onNewColValuesChange,
  loading,
  clickableHeader = () => ({}),
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const getStickyPadding = (index) =>
    columns.slice(0, index).reduce((acc, x) => (x.isSticky ? acc + 1 : acc), 0);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    pageCount,
    gotoPage,
    page,
    state: { pageIndex, pageSize },
  } = useTable({ columns, data }, useSortBy, usePagination);
  return (
    <Box
      className={classes.root}
      style={{
        background:
          color === "green" ? "rgb(237, 247, 233)" : "rgb(239, 247, 255)",
      }}
    >
      <Box className={classes.table}>
        <Table {...getTableProps()} verticalSpacing="lg">
          <thead className={classes.head}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  if (column.id === "newCol" || column.editable) {
                    return (
                      <th>
                        <TextInput
                          styles={(theme) => ({
                            input: {
                              color: theme.colors.blue,
                              background: "transparent",
                            },
                            defaultVariant: {
                              borderColor: `${theme.colors.blue}`,
                            },
                          })}
                          defaultValue={column.name}
                          sx={{
                            width: "150px",
                          }}
                          placeholder={t("Type Here...")}
                          onChange={(e) => onNewColHeaderChange(e.target.value)}
                        />
                      </th>
                    );
                  }
                  return (
                    <th
                      className={classes.th}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      {...clickableHeader(column)}
                      style={{
                        background:
                          color === "green"
                            ? "rgb(237, 247, 233)"
                            : "rgb(239, 247, 255)",

                        position: column.isSticky ? "sticky" : "initial",
                        left: column.isSticky
                          ? `${getStickyPadding(i) * 150}px`
                          : "0",
                      }}
                    >
                      <Box
                        mb="md"
                        sx={{
                          display: "flex",
                        }}
                      >
                        {column.isSorted ? (
                          <Box
                            sx={{
                              marginRight: "5px",
                            }}
                          >
                            {column.isSortedDesc ? <ArrowDown /> : <ArrowUp />}
                          </Box>
                        ) : null}
                        <Box>{column.render("Header")}</Box>
                      </Box>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className={classes.body}>
            {data.length <= 0 ? (
              <Box
                sx={{
                  color: "rgb(153, 153, 153)",
                  padding: "15px 0px",
                }}
              >
                {t(noDataText)}
              </Box>
            ) : null}
            {page.map((row) => {
              const { original } = row;
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={onRowClick(original)}>
                  {row.cells.map((cell, i) => {
                    const { column } = cell;
                    if (column.id === "newCol" || column.editable) {
                      return (
                        <td>
                          <NumberInput
                            styles={(theme) => ({
                              input: {
                                color: theme.colors.blue,
                                background: "transparent",
                              },
                              defaultVariant: {
                                borderColor: `${theme.colors.blue}`,
                              },
                            })}
                            max={10}
                            min={0}
                            defaultValue={cell.value}
                            sx={{
                              width: "150px",
                            }}
                            placeholder={t("Type Here...")}
                            onChange={(e) =>
                              onNewColValuesChange(e, original.id)
                            }
                          />
                        </td>
                      );
                    }
                    return (
                      <td
                        className={classes.td}
                        {...cell.getCellProps()}
                        style={{
                          background:
                            color === "green"
                              ? "rgb(237, 247, 233)"
                              : "rgb(239, 247, 255)",
                          position: column.isSticky ? "sticky" : "initial",
                          left: column.isSticky
                            ? `${getStickyPadding(i) * 150}px`
                            : "0",
                        }}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          {[...Array(pageCount).keys()].map((d) => (
            <PaginationItem
              onClick={() => {
                console.log(d);
                gotoPage(d);
              }}
              active={pageIndex === d}
            >
              {1 + d}
            </PaginationItem>
          ))}
        </Box>
        <Box
          sx={{
            color: "rgb(178, 178, 178)",
            fontSize: "16px",
          }}
        >
          {rows.length} {t(tableText)}
        </Box>
      </Box>
    </Box>
  );
}

const PaginationItem = ({ active = false, children, ...props }) => {
  return (
    <Box
      sx={{
        cursor: active ? "default" : "pointer",
        width: "26px",
        height: "26px",
        display: "flex",
        borderRadius: "13px",
        justifyContent: "center",
        alignItems: "center",
        background: active ? "rgb(1, 158, 211)" : "transparent",
        color: active ? "rgb(255, 255, 255)" : "rgb(178, 178, 178)",
      }}
      {...props}
    >
      {children}
    </Box>
  );
};
export default MTKTable;
