import { Box } from "@mantine/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../../assets/images/icons/arrow-left.svg";
function BackButtonCircle({ to, color = "blue", ...props }) {
  const history = useHistory();
  return (
    <Box
      sx={{
        cursor: "pointer",
        background:
          color === "blue" ? "rgb(239, 247, 255)" : "rgb(237, 247, 233)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "32px",
        height: "32px",
        borderRadius: "16px",
      }}
      to={to}
      component={Link}
      {...props}
    >
      <LeftArrow />
    </Box>
  );
}

export default BackButtonCircle;
