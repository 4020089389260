import React from "react";
import { Box, Button, Loader } from "@mantine/core";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import MTKButton from "../Button";
function SubmitBottomBar({
  onCreate = () => {},
  onCancel = () => {},
  okText = null,
  loading = false,
  color = "blue",
}) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "fixed",
        display: "flex",

        justifyContent: "flex-end",

        alignItems: "center",
        bottom: "0px",
        left: "0px",
        right: "0px",
        zIndex: 401,
        height: "80px",
        background:
          color === "blue" ? "rgb(1, 158, 211)" : "rgb(147, 213, 120)",
        padding: "0px 20px",
      }}
    >
      {loading ? (
        <Loader color={"gray"} />
      ) : (
        <>
          <MTKButton onClick={onCancel} color={color} disabled={loading}>
            {t("Cancel")}
          </MTKButton>
          <MTKButton
            onClick={onCreate}
            color="white"
            textColor={"black"}
            px="25px"
            loading={loading}
          >
            {okText ? okText : t("Create")}
          </MTKButton>
        </>
      )}
    </Box>
  );
}

export default SubmitBottomBar;
