import {
  Box,
  Center,
  createStyles,
  Image,
  SimpleGrid,
  Title,
} from "@mantine/core";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";
import AudioUploader from "../../../components/AudioUploader";
import BackButtonCircle from "../../../components/BackButtonCircle";
import GalleryUploader from "../../../components/GalleryUploader";
import MTKInput from "../../../components/Input";
import AppLayout from "../../../components/layout/AppLayout";
import FormSection from "../../../components/layout/FormSection";
import MediaUploader from "../../../components/MediaUploader";
import MTKSelect from "../../../components/Select";
import MTKSwitch from "../../../components/Switch";
import MTKTextarea from "../../../components/Textarea";
import contentful from "../../../lib/contentful";
import { withLanguage } from "../../../lib/i18";
import {
  capitalize,
  generateRandomId,
  getQueryParams,
} from "../../../utils/utils";

import checkmarkRounded from "../../../assets/images/icons/checkmark-rounded.svg";
import redCircleWithWhiteCross from "../../../assets/images/icons/red-circle-with-white-cross.svg";
import levelPoints from "../../../assets/images/icons/level-points.svg";
import stopwatch from "../../../assets/images/icons/stopwatch.svg";
import { getPointsOptions, getCountDownOptions } from "../NewQuestion/options";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import ReactHtmlParser from "react-html-parser";
import Multiple from "../components/QuestionsForms/Mulltiple";
import useQuestionForm from "../NewQuestion/useQuestionForm";
import { getQuestionByID, getQuestionsOfGame } from "../../../api/Questions";
import SubmitBottomBar from "../../../components/SubmitBottomBar";
import Open from "../components/QuestionsForms/Open";
import Drawing from "../components/QuestionsForms/Drawing";
import Puzzle from "../components/QuestionsForms/Puzzle";
import Click from "../components/QuestionsForms/Click";
import SevenDifferences from "../components/QuestionsForms/SevenDifferences";
import { downloadFileFromStorage } from "../../../api/Storage";
import LoadingScreen from "../../../components/layout/LoadingScreen";
import useUnSavedChanges from "../../../hooks/useUnSavedChanges";
const stringToHtml = (text) => {
  return ReactHtmlParser(documentToHtmlString(text));
};

const useStyles = createStyles((theme, _params, getRef) => ({
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",

    position: "sticky",
    top: 0,
    padding: "50px 0",
    zIndex: 3,
    background: "rgb(255, 255, 255)",
    width: "100%",
    marginBottom: "30px",
    ["@media screen and (max-width: 950px)"]: {
      marginBottom: "0px",
    },
  },
}));

const getPath = (type, id) => {
  let resource = null;
  let rKey = null;

  if (type.startsWith("image")) {
    resource = "Images";
    rKey = "image";
  } else if (type.startsWith("video")) {
    resource = "Videos";
    rKey = "video";
  } else if (type.startsWith("pdf")) {
    resource = "PDF";
    rKey = "pdf";
  } else if (type.startsWith("audio")) {
    resource = "Audio";
    rKey = "audio";
  }
  return `/${resource}/${id}/${rKey}`;
};

function QuestionDetails() {
  const { t } = useTranslation();
  const { courseId, gameId, questionId } = useParams();
  const location = useLocation();
  const queryParams = getQueryParams(location);
  const history = useHistory();
  const [pageContent, setPageContent] = useState(null);
  const [questionMeta, setQuestionMeta] = useState(null);
  const [questionTypes, setQuestionTypes] = useState(null);
  const [selectedQuestionType, setSelectedQuestionType] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { classes } = useStyles();
  const [originalData, setOriginalData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const { form, onFormSubmit, loading, setDefaultData } = useQuestionForm({
    gameId,
    update: true,
  });
  const { unSavedChanges, setUnsavedChanges, openConfirmationModal } =
    useUnSavedChanges({
      onConfirm: () =>
        history.push(
          queryParams.back
            ? queryParams.back
            : withLanguage(`/courses/${courseId}/games/${gameId}/questions`)
        ),
    });
  useEffect(() => {
    contentful("questionsMetadata").then((data) => {
      setQuestionMeta(data.items[0]);
    });
    contentful("questionsItemsDescription").then((data) => {
      setPageContent(data.items[0]);
    });
    contentful("questionTypes").then((data) => setQuestionTypes(data.items));
  }, []);

  const handleAnswersRessources = async (answers) => {
    return await Promise.all(
      answers.map(async (answer) => {
        let media = null;
        let audio = null;
        let mediaCompare = null;
        if (answer?.media?.id) {
          try {
            const file = await downloadFileFromStorage({
              path: getPath(answer?.media?.type, answer?.media?.id),
              name: answer?.media?.name,
              type: answer?.media?.type,
            });
            media = file;
          } catch (error) {}
        }
        if (answer?.mediaCompare?.id) {
          try {
            const file = await downloadFileFromStorage({
              path: getPath(
                answer?.mediaCompare?.type,
                answer?.mediaCompare?.id
              ),
              name: answer?.mediaCompare?.name,
              type: answer?.mediaCompare?.type,
            });
            mediaCompare = file;
          } catch (error) {}
        }
        if (answer?.audio?.id) {
          try {
            const file = await downloadFileFromStorage({
              path: getPath(answer?.audio?.type, answer?.audio?.id),
              name: answer?.audio?.name,
              type: answer?.audio?.type,
            });
            audio = file;
          } catch (error) {}
        }

        return {
          ...answer,
          media,
          audio,
          mediaCompare,
          difficulty: answer?.difficulty ? answer?.difficulty + "" : "",
        };
      })
    );
  };
  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const data = await getQuestionByID(gameId, questionId);

      let feedbackRight = null;
      let feedbackWrong = null;
      let statementAudioFile = null;
      let answers = [];

      if (!data?.statementMediaFiles) {
        data.statementMediaFiles = [];
      }
      if (data.statementAudioFile && data.statementAudioFile.id) {
        const file = await downloadFileFromStorage({
          path: `QuestionStatement/${data.statementAudioFile.id}/audio-file`,
          name: data.statementAudioFile?.name,
          type: data.statementAudioFile?.type,
        });
        statementAudioFile = file;
      }

      if (data.feedbacks && Array.isArray(data.feedbacks)) {
        feedbackRight = { media: null, audio: null, ...data.feedbacks[0] };
        feedbackWrong = { media: null, audio: null, ...data.feedbacks[1] };

        if (feedbackRight?.media?.id) {
          try {
            const file = await downloadFileFromStorage({
              path: getPath(
                feedbackRight?.media?.type,
                feedbackRight?.media?.id
              ),
              name: feedbackRight?.media?.name,
              type: feedbackRight?.media?.type,
            });
            feedbackRight.media = file;
          } catch (error) {}
        }
        if (feedbackRight?.audio?.id) {
          try {
            const file = await downloadFileFromStorage({
              path: getPath(
                feedbackRight?.audio?.type,
                feedbackRight?.audio?.id
              ),
              name: feedbackRight?.audio?.name,
              type: feedbackRight?.audio?.type,
            });
            feedbackRight.audio = file;
          } catch (error) {}
        }
        if (feedbackWrong?.media?.id) {
          try {
            const file = await downloadFileFromStorage({
              path: getPath(
                feedbackWrong?.media?.type,
                feedbackWrong?.media?.id
              ),
              name: feedbackWrong?.media?.name,
              type: feedbackWrong?.media?.type,
            });
            feedbackWrong.media = file;
          } catch (error) {}
        }
        if (feedbackWrong?.audio?.id) {
          try {
            const file = await downloadFileFromStorage({
              path: getPath(
                feedbackWrong?.audio?.type,
                feedbackWrong?.audio?.id
              ),
              name: feedbackWrong?.audio?.name,
              type: feedbackWrong?.audio?.type,
            });
            feedbackWrong.audio = file;
          } catch (error) {}
        }
      }
      console.log({ answers });

      if (data.answers) {
        answers = await handleAnswersRessources(data.answers);
      }

      setSelectedQuestionType(data.type);
      setOriginalData({
        ...data,
        countdown: data.countdown + "",
        points: data.points + "",
        statementAudioFile,
        feedbackRight,
        feedbackWrong,
        answers,
      });
      setDefaultData(
        {
          ...data,
          countdown: data.countdown + "",
          points: data.points + "",
          statementAudioFile,
          feedbackRight,
          feedbackWrong,
          answers,
        },
        { ...data, id: questionId, gameId }
      );
      setIsLoading(false);
    };
    if (gameId && questionId) {
      getData();
    }
  }, [gameId, questionId]);

  useEffect(() => {
    if (gameId) {
      getQuestionsOfGame(gameId).then((data) => {
        setQuestions(data);
      });
    }
  }, [gameId]);

  useEffect(() => {
    if (!isLoading) {
      setUnsavedChanges(true);
    }
  }, [form.values, isLoading]);
  // if (isLoading) return <LoadingScreen />;
  const {
    statementMediaFiles = [],
    feedbackRight,
    feedbackWrong,
    answers,
    statementAudioFile,
    type,
  } = form.values;

  useEffect(() => {
    if (type && Array.isArray(answers) && answers.length === 0) {
      if (type === "multiple") {
        form.setFieldValue(
          "answers",
          [0, 1, 2, 3].map((i) => ({
            answerId: generateRandomId(),
            answer: "",
            isCorrectAnswer: i === 0,
            audio: null,
            media: null,
          }))
        );
      }
      if (type === "open") {
        form.setFieldValue("answers", [
          {
            answerId: generateRandomId(),
            answer: "",
            correctAutomatically: true,
            audio: null,
            media: null,
          },
        ]);
      }
      if (type === "drawing") {
        form.setFieldValue("countdown", "0");
        form.setFieldValue("answers", [
          {
            answerId: generateRandomId(),
            answer: "",
            marked: true,
            audio: null,
            media: null,
          },
        ]);
      }
      if (type === "puzzle") {
        form.setFieldValue("answers", [
          {
            answerId: generateRandomId(),
            answer: "",
            difficulty: "10",
            checked: false,
            audio: null,
            media: null,
          },
        ]);
      }
      if (type === "click") {
        form.setFieldValue("answers", [
          {
            answerId: generateRandomId(),
            answer: "",
            gridSize: "4x4",
            gridAnswer: [],
            ratio: 1,
            audio: null,
            media: null,
          },
        ]);
      }
      if (type === "differences") {
        form.setFieldValue("answers", [
          {
            answerId: generateRandomId(),
            answer: "",
            gridSize: "4x4",
            gridAnswer: [],
            ratio: 1,
            audio: null,
            media: null,
            mediaCompare: null,
          },
        ]);
      }
    }
  }, [type, answers]);
  return (
    <AppLayout loading={isLoading}>
      <Box className={classes.header}>
        <BackButtonCircle
          mr="md"
          color="green"
          onClick={openConfirmationModal}
          // to={
          //   queryParams.back
          //     ? queryParams.back
          //     : withLanguage(`/courses/${courseId}/games/${gameId}/questions`)
          // }
        />
        <Title order={3}>{t("Edit Question")}</Title>
      </Box>

      <form margin="30px 0px 0px 0px">
        <FormSection
          color="green"
          layout="row"
          margin="0px 0px 40px 0px"
          title={
            pageContent?.fields[
              `questionConfigurationTitle${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          }
          description={stringToHtml(
            pageContent?.fields[
              `questionConfigurationDescription${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          )}
        >
          <MTKInput
            label={`${t("Question ID")} *`}
            labelColor="blue"
            disabled
            {...form.getInputProps("userQuestionId")}
            // value={questionData.questionId}
          />
          <MTKInput
            label={`${t("Question Type")} *`}
            labelColor="blue"
            {...form.getInputProps("type")}
            disabled

            // value={selectedQuestionType}
          />
        </FormSection>

        <FormSection
          color="green"
          layout="row"
          margin="0px 0px 40px 0px"
          title={
            pageContent?.fields[
              `questionStatementTitle${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          }
          description={stringToHtml(
            pageContent?.fields[
              `questionStatementDescription${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          )}
        >
          <MTKTextarea
            rows={4}
            label={`${t("Question")} *`}
            labelColor="blue"
            {...form.getInputProps("statement")}

            // value={questionData.statement}
            // onChange={(e) => {
            //   changeQuestionData({ statement: e.target.value });
            // }}
          />
          <AudioUploader
            color="green"
            {...form.getInputProps("statementAudioFile")}
          />
          <div style={{ marginTop: "65px" }}>
            <GalleryUploader
              withYoutubeOption
              files={statementMediaFiles}
              setFiles={(files) =>
                form.setFieldValue(
                  "statementMediaFiles",
                  typeof files === "function"
                    ? files(statementMediaFiles)
                    : files
                )
              }
              recommendedAspectRatio="16/10 (1900*1200)"
              width="100%"
              height="300px"
              size="small"
              ratio={16 / 10}
            />
          </div>
        </FormSection>
        {console.log(selectedQuestionType)}
        {selectedQuestionType === "multiple" && (
          <Multiple form={form} questionMeta={questionMeta} />
        )}
        {selectedQuestionType === "open" && (
          <Open questionMeta={questionMeta} form={form} />
        )}
        {selectedQuestionType === "click" && (
          <Click questionMeta={questionMeta} form={form} />
        )}
        {selectedQuestionType === "drawing" && (
          <Drawing questionMeta={questionMeta} form={form} />
        )}
        {selectedQuestionType === "puzzle" && (
          <Puzzle questionMeta={questionMeta} form={form} />
        )}
        {selectedQuestionType === "differences" && (
          <SevenDifferences questionMeta={questionMeta} form={form} />
        )}

        <FormSection
          transparentBg
          color="green"
          margin="0px 0px 40px 0px"
          contentPadding="0px"
          layout="row"
          title={
            pageContent?.fields[
              `questionFeedbackTitle${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          }
          description={stringToHtml(
            pageContent?.fields[
              `questionFeedbackDescription${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          )}
        >
          <FeedbackAreas>
            <FeedbackArea>
              <Center>
                <Image
                  src={checkmarkRounded}
                  mx="auto"
                  width={37}
                  height={37}
                  display="flex"
                />
              </Center>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  textAlign: "center",
                  color: "#274784",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              >
                {t("Correct Answer Feedback")}
              </div>
              <MediaUploader
                isInGame={true}
                size="small"
                objectDescription={t("a video or image")}
                value={feedbackRight?.media}
                onChange={(file) =>
                  form.setFieldValue("feedbackRight", {
                    ...feedbackRight,
                    media: file,
                  })
                }
                recommendedAspectRatio="16/10 (1900*1200)"
                width="230px"
                height="200px"
                containerHeight="188px"
                ratio={16 / 10}
              />
              <MTKInput
                label={`${t("Answer")}`}
                labelColor="blue"
                value={feedbackRight?.answer}
                onChange={(e) =>
                  form.setFieldValue("feedbackRight", {
                    ...feedbackRight,
                    answer: e.target.value,
                  })
                }
              />
              <AudioUploader
                value={feedbackRight.audio}
                onChange={(file) =>
                  form.setFieldValue("feedbackRight", {
                    ...feedbackRight,
                    audio: file,
                  })
                }
                color="green"
                sx={{
                  maxWidth: "90%",
                }}
              />
            </FeedbackArea>
            <FeedbackArea>
              <Center>
                <Image
                  src={redCircleWithWhiteCross}
                  mx="auto"
                  width={37}
                  height={37}
                  display="flex"
                />
              </Center>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  textAlign: "center",
                  color: "#274784",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
              >
                {t("Wrong Answer Feedback")}
              </div>
              <MediaUploader
                isInGame={true}
                size="small"
                objectDescription={t("a video or image")}
                value={feedbackWrong.media}
                onChange={(file) =>
                  form.setFieldValue("feedbackWrong", {
                    ...feedbackWrong,
                    media: file,
                  })
                }
                recommendedAspectRatio="16/10 (1900*1200)"
                width="230px"
                height="200px"
                containerHeight="188px"
                ratio={16 / 10}
              />
              <MTKInput
                label={`${t("Answer")}`}
                labelColor="blue"
                value={feedbackWrong.answer}
                onChange={(e) =>
                  form.setFieldValue("feedbackWrong", {
                    ...feedbackWrong,
                    answer: e.target.value,
                  })
                }
              />
              <AudioUploader
                color="green"
                sx={{
                  maxWidth: "90%",
                }}
                value={feedbackWrong.audio}
                onChange={(file) =>
                  form.setFieldValue("feedbackWrong", {
                    ...feedbackWrong,
                    audio: file,
                  })
                }
              />
            </FeedbackArea>
          </FeedbackAreas>
        </FormSection>
        <FormSection
          color="green"
          layout="row"
          margin="0px 0px 40px 0px"
          title={
            pageContent?.fields[
              `questionSettingsTitle${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          }
          description={stringToHtml(
            pageContent?.fields[
              `questionSettingsDescription${capitalize(
                withLanguage("").substring(1)
              )}`
            ]
          )}
        >
          <MTKSelect
            label={`${t("Weight")} *`}
            iconLeft={levelPoints}
            leftIconMarginTop="40px"
            leftIconMarginRight="10px"
            leftIconWidth="23px"
            data={getPointsOptions()}
            {...form.getInputProps("points")}
          />

          <MTKSelect
            label={`${t("Countdown")} *`}
            leftIcon={stopwatch}
            leftIconMarginTop="37px"
            leftIconMarginRight="10px"
            leftIconWidth="23px"
            data={getCountDownOptions(t)}
            {...form.getInputProps("countdown")}
          />

          <MTKInput
            display="flex"
            labelColor="blue"
            label={`${t("Question ID")} *`}
            {...form.getInputProps("userQuestionId")}
            leftIcon="report.svg"
            iconWidth="23px"
            leftIconMarginTop="10px"
            leftIconMarginRight="10px"
          />

          <MTKSwitch
            {...form.getInputProps("publishQuestionInGame", {
              type: "checkbox",
            })}
            color="green"
            label={t("Publish Question in Game")}
            margin="10px 0px 10px 0px"
          />
        </FormSection>
        <SubmitBottomBar
          okText={t("Save")}
          onCreate={form.onSubmit(onFormSubmit)}
          color="green"
          // onCreate={() => {
          //   console.log(form.values);
          //   console.log(form.errors);
          // }}
          // onCancel={() => history.push(withLanguage("/courses/"))}
          onCancel={() => history.push(withLanguage("/courses/"))}
          loading={loading}
        />
      </form>
    </AppLayout>
  );
}

export default QuestionDetails;

const FeedbackAreas = ({ children }) => {
  return (
    <SimpleGrid
      cols={2}
      breakpoints={[{ maxWidth: 1500, cols: 1, spacing: "md" }]}
    >
      {children}
    </SimpleGrid>
  );
};

const FeedbackArea = ({ children }) => (
  <Box
    sx={{
      background: "#edf7e9",
      borderRadius: "22px",
      padding: "30px 20px",
    }}
  >
    {children}
  </Box>
);
