const config = {
  apiKey: "AIzaSyA4INtWYlRKoTHqHytwDNTX2QXeaTu3-aA",
  authDomain: "my-torah-kids---prod.firebaseapp.com",
  databaseURL:
    "https://my-torah-kids---prod-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "my-torah-kids---prod",
  storageBucket: "my-torah-kids---prod.appspot.com",
  messagingSenderId: "552301990938",
  appId: "1:552301990938:web:bd94a03affb4b85ab8eda3",
  measurementId: "G-LX6KQS1KYR",
};

export default config;
