import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { withLanguage } from "../lib/i18";
import GlobalContext from "../context/GlobalContext";
import LoadingScreen from "../components/layout/LoadingScreen";
import useIsSuperAdmin from "../hooks/useIsSuperAdmin";
// import { useAuthState } from "react-firebase-hooks/auth";
// import { auth } from "../lib/firebase";

export default function PublicRoute({ redirectTo, children, ...rest }) {
  const { profile, user, isLoaded } = useContext(GlobalContext);
  const isAdmin = useIsSuperAdmin();
  if (!isLoaded) {
    return <LoadingScreen />;
  } else if (isLoaded && profile) {
    return (
      <Redirect to={withLanguage(isAdmin ? "/organisations" : "/classrooms")} />
    );
  }

  return <Route {...rest}>{children}</Route>;
}
