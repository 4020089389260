import React, { useState, useCallback, useRef } from "react";
import { Text, Button, Image, Box, Center, Title } from "@mantine/core";

import errorSignImage from "../../assets/images/icons/error-sign.png";
import { useTranslation } from "react-i18next";
import MTKButton from "../Button";
import MTKSelect from "../Select";
import { useEffect } from "react";
import {
  getOrganisations,
  getOrganisationsOfUser,
} from "../../api/Organisations";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { getClassrooms } from "../../api/Classrooms";
import { getCourses } from "../../api/Courses";
import { getGamesOfCourse } from "../../api/Games";

import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { ReactComponent as DnDIcon } from "../../assets/images/icons/drag-drop.svg";

const ItemTypes = {
  CARD: "card",
};

function OrderModal({ context, id, innerProps }) {
  const { t } = useTranslation();
  const {
    title,
    sortedItems,
    saveNewOrder,
    color = "green",
    isQuestion = false,
  } = innerProps;
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState(sortedItems);

  useEffect(() => {
    setCards(sortedItems);
  }, [sortedItems]);
  return (
    <Box>
      <Title order={3} my="md" align="center">
        {title}
      </Title>

      <Box
        sx={{
          paddingTop: "30px",
          paddingBottom: "30px",
          /* background: red; */
          width: "100%",
        }}
      >
        <DndProvider backend={HTML5Backend}>
          <ItemsContainer
            color={color}
            items={sortedItems}
            cards={cards}
            setCards={setCards}
            isQuestion={isQuestion}
          />
        </DndProvider>
      </Box>
      <Center
        px="xl"
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
        my="lg"
        mt="xl"
      >
        <MTKButton
          width="140px"
          loading={loading}
          color="blue"
          onClick={async () => {
            setLoading(true);
            await saveNewOrder(cards);

            setLoading(false);
            context.closeModal(id);
          }}
          //   onClick={() => context.closeModal(id)}
        >
          {t("Save")}
        </MTKButton>
      </Center>
    </Box>
  );
}
const ItemsContainer = ({ items, cards, setCards, color, isQuestion }) => {
  {
    const moveCard = useCallback((dragIndex, hoverIndex) => {
      setCards((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
    }, []);
    const renderCard = useCallback((card, index) => {
      return (
        <Item
          color={color}
          key={card.id}
          index={index}
          id={card.id}
          text={card.text}
          moveCard={moveCard}
          item={card}
          isQuestion={isQuestion}
        />
      );
    }, []);
    return (
      <>
        <div>{cards.map((card, i) => renderCard(card, i))}</div>
      </>
    );
  }
};

const greenStyle = {
  //   border: '1px solid gray',
  padding: "1rem 1rem",

  backgroundColor: "#93D578",
  color: "white",
  //   cursor: 'move',
  borderRadius: "12px",
  fontWeight: "bold",
  fontSize: "17px",
};
const blueStyle = {
  //   border: '1px solid gray',
  padding: "1rem 1rem",

  backgroundColor: "#019ED3",
  color: "white",
  //   cursor: 'move',
  borderRadius: "12px",
  fontWeight: "bold",
  fontSize: "17px",
};

const Item = ({ id, item, index, moveCard, color, isQuestion }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  const style = color === "green" ? greenStyle : blueStyle;
  return (
    <div
      ref={ref}
      style={{
        opacity,
        display: "flex",
        alignItems: "center",
        marginBottom: ".5rem",
        maxWidth: "300px",
      }}
      data-handler-id={handlerId}
    >
      <div
        style={{
          fontSize: "16px",
          lineHeight: "16px",
          marginRight: "0.5rem",
          opacity: "0.5",
        }}
      >
        {index + 1}
      </div>
      <div
        style={{
          ...style,
          flexGrow: "1",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            cursor: "move",
            marginRight: "10px",
          }}
        >
          <DnDIcon />
        </div>
        <div
          style={{
            maxWidth: "150px",
          }}
        >
          {isQuestion ? item.userQuestionId : item.name}
        </div>

        <NumberInput moveCard={moveCard} index={index} />
      </div>
    </div>
  );
};

const BtnStyle = {
  width: "25px",
  height: "25px",
  lineHeight: "25px",
  borderRadius: "5px",
  backgroundColor: "white",
  color: "#019ED3",
  textAlign: "center",
  cursor: "pointer",
};
const numStyle = {
  width: "25px",
  height: "25px",
  borderRadius: "5px",
  backgroundColor: "#ffffff5c",
  color: "#019ED3",
  textAlign: "center",
  lineHeight: "25px",
  border: "none",
};
const NumberInput = ({ moveCard, index }) => {
  const [count, setCount] = useState(index + 1);

  const goUp = () => {
    moveCard(index, index + 1);
  };
  const goDown = () => {
    moveCard(index, index - 1);
  };

  useEffect(() => {
    setCount(index + 1);
  }, [count]);

  const inputChange = (value) => {
    if (value && value > 0) {
      setCount(value);
      moveCard(value - 1, index);
    } else {
      setCount(value);
    }
  };
  return (
    <div
      style={{
        // background: '#ffffff5c',
        display: "flex",
        // width: '50px',
        borderRadius: "5px",
      }}
    >
      <div style={BtnStyle} onClick={() => goUp()}>
        +
      </div>
      <input
        style={numStyle}
        value={index + 1}
        min={1}
        disabled
        onChange={(e) => {
          inputChange(e.target.value);
        }}
      ></input>
      <div style={BtnStyle} onClick={() => goDown()}>
        -
      </div>
    </div>
  );
};

export default OrderModal;
