import React, { useState } from "react";
import { Box, createStyles, Image } from "@mantine/core";

import logo from "../../../assets/images/logos/my-torah-kids.png";
import boyGirlImage from "../../../assets/images/misc/boy-and-girl.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NavLink } from "react-router-dom";
import { withLanguage } from "../../../lib/i18";

const useStyles = createStyles((theme, _params, getRef) => ({
  sideBar: {
    background: "rgb(255, 255, 255);",
    padding: "25px;",
    width: "280px;",
    borderRight: "0.5px solid rgb(239, 239, 239);",
    display: "flex;",
    flexDirection: "column;",
    [`@media (max-width: 900px)`]: {
      display: "none",
    },
  },
}));

function MTKSideBar() {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <Box className={classes.sideBar}>
      <Box>
        <Image width={"115px"} src={logo} />
      </Box>
      <Box sx={{ flex: "1 1 0%" }}>
        <Box
          sx={{
            marginBottom: "20px",
          }}
        >
          <HeadLine>{t("Menu")}</HeadLine>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Item
              title={t("Organisations")}
              to={withLanguage("/organisations")}
            />
            <Item title={t("Classrooms")} to={withLanguage("/classrooms")} />
            <Item title={t("Courses")} to={withLanguage("/courses")} />
            <Item
              title={t("Find Courses")}
              to={withLanguage("/find-courses-and-games")}
            />
          </Box>
        </Box>

        <Box
          sx={{
            marginBottom: "25px",
          }}
        >
          <HeadLine>{t("Links")}</HeadLine>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Item
              title={t("Volunteer")}
              onClick={() => {
                window.open(
                  "https://mytorahkids.assoconnect.com/page/642969-benevole",
                  "_blank"
                );
              }}
            />
            <Item
              title={t("Donate")}
              onClick={() => {
                window.open(
                  "https://mytorahkids.assoconnect.com/page/687792-faire-un-don",
                  "_blank"
                );
              }}
            />
            <Item
              title={t("Contact")}
              onClick={() => {
                window.open(withLanguage("/contact"), "_blank");
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Image width={"175px"} src={boyGirlImage} />
      </Box>
    </Box>
  );
}

export default MTKSideBar;

const HeadLine = ({ children }) => {
  return (
    <Box
      sx={{
        fontSize: "14px",
        fontWeight: "bold",
        color: "#000000",
        opacity: 0.3,
        marginBottom: "10px",
        marginTop: "10px",
      }}
    >
      {children}
    </Box>
  );
};
const Item = ({ title, onClick = undefined, to = undefined }) => {
  const history = useHistory();
  const [active, setActive] = useState(false);

  let Link = undefined;
  if (to) {
    Link = NavLink;
  } else {
    Link = "div";
  }

  return (
    <Link
      to={to}
      onClick={(e) => {
        e.preventDefault();
        // checkFormHasUnsavedChanges(() => {
        if (onClick) {
          onClick();
        } else {
          history.push(to);
        }
        // });
      }}
      isActive={(match, location) => {
        setActive(match !== null);
      }}
      style={{
        textDecoration: "none",
        height: "45px",
      }}
    >
      <StyledItem active={active}>{title}</StyledItem>
    </Link>
  );
};

const StyledItem = ({ active = false, children }) => {
  return (
    <Box
      sx={{
        color: active ? "#019ed3" : "#00000077",
        fontWeight: active ? "bold" : "normal",
        fontSize: "16px;",
        padding: "13px 20px;",
        borderLeft: `2px solid ${active ? "#019ed3" : "#ffffff"}`,
        cursor: "pointer;",
        height: "45px",
      }}
    >
      {children}
    </Box>
  );
};
