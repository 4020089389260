import { Checkbox } from "@mantine/core";
import React from "react";

function MTKCheckBox({
  label = "",
  checked = false,
  onChange = () => {},
  ref,
  ...props
}) {
  return (
    <Checkbox
      my="md"
      label={label}
      itemRef={ref}
      //   color="cyan"
      checked={checked}
      onChange={onChange}
      styles={(theme) => ({
        input: {
          //   background: theme.colors.blue,
          backgroundColor: checked ? theme.colors.blue : "#fff",
          borderColor: theme.colors.blue,
        },
      })}
      {...props}
    />
  );
}

export default MTKCheckBox;
