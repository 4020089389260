import { Box, Grid, Select } from "@mantine/core";
import React, { useMemo, useState, useEffect } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getClassrooms } from "../../../../api/Classrooms";
import { getCourses } from "../../../../api/Courses";
import { getOrganisations } from "../../../../api/Organisations";
import { getSchoolYears } from "../../../../api/SchoolYear";
import Head from "../../../../components/layout/Head";
import { organisationTypes } from "../../../../constants/types";
import GlobalContext from "../../../../context/GlobalContext";
import {
  getGameSubTopics,
  getGameTopics,
  getGameTopicTypes,
  getJewishSchoolYear,
  withDefaultTitle,
} from "../../../../utils/utils";
import CourseCard from "../../components/CourseCard";
import CourseSection from "./CourseSection";

function FindCoursesTab() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const { profile } = useContext(GlobalContext);
  const [organisationType, setOrganisationType] = useState("-all-");
  const [organisation, setOrganisation] = useState("-all-");
  const [classroom, setClassroom] = useState("-all-");
  const [schoolYear, setSchoolYear] = useState("-all-");
  const [gameTopicType, setGameTopicType] = useState("-all-");
  const [gameTopic, setGameTopic] = useState("-all-");
  const [gameSubTopic, setGameSubTopic] = useState("-all-");

  const [organisations, setOrganisations] = useState(null);
  const [classrooms, setClassrooms] = useState(null);
  const [schoolYears, setSchoolYears] = useState(null);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const [organisations, schoolYears, classrooms, courses] =
        await Promise.all([
          getOrganisations(),
          getSchoolYears(),
          getClassrooms(),
          getCourses(),
        ]);
      const FilteredOrganisations = organisations.filter((d) =>
        classrooms.find((classroom) => classroom.organisationId === d.id)
      );
      setOrganisations(FilteredOrganisations);
      setClassrooms(classrooms);
      setSchoolYears(schoolYears);
      setSchoolYear(schoolYears[0]?.id);
      setCourses(courses);
    };
    getData();
  }, []);

  // - if teacher hide home
  const orgTypesData = useMemo(() => {
    if (organisations && classrooms) {
      const types = [...new Set(organisations.map((o) => o.type))];

      let data = Object.keys(organisationTypes)
        .flatMap((key) => {
          if (types.includes(key)) {
            return {
              value: key,
              label: organisationTypes[key][currentLang],
            };
          } else {
            return [];
          }
        })
        .filter((d) => d);
      if (profile && profile?.type === "teacher") {
        data = data.filter((o) => o.value !== "home");
      }

      return [{ value: "-all-", label: "- All - " }, ...data];
    } else {
      return [];
    }
  }, [organisations]);

  // if type is selected, show only that type
  // else show everything

  const organisationsData = useMemo(() => {
    if (organisations) {
      let data = organisations.map((organisation) => ({
        value: organisation.id,
        label: organisation.name,
        type: organisation.type,
      }));

      if (organisationType && organisationType !== "-all-") {
        data = data.filter((d) => d.type === organisationType);
        return [{ value: "-all-", label: "- All - " }, ...data];
      }
      return [{ value: "-all-", label: "- All - " }, ...data];
    }
    return [];
  }, [organisations, organisationType]);

  const classroomsData = useMemo(() => {
    if (classrooms) {
      let data = classrooms.map((classroom) => ({
        value: classroom.id,
        label: classroom.name,
        organisationId: classroom.organisationId,
      }));

      if (organisation && organisation !== "-all-") {
        data = data.filter(
          (classroom) => classroom.organisationId === organisation
        );
      }
      return [{ value: "-all-", label: "- All - " }, ...data];
    } else {
      return [];
    }
  }, [classrooms, organisation]);

  const schoolYearData = useMemo(() => {
    if (schoolYears) {
      let data = schoolYears.map((schoolYear) => ({
        value: schoolYear.id,
        label: getJewishSchoolYear(schoolYear.key),
      }));

      return [{ value: "-all-", label: "- All - " }, ...data];
    } else {
      return [];
    }
  }, [schoolYears]);

  const gamesTopicTypesData = useMemo(() => {
    return getGameTopicTypes(true);
  }, []);
  const gamesTopicsData = useMemo(() => {
    return getGameTopics(gameTopicType, true);
  }, [gameTopicType]);
  const gameSubTopicData = useMemo(() => {
    return getGameSubTopics(gameTopicType, gameTopic, true);
  }, [gameTopicType, gameTopic]);

  const filteredCourses = useMemo(() => {
    if (courses && classrooms && organisations && schoolYears) {
      return courses
        .map((c) => {
          const courseClassroom = classrooms.find(
            (d) => d.id === c.classroomId
          );
          if (!courseClassroom) return false;

          const courseSchoolYear = schoolYears.find(
            (d) => d.id === courseClassroom.schoolYearId
          );
          if (!courseSchoolYear) return false;

          const courseOrganisation = organisations.find(
            (d) => d.id === courseClassroom?.organisationId
          );
          if (!courseOrganisation) return false;

          const allOrgTypes = organisationType === "-all-";
          const allClassrooms = classroom === "-all-";
          const allOrganisations = organisation === "-all-";

          const filterOrgType =
            allOrgTypes || courseOrganisation?.type === organisationType;

          const filterOrganisation =
            allOrganisations || courseOrganisation?.id === organisation;

          const filterClassroom =
            allClassrooms || courseClassroom?.id === classroom;

          const show =
            filterOrgType &&
            filterOrganisation &&
            filterClassroom &&
            courseSchoolYear?.id === schoolYear;

          return show
            ? {
                ...c,
                classroom: courseClassroom,
                organisation: courseOrganisation,
                schoolYear: courseSchoolYear,
              }
            : null;
        })
        .filter((d) => d);
    } else {
      return [];
    }
  }, [organisationType, organisation, classroom, schoolYear]);

  return (
    <Box mt="60px">
      <Head>
        <title>{withDefaultTitle(t("Find Courses & Games"))}</title>
      </Head>
      <Grid gutter={"xl"}>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("Organisation Type")}
            data={orgTypesData}
            value={organisationType}
            onChange={(value) => {
              setOrganisationType(value);
              setOrganisation("-all-");
            }}
            // data={organisations.map((d) => {
            //   return {
            //     value: d.id,
            //     label: d.name,
            //   };
            // })}
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("Organisation")}
            value={organisation}
            onChange={(value) => {
              setOrganisation(value);
              setClassroom("-all-");
            }}
            data={organisationsData}
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("Classroom")}
            value={classroom}
            onChange={(value) => {
              // setCourses([]);
              setClassroom(value);
            }}
            data={classroomsData}
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("School Year")}
            value={schoolYear}
            onChange={(value) => {
              setSchoolYear(value);
            }}
            data={schoolYearData}
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("Game Topic Type")}
            value={gameTopicType}
            onChange={(value) => {
              setGameTopicType(value);
            }}
            data={gamesTopicTypesData}
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("Game Topic")}
            value={gameTopic}
            onChange={(value) => {
              setGameTopic(value);
            }}
            data={gamesTopicsData}
          />
        </Grid.Col>
        <Grid.Col lg={3} md={12}>
          <Select
            variant="filled"
            labelProps={{
              style: {
                color: "rgb(178, 178, 178)",
              },
            }}
            label={t("Game Sub-topic")}
            value={gameSubTopic}
            onChange={(value) => {
              setGameSubTopic(value);
            }}
            data={gameSubTopicData}
          />
        </Grid.Col>
      </Grid>
      <Box mt="20px">
        {filteredCourses?.length > 0 ? (
          <Box>
            {filteredCourses.map((d) => (
              <CourseSection
                key={d.id}
                course={d}
                organisation={d.organisation}
                classroom={d.classroom}
                schoolYear={d.schoolYear}
                selectedGameTopic={gameTopic}
                selectedGameSubTopic={gameSubTopic}
                selectedGameTopicType={gameTopicType}
              />
            ))}
          </Box>
        ) : (
          <Box
            mt="40px"
            sx={{
              color: "rgb(188, 188, 188)",
              fontSize: "14px",
            }}
          >
            {t("There are no courses.")}
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default FindCoursesTab;
