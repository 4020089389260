import {
  getDocs,
  getDoc,
  collection,
  query,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../lib/firebase";
import { getClassroomsOfUser } from "./Classrooms";

//todo : add validators

export const getOrganisations = async () => {
  try {
    const res = await getDocs(collection(db, "Organisations"));
    const OrganisationsList = res.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return OrganisationsList;
  } catch (error) {
    console.log("getOrganisations", error);
  }
};

export const getOrganisationsOfUser = async (id) => {
  try {
    const orgList = await getOrganisations();

    return orgList.filter(
      (org) =>
        org?.createdByUserId === id || (org?.directors && org?.directors[id])
    );
  } catch (error) {
    console.log("getOrganisationsOfUser", error);
  }
};
export const getOrganisationsUserHasAccessTo = async (id) => {
  try {
    const classrooms = await getClassroomsOfUser(id);
    const orgsIds = classrooms.map((d) => d.organisationId);
    // console.log({orgsIds})
    const orgList = await getOrganisations();
    console.log({ orgsIds, orgList });

    return orgList.filter(
      (org) =>
        org?.createdByUserId === id ||
        (org?.directors && org?.directors[id]) ||
        orgsIds.includes(org.id)
    );
  } catch (error) {
    console.log("getOrganisationsOfUser", error);
  }
};

export const getOrganizationByID = async (id) => {
  const res = await getDoc(doc(db, "Organisations", id));
  if (res.exists()) {
    return { id, ...res.data() };
  } else {
    return null;
  }
};

export const createOrganisation = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "Organisations"), data);
    return docRef;
  } catch (err) {
    console.log("create organisation", err);
  }
};
export const updateOrganisation = async (id, data) => {
  try {
    await updateDoc(doc(collection(db, "Organisations"), id), data);
    return { id };
  } catch (err) {
    console.log("updateOrganisationn", err);
  }
};
export const deleteOrganisation = async (id) => {
  try {
    await deleteDoc(doc(collection(db, "Organisations"), id));
    return { id };
  } catch (err) {
    console.log("deleteOrganisation", err);
  }
};
