import {
  getDocs,
  getDoc,
  collection,
  query,
  doc,
  setDoc,
  addDoc,
  orderBy,
} from "firebase/firestore";
import { db } from "../lib/firebase";

//todo : add validators

export const getSchoolYears = async () => {
  try {
    const yearsRef = collection(db, "SchoolYears");
    const q = query(yearsRef, orderBy("key", "desc"));

    const res = await getDocs(q);
    const SchoolYears = res.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // 'key', 'desc'
    return SchoolYears;
  } catch (error) {
    console.log("getSchoolYears", error);
  }
};
export const getSchoolYearByID = async (id) => {
  const res = await getDoc(doc(db, "SchoolYears", id));
  if (res.exists()) {
    return { id, ...res.data() };
  } else {
    return null;
  }
};
