import React, { useState } from "react";
import { ContextModalProps, ModalsProvider } from "@mantine/modals";
import { Text, Button, Image, Box, Center, Title } from "@mantine/core";

import errorSignImage from "../../assets/images/icons/error-sign.png";
import { useTranslation } from "react-i18next";
import MTKButton from "../Button";
import MTKSelect from "../Select";
import { useEffect } from "react";
import {
  getOrganisations,
  getOrganisationsOfUser,
} from "../../api/Organisations";
import { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { getClassrooms, updateClassroom } from "../../api/Classrooms";
import MTKInput from "../Input";
import { z } from "zod";
import { createStudent, getStudents } from "../../api/Users";
import MTKDateInput from "../DateInput";
import MTKCheckBox from "../CheckBox";
import { serverTimestamp } from "firebase/firestore";

function DeleteStudentsModal({ context, id, innerProps }) {
  const { t } = useTranslation();
  const {
    classroom,
    selectedStudents,
    classroomId,
    handleRefreshData,
    studentsOfClassroom,
  } = innerProps;
  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState("areYouSure");

  const deleteStudents = async (keepGrades) => {
    setLoading(true);

    const currentStudents = { ...classroom.students };
    for (const [key, value] of Object.entries(currentStudents)) {
      const found = selectedStudents.find((s) => s === key);
      if (found) {
        if (keepGrades) {
          currentStudents[key] = {
            type: "student",
            keepGrades: true,
          };
        } else {
          delete currentStudents[key];
        }
      }
    }

    try {
      await updateClassroom(classroomId, {
        students: currentStudents,
      });
    } catch (error) {
      console.log(error);
    }
    handleRefreshData();

    setLoading(false);
    context.closeModal(id);
  };

  const renderStepAreYouSure = () => {
    let title;
    let description;
    if (selectedStudents.length > 1) {
      title = t("Delete Students");
      description = t("Are you sure you want to remove these students?");
    } else {
      title = t("Delete Student");
      description = t("Are you sure you want to remove this student?");
    }
    return (
      <Box>
        <Title order={3} my="md" align="center">
          {title}
        </Title>
        <Text size="sm" align="center" my="md">
          {description}
        </Text>

        <Box my="40px" mx="30px">
          {selectedStudents.map((studentId) => {
            const student = studentsOfClassroom.find((d) => d.id === studentId);
            if (!student) return null;
            return (
              <Box>
                <Box>
                  {student.firstName} {student.name}
                </Box>
                <Box>{student.email}</Box>
              </Box>
            );
          })}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <MTKButton
            fullWidth
            variant="outline"
            color="blue"
            mx="md"
            onClick={() => {
              context.closeModal(id);
            }}
          >
            {t("No")}
          </MTKButton>
          <MTKButton
            fullWidth
            mx="md"
            color="blue"
            onClick={() => {
              setStep("keepGrades");
            }}
          >
            {t("Yes")}
          </MTKButton>
        </Box>
      </Box>
    );
  };

  const renderStepKeepGrades = () => {
    return (
      <Box>
        <Title order={3} my="md" align="center">
          {t("Keep Grades")}
        </Title>
        <Text size="sm" align="center" my="md">
          {t("Do you want to keep the grades?")}
        </Text>
        <Box
          my="40px"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <MTKButton
            fullWidth
            variant="outline"
            color="blue"
            mx="md"
            onClick={async () => {
              await deleteStudents(false);
            }}
          >
            {t("No")}
          </MTKButton>
          <MTKButton
            fullWidth
            mx="md"
            color="blue"
            loading={loading}
            onClick={async () => {
              await deleteStudents(true);
            }}
          >
            {t("Yes")}
          </MTKButton>
        </Box>
      </Box>
    );
  };

  if (step === "areYouSure") {
    return renderStepAreYouSure();
  } else if (step === "keepGrades") {
    return renderStepKeepGrades();
  } else {
    return null;
  }
}

export default DeleteStudentsModal;
