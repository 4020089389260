import React, { useContext } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import GlobalContext from "../../../context/GlobalContext";
import { z } from "zod";
import { getFileDownloadURL, uploadFileToStorage } from "../../../api/Storage";
import {
  checkIfSameFile,
  generateRandomId,
  getResourceInfo,
} from "../../../utils/utils";
import { useForm, zodResolver } from "@mantine/form";
import { createQuestion, updateQuestion } from "../../../api/Questions";
import { serverTimestamp } from "firebase/firestore";
import { withLanguage } from "../../../lib/i18";
import { useModals } from "@mantine/modals";

function useQuestionForm({ update = false } = {}) {
  const [loading, setLoading] = useState(false);
  const [originalData, setOriginalData] = useState(null);
  const { profile } = useContext(GlobalContext);
  const modals = useModals();
  const history = useHistory();
  const { t } = useTranslation();
  const { gameId, courseId, questionId } = useParams();
  const schema = z.object({
    statement: z
      .string()
      .min(
        2,
        t(
          "Please fill in the required fields in order to create a new Question."
        )
      ),
    type: z
      .string()
      .min(
        2,
        t(
          "Please fill in the required fields in order to create a new Question."
        )
      ),
    userQuestionId: z
      .string()
      .min(
        2,
        t(
          "Please fill in the required fields in order to create a new Question."
        )
      ),
    statementAudioFile: z.instanceof(File).or(z.literal(null)),
    statementMediaFiles: z.array(
      z.object({
        id: z.string(),
        type: z.string().or(z.literal(null)),
        url: z.string().url().or(z.literal(null)),
      })
    ),
    points: z.string(),
    countdown: z.string(),
    publishQuestionInGame: z.boolean(),
    feedbackRight: z.object({
      feedbackId: z.string(),
      answer: z.string(),
      isCorrectAnswer: z.boolean(),
      audio: z.instanceof(File).or(z.literal(null)),
      media: z.instanceof(File).or(z.literal(null)),
    }),
    feedbackWrong: z.object({
      feedbackId: z.string(),
      answer: z.string(),
      isCorrectAnswer: z.boolean(),
      audio: z.instanceof(File).or(z.literal(null)),
      media: z.instanceof(File).or(z.literal(null)),
    }),
    answers: z.array(
      z.object({
        answerId: z.string(),
        answer: z.string(),
        isCorrectAnswer: z.boolean().optional(),
        correctAutomatically: z.boolean().optional(),
        marked: z.boolean().optional(),
        checked: z.boolean().optional(),
        difficulty: z.string().optional(),
        gridSize: z.string().optional(),
        gridAnswer: z.array(z.number()).optional(),
        ratio: z.number().optional(),
        audio: z.instanceof(File).or(z.literal(null)),
        media: z.instanceof(File).or(z.literal(null)),
        mediaCompare: z.instanceof(File).or(z.literal(null)).optional(),
      })
    ),
  });

  const onFormSubmit = async (values) => {
    setLoading(true);
    const {
      statement,
      statementAudioFile,
      statementMediaFiles,
      userQuestionId,
      points,
      countdown,
      publishQuestionInGame,
      feedbackRight,
      feedbackWrong,
      type,
      answers,
    } = values;

    const data = {
      type,
      statement,
      userQuestionId,
      points: +points,
      countdown: +countdown,
      publishQuestionInGame,
      feedbacks: [feedbackRight, feedbackWrong],
      createdByUserId: profile.id,
    };

    if (update) {
      data.updatedAt = serverTimestamp();
    } else {
      data.createdAt = serverTimestamp();
    }
    if (!answersValidaton({ type, answers })) {
      setLoading(false);
      return;
    }

    if (statementAudioFile) {
      if (
        originalData &&
        originalData.statementAudioFile &&
        checkIfSameFile(statementAudioFile, originalData.statementAudioFile)
      ) {
        data.statementAudioFile = originalData.statementAudioFile;
      } else {
        const randomId = generateRandomId();
        const aFile = {
          id: randomId,
          type: statementAudioFile.type,
          name: statementAudioFile.name,
          size: statementAudioFile.size,
        };

        try {
          await uploadFileToStorage({
            file: statementAudioFile,
            path: `QuestionStatement/${aFile.id}/audio-file`,
          });
          data.statementAudioFile = aFile;
        } catch (error) {
          console.log("pdf upload error", error);
        }
      }
    }

    const mediaFiles = [];

    // Add files if necessary
    for (const mediaFile of statementMediaFiles) {
      let url = "";
      if (mediaFile.isNew) {
        // Got added
        if ("file" in mediaFile) {
          try {
            // setCurrentlyUploadingFile(mediaFile?.obj?.name);
            let ref;
            if (mediaFile.type === "image") {
              ref = `Images/${mediaFile.id}/image`;
            } else if (mediaFile.type === "video") {
              ref = `Videos/${mediaFile.id}/video`;
            }

            if (ref) {
              await uploadFileToStorage({
                file: mediaFile.file,
                path: ref,
              });
              url = await getFileDownloadURL({ path: ref });
            }
          } catch (error) {
            console.log("Upload error", error);
          }
          // setCurrentlyUploadingFile(undefined);
        }
      } else {
        url = mediaFile.url;
      }

      if (url) {
        mediaFiles.push({
          id: mediaFile.id,
          type: mediaFile.type,
          isYoutube: !!mediaFile.isYoutube,
          url,
        });
      }
    }
    data.statementMediaFiles = mediaFiles;

    if (feedbackRight?.media) {
      const randomId = generateRandomId();
      const iFile = {
        id: randomId,
        type: feedbackRight?.media.type,
        name: feedbackRight?.media.name,
        size: feedbackRight?.media.size,
      };
      const res = getResourceInfo(feedbackRight?.media);

      try {
        await uploadFileToStorage({
          file: feedbackRight.media,
          path: `${res.resource}/${iFile.id}/${res.rKey}`,
        });
        data.feedbacks[0].media = iFile;
      } catch (error) {
        console.log("media upload error", error);
      }
    }
    if (feedbackRight?.audio) {
      const randomId = generateRandomId();
      const aFile = {
        id: randomId,
        type: feedbackRight?.audio.type,
        name: feedbackRight?.audio.name,
        size: feedbackRight?.audio.size,
      };
      const res = getResourceInfo(feedbackRight?.audio);
      try {
        await uploadFileToStorage({
          file: feedbackRight.audio,
          path: `${res.resource}/${aFile.id}/${res.rKey}`,
        });
        data.feedbacks[0].audio = aFile;
      } catch (error) {
        console.log("audio upload error", error);
      }
    }
    if (feedbackWrong?.media) {
      const randomId = generateRandomId();
      const iFile = {
        id: randomId,
        type: feedbackWrong?.media.type,
        name: feedbackWrong?.media.name,
        size: feedbackWrong?.media.size,
      };
      const res = getResourceInfo(feedbackWrong?.media);

      try {
        await uploadFileToStorage({
          file: feedbackWrong.media,
          path: `${res.resource}/${iFile.id}/${res.rKey}`,
        });
        data.feedbacks[1].media = iFile;
      } catch (error) {
        console.log("media upload error", error);
      }
    }
    if (feedbackWrong?.audio) {
      const randomId = generateRandomId();
      const iFile = {
        id: randomId,
        type: feedbackWrong?.audio.type,
        name: feedbackWrong?.audio.name,
        size: feedbackWrong?.audio.size,
      };
      const res = getResourceInfo(feedbackWrong?.audio);

      try {
        await uploadFileToStorage({
          file: feedbackWrong.audio,
          path: `${res.resource}/${iFile.id}/${res.rKey}`,
        });
        data.feedbacks[1].audio = iFile;
      } catch (error) {
        console.log("audio upload error", error);
      }
    }

    if (
      type === "multiple" ||
      type === "drawing" ||
      type === "puzzle" ||
      type === "click" ||
      type === "differences"
    ) {
      data.answers = await handleMultipleAnswer(answers, type);
    } else {
      data.answers = answers;
    }
    if (update) {
      await updateQuestion(questionId, gameId, data);
      modals.openContextModal("success", {
        // title: "Test modal from context",
        centered: true,
        radius: "lg",
        innerProps: {
          modalBody: t("Question updated."),
          onOk: () =>
            history.push(
              withLanguage(`/courses/${courseId}/games/${gameId}/questions`)
            ),
        },
        onCancel: () =>
          history.push(
            withLanguage(`/courses/${courseId}/games/${gameId}/questions`)
          ),
      });
    } else {
      await createQuestion(gameId, data);
      history.push(
        withLanguage(`/courses/${courseId}/games/${gameId}/questions`)
      );
    }

    setLoading(false);
  };

  const feedbackRightDefaultValues = {
    feedbackId: generateRandomId(),
    answer: "",
    isCorrectAnswer: true,
    audio: null,
    media: null,
  };

  const feedbackWrongDefaultValues = {
    feedbackId: generateRandomId(),
    answer: "",
    isCorrectAnswer: false,
    audio: null,
    media: null,
  };
  const form = useForm({
    schema: zodResolver(schema),
    initialValues: {
      statement: "",
      statementAudioFile: null,
      statementMediaFiles: [],
      userQuestionId: "",
      points: "1",
      countdown: "10",
      publishQuestionInGame: true,
      feedbackRight: feedbackRightDefaultValues,
      feedbackWrong: feedbackWrongDefaultValues,
      answers: [],
    },
  });

  const answersValidaton = ({ type, answers }) => {
    if (type === "multiple") {
      if (answers.length < 4 || answers.every((d) => !d.answer && !d.media)) {
        form.setFieldError(
          "answers.answer",
          "at least one Answers is required"
        );
        return false;
      }
      return true;
    }
    // if (type === "open") {
    //   if (
    //     answers.length !== 1 ||
    //     (answers[0].correctAutomatically && !answers[0].answer)
    //   ) {
    //     form.setFieldError("answers.answer", "Automatic answer is required");
    //     return false;
    //   }
    //   return true;
    // }
    return true;
  };
  const setDefaultData = (data, rawData) => {
    setOriginalData(rawData);
    form.setValues(data);
  };
  return { form, loading, onFormSubmit, setDefaultData };
}

export default useQuestionForm;

const handleMultipleAnswer = async (answers, type) => {
  const parsedAnswers = await Promise.all(
    answers.map(async (answer) => {
      console.log({ answer });
      let media = null;
      let mediaCompare = null;
      let audio = null;
      if (answer?.media) {
        const randomId = generateRandomId();
        const iFile = {
          id: randomId,
          type: answer?.media.type,
          name: answer?.media.name,
          size: answer?.media.size,
        };
        media = iFile;
        const res = getResourceInfo(answer?.media);

        try {
          await uploadFileToStorage({
            file: answer.media,
            path: `${res.resource}/${iFile.id}/${res.rKey}`,
            metadata:
              type === "drawing"
                ? {
                    customMetadata: {
                      isDrawing: true,
                    },
                  }
                : null,
          });
        } catch (error) {
          console.log("media upload error", error);
        }
      }

      if (type === "differences" && answer?.mediaCompare) {
        const randomId = generateRandomId();
        const iFile = {
          id: randomId,
          type: answer?.mediaCompare.type,
          name: answer?.mediaCompare.name,
          size: answer?.mediaCompare.size,
        };
        mediaCompare = iFile;
        const res = getResourceInfo(answer?.mediaCompare);

        try {
          await uploadFileToStorage({
            file: answer.mediaCompare,
            path: `${res.resource}/${iFile.id}/${res.rKey}`,
          });
        } catch (error) {
          console.log("mediaCompare upload error", error);
        }
      }

      if (answer?.audio) {
        const randomId = generateRandomId();
        const iFile = {
          id: randomId,
          type: answer?.audio.type,
          name: answer?.audio.name,
          size: answer?.audio.size,
        };
        audio = iFile;
        const res = getResourceInfo(answer?.audio);

        try {
          await uploadFileToStorage({
            file: answer.audio,
            path: `${res.resource}/${iFile.id}/${res.rKey}`,
          });
        } catch (error) {
          console.log("audio upload error", error);
        }
      }
      if (type === "differences") {
        return { ...answer, media, audio, mediaCompare };
      }
      return { ...answer, media, audio };
    })
  );

  return parsedAnswers;
};
