import { createClient } from "contentful";

export default async (contentType) => {
  const client = createClient({
    space: "w4jzu0utgrw6",
    accessToken: "O18Jb7JO4rwRBiQlhLOttRaZT_LiRYw29pnx2yKSoxo",
  });

  const res = await client.getEntries({
    content_type: contentType,
  });

  return res;
};
